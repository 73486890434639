<template>
  <div class="course-detail">
    <div v-if="state=='info'" class="container course_detail">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/course">实验课程</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
      </a-breadcrumb>
      <a-spin :spinning="Loading" size="large" tip="Loading...">
        <div class="bg-white p-4 mb-3">
          <div class="row">
            <div class="col-md-5">
              <div class="img_box">
                <img v-if="data_list.image" :src="`/web/image/hw.course/${courseId}/image_512?t=${new Date().getTime()}`"/>
                <img v-else src="/hw_web/static/src/img/course_default.png"/>
              </div>
            </div>
            <div class="col-md-7">
              <h2>{{ data_list.name }}</h2>
              <p v-if="data_list.english_name">{{ data_list.english_name }}</p>
              <div v-if="data_list.course_nature">
                课程性质：{{ data_list.course_nature }}<span class="mx-3"/>
                适用专业：{{ data_list.applicable_majors }}
              </div>
              <div v-if="(data_list.weekly_hours || 0) != 0 || (data_list.credits || 0) != 0">
                周学时 / 学分：{{ data_list.weekly_hours }} / {{ data_list.credits }}
              </div>
              <div v-if="data_list.teaching_materials">
                使用教材：{{ data_list.teaching_materials }}
              </div>

              <!-- <div class="category mb-0">
                <span v-for="item in data_list.category_ids" :key="item.id">{{ item.name }}</span>
              </div>
              <div class="d-flex">
                难度：{{ data_list.difficulty }}<div class="mx-3"/>课时数：{{ data_list.lesson_nums }}
              </div>
              <div v-if="data_list.start_date">
                开课时间：{{ data_list.start_date }}
              </div>
              <div v-if="data_list.registration_start_date || data_list.registration_end_date">
                报名时间：{{ data_list.registration_start_date }} ~ {{ data_list.registration_end_date }}
              </div>
              <div v-if="data_list.summary" class="summary text-muted mb-3">{{ data_list.summary }}</div> -->

              <div class="text-muted mb-3">
                查看：
                <span>{{ data_list.view_nums }}次</span> ｜
                <img v-if="data_list.is_like" @click="add_like_nums" style="margin-top: -4px;cursor: pointer;"
                     src="/hw_blog/static/src/img/liked.png" height="24" width="24" title="取消点赞"/>
                <img v-else @click="add_like_nums" style="margin-top: -4px;cursor: pointer;"
                     src="/hw_blog/static/src/img/praise.png" height="24" width="24" title="点赞"/>
                <span>&#12288;{{ data_list.like_nums }}</span> ｜
                已收藏：
                <span>{{ data_list.fav_count }}人</span> ｜
                <span v-if="data_list.fav" @click="cancel_fav" style="cursor: pointer;"><span
                    style="color: #f87b6b;">❤</span>已收藏</span>
                <span v-else @click="add_fav" style="cursor: pointer;">❤点击收藏</span>
              </div>

              <div id="product_detail" class="oe_website_sale">
                <template v-if="data_list.joined_course">
                  <div class="text-success">
                    <span style="font-size:14px;color: #378ded;">您已加入此课程，请点击下方课程目录开始学习吧！</span>
                  </div>
                  <a v-if="data_list.is_third_party" :href="data_list.external_links" target="_blank"
                     class="light_yellow btn-lg mt-3">进入学习</a>
                </template>
                <div v-else class="d-grid gap-2" style="width: 300px">
                  <button v-if="data_list.list_price > 0"
                          style="margin-top: 10px;background-color: #ff6607 !important;border-color: #ff6607 !important;"
                          class="btn-primary btn-lg btn-block"
                          @click="join_shop_cart(data_list.id)">购买课程
                    <div class="stat" style="font-size: x-large;">¥ {{ data_list.list_price }}</div>
                  </button>
                  <button v-else type="button" class="btn-primary btn-lg btn-block" @click="join_course">加入课程
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
      <a-card :loading="subLoading" :tab-list="tabListNoTitle" :active-tab-key="noTitleKey"
              @tabChange="key => onTabChange(key)">
        <!--tap1 课程介绍-->
        <div v-if="noTitleKey === 'sub1'">
          <div v-html="data_list.description"></div>
          <p v-if="data_list.course_brief"><span style="display: block;font-size: 16px;color: #8B9EFF;">课程简介
            </span>{{ data_list.course_brief }}</p>
          <p v-if="data_list.course_basic_requirement"><span style="display: block;font-size: 16px;color: #8B9EFF;">教学基本要求
            </span>{{ data_list.course_basic_requirement }}</p>
          <p v-if="data_list.course_assessment_info"><span style="display: block;font-size: 16px;color: #8B9EFF;">考核方式和成绩评定方法
            </span>{{ data_list.course_assessment_info }}</p>
        </div>
        <!--tap2 课程目录-->
        <template v-else-if="noTitleKey === 'sub2'">
          <!--数据加载中骨架-->
          <a-spin :spinning="skeletonActive">
            <a-collapse v-model:activeKey="activeKey" accordion expand-icon-position="end"
                        @change="handleChapterChange">
              <a-collapse-panel v-for="(item, index) in data_list.chapters" :key="item.id"
                                :header="generateHeader(item)">
                <a-skeleton active :loading="skeletonLessonsActive" :paragraph="{ rows: item.lessons.length }">
                  <a-row v-for="(le,y) in item.lessons" :key="le.id" class="accordion">
                    <a-col :span="8" class="line-clamp-1" :title="le.name">{{ le.name }}</a-col>
                    <a-col :span="8">
                      <a-progress v-if="le.video_id" :stroke-color="{'0%': '#108ee9','100%': '#87d068'}"
                                  :percent="le.progress" class="mb-0"/>
                    </a-col>
                    <a-col :span="8">
                      <a-flex justify="flex-end" align="center">
                        <a v-if="le.video_id || le.description" class="course_video" title="视频课程">
                          <img src="/hw_web/static/src/img/course_video_icon.png" height="13" width="13"
                               @click="go_to_study(index,y,'video')"/>
                        </a>
                        <a v-if="le.lab_manual_contents" class="course_experiment" title="实验手册">
                          <img src="/hw_web/static/src/img/course_experiment_icon.png" height="17" width="17"
                               @click="go_to_study(index,y,'lab_manual')"/>
                        </a>
                        <!-- <a v-if="le.exercise_code_num > 0" class="course_case" title="案例实验">
                          <img src="/hw_web/static/src/img/course_case_icon.png" height="13" width="13" 
                              @click="go_to_study(index,y,'code')"/>
                        </a> -->
                        <a v-if="le.exercise_paper_num > 0" class="course_exercise" title="练习刷题">
                          <img src="/hw_web/static/src/img/course_exercise_icon.png" height="17" width="17"
                               @click="go_to_study(index,y,'exercise')"/>
                        </a>
                        <a v-if="le.courseware_num > 0" class="course_courseware" title="本节课件">
                          <img src="/hw_web/static/src/img/course_pdf_icon.png" height="17" width="17"
                               @click="go_to_study(index,y,'courseware')"/>
                        </a>
                      </a-flex>
                    </a-col>
                  </a-row>
                </a-skeleton>
              </a-collapse-panel>
            </a-collapse>
          </a-spin>
          <a-modal v-model:open="openChapterDescription" :title="ChapterDescription.name" @ok="handleOk" width="60vw">
            <p><span style="color: #8B9EFF;margin-right: 10px;">教学目的</span>{{ ChapterDescription.teaching_purpose }}
            </p>
            <p><span
                style="color: #8B9EFF;margin-right: 10px;">教学重点</span>{{ ChapterDescription.teaching_emphasis }}</p>
            <p><span
                style="color: #8B9EFF;margin-right: 10px;">教学难点</span>{{ ChapterDescription.teaching_difficulties }}
            </p>
            <p><span
                style="color: #8B9EFF;margin-right: 10px;">思政融入</span>{{ ChapterDescription.political_education }}
            </p>
            <p><span
                style="color: #8B9EFF;margin-right: 10px;">主要教学方法</span>{{ ChapterDescription.teaching_methods }}
            </p>
            <p><span style="color: #8B9EFF;margin-right: 10px;">教学内容</span></p>
            <div v-html="ChapterDescription.description"/>
          </a-modal>
        </template>
        <!--tap3 课程附件-->
        <template v-else-if="noTitleKey === 'sub3'">
          <p v-for="item in data_list.courseware_ids" :key="item.id">
            <a :href="`/web/image/hw.course.courseware/${item.id}/courseware`" style="color: #000;" target="_blank"
               title="点击查看">{{ item.name }}</a>
            <a :href="`/web/image/hw.course.courseware/${item.id}/courseware`" class="ml-3"
               :download="item.name">下载</a>
          </p>
          <p v-if="data_list.courseware_ids.length == 0" class="text-center my-3">暂无课程附件</p>
        </template>
        <!--tap4 课程评论-->
        <template v-else-if="noTitleKey === 'sub4'">
          <a-row>
            <a-space>
              <p>好评度：{{ data_list.comment_praise }}%</p>
              <p>全部评价：({{ commentTotal }})</p>
              <p>好评：({{ data_list.comment_good_priority }})</p>
              <p>中评：({{ data_list.comment_middle_priority }})</p>
              <p>差评：({{ data_list.comment_bad_priority }})</p>
            </a-space>
          </a-row>
          <!--评论框-->
          <a-form ref="formRef" :model="formComment" autocomplete="off">
            <a-form-item label="评分" name="rate">
              <a-rate v-model:value="formComment.rate"/>
              <span class="ant-rate-text">{{ formComment.rate }}分</span>
            </a-form-item>
            <a-form-item label="评论内容" name="comment" :rules="[{ required: true, message: '请输入评论内容' }]">
              <a-textarea placeholder="请输入评论内容" v-model:value="formComment.comment"
                          :auto-size="{ minRows: 2, maxRows: 5 }"/>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 10, span: 16 }">
              <a-button type="primary" @click="sendComment" :loading="comment_loading">提交</a-button>
            </a-form-item>
          </a-form>
          <!--展示评论内容-->
          <div v-for="comment_rec in commentData" :key="comment_rec.id">
            <a-card :loading="cardLoading">
              <!--父评论-->
              <a-comment>
                <template #actions>
                  <span key="comment-basic-reply-to" @click="reply(comment_rec.id)">回复</span>
                  <a-popconfirm title="确定要删除?" v-if="comment_rec.user_id==userId" @confirm="onCommentDelete(comment_rec.id)">
                    <span key="comment-basic-reply-to">删除</span>
                  </a-popconfirm>
                </template>
                <template #author>{{ comment_rec.user }}</template>
                <template #avatar>
                  <a-avatar :src="`/web/image/res.users/${comment_rec.user_id}/image_512`" :alt="comment_rec.user"/>
                </template>
                <template #content>
                  <a-rate :value="comment_rec.priority" disabled/>
                  <span class="ant-rate-text">{{ comment_rec.priority }}分</span><br/>
                  {{ comment_rec.comment }}
                </template>
                <template #datetime>
                  <a-tooltip :title="dayjs(comment_rec.write_date).format('YYYY-MM-DD HH:mm:ss')">
                    <span>{{ dayjs(comment_rec.write_date).fromNow() }}</span>
                  </a-tooltip>
                </template>
                <!-- 子评论 -->
                <a-comment v-for="sub in comment_rec.comment_ids" :key="sub.id">
                  <template #actions>
                    <a-popconfirm title="确定要删除?" v-if="sub.user_id==userId" @confirm="onCommentDelete(sub.id)">
                      <span key="comment-basic-reply-to">删除</span>
                    </a-popconfirm>
                  </template>
                  <template #author>{{ sub.user }}</template>
                  <template #avatar>
                    <a-avatar :src="`/web/image/res.users/${sub.user_id}/image_512`" :alt="sub.user"/>
                  </template>
                  <template #content>{{ sub.comment }}</template>
                  <template #datetime>
                    <a-tooltip :title="dayjs(sub.write_date).format('YYYY-MM-DD HH:mm:ss')">
                      <span>{{ dayjs(sub.write_date).fromNow() }}</span>
                    </a-tooltip>
                  </template>
                </a-comment>
              </a-comment>
            </a-card>
          </div>
          <!-- 回复子评论 -->
          <a-modal v-model:open="openModal" :title="'回复:'+ formReplay.name" :mask="false">
            <a-form ref="formRef1" :model="formReplay" name="basic" autocomplete="off">
              <a-form-item label="回复内容" name="comment" :rules="[{ required: true, message: '请输入回复内容' }]">
                <a-textarea placeholder="请输入回复内容" v-model:value="formReplay.comment"
                            :auto-size="{ minRows: 2, maxRows: 5 }"/>
              </a-form-item>
            </a-form>
            <template #footer>
              <a-button key="back" @click="openModal = false">取消</a-button>
              <a-button key="submit" type="primary" :loading="replay_loading"
                        @click="sendSubComment">提交
              </a-button>
            </template>
          </a-modal>
          <!--评论的分页-->
          <a-flex style="margin: 10px 0;" justify="center" align="center">
            <a-pagination
                v-model:current="commentPageCurrent"
                :showSizeChanger="commentShowSizeChanger"
                v-model:pageSize="commentPageSize"
                :total="commentTotal"
                :locale="zhCn"
                :pageSizeOptions="commentPageSizeOptions"
                :hideOnSinglePage="true"
            />
          </a-flex>
        </template>
        <!--tap5 直播日历-->
        <template v-else-if="noTitleKey === 'sub5'">
          <a-list :data-source="data_list.training_ids">
            <template #renderItem="{ item }">
              <a-list-item class="px-0 border-0">
                <div class="info">
                  <h5>
                    <a-flex justify="space-between">
                      <router-link :to="`/live/${item.id}`">{{ item.name }}</router-link>
                      <span v-for="category in item.category_ids" :key="category.id">
                        {{ category.name }}
                      </span>
                    </a-flex>
                    <p>{{ item.start_datetime }}</p>
                  </h5>
                  <div class="teacher">
                    <div class="user" v-for="teacher in item.teacher_ids" :key="teacher">
                      <img v-if="teacher.image_1920" :src="`/web/image/hw.teacher/${teacher.id}/image_128`"/>
                      <img v-else src="/hw_web/static/src/img/avatar_live.png" />
                      {{ teacher.name }}
                    </div>
                  </div>
                  <a-flex justify="space-between" align="flex-end">
                    <div v-html="item.description"></div>
                    <!-- 筹备中 -->
                    <!-- <a-button type="primary">观看回放</a-button> -->
                  </a-flex>
                </div>
              </a-list-item>
            </template>
          </a-list>
        </template>
      </a-card>
    </div>
    <div v-if="state=='play'" class="container-fluid course_play">
      <a-flex justify="space-between" align="center" class="top">
        <div>
          <a-breadcrumb v-show="left_menu" separator=">">
            <a-breadcrumb-item>
              <router-link to="/course">实验课程</router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
              <span style="cursor: pointer;" @click="_BackInfo">{{ data_list.name }}</span>
            </a-breadcrumb-item>
            <a-breadcrumb-item>播放</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <a-flex align="center">
          <a-tooltip>
            <template #title>我的笔记</template>
            <img src="@/assets/notes.png" @click="showNoteListModal" width="20" height="20" style="margin-right: 10px;">
          </a-tooltip>
          <a-tooltip>
            <template #title>分屏</template>
            <img src="@/assets/splitScreen.png" @click="onSplitScreen" width="20" height="20">
          </a-tooltip>
        </a-flex>
      </a-flex>
      <a-row :gutter="[16,16]" style="height: calc(100% - 50px);">
        <a-col :xs="24" :md="6" v-show="left_menu">
          <a-card class="menu" :tab-list="tabList" :active-tab-key="tabListKey" @tabChange="key => tabListChange(key)">
            <template v-if="tabListKey === 'tab1'">
              <a-collapse v-model:activeKey="activeKey" accordion expand-icon-position="end" @change="handleChange">
                <a-collapse-panel v-for="(item, index) in data_list.chapters" :key="item.id" :header="item.name">
                  <a-row v-for="(le,y) in item.lessons" :key="le.id" class="lessons">
                    <a-col v-if="lesson_id != le.id" flex="auto" class="line-clamp-1" :title="le.name">{{ le.name }}
                    </a-col>
                    <a-col v-else flex="auto" class="line-clamp-1 active" :title="le.name">{{ le.name }}</a-col>
                    <a-col flex="115px" class="menu_icon">
                      <a-flex justify="flex-end" align="center">
                        <a v-if="le.video_id || le.description" class="course_video" title="视频课程">
                          <img src="/hw_web/static/src/img/course_video_icon.png" height="13" width="13"
                               @click="go_to_play(index,y,'video')"/>
                        </a>
                        <a v-if="le.lab_manual_contents" class="course_experiment" title="实验手册">
                          <img src="/hw_web/static/src/img/course_experiment_icon.png" height="17" width="17"
                               @click="go_to_play(index,y,'lab_manual')"/>
                        </a>
                        <!-- <a v-if="le.exercise_code_num > 0" class="course_case" title="案例实验">
                          <img src="/hw_web/static/src/img/course_case_icon.png" height="13" width="13" 
                              @click="go_to_play(index,y,'code')"/>
                        </a> -->
                        <a v-if="le.exercise_paper_num > 0" class="course_exercise" title="练习刷题">
                          <img src="/hw_web/static/src/img/course_exercise_icon.png" height="17" width="17"
                               @click="go_to_play(index,y,'exercise')"/>
                        </a>
                        <a v-if="le.courseware_num > 0" class="course_courseware" title="本节课件">
                          <img src="/hw_web/static/src/img/course_pdf_icon.png" height="17" width="17"
                               @click="go_to_play(index,y,'courseware')"/>
                        </a>
                      </a-flex>
                    </a-col>
                  </a-row>
                </a-collapse-panel>
              </a-collapse>
            </template>
            <template v-else-if="tabListKey === 'tab2'">
              <div id="lesson_discuss">
                <div v-if="data_list.discuss_content?.length>0" id="lesson_discuss_scroll" class="scroll">
                  <div v-for="comment in data_list.discuss_content" :key="comment.comment_id" class="discuss">
                    <div class="message">
                      <div>
                        <div class="user">
                          <div>
                            <img :src="`/web/image/res.users/${comment.user_info.id}/image_512`"
                                 class="card-img-top mb-3" height="30" widget="30"/>
                          </div>
                          <a :title="comment.user_info.name">{{ comment.user_info.name }}</a>
                        </div>
                        <div class="date">{{ comment.create_time }}</div>
                      </div>
                      <div class="align-items-end">
                        <p style="word-break:break-all">{{ comment.comment_content }}</p>
                        <p class="d-flex align-items-end">
                          <a type="button" class="sub_comment mr-2" @click="create_sub_comment(comment)">
                            <img src="/hw_web/static/src/img/course_reply.png" height="25" widget="25"/>
                          </a>
                          <a-dropdown v-if="comment.is_delete">
                            <a class="ant-dropdown-link" @click.prevent>
                              <img src="/hw_web/static/src/img/blog_option.png" height="22" width="22"/>
                            </a>
                            <template #overlay>
                              <a-menu>
                                <a-menu-item @click="delete_comment(comment)">删除</a-menu-item>
                              </a-menu>
                            </template>
                          </a-dropdown>
                        </p>
                      </div>
                    </div>
                    <div v-if="comment.replay.length > 0" class="reply">
                      <div v-for="sub_comment in comment.replay" :key="sub_comment.comment_id" class="message">
                        <div>
                          <div class="user">
                            <div>
                              <img :src="`/web/image/res.users/${sub_comment.user_info.id}/image_512`"
                                   class="card-img-top mb-3" height="30" widget="30"/>
                            </div>
                            <a :title="sub_comment.user_info.name">{{ sub_comment.user_info.name }}</a>
                          </div>
                          <div class="date">{{ sub_comment.create_time }}</div>
                        </div>
                        <div class="align-items-end">
                          <p style="word-break:break-all;">{{ sub_comment.comment_content }}</p>
                          <a-dropdown v-if="sub_comment.is_delete">
                            <a class="ant-dropdown-link" @click.prevent>
                              <img src="/hw_web/static/src/img/blog_option.png" height="22" width="22"/>
                            </a>
                            <template #overlay>
                              <a-menu>
                                <a-menu-item @click="delete_comment(sub_comment)">删除</a-menu-item>
                              </a-menu>
                            </template>
                          </a-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else id="lesson_discuss_scroll" class="scroll" style="text-align: center">
                  <a>暂无讨论！</a>
                </div>
                <div class="send">
                  <input type="text" class="form-control" id="discuss_content" placeholder="请输入讨论内容"
                         @keydown.enter="handleEnterKey"/>
                  <button class="btn" @click="send_comment(e)">
                    <img src="/hw_web/static/src/img/course_send.png" height="30" widget="30"/>
                  </button>
                </div>
              </div>
            </template>
          </a-card>
        </a-col>
        <a-col :xs="24" :md="right_width">
          <div class="play">
            <div class="split" v-show="!left_menu && (video_url || lab_manual_contents || courseware)">
              <div id="split-0">
                <template v-if="!left_menu">
                  <div class="absolute">
                    左侧屏：
                    <a-tooltip v-if="video_url">
                      <template #title>视频课程</template>
                      <img :src="letfOption==='video' ? require('@/assets/video1.png') : require('@/assets/video0.png')"
                           @click="onLeftSwitch('video')" width="20" height="20">
                    </a-tooltip>
                    <a-tooltip v-if="lab_manual_contents">
                      <template #title>实验手册</template>
                      <WalletTwoTone v-if="letfOption == 'lab_manual'" style="font-size: 20px;"
                                     @click="onLeftSwitch('lab_manual')"/>
                      <WalletOutlined v-else style="font-size: 20px;" @click="onLeftSwitch('lab_manual')"/>
                    </a-tooltip>
                    <a-tooltip>
                      <template #title>本节课件</template>
                      <img
                          :src="letfOption==='courseware' ? require('@/assets/courseware1.png') : require('@/assets/courseware0.png')"
                          @click="onLeftSwitch('courseware')" width="20" height="20">
                    </a-tooltip>
                  </div>
                  <template v-if="video_url">
                    <video v-show="letfOption == 'video'" id="course_play_video" style="width: 100%;" preload="auto"
                           controlslist="nodownload" controls="controls">
                      <source :src="video_url" type="video/mp4"/>
                      抱歉，您的浏览器不支持内嵌视频，请您更换浏览器进行播放！
                      <p class="vjs-no-js">要观看此视频，请启用 JavaScript，并考虑升级到
                        <a href="https://videojs.com/html5-video-support/" target="_blank">支持 HTML5 视频的版本</a></p>
                    </video>
                    <div v-show="letfOption == 'video'" class="description">
                      <div>
                        <a-collapse v-model:activeKey="descriptionActiveKey" :bordered="false" collapsible="header">
                          <a-collapse-panel key="1" header="本节知识点">
                            <div v-html="description"></div>
                          </a-collapse-panel>
                        </a-collapse>
                      </div>
                    </div>
                  </template>
                  <div v-show="letfOption == 'lab_manual'" class="courseware p-4">
                    <RichEditor v-if="lab_manual_contents" height="700" editMode="readonly"
                                v-model="lab_manual_contents"/>
                  </div>
                  <div v-show="letfOption == 'courseware'" class="courseware p-4">
                    <iframe v-if="state == 'play'" :src="`/courseware/lesson_${lesson_id}`">
                      当前浏览器不支持PDF预览，请下载到本地后观看：
                      <a :href="`/courseware/lesson_${lesson_id}`">下载PDF</a>
                    </iframe>
                  </div>
                </template>
              </div>
              <div id="split-1">
                <template v-if="!left_menu">
                  <div class="absolute">
                    右侧屏：
                    <div
                        v-if="(app_id && data_list.environment_ids.length == 0) || (data_list.app_id && data_list.environment_ids.length == 0)"
                        style="height: 20px">
                      <a-tooltip>
                        <template #title>创建实验环境</template>
                        <RightSquareOutlined style="font-size: 20px;" v-if="openCreateApp" @click="handleOkCreateApp"/>
                      </a-tooltip>
                    </div>
                    <a-tooltip v-for="item in data_list.environment_ids" :key="item.id">
                      <template #title>{{ item.custom_name }}</template>
                      <img
                          :src="righrtOption=='environment' && environmentID==item.id ? require('@/assets/environment1.png') : require('@/assets/environment0.png')"
                          @click="selectEnvironmentID(item.id)" width="20" height="20">
                    </a-tooltip>
                    <a-tooltip>
                      <template #title>本节课件</template>
                      <img
                          :src="righrtOption==='courseware' ? require('@/assets/courseware1.png') : require('@/assets/courseware0.png')"
                          @click="onRightSwitch('courseware')" width="20" height="20">
                    </a-tooltip>
                  </div>
                  <iframe v-for="item in data_list.environment_ids" :key="item.id"
                          v-show="righrtOption=='environment' && environmentID==item.id"
                          :src="environmentURL(item)" width="100%" style="height: 80vh;" frameborder="0"/>
                  <div
                      v-if="(app_id && data_list.environment_ids.length == 0) || (data_list.app_id && data_list.environment_ids.length == 0)"
                      v-show="righrtOption == 'environment'" class="h-100 p-4">
                    <a-flex class="h-100" justify="center" align="center" v-if="openCreateApp">
                      <a-button type="primary" size="large" @click="handleOkCreateApp">创建实验环境</a-button>
                    </a-flex>
                  </div>
                  <div v-show="righrtOption == 'courseware'" class="courseware p-4">
                    <iframe v-if="state == 'play'" :src="`/courseware/lesson_${lesson_id}`" style="max-height: 80vh;">
                      当前浏览器不支持PDF预览，请下载到本地后观看：
                      <a :href="`/courseware/lesson_${lesson_id}`">下载PDF</a>
                    </iframe>
                  </div>
                </template>
              </div>
            </div>
            <template v-if="!left_menu && !video_url && !lab_manual_contents && !courseware">
              <div class="absolute">
                右侧屏：
                <div
                    v-if="(app_id && data_list.environment_ids.length == 0) || (data_list.app_id && data_list.environment_ids.length == 0)"
                    style="height: 20px">
                  <a-tooltip>
                    <template #title>创建实验环境</template>
                    <RightSquareOutlined style="font-size: 20px;" v-if="openCreateApp" @click="handleOkCreateApp"/>
                  </a-tooltip>
                </div>
                <a-tooltip v-for="item in data_list.environment_ids" :key="item.id">
                  <template #title>{{ item.custom_name }}</template>
                  <img
                      :src="righrtOption=='environment' && environmentID==item.id ? require('@/assets/environment1.png') : require('@/assets/environment0.png')"
                      @click="selectEnvironmentID(item.id)" width="20" height="20">
                </a-tooltip>
                <a-tooltip>
                  <template #title>本节课件</template>
                  <img
                      :src="righrtOption==='courseware' ? require('@/assets/courseware1.png') : require('@/assets/courseware0.png')"
                      @click="onRightSwitch('courseware')" width="20" height="20">
                </a-tooltip>
              </div>
              <iframe v-for="item in data_list.environment_ids" :key="item.id"
                      v-show="righrtOption=='environment' && environmentID==item.id"
                      :src="environmentURL(item)" width="100%" style="height: 80vh;" frameborder="0"/>
              <div
                  v-if="(app_id && data_list.environment_ids.length == 0) || (data_list.app_id && data_list.environment_ids.length == 0)"
                  v-show="righrtOption == 'environment'" class="h-100 p-4">
                <a-flex class="h-100" justify="center" align="center" v-if="openCreateApp">
                  <a-button type="primary" size="large" @click="handleOkCreateApp">创建实验环境</a-button>
                </a-flex>
              </div>
              <div v-show="righrtOption == 'courseware'" class="courseware p-4">
                <iframe v-if="state == 'play'" :src="`/courseware/lesson_${lesson_id}`" style="max-height: 80vh;">
                  当前浏览器不支持PDF预览，请下载到本地后观看：
                  <a :href="`/courseware/lesson_${lesson_id}`">下载PDF</a>
                </iframe>
              </div>
            </template>
            <template v-if="left_menu">
              <template v-if="Mode == 'video'">
                <video v-if="video_url" id="course_play_video" style="width: 100%;" preload="auto" controlslist="nodownload"
                       controls="controls">
                  <source :src="video_url" type="video/mp4"/>
                  抱歉，您的浏览器不支持内嵌视频，请您更换浏览器进行播放！
                  <p class="vjs-no-js">要观看此视频，请启用 JavaScript，并考虑升级到
                    <a href="https://videojs.com/html5-video-support/" target="_blank">支持 HTML5 视频的版本</a></p>
                </video>
                <div v-if="description" class="description">
                  <div>
                    <a-collapse v-model:activeKey="descriptionActiveKey" :bordered="false" collapsible="header">
                      <a-collapse-panel key="1" header="本节知识点">
                        <div v-html="description"></div>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </template>
              <template v-if="Mode == 'code'">
                <div class="caseCode">
                  <a-flex justify="space-between" align="center">
                    <a-flex align="center">切换语言：
                      <a-select
                          v-model:value="ModeValue"
                          label-in-value
                          style="width: 120px"
                          :options="ModeOptions"
                          @change="changeMode"
                      ></a-select>
                    </a-flex>
                    <a-flex align="center">切换主题：
                      <a-select
                          v-model:value="themeValue"
                          label-in-value
                          :options="themeOptions"
                          @change="changeTheme"
                      ></a-select>
                    </a-flex>
                  </a-flex>
                  <Codemirror
                      v-model="code"
                      placeholder="Code gose here..."
                      :style="codeOptions.style"
                      :mode="codeOptions.mode"
                      :theme="codeOptions.theme"
                      :spellcheck="codeOptions.spellcheck"
                      :autofocus="codeOptions.autofocus"
                      :indent-with-tab="codeOptions.indentWithTab"
                      :tabSize="codeOptions.tabSize"
                      :extensions="codeOptions.extensions"
                  />
                  <div class="row">
                    <div class="col-md p-0">
                      <h5 class="text-center m-0">测试输入：</h5>
                      <div class="px-3">
                        <div v-for="input in data_list.judge_server.test_inputs" :key="input">
                          {{ input }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md p-0">
                      <h5 class="text-center m-0">预计运行结果：</h5>
                      <div class="px-3">
                        <div v-for="result in data_list.judge_server.expected_running_results" :key="result">
                          {{ result }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md p-0">
                      <h5 class="text-center m-0">实际运行结果：</h5>
                      <div class="px-3">
                        <div v-for="result in data_list.judge_server.actual_running_results" :key="result">
                          {{ result }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button class="btn" @click="onclick_previous_exercise_code">上一题</button>
                    <button class="btn" @click="onclick_next_exercise_code">下一题</button>
                    <input class="btn" type="button" id="btn-test" @click="onclick_test" value="自行测试"/>
                    <input class="btn btn-primary" type="button" id="btn-save" @click="onclick_save" value="提交评分"/>
                  </div>
                </div>
              </template>
              <template v-if="Mode == 'exercise'">
                <div class="exercise p-4">
                  <a-flex :justify="'space-between'" :align="'center'" style="margin-bottom: 20px;">
                    {{ data_list1.name }}
                    <a-affix :offset-top="10">
                      <a-button v-if="editorMode == 'create' && data_list1.question_count != 0" type="primary"
                                class="submitHomework" @click="submitHomework">提交练习
                      </a-button>
                      <a-button v-else type="primary" class="saveHomework" @click="_Back">返回</a-button>
                    </a-affix>
                  </a-flex>
                  <a-spin :spinning="spinning" size="large" tip="Loading...">
                    <a-flex :justify="'center'" class="mb-3">
                      学生姓名：{{ data_list1.user_name }}
                      <div class="mx-3"></div>
                      学生编号：{{ data_list1.student_barcode }}
                    </a-flex>
                    <a-flex v-if="editorMode == 'readonly'" :justify="'center'" class="mb-3">
                      总分：{{ data_list1.total }}
                      <div class="mx-3"></div>
                      及格分：{{ data_list1.pass_score }}
                      <div class="mx-3"></div>
                      得分：{{ data_list1.score }}
                      <div class="mx-3"></div>
                      结果：
                      <a-typography-text v-if="data_list1.exercise_result == '通过'" type="success">
                        {{ data_list1.exercise_result }}
                      </a-typography-text>
                      <a-typography-text v-else-if="data_list1.exercise_result == '未提交'" type="warning">
                        {{ data_list1.exercise_result }}
                      </a-typography-text>
                      <a-typography-text v-else-if="data_list1.exercise_result == '未通过'" type="danger">
                        {{ data_list1.exercise_result }}
                      </a-typography-text>
                      <a-typography-text v-else>{{ data_list1.exercise_result }}</a-typography-text>
                    </a-flex>
                    <a-form ref="exerciseformRef" :model="create_list" :label-col="{style: {width: '150px'} }"
                            :disabled="editorMode == 'readonly'">
                      <div v-for="(item,index) in data_list1.question_ids" :key="item.id" class="mb-3">
                        <a-flex justify="space-between">
                          <a-flex>{{ index + 1 }}、
                            <div style="margin-bottom: -1em;" v-html="item.name"></div>
                          </a-flex>
                          <a-flex>{{ item.type }}
                            <div class="mx-2"/>
                            {{ item.score }}分
                          </a-flex>
                        </a-flex>
                        <a-flex v-if="editorMode == 'readonly'" justify="flex-end">
                          答案：
                          <template v-if="item.type == 'QA'">
                            <div v-html="item.qa_solution"/>
                          </template>
                          <template v-else-if="item.type == 'CODE'">
                            <div v-html="item.code_solution"/>
                          </template>
                          <template v-else>{{ item.solution }}</template>
                          <div class="mx-3"></div>
                          得分：{{ create_list[index].answer_score }}
                        </a-flex>
                        <a-radio-group v-if="item.type == '单选'" v-model:value="create_list[index].answer"
                                       :style=style>
                          <div v-for="option in options" :key="option" style="cursor: initial;">
                            <a-radio v-if="`option_${option.toLowerCase()}` in data_list1.question_ids[index]"
                                     :value="option">
                              <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                                         v-html="data_list1.question_ids[index]['option_' + option.toLowerCase()]"></span>
                              </a-flex>
                            </a-radio>
                          </div>
                        </a-radio-group>
                        <a-checkbox-group v-else-if="item.type == '多选'" v-model:value="create_list[index].answer"
                                          :style=style>
                          <div v-for="option in options" :key="option" style="cursor: initial;">
                            <a-checkbox v-if="`option_${option.toLowerCase()}` in data_list1.question_ids[index]"
                                        :value="option">
                              <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                                         v-html="data_list1.question_ids[index]['option_' + option.toLowerCase()]"></span>
                              </a-flex>
                            </a-checkbox>
                          </div>
                        </a-checkbox-group>
                        <a-form-item v-else-if="item.type == '填空'">
                          <a-textarea v-model:value="create_list[index].answer"/>
                        </a-form-item>
                        <a-radio-group v-else-if="item.type == '判断'" v-model:value="create_list[index].answer"
                                       :style=style>
                          <div v-for="option in ['A','B']" :key="option" style="cursor: initial;">
                            <a-radio v-if="`option_${option.toLowerCase()}` in data_list1.question_ids[index]"
                                     :value="option">
                              <a-flex>{{ option }}、<span style="margin-bottom: -1em;"
                                                         v-html="data_list1.question_ids[index]['option_' + option.toLowerCase()]"></span>
                              </a-flex>
                            </a-radio>
                          </div>
                        </a-radio-group>
                        <a-form-item v-else-if="item.type == '问答'">
                          <RichEditor v-model="create_list[index].answer" :height="301" :editMode="editorMode"/>
                        </a-form-item>
                        <a-form-item v-else-if="item.type == '编程'">
                          <RichEditor v-model="create_list[index].answer" :height="301" :editMode="editorMode"/>
                        </a-form-item>
                      </div>
                      <a-empty v-if="data_list1.question_count == 0" :image="simpleImage"/>
                    </a-form>
                  </a-spin>
                </div>
              </template>
              <template v-if="Mode == 'courseware'">
                <div class="courseware p-4">
                  <iframe v-if="state == 'play'" :src="`/courseware/lesson_${lesson_id}`" style="max-height: 80vh;">
                    当前浏览器不支持PDF预览，请下载到本地后观看：
                    <a :href="`/courseware/lesson_${lesson_id}`">下载PDF</a>
                  </iframe>
                </div>
              </template>
              <template v-if="Mode == 'lab_manual'">
                <div class="p-4">
                  <RichEditor v-if="lab_manual_contents" height="700" editMode="readonly"
                              v-model="lab_manual_contents"/>
                </div>
              </template>
            </template>
          </div>
        </a-col>
      </a-row>
      <!-- 我的笔记 -->
      <a-modal v-model:open="openNoteListModal" title="我的笔记" style="width: 70vw" :footer="null">
        <a-flex justify="flex-end">
          <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
          <a-button type="primary" @click="openCreateNoteModal = true" style="margin-left: 16px;">新建笔记</a-button>
        </a-flex>
        <a-modal v-model:open="openCreateNoteModal" title="新建笔记" style="width: 60vw;"
                 :confirm-loading="confirmCreateNote" @ok="handleOkCreateNoteModal">
          <a-form ref="noteformRef" style="margin-top: 30px;" :model="createNoteForm" autocomplete="off">
            <a-form-item label="笔记标题：" name="title" :rules="[{ required: true, message: '请输入笔记标题！'}]">
              <a-input v-model:value="createNoteForm.title" :maxlength="20" placeholder="请输入笔记标题（必填）！"/>
            </a-form-item>
            <a-form-item label="笔记内容：" name="description" :rules="descriptionRules">
              <RichEditor :height="301" :editMode="noteEditorMode" v-model="createNoteForm.description"/>
            </a-form-item>
          </a-form>
        </a-modal>
        <a-table :columns="noteColumns" :data-source="noteData" :pagination="pagination" style="margin-top: 10px;">
          <template #bodyCell="{ column,index }">
            <template v-if="column.dataIndex == 'action'">
              <a-typography-text style="color: #1677ff;cursor: pointer;" @click="showDrawer(index)">
                查看详情
              </a-typography-text>
            </template>
          </template>
        </a-table>
      </a-modal>
      <!--笔记详情信息-->
      <a-drawer width="640" placement="right" :closable="true" :open="open" @close="onClose">
        <template #extra>
          <template v-if="!edit">
            <a-button style="margin-right: 8px" @click="edit = true" type="primary">修改</a-button>
            <a-button style="margin-right: 8px" @click="openDeleteModal = true" danger>删除</a-button>
            <a-button style="margin-right: 8px" @click="onClose">关闭</a-button>
          </template>
          <template v-else>
            <a-button style="margin-right: 8px" @click="onEdit" type="primary">确认修改</a-button>
            <a-button style="margin-right: 8px" @click="onCancel">取消</a-button>
          </template>
        </template>
        <h5>标题：
          <template v-if="!edit">{{ drawerData.title }}</template>
          <a-input v-else v-model:value="drawerData.title"/>
        </h5>
        <h5>创建者：{{ drawerData.creator }}</h5>
        <h5>创建时间：{{ drawerData.create_date }}</h5>
        <h5>内容：</h5>
        <div v-if="!edit" v-html="drawerData.description"></div>
        <RichEditor v-else :height="301" :editMode="noteEditorMode" v-model="drawerData.description"/>
      </a-drawer>
      <a-modal v-model:open="openDeleteModal" title="删除笔记" @ok="onDelete">
        <p>确定删除？ 删除ID：{{ drawerData.id }}</p>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
let state = ref('info')
let skeletonActive = ref(false);
let skeletonLessonsActive = ref(false);
import {useRouter} from "vue-router";
// 我的笔记
import {h, markRaw, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import chapterIcon from '@/assets/icon/chapter_icon.png';
import {RightSquareOutlined, WalletOutlined, WalletTwoTone} from '@ant-design/icons-vue';
import {isFrontendDeploy} from "@/utils/config_utils";
import Split from "@/utils/split";
import {getFailedMessage, getResponseData, jsonOwlRPC, jsonRPC} from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import RichEditor from "@/components/RichEditor.vue";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'; // 导入中文语言包
import relativeTime from 'dayjs/plugin/relativeTime';
import {zhCn} from "@/utils/zhCn";
// 练习
import {Empty, message, notification, Modal} from "ant-design-vue";
// 编程案例
import {Codemirror} from 'vue-codemirror';
// 主题
import {duotoneDark, duotoneLight, gruvboxDark, gruvboxLight, vscodeDark} from '@uiw/codemirror-themes-all';
// 模式
import {python} from "@codemirror/lang-python";
import {javascript} from "@codemirror/lang-javascript";
import {json} from "@codemirror/lang-json";
import {xml} from "@codemirror/lang-xml";
import {html} from "@codemirror/lang-html";
import {css} from "@codemirror/lang-css";
import {java} from "@codemirror/lang-java";
import {cpp} from "@codemirror/lang-cpp";

const router = useRouter();
const courseId = router.currentRoute.value.params.course_id;

function generateHeader(item) {
  return h('div', [
    h('span', item.name),
    h('img', {
      src: chapterIcon,
      style: 'height: 20px; width: 20px; margin-left: 8px; cursor: pointer;',
      onClick: (e) => handleIconClick(e, item)
    }),
  ]);
}

const openChapterDescription = ref(false);
let ChapterDescription = ref({
  "id": '',
  "name": '',
  "english_name": '',
  "teaching_purpose": '',
  "teaching_emphasis": '',
  "teaching_difficulties": '',
  "political_education": '',
  "teaching_methods": '',
  "description": '',
});

function handleIconClick(e, chapter) {
  e.preventDefault();
  e.stopPropagation(); // 阻止事件冒泡
  openChapterDescription.value = true;
  ChapterDescription.value = chapter
}

const handleOk = e => {
  logDebug(e);
  openChapterDescription.value = false;
};

dayjs.extend(relativeTime);
dayjs.locale('zh-cn');

const updateAppStatusIntervalMS = 5000
const updateAppStatusTryTimes = 60

const openCreateApp = ref(true)
const handleOkCreateApp = function () {
  openCreateApp.value = false
  notification.info({
    message: '创建实验环境',
    description: '开始创建创建实验环境',
  });
  jsonRPC({
    url: "/api/experiment/course/create_app",
    params: {
      course_id: courseId,
      app_id: app_id.value,
      app_type: app_type.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data);
      data_list.environment_ids = data.environment_ids;
      data_list.app_environment_ids = data.app_environment_ids;
      if (data.app_environment_ids.length > 0) {
        notification.success({
          message: '创建实验环境成功',
          description: '实验环境入口将在准备就绪后展现出来',
        });
        setTimeout(function () {
          updateAppStatus(updateAppStatusTryTimes)
        }, updateAppStatusIntervalMS)
      }
    },
    fail(error) {
      try {
        try {
          let errMsg = JSON.parse(error);
          let user = errMsg.user || '';
          let message = errMsg.message || '资源不足';
          let details = errMsg.details || '';
          Modal.error({
            title: "创建实验环境失败",
            content: (
              <div>
                <div>{user}</div>
                <div style={{ textIndent: '2em' }}>{message}</div>
                <div>{details}</div>
              </div>
            ),
            width: '60%', // 设置宽度为 60%
            closable: true, // 右上角关闭按钮
            onCancel: () => {
              Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
            },
            footer: () => (
              <div class="d-flex justify-content-center my-3">
                <button type="button" class="btn btn-primary" onClick={async () => {
                    router.push('/cost/topup')
                    Modal.destroyAll(); // 关闭所有弹窗
                  }}>补充资源</button>
              </div>
            ),
          });
        } catch (err) {
          logError("查询失败: 无法解析错误信息", err);
          Modal.error({
            title: "创建实验环境失败",
            content: (
              <div style={{ textIndent: '2em' }}>{JSON.stringify(getFailedMessage(error))}</div>
            ),
            width: '60%', // 设置宽度为 60%
            closable: true, // 右上角关闭按钮
            onCancel: () => {
              Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
            },
            footer: () => (
              <div class="d-flex justify-content-center my-3">
                <button type="button" class="btn btn-primary" onClick={() => {
                    Modal.destroyAll(); // 关闭所有弹窗
                  }}>关闭</button>
              </div>
            ),
          });
        }
      } catch (er) {
        logError("部署创建过程中发生未捕获的错误：", er);
        message.error('部署创建失败，请稍后重试。')
      }
    },
  }).then(function () {
    openCreateApp.value = true
  })
}

const updateAppStatus = (tryCount) => {
  if (tryCount <= 0) {
    return
  }
  try {
    let allReady = true
    const rpcResult = jsonRPC({
      url: "/vue/console/app/status",
      params: {
        app_environment_ids: data_list.app_environment_ids
      },
      success(res) {
        const data = getResponseData(res)
        logDebug(`获取成功`, data);
        for (let app_env_id of data.records) {
          if (app_env_id.app_env_status !== 'Running') {
            allReady = false
          }
        }
      },
      fail(error) {
        logError(`查询失败, `, error);
        allReady = false
      },
    });
    rpcResult.then(function () {
      if (!allReady) {
        setTimeout(function () {
          updateAppStatus(tryCount - 1)
        }, updateAppStatusIntervalMS)
      } else {
        message.success(`创建成功`, 2);
        environmentID.value = data_list.environment_ids[0].id
      }
    })
  } catch (e) {
    logError(`updateAppStatus failed`, e)
  }
}
const environmentURL = (item) => {
  const search = `?desktop_t=${item.environment_url}`;
  return isFrontendDeploy() ? search : '/hw_frontend/static/app/index.html' + search;
};

const data_list = reactive({
  chapters: [],
  comments_num: 0,
  courseware_ids: [],
  environment_ids: [],
  app_environment_ids: [],
  training_ids: [],
  judge_server: {
    test_inputs: [],
    actual_running_results: [],
  },
  result: {}
});
const environmentID = ref(0);
const Loading = ref(true);
const descriptionActiveKey = ref([])
let course_environment_ids = [];
let course_app_environment_ids = [];

//获取课程基本信息
async function fetchDataCourseBaseInfo() {
  await jsonRPC({
    url: "/api/course/base_info",
    params: {
      course_id: courseId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取课程基本信息成功`, data);
      Object.assign(data_list, data);
      course_environment_ids = data_list.environment_ids;
      course_app_environment_ids = data_list.app_environment_ids;
      // 更新课程评论tab页内容
      const sub4Tab = tabListNoTitle.find(item => item.key === 'sub4');
      if (sub4Tab) {
        sub4Tab.tab = `课程评论(${data_list.comments_num}条)`;
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(`查询失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  }).then(() => {
    Loading.value = false;
    subLoading.value = false;
  })
}

// 获取课程目录
async function fetchDataCourseChaptersInfo() {
  skeletonActive.value = true;
  await jsonRPC({
    url: "/api/course/chapters/list",
    params: {
      course_id: courseId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取课程目录成功`, data);
      Object.assign(data_list, data);
      skeletonActive.value = false;
      logDebug(`获取课程目录成功 data_list`, data_list);
    },
    fail(error) {
      logError(`获取课程目录失败, `, error);
      message.error(`获取课程目录失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })
}

function handleChapterChange(e) {
  logDebug(e)
  if (e) {
    fetchDataCourseChaptersLessonsInfo(e)
  }
}

// 获取课程目录中的节
async function fetchDataCourseChaptersLessonsInfo(chapterId) {
  skeletonLessonsActive.value = true
  await jsonRPC({
    url: "/api/course/chapters/lessons/list",
    params: {
      course_id: courseId,
      chapter_id: chapterId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取课程目录中的节成功`, data);
      const chapter = data_list.chapters.find(chapter => String(chapter.id) === String(chapterId));
      logDebug(`获取课程chapter chapter`, chapter);
      if (chapter) {
        chapter.lessons = data.lessons;
        logDebug(`章节 ${chapterId} 的lessons更新成功`, chapter.lessons);
      } else {
        logError(`未找到章节ID为 ${chapterId} 的章节`);
      }
      skeletonLessonsActive.value = false
      logDebug(`获取课程目录中的节成功 data_list`, data_list);
    },
    fail(error) {
      logError(`获取课程目录中的节失败, `, error);
      message.error(`获取课程目录中的节失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })
}

// 获取课件列表
async function fetchDataCourseCoursewareInfo() {
  await jsonRPC({
    url: "/api/course/courseware_ids/info",
    params: {
      course_id: courseId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取课件列表成功`, data);
      Object.assign(data_list, data);
      logDebug(`获取课件列表 data_list`, data_list);
    },
    fail(error) {
      logError(`获取课件列表失败, `, error);
      message.error(`获取课件列表失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })
}

function fetchData() {
  jsonRPC({
    url: "/vue/desktop/course/detail",
    params: {
      course_id: courseId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(`查询失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })
}

onMounted(async () => {
  await fetchDataCourseBaseInfo()
});

function fetchComment() {
  cardLoading.value = true;
  jsonRPC({
    url: "/vue/course/detail/comment",
    params: {
      course_id: courseId,
      page_index: commentPageCurrent.value,
      page_size: commentPageSize.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
      commentData.value = data.records;
      commentTotal.value = data.record_count;
      const sub4Tab = tabListNoTitle.find(item => item.key === 'sub4');
      if (sub4Tab) {
        sub4Tab.tab = `课程评论(${commentTotal.value}条)`;
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    cardLoading.value = false;
  });
}

function fetchTraining() {
  jsonRPC({
    url: "/vue/course/detail/training",
    params: {
      course_id: courseId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}

const activeKey = ref([]);
const tabListNoTitle = [
  {
    key: 'sub1',
    tab: '课程介绍',
  },
  {
    key: 'sub2',
    tab: '课程目录',
  },
  {
    key: 'sub5',
    tab: '直播日历',
  },
  {
    key: 'sub3',
    tab: '课程附件',
  },
  {
    key: 'sub4',
    tab: `课程评论(${data_list.comments_num}条)`,
  },
];
const noTitleKey = ref('sub1');
const subLoading = ref(true);
const onTabChange = async value => {
  subLoading.value = true;
  noTitleKey.value = value;

  switch (noTitleKey.value) {
    case 'sub1':
      break;
    case 'sub2':
      if (data_list.chapters.length == 0) {
        await fetchDataCourseChaptersInfo();
      }
      break;
    case 'sub3':
      if (data_list.courseware_ids.length == 0) {
        await fetchDataCourseCoursewareInfo();
      }
      break;
    case 'sub4':
      if (commentTotal.value == 0) {
        fetchComment();
      }
      break;
    case 'sub5':
      if (data_list.training_ids.length == 0) {
        fetchTraining();
      }
      break;
  }

  subLoading.value = false;
};

const formRef = ref();
const formRef1 = ref();
const comment_loading = ref(false);
const replay_loading = ref(false);
const commentData = ref([])
const openModal = ref(false);
const cardLoading = ref(true);
const commentTotal = ref(0);
const commentShowSizeChanger = ref(true);
const commentPageCurrent = ref(1);
const commentPageSize = ref(8);
const commentPageSizeOptions = ref(Array.from({length: 4}, (_, index) => (commentPageSize.value * (index + 1)).toString()));
watch([commentPageSize, commentPageCurrent], fetchComment);
const formComment = reactive({
  rate: 5,
  comment: '',
});
const formReplay = reactive({
  name: '',
  comment_id: 0,
  comment: '',
});
let sendComment = () => {
  formRef.value.validate()
      .then(() => {
        comment_loading.value = true;
        jsonRPC({
          url: "/vue/course/detail/comment/add",
          params: {
            course_id: courseId,
            input_score: formComment.rate,
            input_comment: formComment.comment,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`获取成功`, data);
            commentPageCurrent.value = 1;
            fetchComment();
          },
          fail(error) {
            logError(`评论失败, `, error);
          },
        }).then(() => {
          formRef.value.resetFields();
          comment_loading.value = false;
        });
        return
      })
      .catch(error => {
        logDebug('error', error);
        return
      });
}
const app_id = ref(false)
const app_type = ref(false)
let go_to_study = (index, y, mode) => {
  if (!data_list.joined_course) {
    message.error('请先加入课程')
    return
  }
  state.value = 'play'
  Mode.value = mode
  let le = data_list.chapters[index].lessons[y];
  app_id.value = le.app_id;
  app_type.value = le.app_type;
  if (le.app_environment_ids.length > 0) {
    data_list.environment_ids = le.environment_ids;
    data_list.app_environment_ids = le.app_environment_ids;
  } else {
    data_list.environment_ids = course_environment_ids;
    data_list.app_environment_ids = course_app_environment_ids;
  }
  lab_manual_contents.value = false;
  if (le.lab_manual_contents) {
    lab_manual_contents.value = le.lab_manual_contents;
  }
  courseware.value = false;
  if (le.courseware_num.length > 0) {
    courseware.value = true;
  }
  chapter_id.value = data_list.chapters[index].id;
  activeKey.value = data_list.chapters[index].id;
  lesson_id.value = le.id;
  video_url.value = le.video_url;
  video_id.value = le.video_id;
  description.value = le.description;
  workId.value = le.exercise_paper_id;
  if (Mode.value == 'video') {
    fetchVideoData();
    let video = document.getElementById("course_play_video");
    if (video) {
      video.load()
    }
  }
  if (Mode.value == 'code') {
    fetchCodeData();
  }
  if (Mode.value == 'exercise') {
    fetchData1();
  }
  getLessonComments();
}
let add_like_nums = () => {
  jsonOwlRPC({
    url: "/add_course_like_nums",
    params: {
      course_id: courseId
    },
    success(res) {
      data_list.like_nums = res.like_nums;
      data_list.is_like = res.is_like;
    },
    fail(error) {
      logError(`获取数据错误, `, error)
    },
  })
}
let cancel_fav = () => {
  jsonRPC({
    url: "/api/fav/delete",
    params: {
      id: courseId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.fav = false;
      data_list.fav_count -= 1
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
let add_fav = () => {
  jsonRPC({
    url: "/api/fav/create",
    params: {
      id: courseId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.fav_id = data;
      data_list.fav = true;
      data_list.fav_count += 1
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
let join_shop_cart = (training_id) => {
  jsonOwlRPC({
    url: `/console/add_course/shop_cart/${training_id}`,
    params: {},
    success() {
      router.push('/shop')
    },
    fail(error) {
      logError(`获取数据错误, `, error)
    },
  })
}
let join_course = () => {
  jsonOwlRPC({
    url: `/join_course`,
    params: {
      course_id: courseId,
    },
    success(res) {
      logDebug(`获取成功`, res);
      message.success("加入成功！")
      fetchData();
    },
    fail(error) {
      logError(`获取数据错误, `, error)
      message.error(`加入课程失败，请稍后再试`);
    },
  })
}
let reply = e => {
  formReplay.comment_id = e;
  formReplay.name = commentData.value.find(obj => obj.id === e).user;
  openModal.value = true;
}
const userId = sessionStorage.getItem('user_id');
const onCommentDelete = (e)=>{
  jsonRPC({
    url: "/vue/course/detail/comment/delete",
    params: {
      comment_id: e,
    },
    success() {
      commentPageCurrent.value = 1;
      fetchComment();
      message.success("删除成功！")
    },
    fail(error) {
      logError(`删除失败, `, error);
      message.error("删除失败！")
    },
  })
}
let sendSubComment = () => {
  formRef1.value.validate()
      .then(() => {
        replay_loading.value = true;
        jsonRPC({
          url: "/vue/course/detail/comment/add",
          params: {
            comment_id: formReplay.comment_id,
            input_comment: formReplay.comment,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`获取成功`, data);
            fetchComment();
          },
          fail(error) {
            logError(`评论失败, `, error);
          },
        }).then(() => {
          formRef1.value.resetFields();
          replay_loading.value = false;
          openModal.value = false;
        });
        return
      })
      .catch(error => {
        logDebug('error', error);
        return
      });
}
// 课程视频 ###############################################################
const chapter_id = ref();
const lesson_id = ref();
const video_id = ref();
const Mode = ref();
const video_url = ref(false);
const description = ref();
let handleChange = (keys) => {
  activeKey.value = keys;
  if (keys) {
    fetchDataCourseChaptersLessonsInfo(keys)
  }
}
onBeforeUnmount(() => {
  clearInterval(timer.value);
})
let _BackInfo = () => {
  state.value = 'info';
  clearInterval(timer.value);
  record_user_video_watch()
  watch_size.value = 0;
}
const tabList = [
  {
    key: 'tab1',
    tab: '章节目录',
  },
  {
    key: 'tab2',
    tab: '讨论',
  },
];
const tabListKey = ref('tab1');
const tabListChange = value => {
  tabListKey.value = value;
};
const lab_manual_contents = ref(false)
const courseware = ref(false)
// 切换学习
let go_to_play = (index, y, mode) => {
  Mode.value = mode;
  let le = data_list.chapters[index].lessons[y];
  app_id.value = le.app_id;
  if (le.app_environment_ids.length > 0) {
    data_list.environment_ids = le.environment_ids;
    data_list.app_environment_ids = le.app_environment_ids;
  } else {
    data_list.environment_ids = course_environment_ids;
    data_list.app_environment_ids = course_app_environment_ids;
  }
  lab_manual_contents.value = false;
  if (le.lab_manual_contents) {
    lab_manual_contents.value = le.lab_manual_contents;
  }
  courseware.value = false;
  if (le.courseware_num.length > 0) {
    courseware.value = true;
  }
  chapter_id.value = data_list.chapters[index].id;
  lesson_id.value = le.id;
  video_id.value = le.video_id;
  getLessonComments();
  clearInterval(timer.value);
  record_user_video_watch();
  watch_size.value = 0;
  video_url.value = false;
  if (mode == 'video') {
    video_url.value = le.video_url;
    description.value = le.description;
    let video = document.getElementById("course_play_video");
    if (video) {
      video.load()
    }
    fetchVideoData();
  } else if (mode.value == 'code') {
    fetchCodeData();
  } else if (mode == 'exercise') {
    editorMode.value = 'create';
    workId.value = le.exercise_paper_id;
    fetchData1();
  }
}
// 获取视频记录
let fetchVideoData = () => {
  jsonOwlRPC({
    url: "/get_video_url_by_lesson",
    params: {
      course_id: courseId,
      chapter_id: chapter_id.value,
      lesson_id: lesson_id.value,
      video_id: video_id.value,
    },
    success(res) {
      logDebug(`获取视频记录成功`, res);
      video_watch_record_id.value = res.video_watch_record_id;
      description.value = res.description;
      let video = document.getElementById("course_play_video");
      if (video) {
        video.currentTime = res.last_watch_size
      }
      video_watch_info();
    },
    fail(error) {
      logError(`获取数据错误, `, error)
    },
  })
}
// 定时器
let startTimer = () => {
  let video = document.getElementById("course_play_video");
  if (!video) {
    return false;
  }

  const recordInterval = 30 * 1000; // 30秒记录一次
  let hasRecorded = false; // 标志，表示是否已经记录
  clearInterval(timer.value);

  timer.value = setInterval(() => {
    speed_play.value = video.playbackRate;
    watch_size.value += 1;
    last_watch_size.value = video.currentTime;
    // 检查是否应该记录
    if (watch_size.value % (recordInterval / 1000) === 0) {
      record_user_video_watch()
      hasRecorded = true; // 设置标志为已记录
    }

    // 如果已记录，清空时长
    if (hasRecorded) {
      watch_size.value = 0;
      hasRecorded = false; // 重置标志
    }
  }, 1000);
}
// 点击播放
let video_watch_info = () => {
  let video = document.getElementById("course_play_video");
  if (!video) {
    return false;
  }

  video.addEventListener('contextmenu', function (event) {
    event.preventDefault();
  });

  // 在视频暂停时清除计时器
  video.addEventListener("pause", function () {
    logDebug(`暂停`);
    clearInterval(timer.value); // 清除计时器
    record_user_video_watch()
    watch_size.value = 0;
  });
  // 启动计时器
  video.addEventListener("playing", function () {
    logDebug(`启动`);
    startTimer()
  }, false);
}
let video_watch_record_id = ref(0);
let timer = ref();
let speed_play = ref();
let watch_size = ref(0);
let last_watch_size = ref();
// 定时更新记录
let record_user_video_watch = () => {
  if (watch_size.value < 10) {
    return false;
  }
  jsonOwlRPC({
    url: "/record/watch_info",
    params: {
      course_id: courseId,
      video_id: video_id.value,
      chapter_id: chapter_id.value,
      lesson_id: lesson_id.value,
      watch_size: watch_size.value,
      video_watch_record_id: video_watch_record_id.value,
      speed_play: speed_play.value,
      last_watch_size: last_watch_size.value,
    },
    success(res) {
      logDebug("定时更新记录成功")
      video_watch_record_id.value = res;
    },
    fail(error) {
      logError(`获取数据错误, `, error)
    },
  })
}
onBeforeUnmount(() => {
  clearInterval(timer.value);
})


const appEnvId = courseId
const noteEditorMode = ref('write')
const openNoteListModal = ref(false);
const handlePageChange = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page;
  loadNoteData()
}
const pagination = reactive({
  // 分页配置
  pageSize: 5, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const noteColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '笔记标题',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '学生',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: '提交时间',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: '笔记内容',
    dataIndex: 'action',
    key: 'action',
  },
];
const descriptionRules = [
  {
    required: true,
    message: '请输入笔记内容！',
    validator(_, value) {
      // 如果内容为空或者只是一个空的 <p><br></p> 标签
      const isEmpty = !value || /^<p><br><\/p>$/i.test(value.trim());
      
      if (isEmpty) {
        return Promise.reject('请输入笔记内容！');
      }
      return Promise.resolve();
    }
  }
];
const noteData = ref([]);
const loadNoteData = () => {
  return jsonRPC({
    url: "/api/experiment/course/note",
    params: {
      course_id: appEnvId,
      search: search.value,
      page_index: pagination.current,
      page_size: pagination.pageSize,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('loadNoteData', data)
      noteData.value = data.records;
      pagination.total = data.record_count;
    },
    fail(error) {
      logError(`获取笔记列表失败, `, error)
    },
  })
}
const showNoteListModal = () => {
  loadNoteData().then(() => {
    openNoteListModal.value = true;
  })
}
let letfOption = ref('video')
watch([video_url, lab_manual_contents], () => {
  if (!video_url.value && lab_manual_contents.value) {
    letfOption.value = 'courseware'
  }
})
let righrtOption = ref('environment')
let onLeftSwitch = (e) => {
  letfOption.value = e;
  if (letfOption.value == 'video') {
    let le = data_list.chapters.find(ch => ch.id === chapter_id.value)?.lessons.find(le => le.id === lesson_id.value);
    video_url.value = le.video_url;
    description.value = le.description;
    let video = document.getElementById("course_play_video");
    if (video) {
      video.load()
    }
    fetchVideoData();
  }
}
let selectEnvironmentID = (e) => {
  environmentID.value = e;
  righrtOption.value = 'environment'
}
let onRightSwitch = (e) => {
  righrtOption.value = e
}
let left_menu = ref(true)
let right_width = ref(18)
let onSplitScreen = () => {
  if (left_menu.value) {
    left_menu.value = false;
    right_width.value = 24;
    if (document.getElementById("split-0") && document.querySelectorAll(".gutter.gutter-horizontal").length == 0) {
      Split(['#split-0', '#split-1'], {
        minSize: 300,
      });
    }
  } else {
    left_menu.value = true;
    right_width.value = 18;
  }
  clearInterval(timer.value);
  fetchVideoData();
  if ((app_id.value && data_list.environment_ids.length == 0) || (data_list.app_id && data_list.environment_ids.length == 0)) {
    righrtOption.value = 'environment'
  } else {
    righrtOption.value = 'courseware'
  }
}
const edit = ref(false);
const open = ref(false);
const drawerData = ref();
const search = ref("");
const showDrawer = (e) => {
  drawerData.value = noteData.value[e];
  open.value = true;
};
let openDeleteModal = ref(false);
const onDelete = () => {
  openDeleteModal.value = false;
  jsonRPC({
    url: "/api/experiment/course/note/delete",
    params: {
      id: drawerData.value.id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('onDelete', data)
      message.success('删除笔记成功')
      loadNoteData()
    },
    fail(error) {
      logError(`删除笔记失败, `, error)
    },
  }).then(() => {
    open.value = false;
  })
};
const onEdit = () => {
  jsonRPC({
    url: "/api/experiment/course/note/edit",
    params: {
      id: drawerData.value.id,
      title: drawerData.value.title,
      description: drawerData.value.description,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('onEdit', data)
      message.success('修改笔记成功')
      loadNoteData()
    },
    fail(error) {
      logError(`修改笔记失败, `, error)
    },
  }).then(() => {
    edit.value = false;
  })
};
const onCancel = () => {
  edit.value = false;
  open.value = false;
  loadNoteData()
};
const onClose = () => {
  open.value = false;
};
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    pagination.current = 1;
    search.value = searchValue;
    loadNoteData()
  }
};
const openCreateNoteModal = ref(false);
const confirmCreateNote = ref(false);
const noteformRef = ref();
const createNoteForm = reactive({
  'title': '',
  'description': '',
})
const createNote = () => {
  return jsonRPC({
    url: "/api/experiment/course/note/create",
    params: {
      title: createNoteForm.title,
      description: createNoteForm.description,
      course_id: appEnvId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(data)
    },
    fail(error) {
      logError(`创建笔记失败, `, error)
    },
  })
}
const handleOkCreateNoteModal = () => {
  noteformRef.value.validate()
      .then(() => {
        confirmCreateNote.value = true
        createNote().then(() => {
          openCreateNoteModal.value = false;
          confirmCreateNote.value = false;
          loadNoteData()
          for (const key in createNoteForm) {
            createNoteForm[key] = ''; // 将每个字段重置为空字符串
          }
        })
      })
      .catch(error => {
        logError(`error`, error)
        return
      });
};

// 获得评论
const comment_id = ref(0);
let getLessonComments = () => {
  jsonOwlRPC({
    url: "/lessons_comment",
    params: {
      lesson_id: lesson_id.value,
    },
    success(res) {
      logDebug(`获取讨论成功`, res);
      data_list.discuss_content = res
    },
    fail(error) {
      logError(`获取数据错误, `, error)
    },
  }).then(() => {
    comment_id.value = 0;  // 清空评论id
    // 清空输入框并设置 placeholder
    let discuss_content_id = document.getElementById("discuss_content")
    if (discuss_content_id) {
      document.getElementById("discuss_content").value = '';
      document.getElementById("discuss_content").setAttribute("placeholder", "请输入讨论内容");
      // 跳转到顶部
      document.querySelector(".scroll").scrollTop = 0;
    }
  })
}
// 回复单个评论
let create_sub_comment = (e) => {
  // 清空输入框并设置 placeholder，然后将焦点设置到输入框
  var discussContent = document.getElementById("discuss_content");
  discussContent.value = '';
  discussContent.setAttribute("placeholder", "回复：" + e.user_info.name);
  discussContent.focus();
  comment_id.value = e.comment_id;
}
// 回车发送
let handleEnterKey = (event) => {
  if (event.key === 'Enter') {
    send_comment();
  }
}
// 提交评论
let send_comment = () => {
  let input_datas = document.getElementById("discuss_content").value.trim();
  if (input_datas.length <= 0) {
    // 将焦点设置到输入框
    document.getElementById("discuss_content").focus();
    return false
  }
  jsonOwlRPC({
    url: "/add_lessons_comment",
    params: {
      lesson_id: lesson_id.value,
      input_data: input_datas,
      comment_id: comment_id.value,
    },
    success(res) {
      logDebug(res);
      getLessonComments()
    },
    fail(error) {
      logError(`获取数据错误, `, error)
    },
  })
}
// 删除评论
let delete_comment = (e) => {
  if (confirm('确认删除？')) {
    jsonOwlRPC({
      url: "/delete_lessons_comment",
      params: {
        comment_id: e.comment_id,
      },
      success(res) {
        logDebug(res);
        getLessonComments()
      },
      fail(error) {
        logError(`获取数据错误, `, error)
      },
    })
  }
}

const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
const editorMode = ref('create');
const exerciseformRef = ref();
const workId = ref();
const spinning = ref(false);
const options = Array.from({length: 8}, (_, i) => String.fromCharCode(65 + i))
const style = {display: 'flex', flexDirection: 'column'}
const data_list1 = reactive({})
const create_list = reactive({})

function fetchData1(e) {
  jsonRPC({
    url: "/vue/console/class/exercise/detail",
    params: {
      workId: workId.value,
      record_id: e,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list1, data.records);
      Object.assign(create_list, data.answer_ids);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    spinning.value = false; // 加载完成后关闭加载状态
  });
}

// 提交作业
let submitHomework = () => {
  spinning.value = true;
  jsonRPC({
    url: "/vue/console/class/exercise/submit",
    params: {
      workId: workId.value,
      create_list: create_list,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      message.success('提交成功')
      editorMode.value = 'readonly';
      fetchData1(data);
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}
// 返回
let _Back = () => {
  editorMode.value = 'create';
  fetchData1();
  message.info("开始新的练习")
}

const code = ref(`print('Hello, world!')`)
// 选项
const codeOptions = reactive({
  style: {height: "400px", border: "1px solid black"},
  mode: "python",
  theme: 'vscodeDark',
  spellcheck: true,
  autofocus: true,
  indentWithTab: true,
  tabSize: 2,
  extensions: [python(), vscodeDark],
});
// 改变主题
const themes = [vscodeDark, gruvboxDark, gruvboxLight, duotoneDark, duotoneLight];
const themeValue = ref('vscodeDark');
const themeOptions = [
  {
    label: 'vscodeDark',
    value: 'vscodeDark',
    key: 0,
  },
  {
    label: 'gruvboxDark',
    value: 'gruvboxDark',
    key: 1,
  },
  {
    label: 'gruvboxLight',
    value: 'gruvboxLight',
    key: 2,
  },
  {
    label: 'duotoneDark',
    value: 'duotoneDark',
    key: 3,
  },
  {
    label: 'duotoneLight',
    value: 'duotoneLight',
    key: 4,
  },
];

function changeTheme() {
  if (ModeValue.value == 'python') {
    codeOptions.extensions = [Modes[0], themes[themeValue.value.key]];
  } else {
    codeOptions.extensions = [Modes[ModeValue.value.key], themes[themeValue.value.key]];
  }
}

// 改变模式
const Modes = [python(), cpp(), javascript(), json(), xml(), html(), css(), java()];
const ModeValue = ref('python');
const ModeOptions = [
  {
    label: 'python',
    value: 'python',
    key: 0,
  },
  {
    label: 'C++',
    value: 'C++',
    key: 1,
  },
  {
    label: 'javascript',
    value: 'javascript',
    key: 2,
  },
  {
    label: 'json',
    value: 'json',
    key: 3,
  },

  {
    label: 'xml',
    value: 'xml',
    key: 4,
  },
  {
    label: 'html',
    value: 'html',
    key: 5,
  }, {
    label: 'css',
    value: 'css',
    key: 6,
  },
  {
    label: 'java',
    value: 'java',
    key: 7,
  },
];

function changeMode() {
  if (themeValue.value == 'vscodeDark') {
    codeOptions.extensions = [Modes[ModeValue.value.key], themes[0]];
  } else {
    codeOptions.extensions = [Modes[ModeValue.value.key], themes[themeValue.value.key]];
  }
}

// 获取题目及操作
let fetchCodeData = () => {
  let le = data_list.chapters.find(ch => ch.id === chapter_id.value)?.lessons.find(le => le.id === lesson_id.value);
  jsonOwlRPC({
    url: "/exercise_code_root",
    params: {
      exercise_code_id: le.exercise_code_ids[0].id,
    },
    success(res) {
      logDebug(`获取记录成功`, res);
      Object.assign(data_list, res);
    },
    fail(error) {
      logError(`获取数据错误, `, error)
    },
  })
}
let onclick_test = () => {
  if (code.value.length > 0) {
    submit_test_running()
    let wait = 5; // 初始等待时间
    let time = (o) => {
      if (wait === 0) {
        o.removeAttribute("disabled");
        o.value = "自行测试";
        wait = 5; // 重置等待时间
      } else {
        o.setAttribute("disabled", true);
        o.value = `重新测试(${wait})`;
        wait--;
        setTimeout(() => {
          time(o);
        }, 1000);
      }
    };
    // 为按钮绑定点击事件
    document.getElementById("btn-test").onclick = function () {
      time(this); // 传入当前按钮元素
    };
  }
  let btn_save = document.getElementById('btn-save');
  if (btn_save.value == "已提交") {
    btn_save.removeAttribute("disabled");
    btn_save.value = "提交评分";
  }
}

let onclick_next_exercise_code = () => {
  let le = data_list.chapters.find(ch => ch.id === chapter_id.value)?.lessons.find(le => le.id === lesson_id.value);
  const exercise_code_ids = le.exercise_code_ids.map(x => x.id)
  const exercise_code_index = exercise_code_ids.indexOf(data_list.exercise_code_id)
  if (exercise_code_ids.length <= 1 || exercise_code_index === exercise_code_ids.length - 1) {
    message.error("没有下一道案例题了！")
    return
  }
  do_onclick_exercise_code(exercise_code_index + 1)
  ModeValue.value = ModeOptions[0].value
}

let onclick_previous_exercise_code = () => {
  let le = data_list.chapters.find(ch => ch.id === chapter_id.value)?.lessons.find(le => le.id === lesson_id.value);
  const exercise_code_ids = le.exercise_code_ids.map(x => x.id)
  const exercise_code_index = exercise_code_ids.indexOf(data_list.exercise_code_id)
  if (exercise_code_ids.length <= 1 || exercise_code_index === 0) {
    message.error("没有上一道案例题了！")
    return
  }
  do_onclick_exercise_code(exercise_code_index - 1)
  ModeValue.value = ModeOptions[0].value
}

let do_onclick_exercise_code = (exercise_code_index) => {
  let le = data_list.chapters.find(ch => ch.id === chapter_id.value)?.lessons.find(le => le.id === lesson_id.value);
  const exercise_code = le.exercise_code_ids[exercise_code_index]
  data_list.judge_server.exercise_code = exercise_code
  data_list.exercise_code_id = exercise_code.id

  const q_code = exercise_code.question_code
  fetchCasesData(q_code).then(r => {
    logDebug("fetchCasesData result", r)
    if (r === true) {
      // const out_state = data_list
      // this.env.bus.trigger('hw_course.CourseStudy.UpdateExerciseCode', {
      //     args: {
      //         chapter_id: out_state.chapter_id,
      //         lesson_id: out_state.lesson_id,
      //         exercise_code_id: out_state.exercise_code_id
      //     }
      // })
    }
  })
}

let submit_test_running = () => {
  // 从lesson中拿到案例的question_code，返回获取结果的url
  let le = data_list.chapters.find(ch => ch.id === chapter_id.value)?.lessons.find(le => le.id === lesson_id.value);
  const exercise_code = le.exercise_code_ids[0]
  data_list.judge_server.exercise_code = exercise_code
  data_list.exercise_code_id = exercise_code.id
  data_list.judge_server.actual_running_results = []
  const q_code = data_list.judge_server.exercise_code.question_code
  const source_code_language = ModeValue.value

  jsonOwlRPC({
    url: `/exercise/judge/problems/${q_code}`,
    params: {
      "question_code": q_code,
      "language": source_code_language,
      "source": code.value,
      "judge": ""
    },
    success(res) {
      return self_test_running(res.data)
    },
    fail(error) {
      logError(`获取数据错误, `, error)
      data_list.judge_server.actual_running_results = ['无法连接到裁判服务器']
    },
  })
}

let self_test_running = (judge_submit_url) => {
  // 获取当前提交问题的判定结果
  jsonOwlRPC({
    url: "/exercise/judge/problems/results",
    params: {
      "judge_submit_url": judge_submit_url
    },
    success(res) {
      let cases_data = res.results
      if (cases_data.status === 'P' || cases_data.status === 'G') {
        data_list.judge_server.actual_running_results = ['运行中。。。']
        setTimeout(() => {
          self_test_running(judge_submit_url)
        }, 3000);
        return true;
      } else {
        self_test_running_results(cases_data)
        return true;
      }
    },
    fail(error) {
      logError(`获取数据错误, `, error)
      data_list.judge_server.actual_running_results = ['无法连接到裁判服务器']
      return false
    },
  })
}

let self_test_running_results = (cases_data) => {
  // 对判定结果进行解析
  if (cases_data.status !== 400) {
    data_list.result = cases_data
    const status_data = []
    if (cases_data.status) {
      if (`${cases_data.status}` === "CE") {
        status_data.push(cases_data.status + "编译错误" + `${cases_data.info}`)
      } else if (`${cases_data.status}` === "QU") {
        status_data.push(cases_data.status + "未知错误，请重试！")
      }
    } else {
      data_list.result = {}
      status_data.push("获取判题结果失败")
    }
    if (cases_data.cases) {
      if (cases_data.cases.length > 0) {
        for (let i in cases_data.cases) {
          let data = cases_data.cases[i]
          if (data.status === "WA") {
            status_data.push("Case " + data.case_id + " 答案错误")
          } else if (data.status === "AC") {
            status_data.push("Case " + data.case_id + " 运行结果：成功！" + "用时:" + data.time + "得分" + data.total)
          } else if (data.status === "CE") {
            status_data.push("Case " + data.case_id + " 编译错误")
          } else if (data.status === "IR") {
            status_data.push("Case " + data.case_id + " 运行错误,退出代码")
          } else if (data.status === "TLE") {
            status_data.push("Case " + data.case_id + " 超出时间限制")
          } else if (data.status === "MLE") {
            status_data.push("Case " + data.case_id + " 超出内存限制")
          } else if (data.status === "IE") {
            status_data.push("Case " + data.case_id + " Internal Error")
          } else {
            status_data.push("未知错误，请重试！")
          }
        }
      }
    }

    data_list.judge_server.actual_running_results = status_data
    return true
  }
  if (cases_data.status === 400) {
    data_list.judge_server.actual_running_results = ["获取判题结果失败"]
    return false
  }

}

let fetchCasesData = (e) => {
  return jsonOwlRPC({
    url: "/exercise/judge/problems",
    params: {
      'question_code': e
    },
    success(res) {
      if (res.status === 200) {
        const re = res.problems_judge_data[0].cases_data
        let CaseInputArray = []
        let CaseOutputArray = []
        for (let i in re) {
          const test = re[i]
          CaseInputArray.push(test.case_input)
          CaseOutputArray.push(test.case_output)
        }
        data_list.judge_server.test_inputs = CaseInputArray
        data_list.judge_server.expected_running_results = CaseOutputArray

        // 清空输入的内容，和结果

        data_list.judge_server.actual_running_results = []

        // 设置允许使用的语言
        const languages = []
        const language_map = res.problems_judge_data[0].language
        Object.keys(language_map).forEach(function (x) {
          languages.push({
            id: x,
            name: language_map[x]
          })
        })
        data_list.judge_server.languages = languages

        if (res.problems_judge_data[0].exercise_cases_template) {
          data_list.judge_server.exercise_cases_template = res.problems_judge_data[0].exercise_cases_template
        }
      }
      return true
    },
    fail(error) {
      logError(`获取数据错误, `, error)
      return false
    },
  })
}

let onclick_save = () => {
  if (!data_list.judge_server.actual_running_results
    || data_list.judge_server.actual_running_results.length === 0
    || !data_list.judge_server.exercise_code
    || !data_list.judge_server.exercise_code.question_code
  ) {
    data_list.result = {}
    message.info('请先自行测试！')
    return false
  }
  if (data_list.result) {
    const q_code = data_list.judge_server.exercise_code.question_code
    const source_code_language = ModeValue.value;

    jsonOwlRPC({
      url: `/exercise/judge/problems/save/${q_code}`,
      params: {
        "course_id": data_list.course_id,
        "chapter_id": data_list.chapter_id,
        "lesson_id": data_list.lesson_id,
        "question_code": q_code,
        "language": source_code_language,
        "source": code.value,
        "judge": "test1",
        "data": data_list.result,
      },
      success(res) {
        logDebug(res)
        message.success('提交成功')
        let btn = document.getElementById('btn-save');
        btn.setAttribute("disabled", true);
        btn.value = "已提交";
        return true
      },
      fail(error) {
        logError(`获取数据错误, `, error)
        return false
      },
    })
  } else {
    logDebug('缺少数据，无法保存')
    return false
  }


  let wait = 30;

  function time(o) {
    if (wait == 0) {
      o.removeAttribute("disabled");
      o.value = "提交评分";
      wait = 30;
    } else {
      o.setAttribute("disabled", true);
      o.value = "重新提交(" + wait + ")";
      wait--;
      setTimeout(function () {
        time(o)
      }, 1000)
    }
  }

  document.getElementById("btn-save").onclick = function () {
    time(this);
  }
}
</script>

<style scoped lang="scss">
.course-detail {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 16px;

  .course_detail {
    height: 100%;

    .row {
      margin-bottom: -15px;

      > * {
        margin-bottom: 1rem;
      }

      > .col-md-7 {
        > div:not(:last-child) {
          margin-bottom: 10px;
        }

        h2 {
          font-size: 18px;
          font-weight: bold;
        }

        .category {
          span {
            display: inline-block;
            padding: 4px 10px;
            color: #52c9ff;
            border: 1px solid #52c9ff;
            border-radius: 8px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }

        .btn {
          padding: 7px 30px;
          border-radius: 20px
        }

        .light_yellow {
          display: inline-block;
          color: #FFFFFF;
          background-color: #c4e9aa;
          border-color: #c4e9aa;

          &:hover {
            background-color: #aacf8f;
            border-color: #aacf8f;
          }
        }
      }
    }

    .accordion {
      padding: 7px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #e4efff;
      }

      a {
        display: inline-block;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        user-select: none;

        &:not(:last-child) {
          margin-right: 10px;
        }

        background-color: #BFBFBF;

        &.course_video {
          background-color: #94d9fe;
        }

        &.course_experiment {
          background-color: #C1AAFF;
        }

        &.course_case {
          background-color: #99d094;
        }

        &.course_exercise {
          background-color: #ff8857;
        }

        &.course_courseware {
          background-color: #ff8b8b;
        }
      }
    }
  }

  .course_play {
    .top {
      height: 50px;

      img {
        cursor: pointer;
      }
    }

    .menu {
      height: 100%;
      background-color: #fff;
      border-radius: 0;

      .lessons {
        > div:first-child {
          max-width: calc(100% - 115px);
        }

        padding: 7px 0;

        &:not(:last-child) {
          border-bottom: 1px solid #e4efff;
        }

        .line-clamp-1 {
          cursor: context-menu;

          &.active {
            color: #ff8c00;
          }
        }

        .menu_icon {
          overflow: hidden;

          a {
            display: inline-block;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            overflow: hidden;
            text-align: center;
            user-select: none;

            &:not(:last-child) {
              margin-right: 5px;
            }

            background-color: #BFBFBF;

            &.course_video {
              background-color: #94d9fe;
            }

            &.course_experiment {
              background-color: #C1AAFF;
            }

            &.course_case {
              background-color: #99d094;
            }

            &.course_exercise {
              background-color: #ff8857;
            }

            &.course_courseware {
              background-color: #ff8b8b;
            }
          }
        }
      }

      #lesson_discuss {
        position: relative;
        height: calc(100vh - 174px);
        padding: 0;
        margin: -24px;

        > .scroll {
          padding: 15px;
          height: calc(100% - 50px);
          overflow: auto;

          &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 6px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 6px;
          }

          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            background-color: skyblue;
            background-image: -webkit-linear-gradient(45deg,
                rgba(255, 255, 255, 0.2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0.2) 75%,
                transparent 75%,
                transparent);
          }

          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #ededed;
            border-radius: 10px;
          }

          > .discuss {
            box-shadow: 2px 2px 4px 1px #a9a9a9;
            padding: 1rem;
            margin-bottom: 1rem;

            .message {
              position: relative;

              > div {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:not(:last-child) {
                  margin-bottom: 10px;
                }

                > .user {
                  display: flex;
                  align-items: center;

                  > div {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    flex-shrink: 0;
                  }

                  > a {
                    margin-left: 5px;
                    font-size: 16px;
                  }
                }

                > .date {
                  font-size: 12px;
                  color: #909090;
                }

                > p {
                  margin-bottom: 0;
                }
              }

              > p {
                position: relative;
                margin: 0;
                padding-right: 3rem;
                padding-bottom: 5px;
                word-break: break-all;

                > a {
                  position: absolute;
                  bottom: 5px;
                  right: 0;
                }
              }

            }

            > .reply {
              padding-left: 10px;
              padding-right: 10px;
              background-color: #e3f7ff;
              margin-top: 10px;

              > .message:not(:last-child) {
                border-bottom: 1px solid #bbb;
              }

              > .message {
                padding-top: 10px;
              }
            }
          }
        }

        > .send {
          width: 100%;
          height: 50px;
          background-color: #033e80;
          display: flex;
          align-items: center;
          padding: 0 15px;

          input {
            border-radius: 1rem;
          }

          button {
            padding: 0;
            margin-left: 15px;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }

    .play {
      position: relative;
      height: 100%;
      min-height: 50vh;
      background-color: #fff;

      .absolute {
        position: absolute;
        left: 0;
        top: -50px;
        height: 50px;
        display: flex;
        align-items: center;

        > * {
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      .description {
        padding: 20px;
        overflow: auto;
        >div{
          max-height: 80vh;
        }

        h4 {
          display: inline-block;
          width: 200px;
          padding-bottom: 10px;
          border-bottom: 2px solid #0078ff;
        }
      }

      .caseCode {
        > .row {
          border-radius: 4px;
          border: 1px solid #033e80;
          margin: 0px;

          > div {
            position: relative;
            height: 125px;

            &:not(:first-child) {
              &:after {
                content: '';
                position: absolute;
                top: 5%;
                bottom: 5%;
                left: 0;
                height: 90%;
                width: 2px;
                background-color: #033e80;
              }
            }

            > div {
              position: absolute;
              width: 100%;
              height: calc(100% - 18.38px);
              overflow: auto;

              &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 6px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 6px;
              }

              &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                background-color: skyblue;
                background-image: -webkit-linear-gradient(45deg,
                    rgba(255, 255, 255, 0.2) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 0.2) 50%,
                    rgba(255, 255, 255, 0.2) 75%,
                    transparent 75%,
                    transparent);
              }

              &::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #ededed;
                border-radius: 10px;
              }

              > div {
                border-bottom: 1px solid gray;
              }
            }
          }
        }

        > div:last-child {
          display: flex;
          justify-content: flex-end;
          margin: 10px;

          > .btn {
            color: #0078ff;
            border-color: #0078ff;
            margin-left: 10px;

            &:focus {
              box-shadow: 0 0 0 0.2rem #a8d1ff;
            }

            &.btn-primary {
              color: #fff;
              background-color: #0078ff;
            }
          }
        }
      }

      .courseware {
        height: 100%;

        iframe {
          width: 100%;
          height: calc(100% - 90px);
          min-height: 70vh;
          max-height: 80vh;
        }
      }

      .exercise {
        button {
          border-radius: 16px;

          &.submitHomework {
            background-color: #6FA6FF;
          }

          &.saveHomework {
            background-color: #D4D4D4;
          }

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      .split {
        height: 100%;
        display: flex;
        flex-direction: row;

        #split-0, #split-1 {
          position: relative;
          min-width: 300px;
        }
      }
    }
  }
}

.info {
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: 1px 2px 4px 0px #1540e666;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  padding: 15px;

  h5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    a {
      font-size: 16px;
      color: #000;
      font-weight: bold;

      &:hover {
        color: #e57f14;
      }
    }

    span {
      display: inline-block;
      color: #52c9ff;
      font-size: 12px;
      border: 1px solid #52c9ff;
      border-radius: 4px;
      padding: 1px 4px;
      margin-left: 5px;
    }

    p {
      font-size: 14px;
      color: #53A6FF;
      margin-bottom: 0;
    }
  }

  .teacher {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .user {
      display: flex;
      align-items: center;
      margin-right: 10px;

      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }

  button {
    border-radius: 32px;
  }
}
</style>
