<template>
  <div id="booting">
    <a-row style="width:100%; height: 30vh;">
    </a-row>
    <a-row justify="space-around" style="width:100%; height: 30vh;">
      <a-col :span="4" class="boot-item-col">
        <a-button class="boot-item" ghost type="primary" @click="showRestartModal">
          <div>
            <ReloadOutlined/>
            <span>&nbsp;重启</span>
          </div>
        </a-button>
        <a-modal v-model:open="openRestart" :confirm-loading="confirmLoadingRestart"
                 :maskClosable="false"
                 title="确定重启？"
                 @ok="handleOkRestart">
        </a-modal>
      </a-col>
      <a-col :span="4" class="boot-item-col">
        <a-button class="boot-item" ghost type="primary" @click="showStopModal">
          <div>
            <PoweroffOutlined/>
            <span>&nbsp;关机</span>
          </div>
        </a-button>
        <a-modal v-model:open="openStop" :confirm-loading="confirmLoadingStop"
                 :maskClosable="false"
                 title="确定关机？"
                 @ok="handleOkStop">
        </a-modal>
      </a-col>
      <a-col :span="4" class="boot-item-col">
        <a-button class="boot-item" ghost type="primary" @click="showStartModal">
          <div>
            <LoginOutlined/>
            <span>&nbsp;开机</span>
          </div>
        </a-button>
        <a-modal v-model:open="openStart" :confirm-loading="confirmLoadingStart"
                 :maskClosable="false"
                 title="确定开机？"
                 @ok="handleOkStart">
        </a-modal>
      </a-col>
      <a-col :span="4" class="boot-item-col">
        <a-button class="boot-item" danger ghost type="primary" @click="showDeleteModal">
          <div>
            <DeleteOutlined/>
            <span>&nbsp;销毁</span>
          </div>
        </a-button>
        <a-modal v-model:open="openDelete" :confirm-loading="confirmLoadingDelete"
                 :maskClosable="false"
                 title="确定销毁？"
                 @ok="handleOkDelete">
        </a-modal>
      </a-col>
    </a-row>
    <a-row style="height: 20vh;">
    </a-row>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {DeleteOutlined, LoginOutlined, PoweroffOutlined, ReloadOutlined} from '@ant-design/icons-vue';
import {onMounted, reactive, ref} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import useDebugStore from "@/stores/debugStore";

logDebug("TerminalSetting setup!");

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
});
const contentHeight = ref(props.styleSetting.contentStyle.height)

const emit = defineEmits(['refresh']); //声明 emits
const primal = props.primal;
const data = props.data;
const envId = data["env_id"];

const envStatus = reactive({
  'env_id': envId,
  'env_status': 'Running',
  'name': '',
  'status': '',
  'custom_name': '',
  'deployment_id': 0,
  'app_env_id': 0,
  'app_deploy_id': 0,
})

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalBooting onMounted. envId[${envId}]`);
});


const loadEnvStatus = function () {
  return jsonRPC({
    url: "/api/experiment/app/env_status",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取实验环境状态成功`, data);
      Object.assign(envStatus, data);
      return true
    },
    fail(error) {
      logError(`获取实验环境状态失败`, error);
      message.error(`获取实验环境状态失败`, 3)
      return false
    },
  });
}


const onSelected = function () {
  logDebug(`TerminalBooting onSelected. data[${JSON.stringify(data)}]`);

  loadEnvStatus();
};

const showRestartModal = function () {
  logDebug('handleRestart')
  openRestart.value = true;
}

const showStopModal = function () {
  logDebug('handleStop')
  openStop.value = true
}

const showStartModal = function () {
  logDebug('handleStart')
  openStart.value = true
}

const showDeleteModal = function () {
  logDebug('handleDelete')
  openDelete.value = true
}
const debugStore = useDebugStore();  // 获取 debugStore 实例

const openRestart = ref(false);
const confirmLoadingRestart = ref(false);
const handleOkRestart = () => {
  confirmLoadingRestart.value = true;
  let is_debug = true;
  debugStore.setDebugSettings(envId, is_debug);
  const result = jsonRPC({
    url: "/api/experiment/app/restart",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`重启实验环境成功`, data);
      message.info(`重启实验环境成功`, 3)
      window.location.reload();
      return true
    },
    fail(error) {
      logError(`重启实验环境成功失败`, error);
      message.error(`重启实验环境成功失败`, 3)
      return false
    },
  });

  result.then(() => {
    openRestart.value = false;
    confirmLoadingRestart.value = false;
  })
};

const openStop = ref(false);
const confirmLoadingStop = ref(false);
const handleOkStop = () => {
  confirmLoadingStop.value = true;

  const result = jsonRPC({
    url: "/api/experiment/app/stop",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`停止实验环境成功`, data);
      message.info(`停止实验环境成功`, 3)
      window.location.reload();
      return true
    },
    fail(error) {
      logError(`停止实验环境失败`, error);
      message.error(`停止实验环境失败`, 3)
      return false
    },
  });

  result.then(() => {
    openStop.value = false;
    confirmLoadingStop.value = false;
  });
};

const openStart = ref(false);
const confirmLoadingStart = ref(false);
const handleOkStart = () => {
  confirmLoadingStart.value = true;

  const result = jsonRPC({
    url: "/api/experiment/app/start",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`启动实验环境成功`, data);
      message.info(`启动实验环境成功`, 3)
      window.location.reload();
      return true
    },
    fail(error) {
      logError(`启动实验环境失败`, error);
      message.error(`启动实验环境失败`, 3)
      return false
    },
  });

  result.then(() => {
    openStart.value = false;
    confirmLoadingStart.value = false;
  });
};

const openDelete = ref(false);
const confirmLoadingDelete = ref(false);


const handleOkDelete = () => {
  confirmLoadingDelete.value = true;

  const result = jsonRPC({
    url: `/vue/console/app/delete`,
    params: {
      app_id: envStatus.app_env_id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`删除实验环境成功`, data);
      message.success(`删除实验环境成功`)
      emit("refresh", data)
      return true
    },
    fail(error) {
      logError(`删除实验环境失败`, error);
      message.error(`删除实验环境失败`, 3)
      return false
    },
  });

  result.then(() => {
    openDelete.value = false;
    confirmLoadingDelete.value = false;
  });
};


defineExpose({
  primal,
  data,
  onSelected,
});
</script>

<style lang="scss" scoped>

#booting {
  background: dimgrey;
  width: 100%;
  height: v-bind(contentHeight);
}

.boot-item-col {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.boot-item {
  color: white;
  height: 40px;
  font-size: 20px;
  //line-height: 40px;
  width: 20vw;
  //text-align: center;
  border-radius: 20px;
}

</style>