<template>
  <div class="container blog_write">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link :to="backPath">{{ backTitle }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>发帖</a-breadcrumb-item>
    </a-breadcrumb>
    <a-form ref="formRef" :model="data_list" :rules="rules" 
      :label-col="labelCol" :wrapper-col="wrapperCol" :disabled="editorMode === 'readonly'">
      <a-form-item label="标题" name="name">
        <a-input 
          v-model:value="data_list.name" 
          placeholder="请输入标题（最多30个字）" 
          :maxlength="30"
          show-count
          @change="handleTitleChange"
        />
      </a-form-item>
      <a-form-item label="技术分区" name="section_id">
        <a-flex :gap="20" wrap="wrap">
          <a-flex-item v-for="section in sectionItems" :key="section.id" @click.stop="selectCategory(section)">
            <span class="categoryItem" :class="{ active: data_list.section_ids.includes(section.id) }">{{ section.name }}</span>
          </a-flex-item>
        </a-flex>
      </a-form-item>
      <a-form-item label="正文">
        <div :class="{ richZone: true, hasContentError }" @keydown="handleEditor">
          <div v-if="showTopicPopover === true && isEmptyTopic === false || 
          showUsersPopover === true && isEmptyUser === false" class="editorLayer"  
          :style="{ bottom:layerPosition.bottom,right: layerPosition.right,left: layerPosition.left, top: layerPosition.top }">
            <template v-if="topicList.length && showTopicPopover === true" >
              <div v-for="topic in topicList" :key="topic.id">
                <div class="pop_data" @click="handleTopicClick(topic)">
                  <span class="line-clamp-1"># {{ topic.name }}</span>
                  <span>{{ topic.visits }}次浏览</span>
                </div>
              </div>
            </template>
            <div v-else-if="showTopicPopover === true" class="newTopic" @click="handleNewTopicClick">添加新话题</div>
            <template v-if="usersList.length && showUsersPopover === true" >
            <div v-for="user in usersList" :key="user.id">
              <div class="pop_data" @click="handleUserChange(user)">
                <span><span class="circle">⚪</span> {{ user.name }}</span>
              </div>
            </div>
          </template>
          <div v-else-if="showUsersPopover === true" class="noData">没有找到相关用户</div>
          </div>
          <RichEditor @sendTopicLink="sendTopicLink" @removeTopicLink="removeTopicLink" @updateLayerPosition="updateLayerPosition" 
            ref="richEditorRef" height="500" :editMode="editorMode" v-model="data_list.content"/>
          <div class="richZoneBtn">
            <a-button :class="['button', { activeBtn: showTopicPopover === true }]" @click="addTopicHead"># 话题-></a-button>
            <a-button :class="['button', { activeBtn: showUsersPopover === true }]" @click="addUserHead">@ 用户</a-button>
          </div>
        </div>
        <div class="tip" v-show="hasContentError">请输入文章内容</div>
      </a-form-item>
      <a-flex justify="center" align="center" gap="20">
        <a-button type="primary" @click="onPublish">发布</a-button>
        <a-button @click="onCancel">取消</a-button>
      </a-flex>
    </a-form>
  </div>
</template>

<script setup>
import RichEditor from "@/components/RichEditor.vue";
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, computed, onUnmounted } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const hasContentError = ref(false);

const sectionItems = ref([]);
const showTopicPopover = ref(false);
const showUsersPopover = ref(false);
const isEmptyTopic = ref(false);
const isEmptyUser = ref(false);

const lastInputLinkString = ref('');
const topicList = ref([]);
const usersList = ref([])

const editorMode = ref('create')
const formRef = ref()
const rules = {
  name: [
    {
      required: true,
      message: '请输入标题',
    },
    {
      validator: (_, value) => {
        if (value && value.length > 30) {
          return Promise.reject('标题最多30个字');
        }
        return Promise.resolve();
      }
    }
  ],
  content: [
    {
      required: true,
      message: '请输入文章内容',
      validator: (rule, value) => {
        if (!value || value.trim() === '<p><br></p>' || value.trim() === '') {
          return Promise.reject('请输入文章内容');
        }
        return Promise.resolve();
      }
    }
  ],
}
const labelCol = {style: {width: '100px',},};
const wrapperCol = {span: 20,};

const data_list = reactive({
  section_ids: [],
  name: null,
  subtitle: null,
  tag_ids: null,
  content: null,
});

const richEditorRef = ref(null);

const at_users = ref([]);
const at_topics = ref([]);
const layerPosition = ref({left:0,top:0});

const updateLayerPosition = (newValue) => {
  layerPosition.value = newValue;
}
const removeTopicLink = ()=>{
  showUsersPopover.value = false;
  showTopicPopover.value = false;
}

let searchFlag = 1;
let setTimeoutFlag1 = null;
const sendTopicLink = (topicLink) => {
  isEmptyTopic.value = false;
  isEmptyUser.value = false;
  lastInputLinkString.value = topicLink;
  let url = topicLink.indexOf('#') === 0 ?`/api/blog/write/matchTopic`:`/api/blog/write/matchUser`;
  if(searchFlag === 1){
    clearTimeout(setTimeoutFlag1);
    searchFlag=0;
    const keyword = (topicLink.startsWith('#') || topicLink.startsWith('@')) ? topicLink.slice(1) : topicLink;
    logDebug('keyword',keyword);
    jsonRPC({
        url,
        params: {
          keyword
        },
        success(res) {
          const data = getResponseData(res);
          logDebug(data);
          if(data.topics){ 
            // 确保每个话题都有 visits 属性
            data.topics = data.topics.map(topic => ({
              ...topic,
              visits: topic.visits || 0  // 如果 visits 不存在则默认为 0
            }));
            topicList.value = data.topics;
          }else if(data.users){
            usersList.value = data.users;
          }
          setTimeoutFlag1 = setTimeout(()=>{
            searchFlag=1;
          },500);
        },
        fail() {
          setTimeoutFlag1 = setTimeout(()=>{
            searchFlag=1;
          },500);
        },
    });
  }
}

const addTopicHead = () => {
  if(showUsersPopover.value){
    message.warning('您正在输入用户，请先完成输入');
    return ;
  }
  
  let type = 'topicHead';
  if(showTopicPopover.value){
    type = 'clearInput';
  }else{
    topicList.value = [];
  }
  
  if (richEditorRef.value) {
    richEditorRef.value.insertTopicOrUser({type});
  }
  isEmptyTopic.value = true;
  showTopicPopover.value = !showTopicPopover.value;
};
const addUserHead = () => {
  if(showTopicPopover.value){
    message.warning('您正在输入话题，请先完成输入');
    return ;
  }

  let type = 'userHead';
  if(showUsersPopover.value){
    type = 'clearInput';
  }else{
    usersList.value = [];
  }
  
  if (richEditorRef.value) {
    richEditorRef.value.insertTopicOrUser({type});
  }
  isEmptyUser.value = true;
  showUsersPopover.value =!showUsersPopover.value;
};

const handleEditor = e => {
  //根据输入更新数据
  if (showTopicPopover.value || showUsersPopover.value) {
    if(richEditorRef.value){
      richEditorRef.value.setLayerPosition();
    }
  }
  
  if (e.key === 'Backspace' && richEditorRef.value) {
    richEditorRef.value.backKeyDown(showTopicPopover.value, showUsersPopover.value);
  }
};

const handleNewTopicClick = () => {
  const post_topic_name = lastInputLinkString.value.slice(1);
  logDebug(post_topic_name);
  if(post_topic_name.length === 0 || post_topic_name.trim() === ''){
    message.warning('请输入话题名称');
    return;
  } 
  jsonRPC({
    url:'/api/blog/post/topic/create',
    params: {
      post_topic_name
    },
    success(res) {
      message.success('创建话题成功');
      const data = getResponseData(res);
      logDebug(data);
      const obj = {id:data.post_topic_id, name:post_topic_name};
      at_topics.value.push(obj);
      if (richEditorRef.value ) {
        richEditorRef.value.insertTopicOrUser({type: 'topic', obj});
      }
      setTimeoutFlag1 = setTimeout(()=>{
        searchFlag=1;
      },500);
    },
    fail() {
      message.error('创建话题失败');
      setTimeoutFlag1 = setTimeout(()=>{
        searchFlag=1;
      },500);
    }
  });
  showTopicPopover.value = false;
};

const handleUserChange = (user) => {
  showUsersPopover.value = false;
  let hasItem = undefined;
  if (at_users.value.find(item => item.id === user.id)) {
    message.warning('用户已存在，请不要重复添加');
    hasItem = 1;
  }

  if (richEditorRef.value && user) {
    at_users.value.push(user);
    richEditorRef.value.insertTopicOrUser({type: 'user', obj:user, hasItem});
  }
};

const handleTopicClick = (topic) => {
  showTopicPopover.value = false;
  let hasItem = undefined;
  if (at_topics.value.find(item => item.id === topic.id)) {
    message.warning('话题已存在，请不要重复添加');
    hasItem = 1;
  }

  if (richEditorRef.value && topic) {
    at_topics.value.push(topic);
    richEditorRef.value.insertTopicOrUser({type: 'topic', obj:topic, hasItem});
  }
};

const selectCategory = (section) => {
  const index = data_list.section_ids.indexOf(section.id);
  if (index === -1) {
    // 最多选择3个分区
    if (data_list.section_ids.length >= 5) {
      message.warning('最多只能选择5个技术分区');
      return;
    }
    data_list.section_ids.push(section.id);
  } else {
    data_list.section_ids.splice(index, 1);
  }
};

const onPublish = () => {
  const user_ids = at_users.value.map(item=>item.id);
  const topic_ids = at_topics.value.map(item=>item.id);
  
  // 检查内容是否为空
  if (!data_list.content || data_list.content.trim() === '<p><br></p>' || data_list.content.trim() === '') {
    hasContentError.value = true;
    return message.warning('请输入文章内容');
  }else{
    hasContentError.value = false;
  }
  if (data_list.section_ids.length == 0) {
    message.warning('请选择至少一个技术分区');
    return;
  }

  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: `/api/blog/write`,
      params: {
        section_ids: data_list.section_ids,
        name: data_list.name,
        topic_ids,
        user_ids,
        content: data_list.content,
      },
      success() {
        onCancel()
        message.success(`创建成功`);
      },
      fail(error) {
        logError(`创建失败`, error);
      },
    });
  })
  .catch(error => {
    logError('error', error);
  });
}

const backPath = computed(() => {
  // 从 referrer 获取来源路径
  const fromPath = route.query.from || route.params.from;
  
  // 如果有 from 参数就用它，否则尝试获取上一个路由
  if (fromPath) {
    return fromPath;
  }
  
  // 获取路由历史
  const previousRoute = router.options.history.state.back;
  // 如果有上一个路由，使用它的路径
  if (previousRoute) {
    return previousRoute;
  }
  
  // 如果都没有，返回默认路径
  return '/blog';
});

const backTitle = computed(() => {
  // 根据来源路径显示不同的标题
  const previousRoute = router.options.history.state.back;
  if(previousRoute.includes('forum/section')  ){
    return '技术论坛';
  }else if(previousRoute.includes('forum')){
    return '技术论坛';
  }else{
    return '技术论坛';
  }
});

const onCancel = () => {
  router.push(backPath.value)
}

async function fetchData() {
  await jsonRPC({
    url: `/api/blog/write`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取发帖成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取发帖失败`, error);
      message.error(`获取发帖失败，请稍后再试`);
    },
  });
  await jsonRPC({
    url: `/api/blog/forum/section`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取技术论坛成功`, data);
      sectionItems.value = data.records;
      // 如果user_id != 2, 就删除sectionItems里name == '官方公告'的项
      if(sessionStorage.getItem('user_id') != 2){
        sectionItems.value = sectionItems.value.filter(item => item.name != '官方公告');
      }
      // 如果sectionItems.value数组对象的id等于props.id，则data_list.section_ids添加该props.id
      if (sectionItems.value.some(item => item.id === Number(route.query.section_id))) {
        data_list.section_ids.push(Number(route.query.section_id));
      }
    },
    fail(error) {
      logError(`获取技术论坛失败`, error);
      message.error(`获取技术论坛失败，请稍后再试`);
    },
  });
}

// 添加全局样式
const addMessageStyle = () => {
  const style = document.createElement('style');
  style.id = 'blog-write-message-style';
  style.innerHTML = `
    .my-message {
      z-index: 1000000 !important;
    }
  `;
  document.head.appendChild(style);
};

// 移除全局样式
const removeMessageStyle = () => {
  const style = document.getElementById('blog-write-message-style');
  if (style) {
    style.remove();
  }
};

const handleTitleChange = (e) => {
  const value = e.target.value;
  if (value && value.length > 30) {
    data_list.name = value.slice(0, 30);
    message.warning('标题最多30个字');
  }
};

onMounted(() => {
  fetchData();
  trackUV(router.currentRoute);
  addMessageStyle();
});

onUnmounted(() => {
  removeMessageStyle();
});
</script>

<style scoped lang="scss">
  .newTopic{
    color: #68A7FF;
    cursor: pointer;
    text-align: right;
  }
  .pop_data{
    color: #969696;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 5px;
    span{
      max-width: 200px;
    }
    .circle{
      opacity: 0.3;
    }
    &:hover{
      background-color: #e6f7ff;
    }
  }
.blog_write {
  margin-bottom: 30px;
  .ant-form{
    background-color: #fff;
    padding: 20px;
  }
  .activeBtn{
    background-color: #D5E9FF;
  }
  .categoryItem{
    line-height: 20px;
    border-radius: 15px;
    text-align: center;
    padding: 4px 13px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    &:hover{
      background-color: #e6f7ff;
    }
    &.active{
      background-color: #D5E9FF;
    }
  }
  .tip{
      color: #ff4d4f;
    }
  .richZone{
    position: relative;
    &.hasContentError{
      border: 1px solid #ff4d4f;
    }

    .editorLayer{
      position: absolute;
      width:400px;
      padding: 20px;
      border-radius: 15px;
      z-index: 200000;
      padding: 10px;
      line-height: 20px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 1);
      // background-color: red;
      text-align: center;
      box-shadow: 1px 1px 4px 2px rgba(176, 176, 176, 0.4);

    }
    .richZoneBtn{
      position: absolute;
      z-index: 100000;
    }
    .button{
      border-radius: 15px;
      height: 30px;
      width: 83px;
      color:#949494;
      border: 1px solid rgba(187, 187, 187, 1);
      position: absolute;
      z-index: 100000;
      bottom: 15px;
      &:nth-child(1){
        left: 18px;
      }
      &:nth-child(2){
        left: 113px;
      }
 
    }
  }
}
.blog_write {
  :deep(.my-message) {
    z-index: 1000000 !important;
  }
}
</style>
