<template>
  <div class="app_namespace">
    <a-row style="margin: 10px 0">
      <a-col :span="16"></a-col>
      <a-col :span="8" style="display: flex; flex-direction: row; justify-content: space-around">
        <a-input-search allowClear enter-button placeholder="请输入名称" style="width: 200px" @search="onSearch"/>
      </a-col>
    </a-row>
    <div class="row mx-0">
      <div class="col-md-4" v-for="row in data_list.records1" :key="row.id">
        <!-- <div class="abnormal"></div> -->
        <!-- <div class="deactivated"></div> -->
        <div :class="row.number ? '' : 'deactivated'">
          <div class="top">
            <h5>
              <img src="/hw_web_console/static/src/img/console_namespace.png" />
              {{row.name}}
              <span>{{row.ns_type}}</span>
            </h5>
            <p v-html="row.description"></p>
          </div>
          <div class="info">
            <h5><span>创建应用：{{row.number}}</span></h5>
            <div>
              <div>
                <span>{{row.limits_cpu}}核</span>
                <span>{{row.limits_memory}}GB</span>
              </div>
              <div v-if="!row.number">
                <a-button type="link" @click.stop="openEdit(row)">编辑</a-button>
                <a-popconfirm title="是否确定删除此任务?" @confirm.stop="confirm(row.id)">
                  <a-button danger @click.stop type="text">删除</a-button>
                </a-popconfirm>
              </div>
            </div>
          </div>
          <span v-if="row.number">运行中</span>
          <span v-else>未使用</span>
        </div>
      </div>
    </div>
    <a-modal v-model:open="open" title="编辑命名空间信息" okText="保存信息" @ok="handleOk">
      <a-form ref="formRef" :model="namespaceInfo" :rules="rules" layout="horizontal"
        :label-col="{style: {width: '120px'} }" :wrapper-col="{span: 14}">
        <a-form-item label="命名空间名称" name="name">
          <a-input v-model:value="namespaceInfo.name"/>
        </a-form-item>
        <a-form-item label="是否公开" name="ns_type">
          <a-select v-model:value="namespaceInfo.ns_type" :options="data_list.ns_type_ids"></a-select>
        </a-form-item>
        <a-form-item label="命名空间介绍" name="description">
          <a-textarea v-model:value="namespaceInfo.description" :rows="5"/>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-flex justify="center" class="py-3">
      <a-pagination
          v-model:current="current1"
          :showSizeChanger="showSizeChanger"
          v-model:page-size="pageSize"
          :total="total1"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
          :hideOnSinglePage="true"
      />
    </a-flex>
    <div class="dashed_line"></div>
    <h3>公共命名空间</h3>
    <div class="row mx-0">
      <div class="col-md-4" v-for="row in data_list.records" :key="row.id">
        <!-- <div class="abnormal"></div> -->
        <!-- <div class="deactivated"></div> -->
        <div :class="row.number ? '' : 'deactivated'">
          <div class="top">
            <h5>
              <img src="/hw_web_console/static/src/img/console_namespace.png" />
              {{row.name}}
              <span>{{row.ns_type}}</span>
            </h5>
            <p v-html="row.description"></p>
          </div>
          <div class="info">
            <h5><span>创建应用：{{row.number}}</span></h5>
            <div>
              <div>
                <span>{{row.limits_cpu}}核</span>
                <span>{{row.limits_memory}}GB</span>
              </div>
            </div>
          </div>
          <span v-if="row.number">运行中</span>
          <span v-else>未使用</span>
        </div>
      </div>
    </div>
    <a-flex justify="center" class="py-3">
      <a-pagination
          v-model:current="current"
          :showSizeChanger="showSizeChanger"
          v-model:page-size="pageSize"
          :total="total"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
          :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
logDebug("ConsoleAppNamespace setup.");
import { onMounted, ref, reactive, watch } from "vue";
import { jsonRPC } from "@/utils/http_utils";
import { zhCn } from "@/utils/zhCn";

const open = ref(false)
const formRef = ref()
const rules = {
  name: [
    {
      required: true,
      message: '请输入命名空间名称',
      trigger: 'blur',
    },
  ],
  ns_type: [
    {
      required: true,
      message: '请选择是否公开',
      trigger: 'change',
    },
  ],
  description: [
    {
      required: true,
      message: '请输入命名空间介绍',
      trigger: 'blur',
    },
  ],
}
const namespaceInfo = reactive({
  id: '',
  name: '',
  ns_type: '',
  description: '',
});
const openEdit = (e)=>{
  open.value = true;
  namespaceInfo.id = e.id;
  namespaceInfo.name = e.name;
  namespaceInfo.ns_type = e.ns_type == '公开'?'public':'private';
  namespaceInfo.description = e.description;
}
const handleOk = ()=>{
  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/vue/console/namespace/update",
      params: {
        id: namespaceInfo.id,
        name: namespaceInfo.name,
        ns_type: namespaceInfo.ns_type,
        description: namespaceInfo.description,
      },
      success() {
        fetchData()
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    }).then(()=>{
      open.value = false;
      formRef.value.resetFields();
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
}

const data_list = reactive({
  ns_type_ids: []
});
const total1 = ref(0);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(3);
const current1 = ref(1);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
function fetchData() {
  jsonRPC({
    url: "/vue/console/namespace",
    params: {
      page_index1: current1.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      Object.assign(data_list, res.data.result.data);
      total1.value = data_list.record_count1;
      total.value = data_list.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

onMounted(() => {
  fetchData();
});
watch([pageSize, current1 ,current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current1.value = 1;
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};

import { message } from 'ant-design-vue';
const confirm = namespace_id => {
  jsonRPC({
    url: "/vue/console/namespace/delete",
    params: {
      namespace_id
    },
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      fetchData();
      message.success('删除成功');
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
</script>

<style scoped lang="scss">
.row {
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: -1rem;
  > .col-md-4 {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 1rem;
    > div {
      position: relative;
      height: 100%;
      border: 1px solid #dbedff;
      border-radius: 8px;
      padding: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        padding: 5px;
        h5 {
          font-size: 16px;
          margin-bottom: 1rem;
          margin-right: 65px;
          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
          span {
            display: inline-block;
            margin-left: 10px;
            height: 26px;
            line-height: 26px;
            padding: 0 1rem;
            border-radius: 20px;
            background-color: #57b1ff;
            color: #fff;
          }
        }
        p {
          color: #807e7e;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
      .info {
        border-top: 1px solid #cdcdcd;
        h5 {
          margin: 15px 5px 20px;
          font-size: 16px;
          span {
            display: inline-block;
            margin-left: 30px;
            font-size: 16px;
          }
        }
        > div {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: space-between;
          margin: 0 5px 5px;
          span {
            display: inline-block;
            width: 100px;
            height: 30px;
            line-height: 30px;
            background-color: #dbedff;
            border-radius: 4px;
            text-align: center;
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
          a {
            color: #cdcdcd;
          }
        }
      }
      > span {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: 80px;
        height: 30px;
        line-height: 30px;
        background-color: #dbedff;
        border-radius: 0px 8px 0px 8px;
        text-align: center;
      }
      &.deactivated {
        border: 1px solid #ffe5be;
        > span {
          background-color: #ffe5be;
        }
      }
      &.abnormal {
        border: 1px solid #ffdbdb;
        > span {
          background-color: #ffdbdb;
        }
      }
    }
  }
}
.dashed_line {
  border-bottom: 2px dashed #61b0ff;
  margin: 40px 0 20px;
}
</style>
