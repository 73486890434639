<template>
  <div class="blog_category">
    <div class="top">
      <h2 v-if="data_list.category_name">{{data_list.category_name}}</h2>
      {{data_list.comment_count}} 互动 ， {{data_list.record_count}} 帖子
    </div>
    <a-row gutter="20" class="mx-0">
      <a-col :span="2"></a-col>
      <a-col :span="20">
        <div class="info">
          <div class="blog_title">
            <div class="tags">
              <a class="tag" :class="{ active: tag_id == 1 }" @click="onTag(1)">最新</a>
              <a class="tag" :class="{ active: tag_id == 0 }" @click="onTag(0)">推荐</a>
              <a class="tag" :class="{ active: tag_id == 2 }" @click="onTag(2)">我的关注</a>
            </div>
            <div class="search">
              <a @click="goToWrite" class="write_blog">
                <img src="/hw_blog/static/src/img/write_blog.png"/>
                <span>发帖子</span>
              </a>
              <a-input-search placeholder="请输入帖子关键词" enter-button allowClear @search="onSearch"/>
            </div>
          </div>
          <div class="blog_list" v-for="(item, index) in data_list.records" :key="index">
            <div class="blog_list_top">
              <router-link :to="`/blog/homepage?user_id=${item.user.id}`" class="user">
                <img v-if="item.user.has_image" :src="`/web/image/res.users/${item.user.id}/image_128`"/>
                <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
                {{item.user.name}}
              </router-link>
              <span class="ml-3">{{ item.post_date }}</span>
            </div>
            <div class="blog_list_content">
              <div>
                <h5>
                  <router-link :to="`/blog/${item.id}`">{{ item.name }}</router-link>
                  <span v-for="i in item.tag_ids" :key="i.id">{{i.name}}</span>
                </h5>
                <div ref="contentRefs" :style="item.ellipsis ? ellipsisStyle : expandedStyle">
                  <div v-html="item.content"></div>
                </div>
                <a-flex v-if="expandableItems[index]" justify="center">
                  <a-button type="text" @click="item.ellipsis=!item.ellipsis" style="color: #429BFF;">
                    <template v-if="item.ellipsis" #icon><DownOutlined style="display: inline-flex;"/></template>
                    <template v-else #icon><UpOutlined style="display: inline-flex;"/></template>
                    <template v-if="item.ellipsis">展开全文</template>
                    <template v-else>收起全文</template>
                  </a-button>
                </a-flex>
              </div>
              <img v-if="item.is_image" :src="`/web/image/hw.blog.post/${item.id}/image_512`"/>
            </div>
            <div class="blog_list_bottom">
              <a-tooltip placement="bottom">
                <template #title>浏览量</template>
                <span>
                  <img src="/hw_blog/static/src/img/visited.png"/>
                  <span>{{ item.visits }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template #title>评论人数</template>
                <span>
                  <img v-if="item.is_comments" src="/hw_blog/static/src/img/info.png"/>
                  <img v-else src="/hw_blog/static/src/img/comment.png"/>
                  <span>{{ item.comments }}</span>
                </span>
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template #title>点赞</template>
                <span>
                  <img v-if="item.is_likes" @click="onBlogLike(item.id)" src="/hw_blog/static/src/img/liked.png"/>
                  <img v-else @click="onBlogLike(item.id)" src="/hw_blog/static/src/img/praise.png"/>
                  <span>{{ item.likes }}</span>
                </span>
              </a-tooltip>
            </div>
          </div>
          <!-- 分页 -->
          <a-flex justify="center" class="mt-3">
            <a-pagination
              v-model:current="current"
              :showSizeChanger="showSizeChanger"
              v-model:page-size="pageSize"
              :total="data_list.record_count"
              :locale="zhCn"
              :pageSizeOptions="pageSizeOptions"
              :hideOnSinglePage="true"
            />
          </a-flex>
        </div>
      </a-col>
      <a-col :span="2"></a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { UpOutlined, DownOutlined } from '@ant-design/icons-vue';
import { logDebug, logError } from "@/utils/logger";
import { h,onMounted, ref, reactive, watch, nextTick } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { zhCn } from "@/utils/zhCn";
import { getRouterParam } from "@/utils/router_utils";
import { message, Modal } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter } from "vue-router";

const router = useRouter();
const category_id = ref(getRouterParam(router, "id"));
const tag_id = ref(1);
const search = ref('');
const data_list = reactive({
  records: [],
  record_count: 0,
  category_name: null,
  comment_count: 0,
});
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
const goToWrite = () => {
  if (!data_list.meta1phone) {
    Modal.warning({
      title: '实名认证提醒',
      content: h('div', {}, [
        h('p', '使用应用、实训、论坛等需要实名认证，未实名认证将会被限制使用，首次实名认证成功可获得 30 点算力点。'),
        h(
          'a',
          {
            style: { color: 'blue', cursor: 'pointer' },
            onClick: () => {
              router.push('/my'); // 点击蓝色 a 标签跳转
              Modal.destroyAll(); // 关闭弹窗
            },
          },
          '点击进行实名'
        )
      ]),
      width: '50%',
      footer: null,
      maskClosable: true,
      closable: true,
    });
    return;
  }
  router.push('/blog/write');
};
function fetchData() {
  jsonRPC({
    url: `/api/blog/category`,
    params: {
      category_id: category_id.value,
      tag_id: tag_id.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取数据成功`, data);
      Object.assign(data_list, data);
      checkExpandable();
    },
    fail(error) {
      logError(`获取数据失败`, error);
      message.error(`获取数据失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV(router.currentRoute);
});
watch([tag_id, pageSize, current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
const onTag = (e)=>{
  tag_id.value = e;
}
const onBlogLike = (e) => {
  jsonRPC({
    url: `/api/blog/like/${e}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
const contentRefs = ref([]); // 文章内容的 DOM 引用
const expandableItems = ref([]); // 存储每篇文章是否需要展开按钮
const ellipsisStyle = {
  maxHeight: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 5, // 省略 5 行
};
const expandedStyle = {
  maxHeight: 'none',
  overflow: 'visible',
};
// **计算哪些文章需要“展开”按钮**
const checkExpandable = () => {
  nextTick(() => {
    expandableItems.value = contentRefs.value.map((content) => {
      if (content) {
        const lineHeight = parseFloat(getComputedStyle(content).lineHeight); // 获取行高
        const maxVisibleHeight = lineHeight * 5; // 5 行的高度
        return content.scrollHeight > maxVisibleHeight; // 计算是否超出
      }
      return false;
    });
  });
};
</script>

<style scoped lang="scss">
.blog_category {
  margin-bottom: 50px;
  .top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 95px;
    background-color: #fff;
    text-align: center;
    margin-bottom: 15px;
  }
  .info{
    // background-color: #fff;
    .blog_title{
      display: flex;
      margin-bottom: 15px;
      .tags {
        flex: 1 1 100%;
        display: flex;
        flex-wrap: wrap;
        .tag {
          color: #000;
          border-radius: 8px;
          padding: 0.3rem 1rem;
          cursor: pointer;
          &.active,
          &:hover {
            background-color: #D5E9FF;
          }
          &:not(:last-child){
            margin-right: 10px;
          }
        }
      }
      .search {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        .write_blog {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          background-color: #77b7ff;
          color: #fff;
          border-radius: 15px;
          padding: 0.25rem 0.75rem;
          line-height: 20px;
          margin-right: 1rem;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .ant-btn-primary{
          background-color: #77b7ff;
        }
      }
    }
    .blog_list{
      padding: 15px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.4);
      margin-bottom: 15px;
      .blog_list_top{
        display: flex;
        align-items: center;
        color: #A1A1A1;
        margin-bottom: 5px;
        .user{
          display: flex;
          align-items: center;
          color: #A1A1A1;
          &:hover{
            color: #ff8c00;
          }
        }
        img{
          height: 25px;
          width: 25px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
      .blog_list_content{
        display: flex;
        gap: 10px;
        >div{
          flex-grow: 1;
          h5{
            display: flex;
            flex-flow: wrap;
            gap: 5px;
            a{
              color: #000;
              font-size: 18px;
              font-weight: bold;
              &:hover{
                color: #ff8c00;
              }
            }
            span{
              background-color: #E4EFFF;
              color: #2580fd;
              padding: 3px 7px;
              border-radius: 4px;
              margin-bottom: -3px;
              position: relative;
              top: -2px;
              margin-left: 6px;
              font-size: 14px;
            }
          }
        }
        >img{
          flex-shrink: 0;
          width: 100%;
          max-width: 200px;
          object-fit: cover;
        }
      }
      .blog_list_bottom{
        display: flex;
        justify-content: flex-end;
        column-gap: 18px;
        margin-top: 5px;
        img{
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
