<template>
  <div id="bucket-mount1">
    <div id="bucket-mount">
      <h4>存储桶挂载</h4>
      <div v-if="!vm_enable" class="bucket-mount-content">
        <div id="mounted_table">
          <a-table bordered :data-source="oss_mounted_list" :columns="columns" :pagination="false">
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'operation'">
                <a-popconfirm
                    v-if="oss_mounted_list.length"
                    title="确定删除?"
                    @confirm="onDelete(record.id)"
                >
                  <a-button danger size="small">删除</a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
          <a-alert type="warning" message="需要新挂载，请先删除已挂载路径" v-if="oss_mounted_list.length"></a-alert>
        </div>
        <div>
          <a-card title="存储桶挂载">
            <!-- 选择桶 -->
            <a-form layout="vertical" ref="formRef" :model="data_list" class="mb-3" :disabled="oss_mounted_list.length">
              <!-- 选择挂载类型 -->
              <a-form-item label="挂载类型" :rules="[{ required: true, message: '请选择挂载类型!' }]">
                <a-radio-group v-model:value="data_list.mount_type" :options="tagsDataOptions" optionType="button"
                               buttonStyle="solid"/>
              </a-form-item>
              <!-- 选择挂载桶 -->
              <a-form-item label="选择挂载桶" name="selectedBucket" :rules="[{ required: true, message: '请选择桶!' }]">
                <a-select
                    v-model:value="data_list.selectedBucket"
                    placeholder="请选择桶"
                    show-search
                    :options="bucketList"
                    :default-active-first-option="false"
                    style="width: 100%;"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                >
                </a-select>
              </a-form-item>
              <!-- 选择挂载文件夹 -->
              <a-form-item v-if="data_list.mount_type === 'bucket_subdir'" label="选择挂载文件夹"
                           name="selectedbucketTree" :rules="[{ required: true, message: '请选择挂载文件夹!' }]">
                <a-select
                    v-model:value="data_list.selectedbucketTree"
                    placeholder="请选择挂载文件夹"
                    show-search
                    :options="bucketTree"
                    :default-active-first-option="false"
                    style="width: 100%;"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                >
                </a-select>
              </a-form-item>
              <!-- 需要到应用中的文件夹 -->
              <a-form-item name="selectOssMountDirList">
                <template #label>
                <span>
                  挂载目标路径(默认路径：/hi168_oss_data)
                  <a-popover trigger="hover">
                    <template #content>
                      <a>若不可选，请检查当前应用模板中是否配置可挂载路径</a>
                    </template>
                    <QuestionCircleTwoTone class="help-icon"/>
                  </a-popover>
                </span>
                </template>
                <a-select
                    v-model:value="data_list.selectOssMountDirList"
                    :disabled="!oss_mount_dir_list.length"
                    placeholder="请选择挂载文件夹"
                    show-search
                    :options="oss_mount_dir_list"
                    :default-active-first-option="false"
                    style="width: 100%;"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                >
                </a-select>

              </a-form-item>
            </a-form>
            <a-flex justify="center" align="center" gap="15">
              <a-button type="primary" @click="confirmMount" :loading="loading" :disabled="oss_mounted_list.length">
                确认挂载
              </a-button>
              <a-button v-show="false" @click="cancelMount">取消</a-button>
              <a-button v-show="false" @click.prevent="getBucketData">刷新</a-button>
            </a-flex>
          </a-card>
        </div>
      </div>
      <div v-else class="vm-bucket-mount-content">
        <div>
          <div class="steps-header">
            <a-steps
                :items="items"
                v-model:current="current"
            ></a-steps>
          </div>

          <div class="steps-content">
            <!-- 步骤1: 获取访问密钥 -->
            <template v-if="current === 0">
              <div class="key-info-container">
                <a-alert
                    message="请注意保护您的账号安全"
                    banner
                    type="warning"
                    class="mb-4"
                />
                <div style="display: flex; justify-content: flex-end;">
                  <a-button type="link" @click="handleApplyNewAccount">
                    <template #icon><PlusOutlined /></template>
                    申请账号
                  </a-button>
                </div>
                <a-card class="key-info-card">
                  <div class="key-info-item">
                    <div class="key-info-label">OSS用户名称:</div>
                    <div class="key-info-value">
                      <a-typography-paragraph copyable>{{ oss_server_data.oss_user_name }}</a-typography-paragraph>
                    </div>
                  </div>

                  <div class="key-info-item">
                    <div class="key-info-label">
                      OSS Secret Key:
                      <EyeOutlined v-if="!showSecretKey" @click="showSecretKey = true" class="eye-icon"/>
                      <EyeInvisibleOutlined v-else @click="showSecretKey = false" class="eye-icon"/>
                    </div>
                    <div class="key-info-value">
                      <a-typography-paragraph copyable>
                        {{ showSecretKey ? oss_server_data.oss_secret_key : '••••••••••••••••' }}
                      </a-typography-paragraph>
                    </div>
                  </div>

                  <div class="key-info-item">
                    <div class="key-info-label">
                      OSS Access Key:
                      <EyeOutlined v-if="!showAccessKey" @click="showAccessKey = true" class="eye-icon"/>
                      <EyeInvisibleOutlined v-else @click="showAccessKey = false" class="eye-icon"/>
                    </div>
                    <div class="key-info-value">
                      <a-typography-paragraph copyable>
                        {{ showAccessKey ? oss_server_data.oss_access_key : '••••••••••••••••' }}
                      </a-typography-paragraph>
                    </div>
                  </div>

                  <div class="key-info-actions">
                    <a-button type="primary" @click="copyAllInfo">复制全部</a-button>
                  </div>
                </a-card>
              </div>
            </template>

            <!-- 步骤2: 安装挂载工具 -->
            <template v-if="current === 1">
              <p>请依次执行以下命令安装必要的工具：</p>

              <a-typography-paragraph copyable>
                <pre># Debian/Ubuntu 系统
sudo apt-get update
sudo apt-get install -y s3fs</pre>
              </a-typography-paragraph>

              <a-alert
                  class="mt-3"
                  message="其他系统安装方式"
                  description="如果上述命令无法安装，请根据您的系统选择对应的安装命令："
                  type="info"
                  show-icon
              />

              <a-typography-paragraph copyable class="mt-3">
                <pre># CentOS/RHEL 系统
sudo yum install epel-release
sudo yum install s3fs-fuse

# Fedora 系统
sudo dnf install s3fs-fuse

# SUSE/openSUSE 系统
sudo zypper install s3fs</pre>
              </a-typography-paragraph>

              <a-alert
                  class="mt-3"
                  message="源码编译安装"
                  description="如果以上方法都无法安装，请尝试从源码编译安装："
                  type="info"
                  show-icon
              />

              <a-typography-paragraph copyable class="mt-3">
                <pre># 安装编译所需的依赖
sudo apt-get install -y build-essential libfuse-dev libcurl4-openssl-dev libxml2-dev pkg-config automake

# 下载并编译安装 s3fs
wget https://github.com/s3fs-fuse/s3fs-fuse/archive/refs/tags/v1.91.tar.gz
tar -xzf v1.91.tar.gz
cd s3fs-fuse-1.91
./autogen.sh
./configure
make
sudo make install

# 验证安装
s3fs --version</pre>
              </a-typography-paragraph>
            </template>

            <!-- 步骤3: 挂载存储桶 -->
            <template v-if="current === 2">
              <div>
                <p>选择要挂载的存储桶：</p>
                <a-form layout="vertical" ref="formRef" :model="data_list" class="mb-3">
                  <a-form-item label="选择挂载桶" name="selectedBucket"
                               :rules="[{ required: true, message: '请选择桶!' }]">
                    <a-select
                        v-model:value="data_list.selectedBucket"
                        placeholder="请选择桶"
                        show-search
                        :options="bucketList"
                        :default-active-first-option="false"
                        style="width: 100%;"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                    >
                    </a-select>
                  </a-form-item>
                </a-form>
              </div>
              <div v-if="data_list.selectedBucket">
                <p>执行以下命令挂载存储桶:</p>
                <a-typography-paragraph copyable>
                  <pre># 创建挂载目录
sudo mkdir -p /mnt/{{ data_list.selectedBucket }}

# 创建配置文件
echo "{{ oss_server_data.oss_access_key }}:{{ oss_server_data.oss_secret_key }}" > /etc/.passwd-s3fs
chmod 600 /etc/.passwd-s3fs

# 挂载存储桶
s3fs {{ data_list.selectedBucket }} /mnt/{{ data_list.selectedBucket }} \
  -o passwd_file=/etc/.passwd-s3fs \
  -o url={{ oss_server_data.oss_base_url }} \
  -o use_path_request_style \
  -o allow_other \
  -o dbglevel=info \
  -o ssl_verify_hostname=0 \
  -o nonempty

# 验证挂载
df -h | grep s3fs</pre>
                </a-typography-paragraph>

                <a-alert
                    class="mt-3"
                    message="开机自动挂载"
                    description="如需设置开机自动挂载，请将以下内容添加到 /etc/fstab 文件："
                    type="info"
                    show-icon
                />

                <a-typography-paragraph copyable class="mt-3">
                  <pre>
s3fs#{{ data_list.selectedBucket }} /mnt/{{ data_list.selectedBucket }} fuse.s3fs _netdev,allow_other,use_path_request_style,passwd_file=/etc/.passwd-s3fs,url={{ oss_server_data.oss_base_url }},nonempty 0 0</pre>
                </a-typography-paragraph>
              </div>
            </template>

            <!-- 步骤4: 完成 -->
            <template v-if="current === 3">
              <div v-if="data_list.selectedBucket" class="completion-container">
                <a-result
                    status="success"
                    title="存储桶挂载配置完成！"
                    :sub-title="`您可以在 /mnt/${data_list.selectedBucket} 目录下访问存储桶中的文件`"
                >
                  <template #extra>
                    <div class="completion-info">
                      <a-card title="常用操作命令" bordered>
                        <a-space direction="vertical" size="middle" style="width: 100%">
                          <div>
                            <a-typography>
                              <a-typography-title :level="5">查看挂载状态：</a-typography-title>
                              <a-typography-paragraph copyable>
                                df -h | grep s3fs
                              </a-typography-paragraph>
                            </a-typography>
                          </div>

                          <div>
                            <a-typography>
                              <a-typography-title :level="5">进入挂载目录：</a-typography-title>
                              <a-typography-paragraph copyable>
                                cd /mnt/{{ data_list.selectedBucket }}
                              </a-typography-paragraph>
                            </a-typography>
                          </div>

                          <div>
                            <a-typography>
                              <a-typography-title :level="5">卸载存储桶：</a-typography-title>
                              <a-typography-paragraph copyable>
                                sudo umount /mnt/{{ data_list.selectedBucket }}
                              </a-typography-paragraph>
                            </a-typography>
                          </div>
                        </a-space>
                      </a-card>

                      <a-card title="注意事项" class="mt-3" bordered>
                        <a-space direction="vertical" size="small">
                          <a-typography-text>
                            1. 首次挂载后，建议先测试读写权限
                          </a-typography-text>
                          <a-typography-text>
                            2. 如遇到问题，可以查看系统日志:
                            <a-typography-text code>dmesg | tail</a-typography-text>
                          </a-typography-text>
                          <a-typography-text>
                            3. 重启系统后需要重新挂载，除非已配置自动挂载
                          </a-typography-text>
                        </a-space>
                      </a-card>
                    </div>
                  </template>
                </a-result>
              </div>
              <div v-else>
                <a-result
                    status="warning"
                    title="未选择存储桶"
                    sub-title="请返回上一步选择要挂载的存储桶"
                >
                  <template #extra>
                    <a-button type="primary" @click="current = 2">
                      返回选择存储桶
                    </a-button>
                  </template>
                </a-result>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, reactive, watch, computed} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {logDebug, logError} from "@/utils/logger";
import {QuestionCircleTwoTone, EyeInvisibleOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons-vue";

const bucketTree = ref([]); // 挂载点树
const bucketPrefix = ref(''); // 当前选择的前缀
const selectedPath = ref(null); // 当前选择的挂载路径
// 添加密钥显示状态控制
const showSecretKey = ref(false);
const showAccessKey = ref(false);
const formRef = ref()
const loading = ref(false)
const columns = [
  {
    title: '已挂载',
    dataIndex: 'bucket_subdir_path',
    width: '20%',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '5%',
  },
]

const onDelete = id => {
  logDebug("删掉的ID为", id)
  oss_mounted_list.value = oss_mounted_list.value.filter(item => item.id !== id);
  confirmUnmount(id)
};

const confirmUnmount = (item) => {
  logDebug("确认卸载、删掉的ID为", item)

  jsonRPC({
    url: "/api/unmount/oss/bucket",
    params: {
      'mounted_id': item,
      'env_id': envId,
    },
    async success(res) {
      const data = getResponseData(res);
      logDebug(`卸载桶成功`, data);
      await window.parent.postMessage(JSON.stringify({
        event: 'restartApplication',
        data: {
          desktop_app_id: Number(envId) + 10000,
        }
      }), "*");
    },
    fail(error) {
      logError(`卸载桶失败`, error);
      message.error(error);
    },
  });
}

const data_list = reactive({
  mount_type: "bucket", // 挂载类型（桶/文件夹）
  selectedBucket: null, // 当前选择的桶
  selectedbucketTree: null, // 当前选择的桶
  selectOssMountDirList: null, // 当前选择的文件夹
  fullPath: "", // 挂载路径
});
const tagsDataOptions = reactive([
  {
    value: 'bucket',
    label: '存储桶',
  },
  {
    value: 'bucket_subdir',
    label: '桶/文件夹',
  },
]);

// 加载数桶详情文件夹数据
const onBucketDirDetail = (mount_type, bucketName) => {
  if (!bucketName) {
    return
  }
  jsonRPC({
    url: "/api/query/bucket/prefix/dir",
    params: {
      'bucket_name': bucketName,
      'prefix': bucketPrefix.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`加载数桶详情文件夹数据成功`, data);
      data_list.selectedbucketTree = null
      if (data && data.folders.length > 0) {
        bucketTree.value = []
        data.folders.forEach((folder, index) => {
          bucketTree.value.push(
              {
                index: index,
                value: folder.Prefix,
                label: folder.Prefix,
              }
          )
        })
      } else {
        bucketTree.value = []
      }
    },
    fail(error) {
      logError(`加载数桶详情文件夹数据失败`, error);
      message.error(error);
    },
  });
}

// 监听桶文件夹的选择
watch([() => data_list.mount_type, () => data_list.selectedBucket], () => {
  if (data_list.mount_type === "bucket_subdir") {
    // 获取挂载点树数据
    onBucketDirDetail(data_list.mount_type, data_list.selectedBucket);
  }
  selectedPath.value = null;
  data_list.fullPath = "";
})
watch([() => data_list.selectedBucket, () => data_list.selectedbucketTree], () => {
  data_list.fullPath = `${data_list.selectedBucket}/${data_list.selectedbucketTree}`;
})

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})
const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal;
const data = props.data;
const envId = data['env_id']
const vm_enable = data['vm_enable']

function removeTrailingSlash(path) {
  // 判断是否为 null、undefined 或非字符串
  if (path == null || typeof path !== 'string') {
    return path;
  }
  // 判断是否以斜杠结尾并去掉
  if (path.endsWith('/')) {
    return path.slice(0, -1);
  }
  return path;
}

// 确认挂载
const confirmMount = async () => {
  logDebug("确认挂载")
  logDebug(envId)
  logDebug(data_list.mount_type)
  logDebug(data_list.selectedBucket)
  logDebug(removeTrailingSlash(data_list.fullPath))
  formRef.value.validate()
      .then(async () => {
        loading.value = true;
        await jsonRPC({
          url: "/api/mount/oss/bucket",
          params: {
            "mount_type": data_list.mount_type,
            "bucket_name": data_list.selectedBucket,
            "env_id": envId,
            "bucket_subdir_path": removeTrailingSlash(data_list.selectedbucketTree),
            "app_mount_path": removeTrailingSlash(data_list.selectOssMountDirList),
          },
          async success(res) {
            const data = getResponseData(res);
            logDebug(data);
            message.success('挂载成功，服务将重启，请稍等片刻')
            await window.parent.postMessage(JSON.stringify({
              event: 'restartApplication',
              data: {
                desktop_app_id: Number(envId) + 10000,
              }
            }), "*");
          },
          fail(error) {
            logError(`获取自定义桶数据失败`, error);
            message.error(error)
          },
        }).then(() => {
          loading.value = false;
        });
        return
      })
      .catch(error => {
        logError('error', error);
        return
      });
}
// 取消挂载
const cancelMount = () => {
  logDebug("取消挂载")
}

// 桶列表及树形数据示例
const bucketList = ref([]);
const oss_mounted_list = ref([]);
const oss_mount_dir_list = ref([]);
// 获取存储桶数据
const getBucketData = async () => {
  jsonRPC({
    url: "/api/query/user/bucket/list",
    success(res) {
      const data = getResponseData(res);
      logDebug(data);
      bucketList.value = []
      data.user_id_bucket_list.forEach(r => {
        bucketList.value.push({
          value: r.bucket_name,
          label: `ID:${r.key} -名称：${r.bucket_custom_name}`,
        });
      });
    },
    fail(error) {
      logError(`获取自定义桶数据失败`, error);
      message.error(error);
    },
  });
}

// 查询当前APP可用的挂载路径
const queryBaseBucket = async (envId) => {
  logDebug("获取当前实验环境已经挂载的OSS信息");
  logDebug(envId);
  jsonRPC({
    url: "/api/mount/oss/user/base/info",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res)
      oss_mounted_list.value = data.oss_mounted_list
      oss_mount_dir_list.value = data.oss_mount_dir_list
      logDebug("获取当前实验环境已经挂载的OSS信息", data);
      logDebug(data)
      return true
    },
    fail(error) {
      logError(`获取当前实验环境已经挂载的OSS信息失败`, error)
      message.error(`获取当前实验环境已经挂载的OSS信息失败`, 3);
      return false
    }
  })
}

const current = ref(0);
const steps = [
  {
    title: '获取访问密钥',
  },
  {
    title: '安装挂载工具',
  },
  {
    title: '挂载存储桶'
  },
  {
    title: '生成挂载命令',
  },
];
const items = computed(() => steps.map(item => ({
  key: item.title,
  title: item.title
})));

const oss_server_data = reactive({
  "id": null,
  "oss_access_key": null,
  "oss_secret_key": null,
  "oss_user_name": null,
  "oss_base_url": null,
})

const openOSSBucketInfoModal = ref(false);
const openOSSBucketInfoLoading = ref(false);

function queryOssBucketInfo() {
  openOSSBucketInfoLoading.loading = true
  jsonRPC({
    url: "/user/oss/server/info",
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取oss_servers账号信息成功`, data);
      if (data.oss_server_data) {
        Object.assign(oss_server_data, data.oss_server_data);
      }
    },
    fail(error) {
      logError(`获取oss_servers账号信息失败`, error);
      message.error(error);
    },
  }).then(() => {
    openOSSBucketInfoLoading.loading = false
    openOSSBucketInfoModal.value = true
  });

}

onMounted(() => {
  logDebug(`TerminalMountBucket onMounted. envId[${envId}]`);
  getBucketData()
  queryOssBucketInfo()
  queryBaseBucket(envId)
});

const onSelected = () => {
  logDebug(`TerminalStatus onSelected. data[${JSON.stringify(data)}]`);
  getBucketData();
};


function handleApplyNewAccount() {
  // 检查申请时间限制
  const lastApplyTime = localStorage.getItem('lastOssAccountApplyTime');
  const now = Date.now();
  if (lastApplyTime && now - parseInt(lastApplyTime) < 10 * 60 * 1000) {
    const remainingMinutes = Math.ceil((10 * 60 * 1000 - (now - parseInt(lastApplyTime))) / 60000);
    message.warning(`请求过于频繁，请在${remainingMinutes}分钟后再试`);
    return;
  }
  openOSSBucketInfoLoading.loading = true
  jsonRPC({
    url: "/user/oss/server/apply",
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取oss_servers账号信息成功`, data);
      if (data.oss_server_data) {
        Object.assign(oss_server_data, data.oss_server_data);
        message.success("申请新账号成功！");
        // 记录申请时间
        localStorage.setItem('lastOssAccountApplyTime', Date.now().toString());
      }
    },
    fail(error) {
      logError(`申请新账号失败`, error);
      message.error(error);
    },
  }).then(() => {
    openOSSBucketInfoLoading.loading = false
    openOSSBucketInfoModal.value = true
  });}

defineExpose({
  primal,
  data,
  onSelected,
});
</script>

<style scoped lang="scss">
#mounted_table {
  margin-bottom: 15px;
}

#bucket-mount1 {
  height: v-bind(contentHeight);
  overflow-y: scroll;
}

#bucket-mount {
  padding: 15px;
  width: 70%;
  height: 100%;
  margin: 0 auto;
}

:where(.ant-radio-group) {
  :where(.ant-radio-button-wrapper) {
    color: #278FFF;
    border: none;
    line-height: 32px;
    border-radius: 16px;
    background-color: #EDEDED;
    margin-right: 10px;

    &:not(:first-child)::before {
      content: none
    }

    &:first-child {
      border-inline-start: none;
      border-start-start-radius: 0;
      border-end-start-radius: 0;
      border-radius: 16px;
    }

    &:last-child {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
      border-radius: 16px;
    }
  }
}

.steps-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.steps-nav {
  flex: 1;
  margin-right: 24px;
}

.steps-buttons {
  display: flex;
  gap: 8px;

  .ant-btn {
    min-width: 80px;
  }
}


pre {
  background: #f6f8fa;
  padding: 12px;
  border-radius: 6px;
  overflow-x: auto;
}

.copy-button {
  margin-top: 8px;
}

.command-block {
  position: relative;

  .copy-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
}

.key-info-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.key-info-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
}

.key-info-item {
  margin-bottom: 24px;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
}

.key-info-label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.key-info-value {
  margin-left: 16px;
  font-size: 15px;

  :deep(.ant-typography) {
    margin-bottom: 0;
  }
}

.eye-icon {
  margin-left: 12px;
  font-size: 16px;
  cursor: pointer;
  color: #1890ff;

  &:hover {
    color: #40a9ff;
  }
}

.key-info-actions {
  margin-top: 24px;
  text-align: center;
}

:deep(.ant-alert-banner) {
  margin: -24px -24px 24px -24px;
  border-radius: 8px 8px 0 0;
  font-size: 15px;
}

:deep(.ant-typography-copy) {
  margin-left: 12px;
  font-size: 16px;
}

.completion-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
}

.completion-info {
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
}

.mt-3 {
  margin-top: 16px;
}
:where(.ant-typography){
  position: relative;
  // pre的兄弟元素ant-typography-copy的位置
  :where(pre + .ant-typography-copy){
    position: absolute;
    top: 10px;
    right: 12px;
  }
}
</style>