<template>
  <div>
    <a-button id="function-go-home" :style="props.styleSetting.buttonStyle" type="primary"
              @click="clickHandler">
      <a-tooltip>
        <template #title>回到首页</template>
        <HomeOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
  </div>
</template>

<script setup>
import {onMounted} from 'vue';
import {HomeOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug} from "@/utils/logger";

logComponents([HomeOutlined])
logDebug('TerminalFunctionGoHome setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data

const clickHandler = function () {
  logDebug('回到首页')
  logDebug(JSON.stringify(props.data))

  window.location.href = "/home"

  // 下面是触发父组件回调方法的示例：
  // emit("ok", "我是子组件fullscreen传入的ok值"); //触发事件
  // emit("cancel", "我是子组件fullscreen传入的cancel值"); //触发事件
  // emit("refresh", "我是子组件fullscreen传入的refresh值"); //触发事件
  // emit("customize", {
  //   'arg1': '参数1',
  //   'arg2': 2,
  // }) //触发事件
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalFunctionGoHome onMounted.`)
})

const onSelected = function () {
  logDebug(`TerminalFunctionGoHome onSelected.`)
}

defineExpose({
  primal,
  data,
  onSelected,
})


</script>

<style scoped>
#function-go-home {
  background: #00BFFF;
}
</style>

