<template>
  <a-layout id="console">
    <a-layout-sider v-model:collapsed="collapsed" collapsible theme="light" width="200" style="background: #fff">
      <a-menu v-model:selectedKeys="selectedKeys" theme="light" mode="inline">
        <a-menu-item key="home">
          <HomeOutlined />
          <span><router-link to="/console/home">我的主页</router-link></span>
        </a-menu-item>
        <a-menu-item key="live">
          <DesktopOutlined />
          <span><router-link to="/console/live">我的直播</router-link></span>
        </a-menu-item>
        <a-menu-item key="class">
          <TeamOutlined />
          <span><router-link to="/console/class">培训班级</router-link></span>
        </a-menu-item>
        <a-menu-item key="eduAdmin" v-if="check_teacher">
          <ReadOutlined />
          <span><router-link to="/console/eduAdmin">教务管理</router-link></span>
        </a-menu-item>
        <a-menu-item key="app">
          <AppstoreOutlined />
          <span><router-link to="/console/app">我的应用</router-link></span>
        </a-menu-item>
        <a-menu-item key="job">
          <SolutionOutlined />
          <span><router-link to="/console/job">求职就业</router-link></span>
        </a-menu-item>
        <a-menu-item key="course">
          <BookOutlined />
          <span><router-link to="/console/course">我的课程</router-link></span>
        </a-menu-item>
<!--        <a-menu-item key="image">-->
<!--          <HddOutlined />-->
<!--          <span><router-link to="/console/image">我的镜像</router-link></span>-->
<!--        </a-menu-item>-->
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {jsonRPC} from "@/utils/http_utils";
import {logError} from "@/utils/logger";
import {
  HomeOutlined,
  DesktopOutlined,
  TeamOutlined,
  ReadOutlined,
  AppstoreOutlined,
  SolutionOutlined,
  BookOutlined,
} from "@ant-design/icons-vue";
import { watchRouterSelectedKeys } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();
const collapsed = ref(false);

const selectedKeys = ref([]);
watchRouterSelectedKeys(router, selectedKeys, {
  home: "/console/home",
  live: "/console/live",
  class: "/console/class",
  student: "/console/student",
  eduAdmin: "/console/eduAdmin",
  app: "/console/app",
  job: "/console/job",
  course: "/console/course",
  // image: "/console/image",
});
const check_teacher = ref(false)
onMounted(() => {
  return jsonRPC({
    url: "/console/is_teacher",
    params: {},
    success(res) {
      check_teacher.value = res.data.result.data
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
});

import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});

</script>

<style scoped lang="scss">
#console {
  background: #fff;
  min-height: 80vh;
}
</style>
