<template>
  <div class="shop_page">
    <div class="container">
    <a-flex justify="flex-end" align="center">
      <a-button @click="_onMyOrder"><img src="/hw_web/static/src/img/shop_order_list.png" />我的订单</a-button>
      <a-input-search placeholder="请输入商品名称" enter-button allowClear @search="onSearch"/>
    </a-flex>
    <div id="shop_list_info">
      <a-table v-if="data_list.length>0" :row-selection="rowSelection" :columns="columns" :data-source="data_list"
        :pagination="false" :defaultExpandAllRows="true" :expandRowByClick="true" 
        :style="{ height: tableHeight + 'px' }" :scroll="{ y: tableHeight }">
        <template #bodyCell="{ column, record, text }">
          <template v-if="record.key > 0">
            <template v-if="column.dataIndex === 'product_name'">
              <div class="product_info">
                <img v-if="record.image" :src="`/web/image/product.template/${record.product_id}/image_512`"/>
                <div>
                  <p>{{text}}</p>
                  <p>
                    <span v-for="(i,index) in record.product_tpl_variant_value_ids" :key="index"
                      class="badge badge-pill" style="background:blue;color:white">{{i}}
                    </span>
                  </p>
                </div>
              </div>
            </template>
            <template v-if="column.dataIndex === 'product_price_unit'">
              <span style="white-space: nowrap;">¥ {{text.toFixed(2)}}
                <template v-if="record.product_categ_id.includes('实验环境资源')">/ 天</template>
              </span>
            </template>
            <template v-if="column.dataIndex === 'product_price_subtotal'">
              <span style="white-space: nowrap;">¥ {{text.toFixed(2)}}</span>
            </template>
            <template v-if="column.dataIndex === 'product_quantity'">
              <template v-if="['应用', '课程', '直播'].some(categ => record.product_categ_id.includes(categ))">{{ text }}</template>
              <a-flex align="center" gap="small" v-else>
                <div class="number-box">
                  <input type="button" class="on-number" value="-" @click="num_minus(record.order_line_id)"/>
                  <input type="number" :value="text" @input="InputChange(record.order_line_id, $event)"/>
                  <input type="button" class="on-number" value="+" @click="num_plus(record.order_line_id)"/>
                </div>
                <template v-if="record.product_categ_id.includes('实验环境资源')">天</template>
              </a-flex>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-popconfirm title="确定删除？" @confirm="()=>deleteProduct(record.order_line_id)">
                <a class="text-muted mr-3" style="white-space: nowrap;">删除</a>
              </a-popconfirm>
              <a class="text-primary" style="white-space: nowrap;" @click="_BuyProduct(record.order_line_id)">购买</a>
            </template>
          </template>
        </template>
      </a-table>
      <a-table v-else :columns="columns" :data-source="data_list"
        :pagination="false" :defaultExpandAllRows="true" :expandRowByClick="true" 
        :style="{ height: tableHeight + 'px' }" :scroll="{ y: tableHeight }">
      </a-table>
    </div>
    <div class="settle_accounts">
      <div v-if="discount_coupon" class="discount_coupon" :style="{ maxHeight: tableHeight+16 + 'px' }">
        <h4>商品金额<span>¥ {{totalPrice.toFixed(2)}}</span></h4>
        <div class="count">
            <div>优惠券<span>共用 {{couponNum}} 张</span></div>
        </div>
        <!-- 兑换券部分 -->
        <div v-if="couponsRes.gift_card.length > 0">
          <h5>兑换券</h5>
          <label v-for="(coupon, index) in couponsRes.gift_card" :key="index" :class="{ disabled: getCouponDisabled(coupon) }">
            <div>
              <input type="checkbox" v-model="coupon.check" :disabled="getCouponDisabled(coupon)"/>
              <div>
                <h5>{{ coupon.name }}</h5>
                <span>使用次数限制：{{ coupon.limit_use_count }}</span><br />
                <span v-if="coupon.discount_product_list">
                  可兑换商品：
                  <span v-for="(product, pIndex) in coupon.discount_product_list" :key="pIndex">
                    {{ product }}
                  </span>
                </span>
              </div>
            </div>
            <span>{{ coupon.date_from }} ~ {{ coupon.date_to }}</span>
          </label>
        </div>
        <!-- 优惠券部分 -->
        <div v-if="couponsRes.coupons.length > 0">
          <h5>优惠券</h5>
          <label v-for="(coupon, index) in couponsRes.coupons" :key="index" :class="{ disabled: getCouponDisabled(coupon) }">
            <div>
              <input type="checkbox" v-model="coupon.check" :disabled="getCouponDisabled(coupon)"/>
              <div>
                <h5>{{ coupon.name }}</h5>
                <span>使用次数限制：{{ coupon.limit_use_count }}</span><br />
                <span v-if="coupon.discount_product_list">
                  可兑换商品：
                  <span v-for="(product, pIndex) in coupon.discount_product_list" :key="pIndex">
                    {{ product }}
                  </span>
                </span>
              </div>
            </div>
            <span>{{ coupon.date_from }} ~ {{ coupon.date_to }}</span>
          </label>
        </div>
        <!-- 推广券部分 -->
        <div v-if="couponsRes.promotion.length > 0">
          <h5>推广券</h5>
          <label v-for="(coupon, index) in couponsRes.promotion" :key="index" :class="{ disabled: getCouponDisabled(coupon) }">
            <div>
              <input type="checkbox" v-model="coupon.check" :disabled="getCouponDisabled(coupon)"/>
              <div>
                <h5>{{ coupon.name }}</h5>
                <span>使用次数限制：{{ coupon.limit_use_count }}</span><br />
                <span v-if="coupon.discount_product_list">
                  可兑换商品：
                  <span v-for="(product, pIndex) in coupon.discount_product_list" :key="pIndex">
                    {{ product }}
                  </span>
                </span>
              </div>
            </div>
            <span>{{ coupon.date_from }} ~ {{ coupon.date_to }}</span>
          </label>
        </div>
        <div class="RedeemCode">
            <h5>兑换更多优惠券</h5>
            <input type="text" class="form-control" placeholder="请输入优惠券兑换码" ref="exchange"/>
            <div><button class="btn" @click.prevent="_onExchange">兑换</button></div>
        </div>
        <div class="co_discount">共优惠<span>- ¥ {{preferentialPrice.toFixed(2)}}</span></div>
      </div>
      <div class="amount">
        <div>
          <a-checkbox v-model="checkAll" @change="_onChangeAllInput">全选</a-checkbox>
          <a @click="deleteSelectedShopList">删除选中的预购内容</a>
        </div>
        <div>
          <div class="selected">已选择 <span>{{ totalQuantity }}</span> 个内容</div>
          总价：<span>¥ {{ couponNum>0?preferentialTotalPrice.toFixed(2):totalPrice.toFixed(2) }}</span>
          <a href="#" class="open_discount_coupon" @click.prevent="toggleDiscountCoupon">
            优惠明细
            <UpOutlined v-if="discount_coupon"/>
            <DownOutlined v-else/>
          </a>
        </div>
      </div>
      <a-button type="primary" @click="_onSettle">去结算</a-button>
    </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
import {logDebug, logError} from "@/utils/logger";
import { ref, reactive, onMounted, watch } from "vue";
import { jsonRPC, getResponseData, jsonOwlRPC } from "@/utils/http_utils";
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

const data_list = ref([]);  // 商品列表
const selectedRowKeys = ref([]);
const checkAll = ref(false);
const totalQuantity = ref(0);
const totalPrice = ref(0);
const preferentialPrice = ref(0);
const preferentialTotalPrice = ref(0);
const couponNum = ref(0);
const codeIds = ref([]);
const productIds = ref([]);
const discount_coupon = ref(false);
const exchange = ref('');
const couponsRes = reactive({
  gift_card: [],
  coupons: [],
  promotion: [],
});

// 获取优惠券是否禁用
const getCouponDisabled = (coupon) => {
  if (coupon.program_type === 'gift_card') {
    return totalPrice.value <= 0 || !coupon.discount_product_ids.every(id => productIds.value.includes(id));
  } else {
    return totalPrice.value <= 0 || totalPrice.value < coupon.usage_conditions_X || !coupon.discount_product_ids.every(id => productIds.value.includes(id));
  }
};
// 查重方法
const hasTemplateId = (array, templateId) => {
  let count = 0;
  for (let item of array) {
    if (item.template_id === templateId && item.check) count++;
    if (count > 1) return true;
  }
  return false;
};
// 选择优惠券
const onCouponCheck = () => {
  setTimeout(() => {
    couponNum.value = 0;
    codeIds.value = [];
    preferentialPrice.value = 0;
    preferentialTotalPrice.value = totalPrice.value;
    let coupon_ids = [];

    for (let type in couponsRes) {
      let coupon_type = couponsRes[type];
      if (type === 'gift_card') {
        for (let coupon of coupon_type) {
          if (hasTemplateId(coupon_type, coupon.template_id)) {
            coupon.check = false;
          } else {
            if (totalPrice.value > 0 && coupon.discount_product_ids.length > 0) {
              if (coupon.check) {
                couponNum.value += 1;
                codeIds.value.push(coupon.code);
                
                const price = findProductPriceUnit(data_list.value, coupon.discount_product_ids[0]);
                if (price) {
                  preferentialTotalPrice.value -= price;
                }
              }
            } else {
              coupon.check = false;
            }
          }
        }
      } else {
        for (let coupon of coupon_type) {
          if (hasTemplateId(coupon_type, coupon.template_id)) {
            coupon.check = false;
          } else {
            if (totalPrice.value > 0 && totalPrice.value >= coupon.usage_conditions_X) {
              if (coupon.check) {
                coupon_ids.push(coupon);
              }
            } else {
              coupon.check = false;
            }
          }
        }
      }
    }

    // 排序
    coupon_ids.sort((a, b) => {
      if (a.usage_conditions_Y > 0 && b.usage_conditions_Y <= 0) return -1;
      if (a.usage_conditions_Y <= 0 && b.usage_conditions_Y > 0) return 1;
      return b.usage_conditions_Y - a.usage_conditions_Y;
    });

    // 计算价格
    for (let coupon of coupon_ids) {
      couponNum.value += 1;
      codeIds.value.push(coupon.code);
      if (coupon.usage_discount_Y > 0) {
        preferentialTotalPrice.value *= (1 - coupon.usage_discount_Y / 100);
      } else {
        preferentialTotalPrice.value -= coupon.usage_conditions_Y;
      }
    }

    // 排除异常
    if (couponNum.value > 0 && preferentialTotalPrice.value <= 0) {
      preferentialTotalPrice.value = 0.01;
    }

    // 计算优惠
    preferentialPrice.value = totalPrice.value - preferentialTotalPrice.value;

    if (preferentialPrice.value === 0) {
      for (let type in couponsRes) {
        for (let coupon of couponsRes[type]) {
          coupon.check = false;
        }
      }
    }
  }, 200);
};
watch([couponsRes,totalQuantity], onCouponCheck);
// 查找产品价格单位
const findProductPriceUnit = (data, discount_product_id) => {
  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      const items = data[key];
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === discount_product_id) {
          return items[i].product_price_unit;
        }
      }
    }
  }
  return null; // 未找到匹配项时返回 null
};
// 打开/关闭 优惠卷框
const toggleDiscountCoupon = ()=>{
  discount_coupon.value = !discount_coupon.value
}
// 兑换优惠卷
const _onExchange = ()=>{
  const exchangeString = exchange.value.value.trim();
  if (exchangeString.length == 0) {
    message.warning("请输入优惠券兑换码")
    return
  }
  jsonOwlRPC({
    url: "/api/coupon/user/pick_up",
    params: {
      'exchangeString': exchangeString,
    },
    success(res) {
      couponsRes[res.program_type].push(res);
      exchange.value.value = '';
      message.success("兑换成功");
    },
    fail(error) {
      logError("兑换失败", error);
      message.error("兑换失败:"+error);
    },
  });
}

const columns = [
  {
    title: "预购内容",
    dataIndex: "product_name",
    width: "40%",
  },
  {
    title: "单价",
    dataIndex: "product_price_unit",
  },
  {
    title: "数量",
    dataIndex: "product_quantity",
  },
  {
    title: "小计",
    dataIndex: "product_price_subtotal",
  },
  {
    title: "操作",
    dataIndex: "operation",
  },
];

const rowSelection = ref({
  checkStrictly: false, // 是否严格筛选
  hideSelectAll: true,
  selectedRowKeys: ref([]),
  onSelect: (record, selected, selectedRows) => {
    const category = data_list.value.find(item => item.key === record.key);
    if (category) {
      if (category.children.length > 0) {
        if (selected) {
          rowSelection.value.selectedRowKeys = [
            ...rowSelection.value.selectedRowKeys,
            ...category.children.map(child => child.id)
          ];
        } else {
          rowSelection.value.selectedRowKeys = rowSelection.value.selectedRowKeys.filter(id => !category.children.some(child => child.id === id));
        }
      }
    } else {
      if (selected) {
        rowSelection.value.selectedRowKeys.push(record.id);
      } else {
        rowSelection.value.selectedRowKeys = rowSelection.value.selectedRowKeys.filter(id => id !== record.id);
      }
    }
    // 计算选中的 order_line_id 列表
    selectedRoworder_line_ids.value = selectedRows
      .filter(row => row.order_line_id) // 过滤出有 order_line_id 的行
      .map(row => row.order_line_id);   // 提取 order_line_id
    calculateTotal(selectedRows)
    selectedRowKeys.value = rowSelection.value.selectedRowKeys;
  },
});

const calculateTotal = (rows) => {
  const filteredRows = rows.filter(row => row.key > 0);
  totalQuantity.value = filteredRows.reduce((acc, cur) => acc + cur.product_quantity, 0);
  totalPrice.value = filteredRows.reduce((acc, cur) => acc + cur.product_price_subtotal, 0);
};

onMounted(() => {
  fetchData();
  get_user_have_coupons_info();
});
const search = ref("");
function fetchData() {
  jsonOwlRPC({
    url: "/get/shop/cart/product/list",
    params: {
      'searchString': search.value
    },
    success(res) {
      const sortedRes = res.sort((a, b) => a.id - b.id);
      const result = sortedRes.reduce((acc, cur) => {
          let category = acc.find(item => item.product_name === cur.product_categ_id);
          if (!category) {
              const menuCount = -(acc.length + 1);
              category = {
                  'key': menuCount,
                  'product_name': cur.product_categ_id,
                  'children': []
              };
              acc.push(category);
          }
          category.children.push(cur);
          return acc;
      }, []);
      data_list.value = result;
      // 重新计算已选择商品的总数量和总金额
      const selectedRows = result.flatMap(item => item.children).filter(child => rowSelection.value.selectedRowKeys.includes(child.id));
      calculateTotal(selectedRows);
    },
    fail(error) {
      logError("查询失败", error);
    },
  });
}
// 获得优惠卷信息
function get_user_have_coupons_info() {
  jsonOwlRPC({
    url: "/api/get/user/coupons/info",
    success(res) {
      Object.assign(couponsRes,res)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const _onMyOrder = () => {
  router.push('/order')
};
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    search.value = searchValue;
    fetchData();
  }
};
const _onChangeAllInput = () => {
  checkAll.value = !checkAll.value;
  if (checkAll.value) {
    const allSelectedKeys = data_list.value.flatMap(item => item.children.map(child => child.id));
    rowSelection.value.selectedRowKeys = allSelectedKeys;
    selectedRoworder_line_ids.value = data_list.value.flatMap(item => item.children.map(child => child.order_line_id));
    calculateTotal(data_list.value.flatMap(item => item.children));
  } else {
    rowSelection.value.selectedRowKeys = [];
    selectedRoworder_line_ids.value = []; // 取消全选时，将选中的 order_line_id 清空
    calculateTotal([]); // 取消全选时，将总价和数量清零
  }
  selectedRowKeys.value = rowSelection.value.selectedRowKeys;
};
const deleteProduct = (id) => {
  jsonRPC({
    url: "/vue/desktop/shop/order/delete",
    params: {
      order_line_id: id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      fetchData();
      rowSelection.value.selectedRowKeys = [];
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
const _BuyProduct = (id) => {
  jsonOwlRPC({
    url: "/payment/create_order",
    params: {
      order_lines: [id],
      code_ids: codeIds.value,
    },
    success(res) {
      router.push(`/check?order_id=${res.order_id}&sales_order_id=${res.sales_order_id}`)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
const InputChange = (id,event) => {
  let inputValue = parseFloat(event.target.value);
  if (inputValue < 1) {
    inputValue = 1;
  } else if (inputValue > 200) {
    inputValue = 200;
  }
  event.target.value = inputValue;
  jsonRPC({
    url: "/vue/desktop/shop/order/input",
    params: {
      order_line_id: id,
      inputValue: inputValue,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      fetchData();
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
const num_minus = (id) => {
  jsonRPC({
    url: "/vue/desktop/shop/order/minus",
    params: {
      order_line_id: id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      fetchData();
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
const num_plus = (id) => {
  jsonRPC({
    url: "/vue/desktop/shop/order/plus",
    params: {
      order_line_id: id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      fetchData();
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
const selectedRoworder_line_ids = ref([])
const deleteSelectedShopList = () => {
  logDebug("删除选中预购内容", selectedRoworder_line_ids.value);
  jsonRPC({
    url: "/vue/desktop/shop/order/delete/all",
    params: {
      order_line_ids: selectedRoworder_line_ids.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      fetchData();
      rowSelection.value.selectedRowKeys = [];
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
const _onSettle = () => {
  if (totalQuantity.value == 0) {
    message.info('请选择商品！', 3);
    return;
  }
  jsonOwlRPC({
    url: "/payment/create_order",
    params: {
      order_lines: selectedRoworder_line_ids.value,
      code_ids: codeIds.value,
    },
    success(res) {
      router.push(`/check?order_id=${res.order_id}&sales_order_id=${res.sales_order_id}`)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};
</script>

<style scoped lang="scss">
.shop_page {
  padding: 16px;
  background-color: #fff;
  .container{
    min-height: calc(80vh - 32px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    >.ant-flex{
      flex-shrink: 0;
      margin-bottom: 16px;
      >.ant-btn{
        color: #96CAFF;
        border: 1px solid #96CAFF;
        margin-right: 15px;
        img{
          height: 25px;
          width: 25px;
          margin-top: -2px;
          margin-right: 5px;
          margin-left: -5px;
        }
      }
    }
    #shop_list_info{
      flex-grow: 1;
      height: 100%;
      margin-bottom: 16px;
      .product_info{
        display: flex;
        align-items: center;
        img{
          height: 90px;
          width: 160px;
        }
        >div{
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          p{
            font-weight: bold;
            margin: 0;
          }
          span{
            margin-top: 10px;
          }
        }
      }
      .number-box {
        border:#e5e5e5 solid 1px;
        overflow:hidden;
        width: 80px;
        input[type='number']{
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            display: none;
          }
          height:30px;
          border-top:none;
          border-bottom:none;
          border-left:#e5e5e5 solid 1px;
          border-right:#e5e5e5 solid 1px;
          margin:0;
          text-align:center;
          width:40px;
          outline:none;
          padding:0 5px;
          float:left;
          line-height:30px;
        }
        input[type='button']{
          height:30px;
          width:19px;
          float:left;
          border:none;
          outline:none;
          background-color:#f3f3f3;
          line-height:30px;
          cursor:pointer;
          padding:0;
          &:hover {
            background-color:#f9f9f9;
          }
          &:active {
            background-color:#f6f6f6;
          }
        }
      }
    }
    .settle_accounts {
      position: relative;
      flex-shrink: 0;
      display: flex;
      border-top-left-radius: 4px;
      box-shadow: 0px 0px 4px 4px #daedff;
      .amount {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 15px 20px;
        color: #929292;
        input {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        h5 {
          margin-right: 15px;
          margin-bottom: 0;
        }
        span {
          color: #007fff;
          font-weight: bold;
          white-space: nowrap;
        }
        > div {
          display: flex;
          .selected {
            margin-right: 5vw;
          }
          .open_discount_coupon{
            margin-left: 20px;
          }
        }
      }
      .ant-btn{
        flex-shrink: 0;
        font-size: 18px;
        height: auto;
        border-radius: 0;
      }
      .discount_coupon{
        max-height: 60vh;
        transition: max-height 0.5s ease-in-out;
        overflow-y: auto;
        position: absolute;
        right: 0;
        bottom: 100%;
        width: 40%;
        padding: 25px;
        background-color: #fff;
        box-shadow: -1px -1px 4px 0px #82828266;
        h4{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
        .count{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            div{
                font-size: 16px;
                span{
                    padding: 4px 12px;
                    color: #160BFF;
                    background-color: #E2EDFF;
                    border-radius: 4px;
                    margin-left: 15px;
                }
            }
            >span{
                color: #3B86FF;
            }
        }
        label{
            background-color: #F1FCFF;
            padding: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            color: #2018C7;
            user-select: none; /* 禁用鼠标选择 */
            &.disabled{
                h5,span{
                    color: #ACACAC;
                }
            }
            >div{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                >div{
                    margin-left: 10px;
                    h5{
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                    span{
                        color: #ACACAC;
                        &.warn{
                            color: #F00;
                        }
                    }
                }
            }
        }
        .RedeemCode{
            margin-top: 15px;
            margin-bottom: 36px;
            h5{
                font-size: 14px;
                margin-bottom: 0;
            }
            input{
                height: initial;
                line-height: 30px;
                padding: 0 0.5rem;
                margin: 5px 0;
                background-color: #f5f5f5;
                border: none;
                box-shadow: none;
            }
            >div{
                display: flex;
                justify-content: flex-end;
                button{
                    background-color: #6EB3FF;
                    color: #fff;
                    line-height: 28px;
                    padding: 0 16px;
                }
            }
        }
        .co_discount{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            >span{
                color: #3B86FF;
            }
        }
      }
    }
  }
}
</style>
