<template>
  <div id="live">
    <div style="display: flex; justify-content: flex-end; margin-bottom: 16px;">
      <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
      <div style="margin-left: 16px;">
        <router-link v-if="data_list.can_create" to="/console/live/form/create">
          <a-button type="primary">创建直播</a-button>
        </router-link>
      </div>
    </div>
    <a-list :grid="{ gutter: 16, column: 4, xs: 1, sm: 2}" :data-source="data_list.records">
      <template #renderItem="{ item }">
        <a-list-item class="px-0">
          <div class="info">
            <router-link :to="`/console/live/${item.id}`" class="img_box">
              <img v-if="item.has_image" :src="`/web/image/hw.training/${item.id}/image_1920?t=${new Date().getTime()}`"/>
              <img v-else src="/hw_web/static/src/img/live_default.png"/>
            </router-link>
            <div>
              <h5>
                <router-link :to="`/console/live/${item.id}`" class="line-clamp-1" :title="item.name">
                  {{item.name}}
                </router-link>
              </h5>
              <h5>
                <span v-for="category in item.course_category_ids" :key="category.id">
                  {{category.name}}
                </span>
              </h5>
              <p>{{item.start_datetime}}</p>
              <div class="teacher">
                <div class="user">
                  <template v-if="item.teacher_ids.length > 0">
                    <img v-if="item.teacher_ids[0]?.has_image" :src="`/web/image/hw.teacher/${item.teacher_ids[0]?.id}/image_128`"/>
                    <img v-else src="/hw_web/static/src/img/avatar_live.png" />
                    {{item.teacher_ids[0]?.name}}
                  </template>
                </div>
                <div class="stat">
                  <img src="/hw_web/static/src/web/img/collect-icon.png" />
                  {{item.like_nums}}
                  <img src="/hw_web/static/src/web/img/share-icon.png" />
                  {{item.view_nums}}
                </div>
              </div>
            </div>
            <span v-if="item.live_state == 2">直播中</span>
            <span v-else-if="item.live_state == 3" style="background-color: #ffeb90">已结束</span>
            <span v-else style="background-color: #ffeb90">未直播</span>
          </div>
        </a-list-item>
      </template>
    </a-list>
    <a-flex justify="center">
      <a-pagination
        v-model:current="current"
        :showSizeChanger="showSizeChanger"
        v-model:page-size="pageSize"
        :total="total"
        :locale="zhCn"
        :pageSizeOptions="pageSizeOptions"
        :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, watch } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import { zhCn } from "@/utils/zhCn";

logDebug("ConsoleLive setup.");

const data_list = reactive({});
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);

function fetchData() {
  jsonRPC({
    url: "/vue/console/live",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      Object.assign(data_list, data);
      total.value = data.record_count
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

onMounted(fetchData);
watch([pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
</script>

<style scoped lang="scss">
#live{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
}

.info {
  height: 100%;
  position: relative;
  box-shadow: 1px 1px 4px 0px #9d9d9d66;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  > div {
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h5 {
      font-size: 16px;
      margin-bottom: 5px;
      a {
        font-weight: bold;
      }
      span {
        display: inline-block;
        color: #52c9ff;
        font-size: 12px;
        border: 1px solid #52c9ff;
        border-radius: 4px;
        padding: 1px 4px;
        margin-right: 5px;
      }
    }
    p {
      color: #a6a6a6;
      margin-bottom: 5px;
    }
    .teacher {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      .user {
        display: flex;
        align-items: center;
        img {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .stat {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 10px;
        }
      }
    }
  }
  > span {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #90ffa5;
    padding: 0 15px;
    line-height: 30px;
    border-bottom-right-radius: 8px;
  }
}
</style>
