<template>
  <div class="course_demand">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
          <router-link to="/console/course/create">新建课程</router-link>
        </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/course/${course_id}`">{{ course_name }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>需求管理</a-breadcrumb-item>
    </a-breadcrumb>
    <a-flex justify="space-between" align="center" class="mb-3">
      <a-menu v-model:selectedKeys="menu_current" mode="horizontal" :items="menu_items"/>
      <div class="search">
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
        <a-button type="primary" @click="onCreateDemand" style="margin-left: 16px;">
          <template #icon><PlusOutlined /></template>
          新建需求
        </a-button>
      </div>
    </a-flex>
    <a-table :columns="columns" :data-source="data_list" :pagination="pagination">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'name'">
          <span>
            <a>{{ record.name }} <InfoCircleTwoTone @click="showModal(record.description)"/></a>
          </span>
        </template>
        <template v-else-if="column.key == 'executor_partner_id'">
          <span>
            <a-tag v-for="partner_id in record.executor_partner_id"
                :key="partner_id" color="processing">
              {{ partner_id.name }}
            </a-tag>
          </span>
        </template>
        <template v-else-if="column.key == 'demand_state'">
          <span>
            <a-tag v-if="record.demand_state == '待发布'">{{ record.demand_state }}</a-tag>
            <a-tag v-if="record.demand_state == '已发布'">{{ record.demand_state }}</a-tag>
            <a-tag v-if="record.demand_state == '待处理'">{{ record.demand_state }}</a-tag>
            <a-tag v-if="record.demand_state == '进行中'" color="blue">{{ record.demand_state }}</a-tag>
            <a-tag v-if="record.demand_state == '已完成'" color="green">{{ record.demand_state }}</a-tag>
          </span>
        </template>
        <template v-else-if="column.dataIndex == 'operation'">
          <a-popconfirm title="你确定要删除当前需求?" @confirm="onDelete(record.id)">
            <a-button type="link" danger>删除</a-button>
          </a-popconfirm>
          <a-button type="link" @click="showDemandData(record.id)">查看</a-button>
        </template>
      </template>
    </a-table>
    <a-modal v-model:open="openModal" title="需求详情" :footer="null">
      <RichEditor height="301" v-model="description"/>
    </a-modal>
    <a-drawer class="course_drawer"
        :title="formState == 'create'?'新建需求':'更新需求'"
        :width="720"
        :open="openDrawer"
        :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }"
        @close="onCloseDrawer"
    >
      <a-form :model="courseDemandForm" :rules="rules" layout="vertical" ref="formRef">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="需求标题" name="name">
              <a-input v-model:value="courseDemandForm.name" placeholder="请输入标题" />
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="执行人" name="executor_partner_id">
              <a-select v-model:value="courseDemandForm.executor_partner_id"
                        placeholder="请选择执行人"
                        style="width: 100%"
                        show-search :options="executor_partner_id_options" :filter-option="filterOption">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="参与者" name="participator_partner_id">
              <a-select v-model:value="participator_partner_ids"
                        placeholder="请选择参与者"
                        style="width: 100%"
                        mode="multiple" :max-tag-count="3"
                        show-search :options="participator_partner_ids_options" :filter-option="filterOption">
              </a-select>
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="截止时间" name="deadline">
              <a-date-picker
                  v-model:value="courseDemandForm.deadline"
                  style="width: 100%"
                  :showTime="showTimeOption"
                  :format="showTimedDateFormat"
                  :get-popup-container="trigger => trigger.parentElement"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="状态" name="demand_state">
              <a-select v-model:value="courseDemandForm.demand_state" 
                :options="demand_state_id_options"></a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="需求备注" name="description">
              <RichEditor height="500" :editMode="'update'" v-model="courseDemandForm.description"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <a-button style="margin-right: 8px" @click="onCloseDrawer">取消</a-button>
        <a-button v-if="formState == 'create'" type="primary" @click="onSubmitCreate">提交</a-button>
        <a-button v-else type="primary" @click="onSubmitUpdate">保存</a-button>
      </template>
    </a-drawer>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref, toRaw, watch} from "vue";
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {useRouter} from 'vue-router';
import {logDebug, logError} from "@/utils/logger";
import { InfoCircleTwoTone,PlusOutlined  } from '@ant-design/icons-vue';
import {newShowTimedDateFormat, newShowTimeOption} from "@/utils/time_utils";
import moment from 'moment';
import dayjs from 'dayjs';
import {message} from "ant-design-vue";
import RichEditor from "@/components/RichEditor.vue";
import {markRaw} from 'vue'

const handlePageChange = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page;
  fetchData();
}
const pagination = reactive({
  // 分页配置
  pageSize: 5, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const menu_current = ref(['all']);
const menu_items = ref([
  {
    key: 'all',
    label: '全部需求',
  },
  {
    key: 'noDone',
    label: '未完成的需求',
  },
  {
    key: 'done',
    label: '已完成的需求',
  },
])

const formRef = ref();

const router = useRouter();
const course_id = router.currentRoute.value.params.course_id
const course_name = ref('')
const participator_partner_ids = ref([])
const courseDemandForm = reactive({
  id: 0,
  name: '',
  executor_partner_id: [],
  demand_state: "draft",
  deadline: null,
  description: '',
});

const showTimeOption = newShowTimeOption()
const showTimedDateFormat = newShowTimedDateFormat()

const executor_partner_id_options = ref([
  {
    value:'',
    label:'',
  }
])

const participator_partner_ids_options = ref([
  {
    value:'',
    label:'',
  }
])
const demand_state_id_options = ref([
  {
    value:'',
    label:'',
  }
])
const rules = {
  name: [
    {
      required: true,
      message: '请输入标题',
    },
  ],
  executor_partner_id: [
    {
      required: true,
      message: '请选择执行人',
    },
  ],
  deadline: [
    {
      required: true,
      message: '请选择截止日期',
    },
  ],
};
const formState = ref('create')
const onSubmitCreate = () => {
  formRef.value.validate()
  .then(() => {
    logDebug('values', courseDemandForm, toRaw(courseDemandForm));
    createDemand()
    onCloseDrawer()
  })
  .catch(error => {
    logDebug('error', error);
  });
};
const onSubmitUpdate = () => {
  formRef.value.validate()
  .then(() => {
    logDebug('values', courseDemandForm, toRaw(courseDemandForm));
    updateDemand()
    onCloseDrawer()
  })
  .catch(error => {
    logDebug('error', error);
  });
};
const openDrawer = ref(false);
const onCreateDemand = () => {
  openDrawer.value = true;
  formState.value = 'create';
  // 清空参与者列表
  participator_partner_ids.value = [];
  // 清理表单数据
  if (formRef.value) {
    formRef.value.resetFields();
  }
};
const onCloseDrawer = () => {
  openDrawer.value = false;
};
const data_list = ref([]);
const search = ref("");

const openModal = ref(false);
const description = ref()
const showModal = (e) => {
  description.value = e;
  openModal.value = true;
};

const columns = [
  {
    title: '需求',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: '执行人',
    dataIndex: 'executor_partner_id',
    key: 'executor_partner_id',
  },
  {
    title: '状态',
    key: 'demand_state',
    dataIndex: 'demand_state',
  },
  {
    title: '截止时间',
    key: 'deadline',
    dataIndex: 'deadline',
  },
  {
    title: '操作',
    dataIndex: 'operation',
  },
];

function onDelete(demand_id) {
  jsonRPC({
    url: `/api/make/course/demand/${demand_id}/delete`,
    params:{},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功删除需求数据`, data);
      message.success('删除成功')
      fetchData()
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}

function createDemand() {
  jsonRPC({
    url: `/api/make/course/${course_id}/demand/create`,
    params:{
      description: courseDemandForm.description,
      name: courseDemandForm.name,
      deadline: courseDemandForm.deadline,
      demand_state: courseDemandForm.demand_state,
      participator_partner_ids: participator_partner_ids.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`创建需求成功`, data);
      fetchData()
    },
    fail(error) {
      logError(`创建需求失败, `, error);
    },
  })
}

function updateDemand() {
  jsonRPC({
    url: `/api/make/course/demand/${courseDemandForm.id}/write`,
    params:{
      description: courseDemandForm.description,
      name: courseDemandForm.name,
      deadline: courseDemandForm.deadline,
      demand_state: courseDemandForm.demand_state,
      participator_partner_ids: participator_partner_ids.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`创建需求成功`, data);
      fetchData()
    },
    fail(error) {
      logError(`创建需求失败, `, error);
    },
  })
}

async function showDemandData(demand_id) {
  await jsonRPC({
    url: `/api/make/course/demand/${demand_id}`,
    params:{},
    success(res) {
      const data = getResponseData(res);
      logDebug(`查看getDemandData成功`, data);
      Object.assign(courseDemandForm, data);
      courseDemandForm.deadline = dayjs(moment(new Date(res.data.result.data.deadline)).toISOString());
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
  openDrawer.value = true;
  formState.value = 'update';
}

function fetchData() {
  jsonRPC({
    url: `/api/make/course/demand/list`,
    params:{
      course_id: course_id,
      page_index: pagination.current,
      page_size: pagination.pageSize,
      search: search.value,
      demand_state: menu_current.value[0],
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      data_list.value = data.records;
      pagination.total = data.record_count;
      course_name.value = data.course_name;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
function getDemandBaseInfo() {
  jsonRPC({
    url: `/api/make/course/demand/base_info`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取创建需求数据成功base_info`, data);
      executor_partner_id_options.value = data.executor_partner_id
      participator_partner_ids_options.value = data.participator_partner_ids
      demand_state_id_options.value = data.demand_state
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
onMounted(()=>{
  fetchData()
  getDemandBaseInfo()
});
watch([menu_current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    pagination.current = 1;
    search.value = searchValue;
    fetchData();
  }
};
</script>

<style scoped lang="scss">
.course_demand{
  padding: 16px;
}
:where(.ant-menu-light){
  background-color: transparent;
  border-bottom: none;
}
:where(.ant-btn >span){
  display: inline-flex !important;
}
:global(.ant-table-wrapper .ant-table-pagination){
  justify-content: center;
}
</style>