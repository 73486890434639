<template>
  <!--  a-config-provider 用于对Ant Design Vue进行全局配置，如主题等。  -->
  <a-config-provider
      :locale="zhCN"
      :theme="{
          token: {
              colorTextDisabled: 'black',
              colorBgContainerDisabled: 'white',
              controlItemBgActiveDisabled: 'white',
          }
      }">
    <div v-if="maintenanceState == 'notMaintenance'" class="hi168--container">
      <router-view v-slot="{ Component }">
        <transition enter-active-class="enterAnimation" leave-active-class="leaveAnimation">
          <keep-alive :include="cachedComponents" :max="20">
            <component :is="Component"/>
          </keep-alive>
        </transition>
      </router-view>
    </div>
    <!-- 网站维护中提示 -->
    <div v-if="maintenanceState == 'underMaintenance'" class="maintenance">
      <div>
        <img src="/hw_frontend/static/img/underMaintenance.png" alt="维护中" />
        <h1>系统维护中......</h1>
        <p>为了给您提供更优质的服务，我们正在对系统进行升级维护，维护期间暂停Hi168相关服务，给您带来的不便敬请谅解!</p>
      </div>
    </div>
  </a-config-provider>
</template>

<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import {onMounted, onUnmounted, ref, watch} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {logInfo, logError} from "@/utils/logger";
import { Modal } from 'ant-design-vue';
import { notification } from 'ant-design-vue';
import coreStore from './stores/core.js';
const $coreStore = coreStore();

import { useRoute } from 'vue-router';
const cachedComponents = ref(['desktop', 'Desktop']);

// 获取当前路由
const route = useRoute();

// 监听路由变化，动态更新缓存组件的 `include` 属性
watch(
  () => route.path, // 监听路由路径变化
  (newPath) => {
    if (newPath === '/console' || newPath === '/login') {
      // 如果当前路由是 /console，清空缓存列表
      cachedComponents.value = [];
    } else {
      // 否则恢复原来的缓存组件列表
      cachedComponents.value = ['desktop', 'Desktop'];
    }
  },
  { immediate: true } // 立即执行一次，确保初始值正确
);

const heartBreakTask = ref(undefined)
const maintenanceState = ref('')  // 空状态不加载
onMounted(async () => {
  await jsonRPC({
    url: "/api/maintenance",
    params: {},
    success(res) {
      const data = getResponseData(res);
      if (data.maintenance){
        maintenanceState.value = 'underMaintenance'
      }else{
        maintenanceState.value = 'notMaintenance'
      }
      // 显示微信登录提示
      if (data.hw_wechat_login_error) {
        Modal.warning({
          title: data.hw_wechat_login_error.title,
          content: data.hw_wechat_login_error.content,
        });
      }
      // 显示微信绑定提示
      if (data.hw_wechat_bind_message) {
        if (data.hw_wechat_bind_message.status == 200) {
          notification.open({
            message: data.hw_wechat_bind_message.title,
            description: data.hw_wechat_bind_message.content,
          });
        }else{
          Modal.warning({
            title: data.hw_wechat_bind_message.title,
            content: data.hw_wechat_bind_message.content,
          });
        }
      }
    },
    fail(error) {
      logError(`获取失败`, error);
      maintenanceState.value == 'notMaintenance'
    },
  });
  if (maintenanceState.value == 'notMaintenance') {
    window.addEventListener('mousedown', onMousedown);
    // 每隔一定时间发送心跳包
    heartBreakTask.value = setInterval(sendHeartbeat, 15000); // 每 15 秒发送一次心跳包
  }
})

onUnmounted(() => {
  window.removeEventListener('mousedown', onMousedown);
  if (heartBreakTask.value) {
    // 必须在组件unmounted的时候清理所有定时任务，否则vue并不会自动释放setInterval的后台任务，会导致请求一直在执行。
    clearInterval(heartBreakTask.value);
  }
})

const onMousedown = function () {
  $coreStore.closeContextMenu();
}

function sendHeartbeat() {
  jsonRPC({
    url: "/api/heartbeat",
    params: {
      "heartbeat":1,
    },
    success(res) {
      const data = getResponseData(res);
      logInfo(`发送心跳成功`, data);
    },
    fail(error) {
      logError(`获取失败`, error);
    },
  });
}
</script>

<style scoped lang="scss">
.leaveAnimation {
  animation: slideOutLeft 0.5s;
}

.enterAnimation {
  animation: fadeIn 0.5s;
}

:global(img) {
  max-width: 100%;
}

:global(video) {
  max-width: 100%;
}

.maintenance{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #96d3ff;
  >div{
    height: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img{
      width: 60%;
    }
    h1{
      font-weight: bold;
    }
    p{
      margin-bottom: 0;
      text-align: left;
    }
  }
}
</style>
