<template>
  <div ref="selectContainer" class="app_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/app">我的应用</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.custom_app_env_name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="App_info">
      <h4>
        应用概览
        <div>
          <button
              v-if="!['Starting', 'Suspending', 'Suspended', 'Stopping', 'Stopped'].includes(data_list.app_env_status_detail)"
              class="btn release" @click="confirmCreateSnapshotModal=true">
            创建快照
          </button>
          <a-modal v-model:open="confirmCreateSnapshotModal" :confirm-loading="confirmCreateSnapshot"
                   :maskClosable="false"
                   title="创建快照"
                   @ok="createSnapshot">
            <a-form id="form_createSnapshot"
                    ref="formRef" :label-col="labelCol"
                    :model="data_list" :rules="rules"
                    :wrapper-col="wrapperCol" @submit="createSnapshot">
              <a-form-item label="快照描述" name="app_snapshot_description">
                <a-input v-model:value="data_list.app_snapshot_description"
                         placeholder="请简单描述本次快照保存的内容和时间（必填）"/>
              </a-form-item>
            </a-form>
          </a-modal>
          <button
              v-if="!['Starting', 'Suspending', 'Suspended', 'Stopping', 'Stopped'].includes(data_list.app_env_status_detail)"
              class="btn release" @click="confirmCreateTemplateModal=true">
            创建模板
          </button>
          <a-modal v-model:open="confirmCreateTemplateModal"
                   :confirm-loading="confirmCreateTemplate" :maskClosable="false"
                   title="创建用户应用模板"
                   width="60%" @ok="createTemplate">
            <p class="px-3" style="color: #2481e9">
              提示：提交创建用户应用模板任务后，根据镜像修改内容大小，用户应用模板制作耗时在数秒钟到数分钟不等。
              请在“快照”列表查看制作进度，或稍后在“桌面端->我的模板”文件夹下查看用户应用模板制作结果。
            </p>
            <a-form ref="formRef" :label-col="labelCol" :model="data_list"
                    :rules="rules"
                    :wrapper-col="wrapperCol" @submit="createTemplate"
            >
              <a-form-item label="模板名称" name="app_template_name">
                <a-input v-model:value="data_list.app_template_name" placeholder="请填写新模板名称"/>
              </a-form-item>
              <a-form-item label="模板图片">
                <a-upload
                    v-model:file-list="data_list.file_list"
                    :customRequest="uploadDummyRequest"
                    :max-count="1"
                    accept=".png,.jpg,.jpeg"
                    list-type="picture-card"
                    @preview="handlePreview"
                >
                  <div v-if="data_list.file_list.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
                <a-modal :footer="null" :open="previewVisible" :title="previewTitle" @cancel="cancelPreview"
                >
                  <img :src="previewImage" alt="example" style="width: 100%"/>
                </a-modal>
              </a-form-item>
              <a-form-item label="模板介绍" name="app_template_description">
                <a-textarea v-model:value="data_list.app_template_description" :rows="5"/>
              </a-form-item>
              <a-form-item v-if="isVM" label="支持ttyd" name="support_ttyd">
                <div style="display: flex">
                  <a-radio-group v-model:value="data_list.support_ttyd">
                    <a-radio value="unchanged">不变</a-radio>
                    <a-radio value="yes">是</a-radio>
                    <a-radio value="no">否</a-radio>
                  </a-radio-group>
                  <a-popover trigger="hover">
                    <template #content>
                      <div style="width: 500px">
                        “支持ttyd”有三种不同取值，它们的含义分别如下。
                        <ul style="margin-left: 20px">
                          <li>
                            “不变”，表示和创建该应用的源应用模板保持一致，不做改变。
                          </li>
                          <li>
                            “是”，表示使用该应用模板创建的应用会提供ttyd终端。
                            <span style="color: red">
                              注意，这要求实验环境里必须安装了ttyd服务，并且ttyd服务使用的是7149端口。
                            </span>
                          </li>
                          <li>
                            “否”，表示使用该应用模板创建的应用不会提供ttyd终端。
                          </li>
                        </ul>
                        <p>
                          建议，如果您在实验环境里安装了ttyd服务，并且ttyd服务使用的是7149端口，那么就选择“是”。
                          其他情况下尽量选择“不变”即可，通常不需要选择“否”。
                        </p>
                        <p>
                          <span style="color: red">注意</span>，如果您的应用是由通过“ISO镜像”制作的应用模板所创建的话，
                          那么此时您的应用环境里的“系统盘（即根路径"/"挂载的磁盘）”还只是一个临时文件，
                          您此时所安装的各种软件很有可能在系统重启后丢失。
                          所以，这里建议您在通过“ISO镜像”制作的应用模板创建应用后，只做安装操作系统这一件事情，不要安装其他各种软件。
                          当安装好操作系统后，点击“创建模板”按钮，将这个应用创建为一个新的用户应用模板。
                          您可以在桌面端的“我的模板”文件夹下看到这个新创建的用户应用模板。
                          然后您可以点击新用户应用模板右侧的“部署”按钮，创建一个新应用。
                          这个新应用将自动去掉“ISO镜像”盘，并从安装了操作系统的硬盘启动。
                          之后您在这个新应用的环境中安装ttyd、webshell等各种软件服务
                          （参考<a download="如何给虚拟机安装shell功能.md"
                                  href="/hw_frontend/static/doc/vm_install_shell.md">如何给虚拟机安装shell功能</a>），
                          这样系统重启后软件就都不会丢失了。
                          最后您再将这个新应用制作为新的用户应用模板即可。
                          之后您可以通过“我的模板”文件夹下的“共享”按钮，将这个支持ttyd和webshell的新用户应用模板共享给其他用户使用。
                          或者您也可以提交通知系统管理人员审核该用户应用模板，将其转成系统应用模板后加入应用中心。
                        </p>
                      </div>
                    </template>
                    <QuestionCircleTwoTone class="help-icon"/>
                  </a-popover>
                </div>
              </a-form-item>
              <a-form-item v-if="isVM" label="支持webshell" name="support_vmshell">
                <div style="display: flex">
                  <a-radio-group v-model:value="data_list.support_vmshell">
                    <a-radio value="unchanged">不变</a-radio>
                    <a-radio value="yes">是</a-radio>
                    <a-radio value="no">否</a-radio>
                  </a-radio-group>
                  <a-popover trigger="hover">
                    <template #content>
                      <div style="width: 500px">
                        “支持webshell”有三种不同取值，它们的含义分别如下。
                        <ul style="margin-left: 20px">
                          <li>
                            “不变”，表示和创建该应用的源应用模板保持一致，不做改变。
                          </li>
                          <li>
                            “是”，表示使用该应用模板创建的应用会提供webshell终端。
                            <span style="color: red">
                              注意，这要求实验环境里必须安装了webshell服务，并且webshell服务使用的是7179端口。
                            </span>
                          </li>
                          <li>
                            “否”，表示使用该应用模板创建的应用不会提供webshell终端。
                          </li>
                        </ul>
                        <p>
                          建议，如果您在实验环境里安装了webshell服务，并且webshell服务使用的是7179端口，那么就选择“是”。
                          其他情况下尽量选择“不变”即可，通常不需要选择“否”。
                        </p>
                        <p>
                          <span style="color: red">注意</span>，如果您的应用是由通过“ISO镜像”制作的应用模板所创建的话，
                          那么此时您的应用环境里的“系统盘（即根路径"/"挂载的磁盘）”还只是一个临时文件，
                          您此时所安装的各种软件很有可能在系统重启后丢失。
                          所以，这里建议您在通过“ISO镜像”制作的应用模板创建应用后，只做安装操作系统这一件事情，不要安装其他各种软件。
                          当安装好操作系统后，点击“创建模板”按钮，将这个应用创建为一个新的用户应用模板。
                          您可以在桌面端的“我的模板”文件夹下看到这个新创建的用户应用模板。
                          然后您可以点击新用户应用模板右侧的“部署”按钮，创建一个新应用。
                          这个新应用将自动去掉“ISO镜像”盘，并从安装了操作系统的硬盘启动。
                          之后您在这个新应用的环境中安装ttyd、webshell等各种软件服务
                          （参考<a download="如何给虚拟机安装shell功能.md"
                                  href="/hw_frontend/static/doc/vm_install_shell.md">如何给虚拟机安装shell功能</a>），
                          这样系统重启后软件就都不会丢失了。
                          最后您再将这个新应用制作为新的用户应用模板即可。
                          之后您可以通过“我的模板”文件夹下的“共享”按钮，将这个支持ttyd和webshell的新用户应用模板共享给其他用户使用。
                          或者您也可以提交通知系统管理人员审核该用户应用模板，将其转成系统应用模板后加入应用中心。
                        </p>
                      </div>
                    </template>
                    <QuestionCircleTwoTone class="help-icon"/>
                  </a-popover>
                </div>
              </a-form-item>
            </a-form>
          </a-modal>
          <button v-if="data_list.is_delete" class="btn" @click="openDeleteModal=true">
            删除
          </button>
          <a-modal v-model:open="openDeleteModal" :confirm-loading="confirmDelete"
                   title="删除"
                   @ok="confirmFormDelete">
            <p>确定删除？ 删除ID：{{ route.params.env_id }}</p>
          </a-modal>
        </div>
      </h4>
      <div class="row">
        <div class="col-md-6">
          <table class="table table-borderless">
            <tbody>
            <tr>
              <td>应用ID</td>
              <td> {{ data_list.id }}</td>
            </tr>
            <tr>
              <td>应用名称</td>
              <td>
                <a-typography-paragraph v-model:content="data_list.custom_app_env_name"
                                        :editable="{ maxlength: 30,
                                        autoSize: { maxRows: 2, minRows: 1 } ,
                                        onChange:()=>change_app_name(data_list)}"
                                        copyable ellipsis>
                  <template #editableTooltip>编辑： 至少为3个字符！</template>
                </a-typography-paragraph>
              </td>
            </tr>
            <tr>
              <td>模板名称</td>
              <td>{{ data_list.app_name }}</td>
            </tr>
            <tr>
              <td>状态</td>
              <td v-if="['Starting', 'Suspending', 'Suspended', 'Stopping', 'Stopped'].includes(data_list.app_env_status_detail)">
                <span class="stopped"></span>已停止
              </td>
              <td v-else>
                <span class="running"></span>运行中
              </td>
            </tr>
            <tr>
              <td>创建时间</td>
              <td>{{ data_list.create_date }}</td>
            </tr>
            <tr>
              <td>到期时间</td>
              <td>{{ data_list.maturity_date }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table table-borderless">
            <tbody>
            <tr>
              <td>命名空间</td>
              <td>{{ data_list.namespace_name }}</td>
            </tr>
            <tr>
              <td>应用配额</td>
              <td>
                CPU:{{ data_list.cpu_limit }}核 内存:{{ data_list.mem_limit }}G 磁盘:{{ data_list.disk_limit }}G
              </td>
            </tr>
            <tr>
              <td>应用算力点</td>
              <td>
                {{ data_list.consume_points }} 算力点 / 小时
              </td>
            </tr>
            <tr>
              <td>应用描述</td>
              <td v-html="data_list.description"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="mirror_list">
      <h4>环境列表</h4>
      <div class="table-responsive">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>#</td>
            <td>图标</td>
            <td>名称</td>
            <td>配额</td>
            <td v-if="isVM">IP</td>
            <td>状态</td>
            <td>操作</td>
          </tr>
          <tr v-for="row in data_list.environment_ids" :key="row.id">
            <td>{{ row.id }}</td>
            <td>
              <img :src="`/web/image?model=hw.experiment.template&amp;id=${row.template_id}&amp;field=image_128`"
                   height="32" width="32"/>
            </td>
            <td>
              <a-typography-paragraph v-model:content="row.custom_name"
                                      :editable="{ maxlength: 20, autoSize: { maxRows: 3, minRows: 2 } ,onChange:()=>change_env_name(row)}"
                                      copyable ellipsis strong>
                <template #editableTooltip>编辑： 至少为3个字符！</template>
              </a-typography-paragraph>
            </td>
            <td>
              <span>CPU:{{ row.cpu_limit }}核 内存:{{ row.mem_limit }}G 磁盘:{{ row.disk_limit }}G &emsp;</span>
              <EditOutlined @click="()=>showResizeResourceModal(row)"/>
              <!-- v-for循环体里的a-modal组件不能够使用循环对象row，否则拿到的数据总是最后一条记录的数据 -->
              <a-modal v-model:open="openResizeResourceModal" :confirm-loading="confirmLoadingResizeResource"
                       :getContainer="()=>$refs.selectContainer"
                       :mask="true" :maskClosable="false" class="setting" style="width: 60vw"
                       title="调整资源" @ok="()=>handleOkResizeResource()">
                <div>主机配置：</div>
                <a-form :disabled="componentDisabled" layout="horizontal">
                  <a-form-item label="CPU" name="cpu_cores">
                    <a-row>
                      <a-col :span="12">
                        <Slider v-model="resizeResourceForm.cpu_cores" :marks="cpu_marks"/>
                      </a-col>
                      <a-col :span="12">
                        <a-flex>
                          <span style="color: #000;flex-shrink: 0;">{{ resizeResourceForm.cpu_cores }} 核</span>
                          <span
                              v-if="resizeResourceForm.Price_unit.name">{{
                              calculateUnit('CPU', resizeResourceForm.cpu_cores)
                            }}算力/{{ calculatePrice }}</span>
                        </a-flex>
                      </a-col>
                    </a-row>
                    <span v-if="resizeResourceForm.Price_unit.name">{{ findModelName('CPU') }}</span>
                  </a-form-item>
                  <a-form-item label="内存" name="memory">
                    <a-row>
                      <a-col :span="12">
                        <Slider v-model="resizeResourceForm.memory" :marks="memory_marks"/>
                      </a-col>
                      <a-col :span="12">
                        <a-flex>
                          <span style="color: #000;flex-shrink: 0;">{{ resizeResourceForm.memory }} G</span>
                          <span
                              v-if="resizeResourceForm.Price_unit.name">{{
                              calculateUnit('RAM', resizeResourceForm.memory)
                            }}算力/{{ calculatePrice }}</span>
                        </a-flex>
                      </a-col>
                    </a-row>
                    <span v-if="resizeResourceForm.Price_unit.name">{{ findModelName('RAM') }}</span>
                  </a-form-item>
                  <a-form-item v-if="resizeResourceForm.type === 'Container'" label="磁盘">
                    <a-input-number
                        v-model:value="resizeResourceForm.disk"
                        :max="max_disk" :precision="0" addon-after="GB" min="10"
                    />
                    <span
                        v-if="resizeResourceForm.Price_unit.name">{{
                        calculateUnit('Disk', resizeResourceForm.disk)
                      }}算力/{{ calculatePrice }}</span>
                  </a-form-item>
                  <a-form-item v-if="resizeResourceForm.type === 'VM'" label="磁盘">
                    <a-input-number
                        v-model:value="resizeResourceForm.disk" :disabled="true"
                        :max="max_disk" :precision="0" addon-after="GB" min="10"
                    />
                    <span
                        v-if="resizeResourceForm.Price_unit.name">{{
                        calculateUnit('Disk', resizeResourceForm.disk)
                      }}算力/{{ calculatePrice }}</span>
                  </a-form-item>
                  <a-form-item label="带宽">
                    <a-input-number
                        v-model:value="resizeResourceForm.ingress_bandwidth"
                        :precision="0" addon-after="Mbps" max="20" min="1"
                    />
                  </a-form-item>
                  <div style="border-top: 3px solid #2580fd;"></div>
                  <a-flex align="center" class="my-3" justify="space-between">
                    <div style="font-size: 16px;">以上配置当前环境算力点总计：</div>
                    <div>
                      <div class="unit-selector">
                        <span v-for="unit in units" :key="unit" :class="{ active: unit === display_unit }" @click="changeUnit(unit)">
                          {{ unit }}
                        </span>
                      </div>
                      <span style="color: #FF5F5F;font-size: 16px;">{{ displayedPoint }} 算力/{{ display_unit }}</span>
                    </div>
                  </a-flex>
                </a-form>
                <div v-if="resizeResourceForm.type==='VM'" v-show="false">
                  <div>附加网卡：
                    <a-button type="link" @click="()=>showAddNicModal()">新增网卡</a-button>
                  </div>
                  <a-table :columns="nicColumns" :data-source="nicData">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'action'">
                      <span>
                        <a-popconfirm title="确定删除该网卡？"
                                      @cancel="()=>{}"
                                      @confirm="()=>confirmRemoveNic(record.name)">
                          <a-button type="text">删除</a-button>
                        </a-popconfirm>
                      </span>
                      </template>
                    </template>
                  </a-table>
                  <a-modal v-model:open="openAdjustNicModal" :confirm-loading="confirmLoadingAdjustNic"
                           :mask="true"
                           :maskClosable="false"
                           title="新增网卡"
                           @ok="()=>handleOkAdjustNic()">
                    确定添加网卡？
                  </a-modal>
                </div>
                <div v-if="resizeResourceForm.type==='VM'">
                  <div>附加磁盘：
                    <a-button type="link" @click="()=>showAddDiskModal()">新增磁盘</a-button>
                  </div>
                  <a-table :columns="diskColumns" :data-source="diskData">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'action'">
                      <span>
                        <a-popconfirm title="确定删除该磁盘？"
                                      @cancel="()=>{}"
                                      @confirm="()=>confirmRemoveDisk(record.name)">
                          <a-button type="text">删除</a-button>
                        </a-popconfirm>
                      </span>
                      </template>
                    </template>
                  </a-table>
                  <a-modal v-model:open="openAdjustDiskModal" :confirm-loading="confirmLoadingAdjustDisk"
                           :mask="true"
                           :maskClosable="false"
                           title="新增磁盘"
                           @ok="()=>handleOkAdjustDisk()">
                    <a-form
                        :label-col="labelCol"
                        :wrapper-col="wrapperCol"
                        layout="horizontal">
                      <a-form-item label="磁盘">
                        <a-input-number v-model:value="adjustDiskForm.storage"
                                        :precision="0"
                                        addon-after='GB'
                                        max="100" min="1"/>
                      </a-form-item>
                    </a-form>
                  </a-modal>
                </div>
              </a-modal>
            </td>
            <td v-if="isVM">{{ row.rdp_ip }}</td>
            <td v-if="['Starting', 'Suspending', 'Suspended', 'Stopping', 'Stopped'].includes(row.env_status)">
              已停止
            </td>
            <td v-else>
              运行中
            </td>
            <td>
              <template v-if="!['Starting', 'Suspending', 'Suspended', 'Stopping', 'Stopped'].includes(row.env_status)"
              >
                <router-link v-if="row.template_type ==='ThirdParty'" :to="row.thirdparty_link"
                             class="btn btn-outline-info btn-sm"
                             target="_blank">
                  打开
                </router-link>
                <router-link v-if="row.template_type ==='VM' && row.has_solution"
                             :to="`/terminal/solution/${row.solution_id}/inner_service_name/${row.inner_service_name}`"
                             class="btn btn-outline-info btn-sm" target="_blank">
                  打开
                </router-link>
                <router-link v-if="row.template_type ==='Container' && row.has_solution"
                             :to="`/terminal/solution/${row.solution_id}/inner_service_name/${row.inner_service_name}`"
                             class="btn btn-outline-info btn-sm" target="_blank">
                  打开
                </router-link>
                <router-link v-if="row.template_type ==='Container' && !row.has_solution"
                             :to="`/experiment/environment/exec/name/${row.env_name}`"
                             class="btn btn-outline-info btn-sm" target="_blank">
                  打开
                </router-link>
              </template>
              <template v-else>
                <a-button disabled size="small" type="primary">打开</a-button>
              </template>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
        v-if="!['Starting', 'Suspending', 'Suspended', 'Stopping', 'Stopped'].includes(data_list.app_env_status_detail)"
        class="info">
      <div class="pot_top">
        <ul class="nav">
          <!-- <li class="nav-item"><a class="nav-link" t-on-click="_onMonitor" href="#">监控</a></li>
          <li class="nav-item"><a class="nav-link" t-on-click="_onEvent" href="#">事件</a></li> -->
          <li class="nav-item"><a class="nav-link active" href="#">快照</a></li>
        </ul>
        <!-- <div class="search_bar">
            <input type="search" t-on-keyup="_handleKeyUp" t-att-value="cacheState.searchString" placeholder="请输入名称" t-ref="search"/>
            <button t-on-click="_onSearch" class="btn btn-sm fa fa-search"/>
        </div> -->
      </div>
      <div class="table-responsive">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>序号</td>
            <td>快照名称</td>
            <td>快照描述</td>
            <td>快照状态</td>
            <td>快照提交时间</td>
            <td>操作</td>
          </tr>
          <tr v-for="row in data_list.app_snapshot_ids" :key="row.id">
            <td>{{ row.id }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.description }}</td>
            <td>{{ row.status }}</td>
            <td>{{ row.commit_date }}</td>
            <td>
              <template v-if="row.status !== 'not_synced'">
                <a-button v-if="row.in_use" style="color: #0b0;" type="link">使用中</a-button>
                <a-popconfirm v-else-if="!['synced'].includes(row.status)" cancel-text="否" ok-text="是" title="是否确认删除？"
                              @confirm="()=>delete_app_snapshot(row.id)">
                  <a-button :disabled="isRollingBack" danger style="color: #666;" type="text">删除</a-button>
                </a-popconfirm>
              </template>
              <template
                  v-if="!row.in_use && ![
                          'not_committed', 'commit_failed', 'not_synced', 'sync_failed'
                          ].includes(row.status)">
                <a-divider type="vertical"/>
                <a-popconfirm cancel-text="否" ok-text="是"
                              title="应用环境当前修改内容将被丢弃，是否确认回滚？"
                              @confirm="()=>rollback_app_environment(row.id)">
                  <a-button :disabled="isRollingBack" type="link">回滚</a-button>
                </a-popconfirm>
                <a-modal v-model:open="openRollbackAppSnapshotModal"
                         :closable="false"
                         :footer="null"
                         :maskClosable="false" title="应用环境回滚一般需要消耗数秒钟到数十秒钟不等，请耐心等待......"
                         width="70%">
                  <a-space style="display: flex;justify-content: center;height: 270px; overflow: hidden">
                    <a-spin size="large"/>
                  </a-space>
                </a-modal>
              </template>
              <template v-if="row.status === 'committed'">
                <a-divider type="vertical"/>
                <a-button :disabled="isRollingBack" type="link"
                          @click="()=>showCreateAppTemplateModal(row)">
                  创建应用模板
                </a-button>
                <!--  注意在 a-table 里的  a-modal 里，不用访问record，否则拿到的数据总是最后一条记录的数据 -->
                <a-modal v-model:open="openCreateAppTemplateModal"
                         cancelText="取消" okText="确认"
                         style="width: 50vw; height: 50vh" title="创建应用模板"
                         @ok="()=>handleOkCreateAppTemplateModal()">
                  <a-form :label-col="{ span: 6 }"
                          :model="createAppTemplateForm"
                          :wrapper-col="{ span: 18 }"
                          autocomplete="off"
                          name="createAppTemplate"
                          style="margin-top: 30px"
                  >
                    <a-form-item
                        :rules="[{ required: true, message: '请填写应用快照ID！' }]"
                        label="快照序号"
                        name="app_snapshot_id"
                    >
                      <a-input v-model:value="createAppTemplateForm.app_snapshot_id"
                               :disabled="true"
                               placeholder="应用快照ID（必填）！"/>
                    </a-form-item>
                    <a-form-item
                        :rules="[{ required: true, message: '请填写应用模板名称！' }]"
                        label="应用模板名称"
                        name="app_snapshot_name"
                    >
                      <a-input v-model:value="createAppTemplateForm.app_snapshot_name"
                               placeholder="应用模板名称（必填）！"/>
                    </a-form-item>
                    <a-form-item
                        :rules="[{ required: true, message: '请填写应用模板描述！' }]"
                        label="应用模板描述"
                        name="app_snapshot_description"
                    >
                      <a-input v-model:value="createAppTemplateForm.app_snapshot_description"
                               placeholder="请简单描述本次创建应用模板包含的内容和时间（必填）！"/>
                    </a-form-item>
                    <a-form-item v-if="isVM" label="支持ttyd" name="support_ttyd">
                      <div style="display: flex">
                        <a-radio-group v-model:value="createAppTemplateForm.support_ttyd">
                          <a-radio value="unchanged">不变</a-radio>
                          <a-radio value="yes">是</a-radio>
                          <a-radio value="no">否</a-radio>
                        </a-radio-group>
                        <a-popover trigger="hover">
                          <template #content>
                            <div style="width: 500px">
                              “支持ttyd”有三种不同取值，它们的含义分别如下。
                              <ul style="margin-left: 20px">
                                <li>
                                  “不变”，表示和创建该应用的源应用模板保持一致，不做改变。
                                </li>
                                <li>
                                  “是”，表示使用该应用模板创建的应用会提供ttyd终端。
                                  <span style="color: red">
                              注意，这要求实验环境里必须安装了ttyd服务，并且ttyd服务使用的是7149端口。
                            </span>
                                </li>
                                <li>
                                  “否”，表示使用该应用模板创建的应用不会提供ttyd终端。
                                </li>
                              </ul>
                              <p>
                                建议，如果您在实验环境里安装了ttyd服务，并且ttyd服务使用的是7149端口，那么就选择“是”。
                                其他情况下尽量选择“不变”即可，通常不需要选择“否”。
                              </p>
                              <p>
                                <span style="color: red">注意</span>，如果您的应用是由通过“ISO镜像”制作的应用模板所创建的话，
                                那么此时您的应用环境里的“系统盘（即根路径"/"挂载的磁盘）”还只是一个临时文件，
                                您此时所安装的各种软件很有可能在系统重启后丢失。
                                所以，这里建议您在通过“ISO镜像”制作的应用模板创建应用后，只做安装操作系统这一件事情，不要安装其他各种软件。
                                当安装好操作系统后，点击“创建模板”按钮，将这个应用创建为一个新的用户应用模板。
                                您可以在桌面端的“我的模板”文件夹下看到这个新创建的用户应用模板。
                                然后您可以点击新用户应用模板右侧的“部署”按钮，创建一个新应用。
                                这个新应用将自动去掉“ISO镜像”盘，并从安装了操作系统的硬盘启动。
                                之后您在这个新应用的环境中安装ttyd、webshell等各种软件服务
                                （参考<a download="如何给虚拟机安装shell功能.md"
                                        href="/hw_frontend/static/doc/vm_install_shell.md">如何给虚拟机安装shell功能</a>），
                                这样系统重启后软件就都不会丢失了。
                                最后您再将这个新应用制作为新的用户应用模板即可。
                                之后您可以通过“我的模板”文件夹下的“共享”按钮，将这个支持ttyd和webshell的新用户应用模板共享给其他用户使用。
                                或者您也可以提交通知系统管理人员审核该用户应用模板，将其转成系统应用模板后加入应用中心。
                              </p>
                            </div>
                          </template>
                          <QuestionCircleTwoTone class="help-icon"/>
                        </a-popover>
                      </div>
                    </a-form-item>
                    <a-form-item v-if="isVM" label="支持webshell" name="support_vmshell">
                      <div style="display: flex">
                        <a-radio-group v-model:value="createAppTemplateForm.support_vmshell">
                          <a-radio value="unchanged">不变</a-radio>
                          <a-radio value="yes">是</a-radio>
                          <a-radio value="no">否</a-radio>
                        </a-radio-group>
                        <a-popover trigger="hover">
                          <template #content>
                            <div style="width: 500px">
                              “支持webshell”有三种不同取值，它们的含义分别如下。
                              <ul style="margin-left: 20px">
                                <li>
                                  “不变”，表示和创建该应用的源应用模板保持一致，不做改变。
                                </li>
                                <li>
                                  “是”，表示使用该应用模板创建的应用会提供webshell终端。
                                  <span style="color: red">
                              注意，这要求实验环境里必须安装了webshell服务，并且webshell服务使用的是7179端口。
                            </span>
                                </li>
                                <li>
                                  “否”，表示使用该应用模板创建的应用不会提供webshell终端。
                                </li>
                              </ul>
                              <p>
                                建议，如果您在实验环境里安装了webshell服务，并且webshell服务使用的是7179端口，那么就选择“是”。
                                其他情况下尽量选择“不变”即可，通常不需要选择“否”。
                              </p>
                              <p>
                                <span style="color: red">注意</span>，如果您的应用是由通过“ISO镜像”制作的应用模板所创建的话，
                                那么此时您的应用环境里的“系统盘（即根路径"/"挂载的磁盘）”还只是一个临时文件，
                                您此时所安装的各种软件很有可能在系统重启后丢失。
                                所以，这里建议您在通过“ISO镜像”制作的应用模板创建应用后，只做安装操作系统这一件事情，不要安装其他各种软件。
                                当安装好操作系统后，点击“创建模板”按钮，将这个应用创建为一个新的用户应用模板。
                                您可以在桌面端的“我的模板”文件夹下看到这个新创建的用户应用模板。
                                然后您可以点击新用户应用模板右侧的“部署”按钮，创建一个新应用。
                                这个新应用将自动去掉“ISO镜像”盘，并从安装了操作系统的硬盘启动。
                                之后您在这个新应用的环境中安装ttyd、webshell等各种软件服务
                                （参考<a download="如何给虚拟机安装shell功能.md"
                                        href="/hw_frontend/static/doc/vm_install_shell.md">如何给虚拟机安装shell功能</a>），
                                这样系统重启后软件就都不会丢失了。
                                最后您再将这个新应用制作为新的用户应用模板即可。
                                之后您可以通过“我的模板”文件夹下的“共享”按钮，将这个支持ttyd和webshell的新用户应用模板共享给其他用户使用。
                                或者您也可以提交通知系统管理人员审核该用户应用模板，将其转成系统应用模板后加入应用中心。
                              </p>
                            </div>
                          </template>
                          <QuestionCircleTwoTone class="help-icon"/>
                        </a-popover>
                      </div>
                    </a-form-item>
                  </a-form>
                </a-modal>
              </template>
              <template v-if="row.status === 'synced'">
                <a-divider type="vertical"/>
                <a-button type="link" @click="()=>showAppSnapshotDetail(row)">
                  应用模板详情
                </a-button>
                <a-modal v-model:open="openShowAppSnapshotDetail"
                         :footer="null" cancelText="取消"
                         okText="确认" style="width: 50vw; height: 50vh" title="应用模板详情"
                         @ok="handleOkShowAppSnapshotDetail">
                  <a-form :disabled="true"
                          :label-col="{ span: 6 }"
                          :model="currentAppSnapshotDetail"
                          :wrapper-col="{ span: 18 }"
                          autocomplete="off"
                          name="appSnapshotDetail"
                          style="margin-top: 30px"
                  >
                    <a-form-item
                        label="应用模板名称"
                        name="app_snapshot_id">
                      <a-input v-model:value="currentAppSnapshotDetail.user_app_name"/>
                    </a-form-item>

                    <a-form-item
                        label="应用模板描述"
                        name="app_snapshot_name">
                      <a-input v-model:value="currentAppSnapshotDetail.user_app_description"/>
                    </a-form-item>
                  </a-form>
                </a-modal>
              </template>
              <template v-if="row.status === 'not_synced'">
                <span>正在制作用户应用模板... <LoadingOutlined/></span>
              </template>
              <template v-if="row.status === 'sync_failed'">
                <a-divider type="vertical"/>
                <span>制作用户应用模板失败，请尝试稍后重新制作。</span>
              </template>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {logComponents, logDebug, logError} from "@/utils/logger";
import {computed, onMounted, reactive, ref, toRaw, watch} from "vue";
import {routerReplace} from "@/utils/router_utils";
import {newCancelPreview, newHandlePreview, newPreviewState, newUploadDummyRequest} from "@/utils/file_utils";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import Slider from "@/components/Slider.vue";
import {useRouter} from "vue-router";
import {message, Modal, notification} from "ant-design-vue";
import {EditOutlined, LoadingOutlined, PlusOutlined, QuestionCircleTwoTone} from "@ant-design/icons-vue";
import {isNullOrUndefined, isTrue} from "@/utils/common_utils";

logComponents([LoadingOutlined, PlusOutlined])
logDebug("ConsoleAppEnvironmentDetail setup.");

const router = useRouter();
const {currentRoute} = useRouter();
const route = currentRoute.value;
const uploadDummyRequest = newUploadDummyRequest()
const {previewVisible, previewImage, previewTitle} = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle)
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)
const openRollbackAppSnapshotModal = ref(false)
const confirmCreateSnapshotModal = ref(false)
const confirmCreateTemplateModal = ref(false)
const confirmCreateSnapshot = ref(false)
const confirmCreateTemplate = ref(false)
const confirmDelete = ref(false)
const openDeleteModal = ref(false)
const data_list = reactive({
  app_snapshot_description: '',
  file_list: [],
  consume_points: 0,
  support_ttyd: 'unchanged',
  support_vmshell: 'unchanged',
});
const formRef = ref();
const rules = {
  app_snapshot_description: [
    {
      required: true,
      message: '请输入活动描述',
      trigger: 'change',
    },
  ],
  app_template_name: [
    {
      required: true,
      message: '请输入新模板名称',
      trigger: 'change',
    },
  ],
  file_list: [
    {
      required: true,
      message: '请上传图片',
      trigger: 'change',
      type: 'object',
    },
  ],
  app_template_description: [
    {
      required: true,
      message: '请输入模板介绍',
      trigger: 'blur',
    },
  ],
}

const labelCol = {
  span: 5,
};
const wrapperCol = {
  span: 13,
};

const isVM = ref(false)

// 最长查询一个小时
const updateSnapshotStatusIntervalMS = 10000
const updateSnapshotStatusTryTimes = 360
const updateSnapshotStatus = function (tryCount) {

  if (tryCount <= 0) {
    logError(`updateSnapshotStatus failed after try ${updateSnapshotStatusTryTimes} times.`)
    return
  }

  try {
    let isAnyOneSyncing = false
    const result = jsonRPC({
      url: `/vue/console/app/detail`,
      params: {
        id: route.params.env_id,
      },
      success(res) {
        logDebug(`查询成功`, getResponseData(res));
        const data = getResponseData(res)
        data_list.app_snapshot_ids = data.app_snapshot_ids
        for (const app_snapshot of data.app_snapshot_ids) {
          if (app_snapshot.status === 'not_synced') {
            isAnyOneSyncing = true
          }
        }
        for (const env_id of data_list.environment_ids) {
          if (env_id.template_type === 'VM') {
            isVM.value = true
            break
          }
        }
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    });
    result.then(function () {
      if (isAnyOneSyncing) {
        setTimeout(function () {
          updateSnapshotStatus(tryCount - 1)
        }, updateSnapshotStatusIntervalMS)
      }
    })
  } catch (e) {
    logError(`updateSnapshotStatus failed`, e)
  }
}

const change_env_name = function (row) {
  logDebug(`查询成功 row row row row row`, row);
  if (row.custom_name && row.custom_name.length <= 3) {
    return
  }
  jsonRPC({
    url: '/api/console/experiment/app/env/custom_name/write',
    params: {
      'env_id': row.id,
      'custom_name': row.custom_name
    },
    success(res) {
      logDebug(`查询成功 change_env_name`, res.data);
    },
    fail(error) {
      logError(`查询失败 change_env_name, `, error);
    },
  })
}
const change_app_name = function (data) {
  logDebug(`成功 custom_app_env_name`, data);
  if (data.custom_app_env_name && data.custom_app_env_name.length <= 0) {
    return
  }
  jsonRPC({
    url: '/api/console/experiment/app/custom_name/write',
    params: {
      'id': data.id,
      'custom_app_env_name': data.custom_app_env_name
    },
    success(res) {
      logDebug(`成功 custom_app_env_name`, res);
    },
  })

}
const loadAppData = function () {
  jsonRPC({
    url: `/vue/console/app/detail`,
    params: {
      id: route.params.env_id,
    },
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      Object.assign(data_list, res.data.result.data);
      if (isNullOrUndefined(data_list.env_id) || data_list.env_id === 0) {
        message.error(`应用不存在或者已经被删除，将自动跳转到“我的应用”页面！`, 3);
        routerReplace(router, '/console/app')
        return
      }
      for (const env_id of data_list.environment_ids) {
        if (env_id.template_type === 'VM') {
          isVM.value = true
          break
        }
      }
      setTimeout(function () {
        updateSnapshotStatus(updateSnapshotStatusTryTimes)
      }, updateSnapshotStatusIntervalMS)
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(`获取应用详情失败，应用不存在或者已经被删除，将自动跳转到“我的应用”页面！`, 3);
      routerReplace(router, '/console/app')
    },
  });
}
const max_cpu = ref(64)
const max_memory = ref(256)
const max_disk = ref(1000)
const getMarks = (max, marks) => {
  return Object.fromEntries(Object.entries(marks).filter(([key]) => key <= max));
};
const cpu_marks = computed(() => getMarks(max_cpu.value, {
  1: '1核',
  2: '2核',
  4: '4核',
  8: '8核',
  16: '16核',
  32: '32核',
  64: '64核'
}));
const memory_marks = computed(() => getMarks(max_memory.value, {
  2: '2G',
  4: '4G',
  8: '8G',
  16: '16G',
  32: '32G',
  64: '64G',
  128: '128G',
  256: '256G',
}));
onMounted(() => {
  loadAppData()
});

const openCreateAppTemplateModal = ref(false);
const createAppTemplateForm = reactive({
  app_snapshot_id: '',
  create_app_snapshot: 'true',
  app_snapshot_name: '',
  app_snapshot_description: '',
  support_ttyd: 'unchanged',
  support_vmshell: 'unchanged',
})
const showCreateAppTemplateModal = function (record) {
  logDebug(`showCreateAppTemplateModal, record[${JSON.stringify(record)}]`)
  Object.assign(createAppTemplateForm, {
    app_snapshot_id: record['id'],
    create_app_snapshot: 'true',
    app_snapshot_name: '',
    app_snapshot_description: '',
    support_ttyd: 'unchanged',
    support_vmshell: 'unchanged',
  })
  openCreateAppTemplateModal.value = true
}

const handleOkCreateAppTemplateModal = () => {
  logDebug(`handleOkCreateAppTemplateModal, createAppTemplateForm[${JSON.stringify(createAppTemplateForm)}]`)

  const createAppResult = jsonRPC({
    url: "/api/experiment/app/snapshot/create_app",
    params: {
      app_snapshot_id: createAppTemplateForm.app_snapshot_id,
      create_app_snapshot: createAppTemplateForm.create_app_snapshot,
      app_snapshot_name: createAppTemplateForm.app_snapshot_name,
      app_snapshot_description: createAppTemplateForm.app_snapshot_description,
      support_ttyd: createAppTemplateForm.support_ttyd,
      support_vmshell: createAppTemplateForm.support_vmshell,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`createAppResult, data[${JSON.stringify(data)}]`)
      message.info(`提交制作用户应用模板任务成功`, 3);
    },
    fail(error) {
      logError(`提交制作用户应用模板任务失败, `, error)
      message.error(`提交制作用户应用模板任务失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  createAppResult.then(() => {
    openCreateAppTemplateModal.value = false
  })
};

const openShowAppSnapshotDetail = ref(false)
const currentAppSnapshotDetail = reactive({
  user_app_name: "",
  user_app_description: '',
})

const showAppSnapshotDetail = function (record) {
  logDebug(`showAppSnapshotDetail, record[${JSON.stringify(record)}]`)
  // 先清空旧的数据
  Object.assign(currentAppSnapshotDetail, {
    user_app_name: '',
    user_app_description: '',
  })

  const appSnapshotId = record['id']

  const getAppSnapshotDetailResult = jsonRPC({
    url: "/api/experiment/app/snapshot/detail",
    params: {
      app_snapshot_id: appSnapshotId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`getAppSnapshotDetailResult, data[${JSON.stringify(data)}]`)
      Object.assign(currentAppSnapshotDetail, data)
    },
    fail(error) {
      logError(`创建应用环境快照失败, `, error)
      message.error(`创建应用环境快照失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  getAppSnapshotDetailResult.then(function () {
    openShowAppSnapshotDetail.value = true
  })
}

const handleOkShowAppSnapshotDetail = function () {
  openShowAppSnapshotDetail.value = false
}

let createSnapshot = () => {
  formRef.value.validate()
      .then(() => {
        logDebug('values', data_list, toRaw(data_list));
        confirmCreateSnapshot.value = true
        const result = jsonRPC({
          url: `/vue/console/experiment/app/snapshot/create`,
          params: {
            env_id: data_list.env_id,
            app_snapshot_description: data_list.app_snapshot_description,
            is_sync: "true",
          },
          success(res) {
            logDebug(`创建应用快照任务成功`, res.data.result);
            notification.info({
              message: '创建应用快照任务成功',
              description: '创建应用快照任务成功，请稍后在快照列表查看任务执行结果！',
              duration: 10, // 设置为0时，通知不会自动关闭
            });
          },
          fail(error) {
            logError(`创建应用快照任务失败, `, error);
            notification.error({
              message: '创建应用快照任务失败',
              description: `创建应用快照任务失败，失败原因：${JSON.stringify(getFailedMessage(error))}！`,
              duration: 10, // 设置为0时，通知不会自动关闭
            });
          },
        });
        result.then(function () {
          confirmCreateSnapshot.value = false
          confirmCreateSnapshotModal.value = false
        }).then(function () {
          loadAppData()
        })
      })
      .catch(error => {
        logDebug('error', error);
      });
}
logDebug(`createSnapshot[${createSnapshot}]`)

const createSnapshotV2 = () => {
  formRef.value.validate()
      .then(() => {
        logDebug('values', data_list, toRaw(data_list));
        confirmCreateSnapshot.value = true
        const result = jsonRPC({
          url: `/vue/console/experiment/app/snapshot/create_async`,
          params: {
            env_id: data_list.env_id,
            app_snapshot_description: data_list.app_snapshot_description,
            is_sync: "true",
          },
          success(res) {
            logDebug(`提交创建应用快照任务成功`, res.data.result);
            notification.info({
              message: '提交创建应用快照任务成功',
              description: '提交创建应用快照任务成功，请稍后在快照列表查看任务执行结果！',
              duration: 10, // 设置为0时，通知不会自动关闭
            });
          },
          fail(error) {
            logError(`提交创建应用快照任务失败, `, error);
            notification.error({
              message: '提交创建应用快照任务失败',
              description: `提交创建应用快照任务失败，失败原因：${JSON.stringify(getFailedMessage(error))}！`,
              duration: 10, // 设置为0时，通知不会自动关闭
            });
          },
        });
        result.then(function () {
          confirmCreateSnapshot.value = false
          confirmCreateSnapshotModal.value = false
        }).then(function () {
          loadAppData()
        })
      })
      .catch(error => {
        logDebug('error', error);
      });
}
logDebug(`createSnapshotV2[${createSnapshotV2}]`)

let createTemplate = () => {
  formRef.value.validate()
      .then(() => {
        logDebug('values', data_list, toRaw(data_list));
        confirmCreateTemplate.value = true
        const createAppResult = jsonRPC({
          url: `/vue/console/experiment/app/snapshot/create_app`,
          params: {
            env_id: data_list.env_id,
            create_app_snapshot: 'true',
            file_list: data_list.file_list,
            app_snapshot_name: data_list.app_template_name,
            app_snapshot_description: data_list.app_template_description,
            is_sync: "true",
            support_ttyd: data_list.support_ttyd,
            support_vmshell: data_list.support_vmshell,
          },
          success(res) {
            const data = getResponseData(res)
            logDebug(`createAppResult, data[${JSON.stringify(data)}]`)
            notification.info({
              message: '提交制作用户应用模板任务成功',
              description: '提交制作用户应用模板任务成功，请稍后在我的应用模板页面查看任务执行结果！',
              duration: 10, // 设置为0时，通知不会自动关闭
            });
          },
          fail(error) {
            logError(`提交制作用户应用模板任务失败, `, error);
            notification.error({
              message: '提交制作用户应用模板任务失败',
              description: `提交制作用户应用模板任务失败，失败原因：${JSON.stringify(getFailedMessage(error))}！`,
              duration: 10, // 设置为0时，通知不会自动关闭
            });
          },
        });
        createAppResult.then(function () {
          confirmCreateTemplate.value = false
          confirmCreateTemplateModal.value = false
        }).then(function () {
          loadAppData()
        })
      })
      .catch(error => {
        logDebug('error', error);
      });
}
logDebug(`createTemplate[${createTemplate}]`)
const createTemplateV2 = () => {
  formRef.value.validate()
      .then(() => {
        logDebug('values', data_list, toRaw(data_list));
        confirmCreateTemplate.value = true
        const createAppResult = jsonRPC({
          url: `/vue/console/experiment/app/snapshot/create_app_async`,
          params: {
            env_id: data_list.env_id,
            create_app_snapshot: 'true',
            file_list: data_list.file_list,
            app_snapshot_name: data_list.app_template_name,
            app_snapshot_description: data_list.app_template_description,
            is_sync: "true",
          },
          success(res) {
            const data = getResponseData(res)
            logDebug(`createAppResult, data[${JSON.stringify(data)}]`)
            notification.info({
              message: '提交制作用户应用模板任务成功',
              description: '提交制作用户应用模板任务成功，请稍后在我的应用模板页面查看任务执行结果！',
              duration: 10, // 设置为0时，通知不会自动关闭
            });
          },
          fail(error) {
            logError(`提交制作用户应用模板任务失败, `, error);
            notification.error({
              message: '提交制作用户应用模板任务失败',
              description: `提交制作用户应用模板任务失败，失败原因：${JSON.stringify(getFailedMessage(error))}！`,
              duration: 10, // 设置为0时，通知不会自动关闭
            });
          },
        });
        createAppResult.then(function () {
          confirmCreateTemplate.value = false
          confirmCreateTemplateModal.value = false
        }).then(function () {
          loadAppData()
        })
      })
      .catch(error => {
        logDebug('error', error);
      });
}
logDebug(`createTemplateV2[${createTemplateV2}]`)
let confirmFormDelete = () => {
  confirmDelete.value = true
  jsonRPC({
    url: `/vue/console/app/delete`,
    params: {
      app_id: route.params.env_id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`删除实验环境成功`, data);
      message.success(`删除实验环境成功`)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    confirmDelete.value = false
    openDeleteModal.value = false
    routerReplace(router, '/console/app')
  })
}

const isRollingBack = ref(false)

let rollback_app_environment = (app_snapshot_id) => {
  isRollingBack.value = true

  const rollbackAppSnapshotResult = jsonRPC({
    url: `/vue/console/experiment/app/snapshot/rollback`,
    params: {
      env_id: data_list.env_id,
      app_snapshot_id: app_snapshot_id,
    },
    success(res) {
      logDebug(`回滚应用环境快照成功`, res.data.result);
    },
    fail(error) {
      logError(`回滚应用环境快照失败, `, error)
      try {
        try {
          let errMsg = JSON.parse(error);
          let user = errMsg.user || '';
          let message = errMsg.message || '资源不足';
          let details = errMsg.details || '';
          Modal.error({
            title: "回滚应用环境快照失败",
            content: (
                <div>
                  <div>{user}</div>
                  <div style={{textIndent: '2em'}}>{message}</div>
                  <div>{details}</div>
                </div>
            ),
            width: '60%', // 设置宽度为 60%
            closable: true, // 右上角关闭按钮
            onCancel: () => {
              Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
            },
            footer: () => (
                <div class="d-flex justify-content-center my-3">
                  <button type="button" class="btn btn-primary" onClick={async () => {
                    router.push('/cost/topup')
                    Modal.destroyAll(); // 关闭所有弹窗
                  }}>补充资源
                  </button>
                </div>
            ),
          });
        } catch (err) {
          logError("查询失败: 无法解析错误信息", err);
          Modal.error({
            title: "回滚应用环境快照失败",
            content: (
                <div style={{textIndent: '2em'}}>{JSON.stringify(getFailedMessage(error))}</div>
            ),
            width: '60%', // 设置宽度为 60%
            closable: true, // 右上角关闭按钮
            onCancel: () => {
              Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
            },
            footer: () => (
                <div class="d-flex justify-content-center my-3">
                  <button type="button" class="btn btn-primary" onClick={() => {
                    Modal.destroyAll(); // 关闭所有弹窗
                  }}>关闭
                  </button>
                </div>
            ),
          });
        }
      } catch (er) {
        logError("回滚应用环境快照失败发生未捕获的错误：", er);
        message.error('回滚应用环境快照失败，请稍后重试。')
      }
    },
  })
  message.info(`已触发应用环境回滚，请稍等片刻！`, 8)

  rollbackAppSnapshotResult.then(function (res) {
    if (isTrue(res)) {
      openRollbackAppSnapshotModal.value = true
      setTimeout(function () {
        isRollingBack.value = false
        openRollbackAppSnapshotModal.value = false
        routerReplace(router, '/console/app')
        message.success(`回滚应用环境快照成功！`)
      }, 60000)
    }
  });
}
let delete_app_snapshot = (app_snapshot_id) => {
  const result = jsonRPC({
    url: `/vue/console/experiment/app/snapshot/delete`,
    params: {
      app_snapshot_id: app_snapshot_id,
    },
    success(res) {
      logDebug(`查询成功`, res.data.result);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
  result.then(function () {
    loadAppData()
  })
}

// 查询资源
const loadResource = function (env_id) {
  return jsonRPC({
    url: `/api/experiment/app/resource`,
    params: {
      env_id: env_id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询实验环境资源成功`, data);
      Object.assign(resizeResourceForm, data)
      resizeResourceForm.capacity_type_level = JSON.parse(data.capacity_type_level)
      try {
        resizeResourceForm.Price_unit = resizeResourceForm.capacity_type_level?.[resizeResourceForm.app_solution_id?.capacity_type_level]?.[0]?.capacity || {};
      } catch (error) {
        logError(error);
        resizeResourceForm.Price_unit = {};
      }
      resizeResourceForm.env_id = env_id;
      const diskDataTemp = []
      for (const disk of data.extra_disk_ids) {
        diskDataTemp.push({
          disk_id: disk.disk_id,
          name: disk.name,
          pvc_name: disk.pvc_name,
          pvc_namespace: disk.pvc_namespace,
          storage: disk.storage,
          mount_path: disk.mount_path,
          deployment_id: disk.deployment_id,
        })
      }
      diskData.value = diskDataTemp

      const nicDataTemp = []
      for (const nic of data.extra_nic_ids) {
        nicDataTemp.push({
          nic_id: nic.nic_id,
          name: nic.name,
          nic_type: nic.nic_type,
          nic_mode: nic.nic_mode,
          deployment_id: nic.deployment_id,
        })
      }
      nicData.value = nicDataTemp

      return true
    },
    fail(error) {
      logError(`查询实验环境资源失败！`, error)
      message.error(`查询实验环境资源失败！`, 3);
      return false
    },
  });
}

// 调整资源
const openResizeResourceModal = ref(false)
const confirmLoadingResizeResource = ref(false)
const resizeResourceForm = reactive({
  "env_id": 0,
  "type": '',
  "cpu_cores": 0,
  "memory": 0,
  "disk": 0,
  "ingress_bandwidth": 0,
  "egress_bandwidth": 0,
  Price_unit: {},
})
const unitMap = {
  hours: '小时',
  day: '天',
  month: '月'
};
const total_price = ref(0)
const calculateUnit = (e, unit) => {
  let sum = 1
  for (const x of resizeResourceForm.Price_unit.capacity_type_op || []) {
    if ((x?.hardware_id?.hardware_type || '').toUpperCase() === (e || '').toUpperCase()) {
      sum = e == 'RAM' ? x.use_point * unit * 1024 : x.use_point * unit;
      break
    }
  }
  // 保留8位小数并去除末尾零
  return parseFloat(sum.toFixed(8));
}
watch(resizeResourceForm, () => {
  try {
    let types = ['Disk', 'CPU', 'RAM', 'IngressBandwidth', 'EgressBandwidth', 'Other'];
    let sum = 0;
    for (const x of resizeResourceForm.Price_unit.capacity_type_op || []) {
      if (types.includes(x.hardware_id.hardware_type)) {
        sum += x.use_point * total_unit(x.hardware_id.hardware_type).toFixed(8)
      } else {
        sum += x.use_point * x.use_number.toFixed(8)
      }
    }
    total_price.value = parseFloat(sum.toFixed(8));
  } catch (error) {
    logError('计算价格时出错:', error);
  }
})
const findModelName = (e) => {
  let sum = ''
  for (const x of resizeResourceForm.Price_unit.capacity_type_op || []) {
    if ((x?.hardware_id?.hardware_type || '').toUpperCase() === (e || '').toUpperCase()) {
      sum = x.name;
      break
    }
  }
  return sum; // 查找型号
}
const calculatePrice = computed(() => {
  let sum = '时'
  if (unitMap[resizeResourceForm.Price_unit.unit_op]) {
    sum = unitMap[resizeResourceForm.Price_unit.unit_op]
  }
  return sum;
})
const total_unit = (e) => {
  let sum = 1
  if (e == 'CPU') {
    sum = resizeResourceForm.cpu_cores
  } else if (e == 'RAM') {
    sum = resizeResourceForm.memory * 1024
  } else if (e == 'Disk') {
    sum = resizeResourceForm.disk
  }
  return sum;
}
// 单位列表及换算关系
const units = ['时', '日', '月', '年'];
// 设置 display_unit，判断 calculatePrice 是否在 units 里
const display_unit = ref(units.includes(calculatePrice.value) ? calculatePrice.value : '时');
// 监听 calculatePrice 变化，自动更新 display_unit
watch(calculatePrice, (newVal) => {
  display_unit.value = units.includes(newVal) ? newVal : '时';
});
// 计算数值
const conversionRates = { '时': 1, '日': 24, '月': 24 * 30, '年': 24 * 365 };
// **改成乘法运算**
const displayedPoint = computed(() => {
  return parseFloat((total_price.value * conversionRates[display_unit.value]).toFixed(8));
});
// 切换单位
const changeUnit = (unit) => {
  display_unit.value = unit;
};
const handleOkResizeResource = function () {
  confirmLoadingResizeResource.value = true;
  const result = jsonRPC({
    url: `/api/experiment/app/resource/resize`,
    params: {
      env_id: resizeResourceForm.env_id,
      cpu_cores: resizeResourceForm.cpu_cores,
      memory: resizeResourceForm.memory,
      disk: resizeResourceForm.disk,
      ingress_bandwidth: resizeResourceForm.ingress_bandwidth,
      // egress_bandwidth与ingress_bandwidth保持一致，简化配置项
      egress_bandwidth: resizeResourceForm.ingress_bandwidth,
      total_price: total_price.value,  // 新的总价
      is_compute_point: total_price.value > 0,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`调整资源成功`, data);
      message.info(`调整资源成功！`, 3);
      window.location.reload();
    },
    fail(error) {
      logError(`调整资源失败, `, error);
      message.error(`调整资源失败！`, 3);
    },
  });
  result.then(function () {
    openResizeResourceModal.value = false
    confirmLoadingResizeResource.value = false;
  })
}
const showResizeResourceModal = function (record) {
  logDebug(`showResizeResourceModal, record[${record}]`)
  loadResource(record.id).then(function (res) {
    if (isTrue(res)) {
      openResizeResourceModal.value = true
    }
  })
}

// 添加网卡
const openAdjustNicModal = ref(false)
const confirmLoadingAdjustNic = ref(false)
const nicColumns = [
  {
    title: '网卡名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '网卡类型',
    dataIndex: 'nic_type',
    key: 'nic_type',
  },
  {
    title: '网卡模式',
    dataIndex: 'nic_mode',
    key: 'nic_mode',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const nicData = ref([]);
const showAddNicModal = function () {
  openAdjustNicModal.value = true
}
const confirmRemoveNic = function (nic_name) {
  const result = jsonRPC({
    url: `/api/experiment/app/nic/remove`,
    params: {
      env_id: resizeResourceForm.env_id,
      nic_name: nic_name,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`删除网卡成功`, data);
      message.info(`删除网卡成功！`, 3);
    },
    fail(error) {
      logError(`添加网卡失败, `, error);
      message.error(`添加网卡失败！`, 3);
    },
  });

  return new Promise(resolve => {
    result.then(function () {
      loadResource(resizeResourceForm.env_id).then(function () {
        resolve(true)
      })
    })
  });
}
const handleOkAdjustNic = function () {
  logDebug(`handleOkAdjustNic`)
  confirmLoadingAdjustNic.value = true;
  const result = jsonRPC({
    url: `/api/experiment/app/nic/add`,
    params: {
      env_id: resizeResourceForm.env_id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`添加网卡成功`, data);
      message.info(`添加网卡成功！`, 3);
    },
    fail(error) {
      logError(`添加网卡失败, `, error);
      message.error(`添加网卡失败！`, 3);
    },
  });
  result.then(function () {
    openAdjustNicModal.value = false
    confirmLoadingAdjustNic.value = false;
  }).then(function () {
    loadResource(resizeResourceForm.env_id)
  })
}
// 添加磁盘
const openAdjustDiskModal = ref(false)
const confirmLoadingAdjustDisk = ref(false)
const adjustDiskForm = reactive({
  "storage": 20,
})
const diskColumns = [
  {
    title: '磁盘名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '磁盘大小（GB）',
    dataIndex: 'storage',
    key: 'storage',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const diskData = ref([]);
const showAddDiskModal = function () {
  logDebug(`showAddDiskModal`)
  openAdjustDiskModal.value = true
}
const confirmRemoveDisk = function (disk_name) {
  logDebug(`confirmRemoveDisk`)
  const result = jsonRPC({
    url: `/api/experiment/app/disk/remove`,
    params: {
      env_id: resizeResourceForm.env_id,
      disk_name: disk_name,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`删除磁盘成功`, data);
      message.info(`删除磁盘成功！`, 3);
    },
    fail(error) {
      logError(`删除磁盘失败, `, error);
      message.error(`删除磁盘失败！`, 3);
    },
  });
  return new Promise(resolve => {
    result.then(function () {
      loadResource(resizeResourceForm.env_id).then(function () {
        resolve(true)
      })
    })
  });
}
const handleOkAdjustDisk = function () {
  logDebug(`handleOkAdjustDisk`)
  confirmLoadingAdjustDisk.value = true;
  const result = jsonRPC({
    url: `/api/experiment/app/disk/add`,
    params: {
      env_id: resizeResourceForm.env_id,
      storage: adjustDiskForm.storage,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`添加磁盘成功`, data);
      message.info(`添加磁盘成功！`, 3);
    },
    fail(error) {
      logError(`添加磁盘失败, `, error);
      message.error(`添加磁盘失败！`, 3);
    },
  });
  result.then(function () {
    openAdjustDiskModal.value = false
    confirmLoadingAdjustDisk.value = false;
  }).then(function () {
    loadResource(resizeResourceForm.env_id)
  })
}
</script>

<style lang="scss" scoped>
.app_detail {
  padding: 16px;

  .breadcrumb {
    margin-bottom: 0;

    a,
    .active {
      color: #101010;
      font-size: 16px;
    }

    a:hover {
      color: #ff8c00;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      display: inline;
      content: "";
      padding-right: 24px;
      background: url(@/assets/project_double_right.png) no-repeat;
      background-size: 20px;
    }
  }

  .search_bar {
    display: flex;
    align-items: center;
    line-height: 100%;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    border: 1px solid #ced4da;

    input {
      border: none;
      background-color: transparent;

      &:focus-visible {
        outline: none;
      }
    }

    button {
      &.fa {
        font-weight: normal;
        bottom: auto;
        color: #77b7ff;
      }
    }
  }

  .App_info {
    background-color: #fff;
    padding: 15px;
    box-shadow: -1px 2px 4px 0px #a1a1a1;
    border-radius: 8px;
    margin-bottom: 20px;

    h4 {
      font-size: 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .btn {
        padding: 0;
        background-color: #cdcdcd;
        width: 100px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: #fff;

        &.release {
          background-color: #2481e9;
          margin-right: 10px;
        }
      }
    }

    tr {
      td:first-child {
        white-space: nowrap;
        color: #807e7e;
      }

      td:last-child {
        width: 100%;

        > span {
          position: relative;
          display: inline-block;
          width: 22px;

          &::before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: absolute;
            top: -13px;
            left: 0;
          }

          &.running {
            &::before {
              background-color: #adffb5;
            }
          }

          &.stopped {
            &::before {
              background-color: lightgrey;
            }
          }
        }
      }
    }
  }

  .mirror_list {
    background-color: #fff;
    padding: 15px;
    box-shadow: -1px 2px 4px 0px #a1a1a1;
    border-radius: 8px;
    margin-bottom: 20px;

    h4 {
      font-size: 18px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      .btn {
        background-color: #2481e9;
        font-size: 16px;
        color: #fff;
        padding: 0;
        width: 100px;
        height: 30px;
        line-height: 30px;
      }
    }

    td {
      text-align: center;
      vertical-align: middle;
    }

    tr:first-child {
      td {
        background-color: #f4faff;
      }
    }
  }

  .info {
    background-color: #fff;
    padding: 15px;
    box-shadow: -1px 2px 4px 0px #a1a1a1;
    border-radius: 8px;

    .nav {
      margin-bottom: 20px;

      a {
        font-size: 16px;

        &.active {
          border-bottom: 2px solid #2481e9;
        }
      }
    }

    .event_top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .form-control {
        width: initial;
        margin-right: 10px;
      }
    }

    .pot_top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    td {
      text-align: center;
      vertical-align: middle;
    }

    tr:first-child {
      td {
        background-color: #f4faff;
      }
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .setting {
    form {
      span {
        display: inline-block;
        color: #3894FF;
        margin-left: 15px;
        margin-top: 5px;
      }
    }
  }
}

.help-icon {
  margin-left: 20px;
}

.help-icon-hidden {
  visibility: hidden;
}
</style>
