<template>
  <div class="forum_bage">
    <a-row gutter="20">
      <a-col :xs="24" :sm="10" :md="8" :lg="4">
        <div class="showing">
          <p class="line-clamp-1"><span>{{totoal_visits}}</span> 用户讨论中</p>
          <p class="line-clamp-1"><span>{{totoal_posts}}</span> 帖子更新中</p>
        </div>
        <div class="blog_hot_post">
          <h5>热门应用
            <span @click="onOneChange"><RedoOutlined />换一批</span>
          </h5>
          <ul>
            <li v-for="(item, index) in app_list" :key="index" @click="toAppDetail(item)">
              <a-flex gap="10" align="center">
                <span style="flex-shrink: 0;"><img :src="item.image_512"></span>
                <span :title="item.name" class="ellipsis-text">{{item.name}}</span>
              </a-flex>
            </li>
          </ul>
        </div>
      </a-col>
      <a-col :xs="24" :sm="14" :md="16" :lg="20">
        <div class="forum_list">
          <a-card hoverable v-for="item in data_list.records" :key="item.id" @click="enterDetail(item.id);">
            <div class="info">
              <div>
                <h5 :title="item.abstract?`板块摘要:${item.abstract}`:''"><img :src="item.image_url">{{ item.name }}</h5>
                <!-- <div>板块摘要</div> 
                <div>{{item.abstract}}</div> -->
              </div>
              <div>
                <span>{{item.total_visits}}互动，</span>
                <span>{{item.total_posts}}帖子</span>
              </div>
            </div>
          </a-card>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { RedoOutlined } from '@ant-design/icons-vue';
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter } from "vue-router";
const router = useRouter();

const data_list = reactive({
  records: [],
  record_count: 0,
  user_blog_num: 0,
  reply_comment_count: 0,
  comment_count: 0,
  like_count: 0,
  hot_blog: [],
  hot_blog_page_count: 2,
  hot_blog_page_index: 1,
});
const totoal_visits = ref(0);
const totoal_posts = ref(0);
const app_list = ref([]);

const toAppDetail = (item) => {
  router.push({
    path: `/app/${item.id}`,
  });
}
function enterDetail(e) {
  router.push(`/forum/section/${e}`)
}

async function fetchData() {
  await jsonRPC({
    url: `/api/blog/forum/section`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取技术论坛成功`, data);
      Object.assign(data_list, data);
      data.records.forEach(item => {  
        totoal_visits.value += item.total_visits;
        totoal_posts.value += item.total_posts;
      });
    },
    fail(error) {
      logError(`获取技术论坛失败`, error);
      message.error(`获取技术论坛失败，请稍后再试`);
    },
  });
  await jsonRPC({
    url: "/api/app_list",
    params: {
      state: 'All',
      page_index: 1,
      page_size: 10,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      app_list.value = data.records;
    },
    fail(error) {
      logError(`查询失败app, `, error);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV(router.currentRoute);
});
const onOneChange = () => {
  if (app_list.value.length > 0) {
    const shuffled = app_list.value.sort(() => 0.5 - Math.random());
    app_list.value = shuffled.slice(0, 10);
  }
};
</script>

<style scoped lang="scss">
.forum_bage {
  padding: 25px;
  .showing{
    @mixin span{
      font-size: 36px;
      font-weight: 600;
       // 屏幕宽度小于 1200px 时的样式
      @media (max-width: 1200px) {
        font-size: 32px;
      }
    }
    p:nth-child(1){
      span{
        color: #FF3838;
        @include span();
      }
    }
    p:nth-child(2){
      span{
        color: #FFB029;
        @include span();
      }
    }
  }
  .blog_hot_post{
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5{
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      span{
        font-size: 14px;
        color: #949494;
        cursor: pointer;
        display: flex;
        gap: 5px;
      }
    }
    ul{
      li{
        margin: 10px 0;
        span{
          cursor: pointer;
          &:hover{
            color: #ff8c00;
          }
          img{
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
        }
        .ellipsis-text {
          display: inline-block;  /* 让它成为块级元素 */
          white-space: nowrap;    /* 禁止换行 */
          overflow: hidden;       /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 用省略号代替超出内容 */
        }
      }
    }
  }
  :where(.ant-card){
    margin-bottom: 15px;
    :where(.ant-card-body){
      padding: 15px 25px;
    }
  }
  .forum_list{
    .info{
      color:#101010;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5{
        font-size: 16px;
        margin-bottom: 0;
        img{
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
