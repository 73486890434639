<template>
  <a-modal :open="updateDialog" width="40%" :footer="null" :title="false" :closable="false" :keyboard="false" :maskClosable="false">
    <div style="text-align: center;">
      <h4>{{ data_list.home_config_copyright }}</h4>
      <p>全新升级</p>
      <div class="version">
        <img src="/hw_frontend/static/img/UpdateVersion_bg.png">
        <span>V {{ version }} 版本</span>
      </div>
      <p v-if="countUpdateTime!=0" class="Tips"><LoadingOutlined style="font-size: 30px;"/>新版本正在启动中，请稍候</p>
      <a-button v-else type="primary" @click.prevent="updateNow" shape="round" class="ml-auto mr-auto mt-10 block" style="background-color: #68A7FF;">
        开启全新体验
      </a-button>
    </div>
  </a-modal>
</template>

<script setup>
import { LoadingOutlined } from '@ant-design/icons-vue';
import {logDebug, logError} from "@/utils/logger";
import {onMounted, onBeforeUnmount, ref, reactive} from 'vue';
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import dayjs from 'dayjs';
import axios from "axios";
import {normalizeURL} from "@/utils/http_utils";

import { getFrontendVersion, getDesktopVersion } from "@/utils/version";
const $getFrontendVersion = getFrontendVersion()
const $getDesktopVersion = getDesktopVersion()
const version = ref('')
if ($getDesktopVersion) {
  version.value = $getDesktopVersion
}else{
  version.value = $getFrontendVersion
}

const interCheck = ref(null);
const countUpdateTime = ref(5);
const newVersionTime = ref(dayjs().format('YYYY-MM-DD HH:mm:ss'));
const updateDialog = ref(false);

function startCheck() {
  if (!interCheck.value) {
    interCheck.value = setInterval(checkVersion, 60*60*1000);
  }
}

function endCheck() {
  clearInterval(interCheck.value);
  interCheck.value = null;
}

async function checkVersion() {
  try {
    const url = '/version.json?t=' + Date.now();
    const publicVersion = await axios.get(normalizeURL(url), {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '-1',
      },
    });

    if (isValidResponse(publicVersion)) {
      const NEW_VERSION_TIME = publicVersion.data.VERSION_TIME;
      const VERSION_TIME = process.env.VERSION_TIME;

      logDebug(`当前版本时间: ${VERSION_TIME}`);
      logDebug(`新版本时间: ${NEW_VERSION_TIME}`);

      if (isNewVersionAvailable(NEW_VERSION_TIME, VERSION_TIME)) {
        endCheck();
        newVersionTime.value = NEW_VERSION_TIME;
        await startUpdate();
      }
    } else {
      logError('获取的版本信息格式不正确:', publicVersion);
    }
  } catch (error) {
    logError('获取版本信息时出错:', error.message || error);
  }
}

// 检查响应的有效性
function isValidResponse(response) {
  return response && response.data;
}

// 比较新版本时间与当前版本时间
function isNewVersionAvailable(newVersionTime, currentVersionTime) {
  return newVersionTime && new Date(newVersionTime) > new Date(currentVersionTime);
}

function updateNow() {
  updateDialog.value = false;
  countUpdateTime.value = 5;
  location.reload(true);
}

async function startUpdate() {
  countUpdateTime.value = 5; // 初始化计数器
  updateDialog.value = true; // 显示对话框

  const countdown = () => {
    if (countUpdateTime.value > 0) {
      countUpdateTime.value--; // 倒计时减一
      setTimeout(countdown, 1000); // 递归调用，每秒更新一次
    } else {
      updateDialog.value = false;
      location.reload(true); // 倒计时结束，刷新页面
    }
  };
  countdown(); // 启动倒计时
}

const data_list = reactive({
  home_config_copyright: '',
});

onMounted(() => {
  jsonRPC({
    url: "/vue/home/layout",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取layout数据成功`, data)
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取失败`, error);
    },
  });
  startCheck();
});

onBeforeUnmount(() => {
  endCheck(); // 清理定时器
});
</script>

<style lang="scss" scoped>
.version{
  position: relative;
  margin-bottom: 20px;
  span{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 16px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    font-weight: bold;
  }
}
.Tips{
  color: rgb(104, 167, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
