import {isEmpty, isNotNullOrUndefined} from "@/utils/common_utils";
import {logDebug} from "@/utils/logger";

export function loadStyle(url, id = undefined) {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url
    if (isEmpty(id)) {
        link.id = url
    } else {
        link.id = id
    }
    const head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
}

export function removeStyle(id, url = undefined) {
    if (isNotNullOrUndefined(url)) {
        id = url
    }
    const link = document.getElementById(id)
    if (isNotNullOrUndefined(link)) {
        link.remove()
    }
}

export async function loadJavaScriptIfNotExist(url = undefined) {
    const jsDom = document.getElementById(url)
    if (jsDom) {
        return
    }
    const loadResult = new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = url;
        script.id = url;
        script.onload = () => {
            logDebug(`loadJavaScriptIfNotExist[${url}]`)
            resolve()
        };
        document.head.appendChild(script);
    })
    await loadResult
}

export async function loadStyleIfNotExist(url) {
    const styleDom = document.getElementById(url)
    if (styleDom) {
        return
    }
    const loadResult = new Promise((resolve) => {
        const link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.href = url
        link.id = url
        const head = document.getElementsByTagName('head')[0]
        head.appendChild(link)
        logDebug(`loadStyleIfNotExist[${url}]`)
        resolve()
    })
    await loadResult
}

