<template>
  <div class="service">
    <div style="margin: 20px">
      <a-row>
        <a-col :span="24">
          <a-typography-title :level="4">网络信息</a-typography-title>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-row>
            <a-col :span="3">
              <a-typography-text>IPv4 地址:</a-typography-text>
            </a-col>
            <a-col :span="5">
              <a-typography-text copyable>
                {{ networkInfo.ipv4Address || '未开通' }}
              </a-typography-text>
            </a-col>
            <a-col :span="3">
              <a-typography-text>IPv6 地址:</a-typography-text>
            </a-col>
            <a-col :span="5">
              <a-typography-text>
                {{ networkInfo.ipv6Address || '未开通' }}
              </a-typography-text>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-col :span="12">
          <a-typography-title :level="4">网络服务</a-typography-title>
        </a-col>
        <a-col :span="8">
        </a-col>
        <a-col :span="4">
          <a-button
              v-if="activeTab === 'service'"
              type="primary"
              @click="handleOpenAddServiceModal"
          >
            添加服务
          </a-button>
          <a-modal v-model:open="openAddServiceModal" title="添加服务" @ok="handlePpenAddServiceModalOk">
            <a-form
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                layout="horizontal">
              <a-form-item :rules="[{ required: true, message: '请填写服务类型' }]" label="服务类型">
                <div style="display: flex">
                  <a-select v-model:value="createServiceForm.protocol">
                    <a-select-option value="HTTP">HTTP</a-select-option>
                    <a-select-option value="TCP-LB">TCP</a-select-option>
                    <a-select-option value="UDP-LB">UDP</a-select-option>
                    <a-select-option value="HTTP-TTYD">TTYD终端</a-select-option>
                    <a-select-option value="HTTP-WEBSHELL">WEBSHELL终端</a-select-option>
                    <!--              <a-select-option value="TCP">TCP(NodePort)</a-select-option>-->
                    <!--              <a-select-option value="UDP">UDP(NodePort)</a-select-option>-->
                  </a-select>
                  <a-popover trigger="hover">
                    <template #content>
                      <div style="width: 500px">
                        <a-typography>
                          <a-typography-paragraph>
                            “服务”用于访问应用环境里部署的程序服务。
                            比如，如果你在应用环境里部署了一个Nginx服务，绑定“80”端口提供网站访问。
                            那么，为了能够在浏览器上直接访问这个应用环境里的Nginx网站，
                            你可以在此添加一个“服务类型”为“HTTP”的服务端口，并将“内部端口”设置为“80”。
                            再比如，如果应用环境里运行了一个MySQL数据库，绑定“3306”端口提供服务。
                            那么，为了将这个“3306”端口的MySQL数据库服务提供给外部用户或程序使用，
                            就需要在此添加一个“服务类型”为“TCP”的服务端口，并将“内部端口”设置为“3306”。
                          </a-typography-paragraph>
                          <a-typography-paragraph>
                            “服务类型”包括五种：HTTP、TCP、UDP、TTYD终端和WEBSHELL终端。其中：
                            <ol>
                              <li>
                                HTTP，表示应用环境里运行的程序服务提供的是HTTP访问方式，比如在8080端口运行的Web网站。
                                对于HTTP端口类型，Hi168平台会提供一个生成的URL让外部用户或程序访问应用环境中的HTTP服务。
                              </li>
                              <li>
                                TCP，表示应用环境里运行的程序服务提供的是TCP访问方式，比如在22端口运行的SSH服务，在3306端口运行的MySQL服务等。
                                对于TCP服务类型，可以进一步指定是HTTP或HTTPS服务，这样将能够更加方便地通过点击HTTP或HTTPS链接直接访问服务。
                              </li>
                              <li>
                                UDP，表示应用环境里运行的程序服务提供的是UDP访问方式。
                              </li>
                              <li>
                                TTYD终端，表示应用环境里提供了TTYD终端功能。只有安装了TTYD终端功能的应用环境才能够在此使用TTYD终端服务。
                                您可以参考<a download="如何给虚拟机安装shell功能.md"
                                             href="/hw_frontend/static/doc/vm_install_shell.md">如何给虚拟机安装shell功能</a>。
                              </li>
                              <li>
                                WEBSHELL终端，表示应用环境里提供了WEBSHELL终端功能。只有安装了WEBSHELL终端功能的应用环境才能够在此使用WEBSHELL终端服务。
                                您可以参考<a download="如何给虚拟机安装shell功能.md"
                                             href="/hw_frontend/static/doc/vm_install_shell.md">如何给虚拟机安装shell功能</a>。
                              </li>
                            </ol>
                          </a-typography-paragraph>
                        </a-typography>
                      </div>
                    </template>
                    <QuestionCircleTwoTone class="help-icon"/>
                  </a-popover>
                </div>
              </a-form-item>
              <a-form-item :rules="[{ required: true, message: '请填写内部端口' }]" label="内部端口">
                <a-input-number v-model:value="createServiceForm.servicePort"/>
              </a-form-item>
              <a-form-item v-if="['TCP', 'TCP-LB'].includes(createServiceForm.protocol)"
                           label="提供HTTP/HTTPS">
                <div style="display: flex">
                  <a-radio-group v-model:value="createServiceForm.tcpServiceType"
                                 name="tcpServiceType">
                    <a-radio value="TCP">无</a-radio>
                    <a-radio value="HTTP">HTTP</a-radio>
                    <a-radio value="HTTPS">HTTPS</a-radio>
                  </a-radio-group>
                  <a-popover trigger="hover">
                    <template #content>
                      <div style="width: 500px">
                        如果您的TCP端口是HTTP或HTTPS服务，可以在此指定具体的端口服务类型，
                        这样将能够更加方便地通过点击HTTP或HTTPS链接直接访问服务。
                      </div>
                    </template>
                    <QuestionCircleTwoTone class="help-icon"/>
                  </a-popover>
                </div>
              </a-form-item>
            </a-form>
          </a-modal>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <div>
            <a-table :columns="serviceColumns" :data-source="serviceData"
                     :locale="{emptyText: '暂无数据'}" :pagination="false" class="service-table">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'action'">
                  <a-popconfirm cancel-text="否" ok-text="是" title="确认删除？"
                                @confirm="()=>deleteService(record)">
                    <a-button type="link">
                      删除
                    </a-button>
                  </a-popconfirm>
                </template>
                <template v-if="column.key === 'adjust_access_url'">
                  <a v-if="record['service_type'] === 'HTTP服务' &&
                  !['无', '', 'none', 'false', 'undefined', 'null'].includes(`${record['adjust_access_url']}`.trim())"
                     :href="record['adjust_access_url']" target="_blank">
                    {{ record['adjust_access_url'] }}
                  </a>
                  <a v-else-if="record['adjust_access_url'].toLowerCase().trim().startsWith('http')"
                     :href="record['adjust_access_url']" target="_blank">
                    {{ record['adjust_access_url'] }}
                  </a>
                  <span v-else>{{ record['adjust_access_url'] }}</span>
                </template>
              </template>
            </a-table>
            <p class="service-tip">TCP服务和UDP服务请使用专用工具访问。</p>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref, watch} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {isEmpty, isNotEmpty, isTrue} from "@/utils/common_utils";
import {QuestionCircleTwoTone} from '@ant-design/icons-vue';

logDebug('TerminalService setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const emit = defineEmits(['refresh']); //声明 emits

const primal = props.primal
const data = props.data

const envId = data['env_id']

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 14,
};

const createServiceForm = reactive({
  protocol: '',
  servicePort: '',
  tcpServiceType: 'TCP',
})

const openAddServiceModal = ref(false)
const handleOpenAddServiceModal = function () {
  openAddServiceModal.value = true
}

watch(() => createServiceForm.protocol, function () {
  logDebug(`createServiceForm[${JSON.stringify(createServiceForm)}]`)
  if (createServiceForm.protocol === 'HTTP-TTYD') {
    createServiceForm.servicePort = 7149
  } else if (createServiceForm.protocol === 'HTTP-WEBSHELL') {
    createServiceForm.servicePort = 7179
  } else if (createServiceForm.protocol === 'HTTP') {
    createServiceForm.servicePort = ''
  } else if (createServiceForm.protocol === 'TCP') {
    createServiceForm.servicePort = ''
  } else {
    createServiceForm.servicePort = ''
  }
})

const addService = async function () {
  logDebug(`addService, createServiceForm[${JSON.stringify(createServiceForm)}]`)

  if (isEmpty(createServiceForm.protocol)) {
    message.error("请填写“服务类型”")
    return
  }
  if (isEmpty(createServiceForm.servicePort) || `${createServiceForm.servicePort}` === '0') {
    message.error("请填写“内部端口”")
    return
  }

  const port = parseInt(`${createServiceForm.servicePort}`);
  const is_lb = `${createServiceForm.protocol}`.toUpperCase().endsWith("-LB")

  let protocol
  let is_http = false
  let isShell = false
  let shellType = 'ttyd'
  if (createServiceForm.protocol === "HTTP") {
    protocol = "TCP"
    is_http = true
  } else if (createServiceForm.protocol === "TCP") {
    protocol = "TCP"
  } else if (createServiceForm.protocol === "UDP") {
    protocol = "UDP"
  } else if (createServiceForm.protocol === "TCP-LB") {
    protocol = "TCP"
  } else if (createServiceForm.protocol === "UDP-LB") {
    protocol = "UDP"
  } else if (createServiceForm.protocol === "HTTP-TTYD") {
    protocol = "TCP"
    isShell = true
    shellType = "ttyd"
  } else if (createServiceForm.protocol === "HTTP-WEBSHELL") {
    protocol = "TCP"
    isShell = true
    shellType = "webshell"
  } else {
    protocol = "TCP"
  }

  const params = {
    target_port: port,
    env_id: envId,
    is_http: is_http,
    protocol: protocol,
    external_domain_list: '',
    is_lb: is_lb,
    tcp_service_type: createServiceForm.tcpServiceType,
    is_shell: isShell,
    shell_type: shellType,
  }

  const addServiceResult = jsonRPC({
    url: "/api/experiment/service/add",
    params: params,
    success(res) {
      const data = getResponseData(res)
      logDebug('addServiceResult', data)
      loadService()
    },
    fail(error) {
      logError(`添加应用环境服务失败, `, error)
      message.error(`添加应用环境服务失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return addServiceResult.then(function (res) {
    logDebug(`addServiceResult, `, res)
    loadService()
    emit("refresh", {}); //触发事件
  })
}

const handlePpenAddServiceModalOk = function () {
  addService()
  openAddServiceModal.value = false
}

const serviceColumns = ref([
  {
    title: 'ID',
    // dataIndex是JSON数据的字段名称
    dataIndex: 'id',
    // key是column的索引名称
    key: 'id',
  },
  {
    title: '服务类型',
    dataIndex: 'adjust_service_type',
    key: 'adjust_service_type',
  },
  {
    title: '内部端口',
    dataIndex: 'target_port',
    key: 'target_port',
  },
  {
    title: '外部端口',
    key: 'adjust_node_port',
    dataIndex: 'adjust_node_port',
  },
  {
    title: 'VIP',
    key: 'adjust_cluster_ip',
    dataIndex: 'adjust_cluster_ip',
  },
  {
    title: '访问地址',
    key: 'adjust_access_url',
    dataIndex: 'adjust_access_url',
  },
  {
    title: '操作',
    key: 'action',
  },
]);
const serviceData = ref([]);

const deleteService = function (record) {
  logDebug(`deleteService[${JSON.stringify(record)}]`)

  const portId = record['id']

  const deleteServiceResult = jsonRPC({
    url: "/api/experiment/service/delete",
    params: {
      port_id: portId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('getServiceResult', data)
      loadService()
    },
    fail(error) {
      logError(`删除指定应用环境服务失败, `, error)
      message.error(`删除指定应用环境服务失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return deleteServiceResult.then(function (res) {
    logDebug(`deleteServiceResult, `, res)
    loadService()
    emit("refresh", {}); //触发事件
  })
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalService onMounted.`)
})

const adjustServiceData = function (services) {
  for (const service of services) {
    service['adjust_service_type'] = service['service_type']
    if (isTrue(service['is_shell'])) {
      if (service['shell_type'] === 'ttyd') {
        service['adjust_service_type'] = 'TTYD终端'
      }
      if (service['shell_type'] === 'webshell') {
        service['adjust_service_type'] = 'WEBSHELL终端'
      }
    }

    if (isTrue(service['is_shell'])) {
      service['adjust_node_port'] = '无'
    } else if (isTrue(service['is_lb'])) {
      service['adjust_node_port'] = service['port']
    } else if (service['node_port'] === 0) {
      service['adjust_node_port'] = '无'
    } else {
      service['adjust_node_port'] = service['node_port']
    }

    if (isTrue(service['is_shell'])) {
      service['adjust_access_url'] = service['access_url']
      if (service['shell_type'] === 'webshell') {
        service['adjust_access_url'] = '无'
      }
    } else if (isTrue(service['is_lb'])) {
      service['adjust_access_url'] = service['host']
      if (service['service_type'] === 'TCP服务') {
        if (['HTTP', 'HTTPS'].includes(service['tcp_service_type'])) {
          service['adjust_access_url'] = `${service['tcp_service_type'].toLowerCase()}://${service['host']}:${service['port']}`
        }
      }
    } else if (isTrue(service['http'])) {
      service['adjust_access_url'] = service['access_url']
    } else {
      service['adjust_access_url'] = service['host']
      if (service['service_type'] === 'TCP服务') {
        if (['HTTP', 'HTTPS'].includes(service['tcp_service_type'])) {
          service['adjust_access_url'] = `${service['tcp_service_type'].toLowerCase()}://${service['host']}:${service['node_port']}`
        }
      }
    }

    if (isNotEmpty(service['cluster_ip']) &&
        !['none', 'false'].includes(`${service['cluster_ip']}`.trim().toLowerCase())) {
      service['adjust_cluster_ip'] = `${service['cluster_ip']}`.trim().toLowerCase()
    } else {
      service['adjust_cluster_ip'] = '无'
    }
  }
  return services
}

const adjustNetworkData = function (data) {
  if (data['template_type'] === 'VM') {
    networkInfo.ipv4Address = data['rdp_ip']
  } else {
    networkInfo.ipv4Address = data['pod_ip']
  }
}

const loadService = function () {
  const getServiceResult = jsonRPC({
    url: "/api/experiment/services",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('getServiceResult', data)
      serviceData.value = adjustServiceData(data['services'])
      adjustNetworkData(data)
    },
    fail(error) {
      logError(`获取服务列表失败, `, error)
      message.error(`获取服务列表失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return getServiceResult.then(function (res) {
    logDebug(`getServiceResult, `, res)
  })
}

const onSelected = function () {
  logDebug(`TerminalService onSelected. data[${JSON.stringify(data)}]`)

  loadService()
}

const activeTab = ref('service');
const networkInfo = reactive({
  ipv4Address: '',
  ipv6Address: '',
  ipv4Gateway: '',
  ipv6Gateway: '',
  ipv4Dns1: '',
  ipv4Dns2: '',
  ipv6Dns: '',
});

defineExpose({
  primal,
  data,
  onSelected,
})

</script>

<style lang="css" scoped>
.help-icon {
  margin-left: 10px;
}

.help-icon-hidden {
  visibility: hidden;
}
</style>