<template>
  <div id="abcClass">
    <div style="display: flex; justify-content: flex-end; margin-bottom: 16px;">
      <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
      <!-- <div style="margin-left: 16px;">
        <a-button type="primary">
          <router-link to="/console/class/form/create">创建班级</router-link>
        </a-button>
      </div> -->
    </div>
    <a-list :grid="{ gutter: 16, column: 4, xs: 1, sm: 2}" :data-source="data_list">
      <template #renderItem="{ item }">
        <a-list-item class="px-0">
          <a-card hoverable style="width: 100%;height: 100%" @click="enterDetail(item.id);">
            <template #cover>
            <div class="info">
              <a class="img_box">
                <img v-if="item.is_image" class="card-image" alt="example" :src="`/web/image/hw.class/${item.id}/image_512?t=${new Date().getTime()}`"/>
                <img v-else src="/hw_web/static/src/img/case_img.jpg"/>
              </a>
              <a class="line-clamp-1 m-3">
                {{item.name}}
              </a>
              <div class="d-flex justify-content-between mb-3">
                <div class="teacher ml-3">
                  {{item.people_number}} 人已参加
                </div>
                <div class="price mr-3">
                  <span v-if="item.is_purchase">免费</span>
                  <span v-else-if="item.list_price > 0">￥{{item.list_price}}</span>
                </div>
              </div>
              <span>限时免费</span>
            </div>
            </template>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
    <a-flex justify="center">
      <a-pagination
        v-model:current="current"
        :showSizeChanger="showSizeChanger"
        v-model:page-size="pageSize"
        :total="total"
        :locale="zhCn"
        :pageSizeOptions="pageSizeOptions"
        :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {logDebug, logError} from "@/utils/logger";
import { routerPush } from "@/utils/router_utils";
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {zhCn} from "@/utils/zhCn";
import { useRouter } from "vue-router";
const router = useRouter();

const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref('');
const pageSizeOptions = ref(Array.from({ length: 4 }, (_, index) => (pageSize.value * (index + 1)).toString()));
function fetchData() {
  jsonRPC({
    url: "/vue/console/class",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      data_list.value = data.records
      total.value = data.record_count
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}

onMounted(() => {
  fetchData()
});
watch([pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
const enterDetail = id =>{
  routerPush(router, `/console/class/${id}`)
}

import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});

</script>

<style scoped lang="scss">
#abcClass{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
}
.info {
  height: 100%;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  > span {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    background-color: #90faff;
    padding: 0 15px;
    line-height: 30px;
    border-bottom-right-radius: 8px;
  }
  .summary {
    span {
      color: #52c9ff;
      font-size: 12px;
      border: 1px solid #52c9ff;
      border-radius: 4px;
      padding: 1px 4px;
      margin-right: 5px;
    }
  }
}
</style>