<template>
  <div class="blog_attention">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/blog">技术论坛</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>关注</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row gutter="20">
      <a-col :span="19">
        <div class="attention_info">
          <a-flex justify="space-between" align="center">
            <a-radio-group v-model:value="tags_state" :options="tagsDataOptions" optionType="button" buttonStyle="solid"/>
            <a-input-search placeholder="请输入关注用户名称" enter-button allowClear @search="onSearch"/>
          </a-flex>
          <div v-if="tags_state=='attention'" class="info">
            <div v-for="row in data_list.attention_user" :key="row.id" class="attention_user">
              <div class="user d-flex">
                <div class="avatar">
                  <img v-if="row.has_image" :src="`/web/image/res.users/${row.id}/image_512`"/>
                  <img v-else src="/hw_blog/static/src/img/blog_default_avatar.png"/>
                </div>
                <div class="user_info">
                  <h5>{{ row.name }}</h5>
                  <p>{{ row.blog_name }}</p>
                </div>
              </div>
              <div class="action">
                <div class="btn unsubscribe" @mouseenter="handleMouseEnter" 
                @mouseleave="handleMouseLeave" @click="handleButtonClick(row.id)">已关注</div>
              </div>
            </div>
            <div v-if="data_list.attention_user.length==0" class="empty">
              <a-empty />
            </div>
          </div>
          <div v-else class="info">
            <div class="blog_list" v-for="(item, index) in data_list.records" :key="index">
              <div class="blog_list_top">
                <router-link :to="`/blog/homepage?user_id=${item.user.id}`" class="user">
                  <img v-if="item.user.has_image" :src="`/web/image/res.users/${item.user.id}/image_128`"/>
                  <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
                  {{item.user.name}}
                </router-link>
                <span class="ml-3">{{ item.post_date }}</span>
              </div>
              <div class="blog_list_content">
                <div>
                  <h5>
                    <router-link :to="`/blog/${item.id}`">{{ item.name }}</router-link>
                    <span v-for="i in item.tag_ids" :key="i.id">{{i.name}}</span>
                  </h5>
                  <div ref="contentRefs" :style="item.ellipsis ? ellipsisStyle : expandedStyle">
                    <div v-html="item.content"></div>
                  </div>
                  <a-flex v-if="expandableItems[index]" justify="center">
                    <a-button type="text" @click="item.ellipsis=!item.ellipsis" style="color: #429BFF;">
                      <template v-if="item.ellipsis" #icon><DownOutlined style="display: inline-flex;"/></template>
                      <template v-else #icon><UpOutlined style="display: inline-flex;"/></template>
                      <template v-if="item.ellipsis">展开全文</template>
                      <template v-else>收起全文</template>
                    </a-button>
                  </a-flex>
                </div>
                <img v-if="item.is_image" :src="`/web/image/hw.blog.post/${item.id}/image_512`"/>
              </div>
              <div class="blog_list_bottom">
                <a-tooltip placement="bottom">
                  <template #title>浏览量</template>
                  <span>
                    <img src="/hw_blog/static/src/img/visited.png"/>
                    <span>{{ item.visits }}</span>
                  </span>
                </a-tooltip>
                <a-tooltip placement="bottom">
                  <template #title>评论人数</template>
                  <span>
                    <img v-if="item.is_comments" src="/hw_blog/static/src/img/info.png"/>
                    <img v-else src="/hw_blog/static/src/img/comment.png"/>
                    <span>{{ item.comments }}</span>
                  </span>
                </a-tooltip>
                <a-tooltip placement="bottom">
                  <template #title>点赞</template>
                  <span>
                    <img v-if="item.is_likes" @click="onBlogLike(item.id)" src="/hw_blog/static/src/img/liked.png"/>
                    <img v-else @click="onBlogLike(item.id)" src="/hw_blog/static/src/img/praise.png"/>
                    <span>{{ item.likes }}</span>
                  </span>
                </a-tooltip>
              </div>
            </div>
            <!-- 分页 -->
            <a-flex justify="center" class="mt-3">
              <a-pagination
                v-model:current="current"
                :showSizeChanger="showSizeChanger"
                v-model:page-size="pageSize"
                :total="data_list.record_count"
                :locale="zhCn"
                :pageSizeOptions="pageSizeOptions"
                :hideOnSinglePage="true"
              />
            </a-flex>
            <div v-if="data_list.records.length==0" class="empty">
              <a-empty />
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="blog_user">
          <div class="user_info">
            <img v-if="data_list.user?.has_image" :src="`/web/image/res.users/${data_list.user?.id}/image_512`"/>
            <img v-else src="/hw_web/static/src/img/avatar_live.png" />
            <h5>{{ data_list.user?.name }}</h5>
          </div>
          <div class="blog_info">
            <router-link :to="`/blog/homepage`">
              <span class="count">{{data_list.user_blog_num}}</span>
              <span class="title">帖子</span>
            </router-link>
            <router-link :to="`/blog/attention`">
              <span class="count">{{data_list.blog_id?.blog_attention_ids.length}}</span>
              <span class="title">关注</span>
            </router-link>
            <router-link :to="`/blog/fan`">
              <span class="count">{{data_list.blog_id?.blog_fan_ids.length}}</span>
              <span class="title">粉丝</span>
            </router-link>
          </div>
        </div>
        <div class="blog_message">
          <h5>互动消息</h5>
          <router-link to="/blog/message?key=reply">
            <img src="/hw_frontend/static/img/blog_@my_icon.png"/>
            @我的
            <a-badge :count="data_list.message_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=comment">
            <img src="/hw_frontend/static/img/blog_comment_icon.png"/>
            评论
            <a-badge :count="data_list.comment_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
          <router-link to="/blog/message?key=like">
            <img src="/hw_frontend/static/img/blog_like_icon.png"/>
            点赞
            <a-badge :count="data_list.like_count" :number-style="{ backgroundColor: '#FF8110' }"/>
          </router-link>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, watch, nextTick } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { message } from "ant-design-vue";
import $ from 'jquery'; // 引入 jQuery
const tags_state = ref('attention');
const tagsDataOptions = reactive([
  {
    label: '最新关注',
    value: 'attention',
  },
  {
    label: '最近发帖',
    value: 'posting',
  },
]);

const search = ref('');
const data_list = reactive({
  attention_user: [],
  records: [],
  record_count: 0,
  user_blog_num: 0,
  reply_comment_count: 0,
  comment_count: 0,
  like_count: 0,
});
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
function fetchData() {
  jsonRPC({
    url: `/api/blog/attention`,
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取关注列表成功`, data);
      Object.assign(data_list, data);
      checkExpandable();
    },
    fail(error) {
      logError(`获取关注列表失败`, error);
      message.error(`获取关注列表失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  $(".unsubscribe").mouseenter(function () {
    $(this).text("取消关注").css("background-color", "lightgray");
  });
  $(".unsubscribe").mouseleave(function () {
    $(this).text("已关注").css("background-color", "transparent");
  });
});
watch([pageSize, current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
// 动态管理按钮文本和背景色
const handleMouseEnter = (event) => {
  event.target.textContent = '取消关注';
  event.target.style.backgroundColor = '#D5E9FF';
};

const handleMouseLeave = (event) => {
  event.target.textContent = '已关注';
  event.target.style.backgroundColor = 'transparent';
};

const handleButtonClick = (e) => {
  jsonRPC({
    url: `/api/blog/unsubscribe/${e}`,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`取消关注成功`, data);
      fetchData()
    },
    fail(error) {
      logError(`取消关注失败`, error);
      message.error(`取消关注失败，请稍后再试`);
    },
  });
};
const onBlogLike = (e) => {
  jsonRPC({
    url: `/api/blog/like/${e}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};
const contentRefs = ref([]); // 文章内容的 DOM 引用
const expandableItems = ref([]); // 存储每篇文章是否需要展开按钮
const ellipsisStyle = {
  maxHeight: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 5, // 省略 5 行
};
const expandedStyle = {
  maxHeight: 'none',
  overflow: 'visible',
};
// **计算哪些文章需要“展开”按钮**
const checkExpandable = () => {
  nextTick(() => {
    expandableItems.value = contentRefs.value.map((content) => {
      if (content) {
        const lineHeight = parseFloat(getComputedStyle(content).lineHeight); // 获取行高
        const maxVisibleHeight = lineHeight * 5; // 5 行的高度
        return content.scrollHeight > maxVisibleHeight; // 计算是否超出
      }
      return false;
    });
  });
};
</script>

<style scoped lang="scss">
  span,a,div{
    word-break: break-all;
    white-space: normal;
  }
.blog_attention {
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  .attention_info{
    height: calc(100% - 15px);
    background-color: #fff;
    padding: 15px 25px;
    .info {
      height: calc(100% - 32px);
      padding: 15px;
      background-color: #fff;
      .attention_user{
        padding: 1rem 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        .user{
          display: flex;
          gap: 10px;
          .avatar{
            img{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .user_info{
            h5{
              font-size: 16px;
              font-weight: bold;
            }
            p{
              margin: 0;
            }
          }
        }
        .action{
          .unsubscribe{
            width: 100px;
            height: 40px;
            border: 1px solid #429BFF;
            transition: background-color 0.3s;
          }
        }
      }
      .blog_title {
        display: flex;
        margin-bottom: 15px;
        .tags {
          flex: 1 1 100%;
          display: flex;
          flex-wrap: wrap;
          .tag {
            color: #000;
            border-radius: 8px;
            padding: 0.3rem 1rem;
            cursor: pointer;
            &.active,
            &:hover {
              background-color: #d5e9ff;
            }
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
        .search {
          flex: 0 0 auto;
          display: flex;
          align-items: center;
          .write_blog {
            display: flex;
            align-items: center;
            gap: 0.3rem;
            background-color: #77b7ff;
            color: #fff;
            border-radius: 15px;
            padding: 0.25rem 0.75rem;
            line-height: 20px;
            margin-right: 1rem;
            img {
              width: 16px;
              height: 16px;
            }
          }
          .ant-btn-primary {
            background-color: #77b7ff;
          }
        }
      }
      .blog_list{
        padding: 15px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.4);
        margin-bottom: 15px;
        .blog_list_top{
          display: flex;
          align-items: center;
          color: #A1A1A1;
          margin-bottom: 5px;
          .user{
            display: flex;
            align-items: center;
            color: #A1A1A1;
            &:hover{
              color: #ff8c00;
            }
          }
          img{
            height: 25px;
            width: 25px;
            margin-right: 5px;
            border-radius: 50%;
          }
        }
        .blog_list_content{
          display: flex;
          gap: 10px;
          >div{
            flex-grow: 1;
            h5{
              display: flex;
              flex-flow: wrap;
              gap: 5px;
              a{
                color: #000;
                font-size: 18px;
                font-weight: bold;
                &:hover{
                  color: #ff8c00;
                }
              }
              span{
                background-color: #E4EFFF;
                color: #2580fd;
                padding: 3px 7px;
                border-radius: 4px;
                margin-bottom: -3px;
                position: relative;
                top: -2px;
                margin-left: 6px;
                font-size: 14px;
              }
            }
          }
          >img{
            flex-shrink: 0;
            width: 100%;
            max-width: 200px;
            object-fit: cover;
          }
        }
        .blog_list_bottom{
          display: flex;
          justify-content: flex-end;
          column-gap: 18px;
          margin-top: 5px;
          img{
            width: 25px;
            height: 25px;
            margin-right: 5px;
          }
        }
      }
    }
    .empty{
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .blog_user {
    border-radius: 8px;
    background-color: #fff;
    padding: 25px;
    margin-bottom: 15px;
    .user_info {
      text-align: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 1rem;
        object-fit: cover;
      }
      h5 {
        font-size: 16px;
      }
    }
    .blog_info {
      display: flex;
      justify-content: space-around;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        .count {
          font-size: 28px;
          color: #429bff;
        }
        .title {
          color: #000;
        }
      }
    }
  }
  .blog_message {
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5 {
      font-weight: bold;
      font-size: 16px;
    }
    img {
      width: 25px;
      height: 25px;
    }
    a {
      display: flex;
      gap: 10px;
      padding: 10px 0;
      color: #101010;
      &:hover {
        color: #ff8c00;
      }
      span {
        flex-shrink: 0;
        margin-left: auto;
        .ant-badge-count {
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
}
:deep(.ant-comment .ant-comment-avatar img) {
  width: 100%;
  height: 100%;
}
.comment_post {
  display: flex;
  margin-top: 1rem;
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    flex: 0 0 auto;
    object-fit: cover;
  }
  input {
    border: 1px solid #77b7ff;
    border-radius: 8px;
    flex: 1 1 100%;
    padding: 0.35rem 0.5rem;

    &:focus-visible {
      border-width: 2px;
    }
  }
  button {
    border-radius: 8px;
    background-color: #77b7ff;
    text-align: center;
    border: 1px solid #77b7ff;
    flex: 0 0 auto;
    color: #ffffff;
    padding: 0.3rem 0.75rem;
  }
}
.comment_list {
  .item {
    .comment_content {
      .avatar {
        flex-shrink: 0;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .content_info {
        flex: 1 1 100%;
        .top {
          color: #047AFF;
        }
        .body {
          color: #000;
        }
        .bottom {
          color: #9499a0;
          a {
            color: #9499a0;
            &:hover,
            &.active {
              color: #ff8c00;
            }
            i {
              font-weight: normal;
              font-size: inherit;
              bottom: auto;
              position: static;
            }
          }
        }
      }
    }
    .reply {
      padding-left: 2rem;
      .item {
        background-color: #EFF6FF;
        padding: 10px;
        border-radius: 8px;
      }
    }
  }
}
:where(.ant-radio-group){
  margin-left: 10px;
  :where(.ant-radio-button-wrapper){
    color: #278FFF;
    border: none;
    line-height: 32px;
    border-radius: 16px;
    background-color: #EDEDED;
    margin-left: 10px;
    &:not(:first-child)::before{
      content: none
    }
    &:first-child{
      border-inline-start: none;
      border-start-start-radius: 0; 
      border-end-start-radius: 0;
      border-radius: 16px;
    }
    &:last-child{
      border-start-end-radius: 0;
      border-end-end-radius: 0;
      border-radius: 16px;
    }
  }
}
:deep(.ant-radio-group-solid .ant-radio-button-wrapper-checked){
  color: #000;
  background: #D5E9FF;
  border-color: #D5E9FF;
  &:hover{
    color: #000;
    background: #D5E9FF;
    border-color: #D5E9FF;
  }
}
:deep(.ant-radio-button-wrapper){
  color: #000;
}
</style>
