<template>
  <div>
    <div class="login-container">
      <component :is="currentComponent" @call-parent-method="parentMethod" :invitation_code="invitation_code"></component>
    </div>
    <div class="login-footer">
      版权所有 @ Hi168云平台  |  <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备13010793号-11</a>  |  上海海文信息技术有限公司技术支持
    </div>
  </div>
</template>

<script setup>
import {logDebug} from "@/utils/logger";
import { ref, computed } from 'vue'
import WebLogin from './WebLogin.vue'
import WebResetPassword from './ResetPassword.vue'
import WebSignup from './Signup.vue'
import {getRouterQuery} from "@/utils/router_utils";
import {useRouter} from "vue-router";

const router = useRouter()
const invitation_code = ref(getRouterQuery(router, "invitation_code"));
const selectedKey = ref('login');
if (invitation_code.value) {
  selectedKey.value = 'signup';
}
const components = {
  login: WebLogin,
  reset: WebResetPassword,
  signup: WebSignup,
}

const currentComponent = computed(() => {
  return components[selectedKey.value]
})

function parentMethod(e) {
  logDebug('父组件方法被调用了');
  logDebug(e);
  selectedKey.value = e.component;
}
</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 48px);
  background: linear-gradient(to bottom right, #2dd4bf, #1e3a8a);
  background-size: cover;
}
.login-footer{
  background-color: #3070B4;
  color: #fff;
  text-align: center;
  line-height: 48px;
  a {
    color: #fff;
    &:hover {
      color: #e57f14;
    }
  }
}
:deep(.ant-form-item){
  margin-bottom: 14px;
}
:deep(.btn:focus){
  box-shadow: none;
}
</style>