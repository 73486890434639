<template>
  <div id="environment">
    <div class="top">
      <div>
        我的算力点：<span class="mr-3" style="color: #2580fd;">{{parseFloat(quotas.total_points.toFixed(8))}} 算力点</span>
        当前应用算力消耗总额：<span style="color: #2580fd;">{{ displayedPoint }} 算力点/{{ display_unit }}</span>
        <div class="unit-selector">
          <span v-for="unit in units" :key="unit" :class="{ active: unit === display_unit }" @click="changeUnit(unit)">
            {{ unit }}
          </span>
        </div>
      </div>
      <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch" style="margin-left: 16px;"/>
      <div style="margin-left: 16px;">
        <a-button type="primary" @click="showAppModal">添加应用</a-button>
        <a-modal v-model:open="open" title="创建应用" :footer="null" width="60%">
          <a class="btn btn-primary" href="/app">使用平台应用模板</a>
        </a-modal>
      </div>
    </div>
    <a-row>
      <a-col :span="24" style="padding: 0 10px">
        <a-list :grid="{ gutter: 16, column: 4 }" :data-source="data_list">
          <template #renderItem="{ item,index }">
            <a-list-item class="px-0">
              <a-card hoverable style="width: 100%;height: 100%" @click="enterDetail(index)">
                <template #cover>
                  <div class="info">
                    <h5>
                      <img :src="item.image_url"/>
                      <a-tooltip>
                        <template #title>{{item.custom_app_env_name}}</template>
                        <div class="line-clamp-1">{{item.custom_app_env_name}}</div>
                      </a-tooltip>
                      <!--<span t-if="row.publish">已发布</span>-->
                      <!--<span t-else="" class="Unpublished">未发布</span>-->
                    </h5>
                    <p>创建时间：{{ item.create_date }}</p>
                    <p>到期时间：{{ item.maturity_date }}</p>
                    <p>应用配额：CPU:{{ item.cpu_limit }}核
                      内存:{{ item.mem_limit }}G
                      磁盘:{{ item.disk_limit }}G</p>
                    <span
                        v-if="['Starting', 'Suspending', 'Suspended', 'Stopping', 'Stopped'].includes(item.app_env_status_detail)">
                      已停止
                      <PauseCircleTwoTone/>
                    </span>
                    <span v-else-if="item.app_env_status === 'Running'">运行中
                      <CheckCircleTwoTone/>
                    </span>
                    <span v-else>创建中
                      <LoadingOutlined/>
                    </span>
                  </div>
                </template>
              </a-card>
            </a-list-item>
          </template>
        </a-list>
      </a-col>
    </a-row>
    <a-flex justify="center" class="py-3">
      <a-pagination
          v-model:current="current"
          :showSizeChanger="showSizeChanger"
          v-model:page-size="pageSize"
          :total="total"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
          :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, watch, computed} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {zhCn} from "@/utils/zhCn";
import {CheckCircleTwoTone, LoadingOutlined, PauseCircleTwoTone} from "@ant-design/icons-vue";
import {isNotNullOrUndefined} from "@/utils/common_utils";
import { routerPush } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();

logDebug("ConsoleAppEnvironment setup.");
let enterDetail = index =>{
  if (data_list.value[index].app_env_status === 'Running' ||
      ['Starting', 'Suspending', 'Suspended', 'Stopping', 'Stopped'].includes(data_list.value[index].app_env_status_detail)) {
    routerPush(router, `/console/app/environment/${data_list.value[index].id}`)
  }
}
const quotas = reactive({
  total_points: 0,
  consume_points: 0,
});
const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
    Array.from({length: 4}, (_, index) =>
        (pageSize.value * (index + 1)).toString()
    )
);
const updateAppStatusIntervalMS = 5000
const updateAppStatusTryTimes = 60
function fetchData() {
  jsonRPC({
    url: "/vue/console/app",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询成功`, data);
      data_list.value = data.records;
      total.value = data.record_count;
      setTimeout(function () {
        updateAppStatus(updateAppStatusTryTimes)
      }, updateAppStatusIntervalMS)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const updateAppStatus = function (tryCount) {
  if (tryCount <= 0) {
    logError(`updateAppStatus failed after try ${updateAppStatusTryTimes} times.`)
    return
  }

  try {
    const app_environment_ids = []
    for (let i = 0; i < data_list.value.length; i++) {
      if (isNotNullOrUndefined(data_list.value[i])) {
        app_environment_ids.push(data_list.value[i].id)
      }
    }
    logDebug(`updateAppStatus, app_environment_ids[${JSON.stringify(updateAppStatus)}]`)
    let allReady = true
    const rpcResult = jsonRPC({
      url: "/vue/console/app/status",
      params: {
        app_environment_ids: app_environment_ids
      },
      success(res) {
        const data = getResponseData(res)
        logDebug(`查询成功`,);
        for (const app_env_id of data.records) {
          for (const app of data_list.value) {
            if (app_env_id.id === app.id) {
              app.app_env_status = app_env_id.app_env_status
              break
            }
          }
          if (app_env_id.app_env_status !== 'Running') {
            allReady = false
          }
        }
      },
      fail(error) {
        logError(`查询失败, `, error);
        allReady = false
      },
    });
    rpcResult.then(function () {
      if (!allReady) {
        setTimeout(function () {
          updateAppStatus(tryCount - 1)
        }, updateAppStatusIntervalMS)
      }
    })
  } catch (e) {
    logError(`updateAppStatus failed`, e)
  }
}

onMounted(() => {
  fetchData();
  jsonRPC({
    url: "/vue/cost/info",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询成功`, data);
      Object.assign(quotas,data)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
});
watch([pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};

const open = ref(false);
const showAppModal = () => {
  open.value = true;
};

import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});
// 单位列表及换算关系
const units = ['时', '日', '月', '年'];
// 设置初始换算单位
const display_unit = ref('时');
// 计算数值
const conversionRates = { '时': 1, '日': 24, '月': 24 * 30, '年': 24 * 365 };
// **改成乘法运算**
const displayedPoint = computed(() => {
  return parseFloat((quotas.consume_points * conversionRates[display_unit.value]).toFixed(8));
});
// 切换单位
const changeUnit = (unit) => {
  display_unit.value = unit;
};
</script>

<style scoped lang="scss">
#environment{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  .top{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
    >span{
      color: #548fff;
    }
  }
}

.info {
  position: relative;
  height: 100%;
  border: 1px solid #dbedff;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;

  > p {
    color: #737373;
    margin-bottom: 10px;
  }

  h5 {
    margin-bottom: 1rem;
    margin-right: 60px;
    display: flex;
    align-items: center;

    a {
      font-size: 16px;
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }

    span {
      display: inline-block;
      margin-left: 10px;
      height: 26px;
      line-height: 26px;
      padding: 0 1rem;
      border-radius: 20px;
      background-color: #57b1ff;
      color: #fff;

      &.Unpublished {
        background-color: #cdcdcd;
      }
    }
  }

  .bottom {
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;

    div {
      color: #737373;
    }

    a {
      color: #cdcdcd;
    }
  }

  > span {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 80px;
    height: 30px;
    line-height: 30px;
    background-color: #dbedff;
    border-radius: 0px 8px 0px 8px;
    text-align: center;
  }

  &.deactivated {
    border: 1px solid #ffe5be;

    > span {
      background-color: #ffe5be;
    }
  }

  &.abnormal {
    border: 1px solid #ffdbdb;

    > span {
      background-color: #ffdbdb;
    }
  }
}
</style>
