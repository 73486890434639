<template>
  <div class="eduAdminClass">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item><router-link to="/console/eduAdmin">教务管理</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info" ref="selectContainer">
      <a-flex justify="space-between" align="center">
        <h4>班级基本信息</h4>
        <div>
          <a-flex gap="middle" v-if="editorMode == 'readonly'">
            <a-button type="primary" @click="copyQRCode">复制二维码</a-button>
            <a-button type="primary" @click="editorMode = 'update'">编辑</a-button>
          </a-flex>
          <a-flex gap="middle" v-if="editorMode == 'update'">
            <a-button type="primary" @click="openDeleteModal=true">删除</a-button>
            <a-modal v-model:open="openDeleteModal" title="删除" @ok="confirmFormDelete">
              <p>确定删除？ 删除ID：{{ class_id }}</p>
            </a-modal>
            <a-button type="primary"  @click="confirmFormUpdate">保存</a-button>
            <a-button type="primary"  @click="editorMode = 'readonly'">取消</a-button>
          </a-flex>
        </div>
      </a-flex>
      <a-form ref="formRef" :model="data_list" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :disabled="editorMode == 'readonly'">
        <a-row :gutter="[16,16]">
          <a-col :span="24" :md="8">
            <a-form-item label="班级编号" name="hw_class_number" :rules="[{ required: true, message: '班级编号不能为空!' }]">
              <a-input v-model:value="data_list.hw_class_number" />
            </a-form-item>
            <a-form-item label="班级名称" name="name" :rules="[{ required: true, message: '班级名称不能为空!' }]">
              <a-input v-model:value="data_list.name" />
            </a-form-item>
            <a-form-item label="负责人" name="teacher_ids" :rules="[{ required: true, message: '请选择负责人!' }]">
              <a-select v-model:value="data_list.teacher_ids" :allowClear="true" mode="multiple" :max-tag-count="3"
                show-search :options="data_list.teacher_options" :filter-option="filterOption">
              </a-select>
            </a-form-item>
            <a-form-item label="班级人数">
              {{data_list.people_number}}
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="8">
            <a-form-item label="所属院校" name="school_id">
              <a-select v-model:value="data_list.school_id" :allowClear="true"
                show-search :options="data_list.school_ids" :filter-option="filterOption">
              </a-select>
            </a-form-item>
            <a-form-item label="所属院系" name="department_id">
              <a-select v-model:value="data_list.department_id" :allowClear="true" show-search :filter-option="filterOption"
                :options="data_list.department_ids.filter(department => department.school_id == data_list.school_id)">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="8">
            <a-form-item label="班级二维码">
              <img id="qrCode" :src="`/web/image/hw.class/${class_id}/qr_code?t=${new Date().getTime()}`"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="学生名单">
          <a-table :columns="columns1" :dataSource="data_list.student_ids" :pagination="pagination1">
            <template #bodyCell="{ column, record,text }">
              <template v-if="column.dataIndex == 'sex'">
                <template v-if="record.sex == 'male'">男</template>
                <template v-if="record.sex == 'female'">女</template>
              </template>
              <template v-if="column.dataIndex == 'name'">
                <router-link :to="`/console/eduAdmin/student/?id=${record.student_id}&mode=readonly`">{{text}}</router-link>
              </template>
              <template v-if="column.dataIndex == 'app'">
                <a href="#" @click.prevent="showConfirm(record)">{{record.environment_ids?.length}}</a>
              </template>
              <template v-if="column.dataIndex == 'operation'">
                <a-popconfirm title="确认移出班级？" @confirm="removeClass(record.student_id)">
                  <a class="ml-3" style="color: #C4C4C4;">移出班级</a>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="班级课程" force-render>
          <a-table :columns="columns2" :dataSource="data_list.course_ids" :pagination="false">
            <template #bodyCell="{ column, record, text }">
              <template v-if="column.dataIndex === 'name'">
                <router-link :to="`/console/eduAdmin/class/course?id=${record.id}&class_id=${class_id}`">{{ text }}</router-link>
              </template>
              <template v-if="column.dataIndex === 'teacher_ids'">
                <span v-for="(item, index) in record.teacher_ids" :key="item.id">
                  {{ item.name }}<span v-if="index !== record.teacher_ids.length - 1">、</span>
                </span>
              </template>
              <template v-if="column.dataIndex === 'exam_time'">
                {{record.exam_start_datetime}} ~ {{record.exam_end_datetime}}
              </template>
              <template v-if="column.dataIndex === 'operation'">
                <a href="#" @click.prevent="editCourse(record.id)">编辑</a>
                <a-popconfirm title="确认删除该课程？" @confirm="removeCourse(record.id)">
                  <a class="ml-3" style="color: #C4C4C4;">删除</a>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="直播实训">
          <a-table :columns="columns3" :dataSource="data_list.live_ids" :pagination="false">
            <template #bodyCell="{ column, record }">
              <!-- <template v-if="column.dataIndex === 'name'">
                <router-link :to="`/console/eduAdmin/class/course?id=${record.id}&class_id=${class_id}`">{{ text }}</router-link>
              </template> -->
              <template v-if="column.dataIndex == 'teacher_ids'">
                <span v-for="(item, index) in record.teacher_ids" :key="item.id">
                  {{ item.name }}<span v-if="index !== record.teacher_ids.length - 1">、</span>
                </span>
              </template>
              <template v-if="column.dataIndex == 'time'">
                {{record.start_time}} - {{record.end_time}}
              </template>
              <template v-if="column.dataIndex == 'operation'">
                <a href="#" @click.prevent="editLive(record.training_id)">编辑</a>
                <a-popconfirm title="确认删除该直播？" @confirm="removeLive(record.id)">
                  <a class="ml-3" style="color: #C4C4C4;">删除</a>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="4" tab="实验报告">
          <a-table :columns="columns4" :dataSource="data_list.report_ids" :pagination="pagination4">
            <template #bodyCell="{ column, record, text }">
              <template v-if="column.dataIndex == 'submit'">
                {{text}}/{{data_list.resource_student_ids.length}}
              </template>
              <template v-if="column.dataIndex == 'correct'">
                {{text}}/{{record.submit}}
              </template>
              <template v-if="column.dataIndex == 'operation'">
                <!-- <a href="#" @click.prevent="editLive">下载</a> -->
                <a v-if="record.submit > 0" href="#" @click.prevent="batch(record.course_id,record.id)">打分</a>
                <span v-else>暂无提交</span>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <!-- <a-tab-pane key="5" tab="班级资源">
          <a-table :columns="columns5" :dataSource="data_list.event_ids" :pagination="false"></a-table>
        </a-tab-pane> -->
        <template #rightExtra>
          <template v-if="activeKey=='1'">
            <a-input-search placeholder="请输入学生姓名" enter-button allowClear @search="onSearch1" style="width: 200px"/>
            <a-button type="primary" @click="openModal1 = true" class="ml-2">添加学生</a-button>
          </template>
          <template v-if="activeKey=='2'">
            <a-button type="primary" @click="clickAddCourse" class="ml-2">添加课程</a-button>
          </template>
          <template v-if="activeKey=='3'">
            <a-button type="primary" @click="openModal21 = true" class="ml-2">添加实训</a-button>
          </template>
          <template v-if="activeKey=='4'">
            <!-- 学期
            <a-select v-model:value="studentForm.semester_id" :allowClear="true"
              show-search :options="data_list.semester_ids" :filter-option="filterOption" placeholder="请选择学期">
            </a-select> -->
          </template>
          <!-- <a-button v-if="activeKey=='5'" @click="openModal5 = true" type="primary" shape="round">分发新资源</a-button> -->
        </template>
      </a-tabs>
      <a-modal v-model:open="openModal1" title="添加班级学生" width="55%">
        <a-row :gutter="[16,16]" class="mx-0">
          <a-col :span="24" :md="8">
            <a-flex align="center">
              <span style="flex-shrink: 0;">院校:</span>
              <a-select v-model:value="studentForm.school_id" :allowClear="true" show-search :filter-option="filterOption"
                :options="data_list.school_ids" style="width: calc(100% - 31px);">
              </a-select>
            </a-flex>
          </a-col>
          <a-col :span="24" :md="8">
            <a-flex align="center">
              <span style="flex-shrink: 0;">院系:</span>
              <a-select v-model:value="studentForm.department_id" :allowClear="true" show-search :filter-option="filterOption"
                :options="data_list.department_ids.filter(department => department.school_id == studentForm.school_id)" style="width: calc(100% - 31px);">
              </a-select>
            </a-flex>
          </a-col>
          <a-col :span="24" :md="8">
            <a-flex align="center">
              <span style="flex-shrink: 0;">专业:</span>
              <a-select v-model:value="studentForm.major_id" :allowClear="true" show-search :filter-option="filterOption"
                :options="data_list.major_ids.filter(major => major.department_id == studentForm.department_id)" style="width: calc(100% - 31px);">
              </a-select>
            </a-flex>
          </a-col>
        </a-row>
        <a-table
          :row-selection="{ selectedRowKeys: studentForm.student_ids, onChange: onSelectChange }"
          :columns="columns11"
          :data-source="filteredStudentOptions"
          :pagination="pagination11"
          :scroll="{ y: 'calc(50vh - 20px)'}"
        >
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex == 'id'">
              <span style="color: #1890ff">ID</span>
            </template>
          </template>
          <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
              <a-input
                ref="searchInput"
                :placeholder="`搜索 ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon><SearchOutlined /></template>
                搜索
              </a-button>
              <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                重置
              </a-button>
            </div>
          </template>
          <template #customFilterIcon="{ filtered, column }">
            <template v-if="column.dataIndex == 'sex'">
              <FilterFilled />
            </template>
            <template v-else>
              <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
          </template>
          <template #bodyCell="{ text, column }">
            <template v-if="studentForm.searchText && studentForm.searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(new RegExp(`(?<=${studentForm.searchText})|(?=${studentForm.searchText})`, 'i'))"
              >
                <mark
                  v-if="fragment.toLowerCase() === studentForm.searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                >
                  {{ fragment }}
                </mark>
                <template v-else>{{ fragment }}</template>
              </template>
            </template>
            <template v-else>
              <template v-if="column.dataIndex == 'name'">
                <div v-html="text"></div>
              </template>
              <template v-else>{{ text }}</template>
            </template>
          </template>
        </a-table>
        <template #footer>
          <span style="margin-right: 8px">
            <template v-if="hasSelected">
            {{ `已选择 ${studentForm.student_ids.length} 项` }}
            </template>
          </span>
          <a-button key="back" @click="openModal1 = false">取消</a-button>
          <a-button key="submit" type="primary" @click="confirmAddStudent" :loading="loading" :disabled="!hasSelected">确定</a-button>
        </template>
      </a-modal>
      <a-modal v-model:open="openModal2" :title="openModal2State == 'create'?'添加课程':'编辑课程'" :footer="null" :getContainer="()=>$refs.selectContainer" width="60%">
        <h4>课程信息</h4>
        <a-form ref="formCourseRef" :model="courseForm" :rules=rules2 :label-col="{style: {width: '100px'} }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="[16,16]">
            <a-col :span="24" :md="12">
              <a-form-item label="课程名称" name="name">
                <a-input v-model:value="courseForm.name" />
              </a-form-item>
              <a-form-item label="在线课程" name="course_id">
                <a-select v-model:value="courseForm.course_id" :allowClear="true"
                          show-search :options="courseForm.course_id_options" :filter-option="filterOption">
                </a-select>
              </a-form-item>
              <a-form-item label="开课学期" name="semester_id">
                <a-select v-model:value="courseForm.semester_id" :allowClear="true"
                  show-search :options="courseForm.semester_options" :filter-option="filterOption">
                </a-select>
              </a-form-item>
              <a-form-item label="授课老师" name="teacher_ids">
                <a-select v-model:value="courseForm.teacher_ids" :allowClear="true" mode="multiple" :max-tag-count="3"
                  show-search :options="courseForm.teacher_ids_options" :filter-option="filterOption" placeholder="请选择授课老师">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-item label="考核方式" name="exam_type">
                <a-select v-model:value="courseForm.exam_type" :allowClear="true"
                  show-search :options="courseForm.exam_type_options" :filter-option="filterOption">
                </a-select>
              </a-form-item>
              <template v-if="courseForm.exam_type == 'online' || courseForm.exam_type == 'exam'">
                <a-form-item label="开始时间" name="exam_start_datetime">
                  <a-date-picker
                    v-model:value="courseForm.exam_start_datetime"
                    :showTime="showTimeOption"
                    :format="showTimedDateFormat"
                    placeholder="请选择开始时间"
                  />
                </a-form-item>
                <a-form-item label="结束时间" name="exam_end_datetime">
                  <a-date-picker
                    v-model:value="courseForm.exam_end_datetime"
                    :showTime="showTimeOption"
                    :format="showTimedDateFormat"
                    placeholder="请选择结束时间"
                  />
                </a-form-item>
              </template>
              <a-form-item v-if="courseForm.exam_type == 'online'" label="考试卷" name="exam_paper_ids">
                <a-select v-model:value="courseForm.exam_paper_ids" :allowClear="true" mode="multiple" :max-tag-count="3"
                  show-search :options="courseForm.exam_paper_options" :filter-option="filterOption">
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <h4>实训计划</h4>
        <div class="table-responsive" style="margin-top: 10px;">
          <table class="table table-bordered text-center">
            <thead>
              <tr style="background-color: #F6FCFF;">
                <th style="border-bottom-width: 1px;">直播名称</th>
                <th style="border-bottom-width: 1px;">开始时间</th>
                <th style="border-bottom-width: 1px;">结束时间</th>
                <th style="border-bottom-width: 1px;">实训讲师</th>
                <th style="border-bottom-width: 1px;">实训应用</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in courseForm.live_ids" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.start_time }}</td>
                <td>{{ item.end_time }}</td>
                <td><span v-for="(x, i) in item.teacher_ids" :key="i">
                  {{ x.name }}<span v-if="i !== item.teacher_ids.length - 1">、</span>
                </span></td>
                <td>{{ item.app }}</td>
              </tr>
              <tr>
                <td colspan="20" @click="openModal21 = true">
                  <PlusOutlined style="color: #428DFF;"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <a-flex justify="center" class="my-3">
          <a-button v-if="openModal2State == 'create'" type="primary" @click="confirmAddCourse">确定</a-button>
          <a-button v-else type="primary" @click="confirmUpdateCourse">更新</a-button>
        </a-flex>
      </a-modal>
      <a-modal v-model:open="openModal21" title="添加实训" :footer="null" width="55%">
        <a-form ref="formCourseLiveRef" :model="liveForm" :rules="rules21" :label-col="{style: {width: '100px'} }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="[16,16]">
            <a-col :span="24" :md="12">
              <a-form-item label="直播名称" name="name">
                <a-input v-model:value="liveForm.name" />
              </a-form-item>
              <a-form-item label="直播讲师" name="teacher_id">
                <a-select v-model:value="liveForm.teacher_id" :allowClear="true"
                          show-search :options="data_list.training_teacher_options" :filter-option="filterOption">
                </a-select>
              </a-form-item>
              <a-form-item label="模板类型：">
                <a-select v-model:value="liveForm.app_type">
                  <a-select-option value="office">官方模板</a-select-option>
                  <!--<a-select-option value="user">我的模板</a-select-option>-->
                </a-select>
              </a-form-item>
              <a-form-item label="官方模板：" v-if="liveForm.app_type == 'office'" name='experiment_app_id'>
                <a-select v-model:value="liveForm.experiment_app_id">
                  <a-select-option v-for="item in data_list.app_options" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="我的模板：" v-if="liveForm.app_type == 'user'" name='experiment_user_app_id'>
                <a-select v-model:value="liveForm.experiment_user_app_id">
                  <a-select-option v-for="item in data_list.user_app_options" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-item label="开始时间" name="start_time">
                <a-date-picker
                    v-model:value="liveForm.start_time"
                    :showTime="showTimeOption"
                    :format="showTimedDateFormat"
                />
              </a-form-item>
              <a-form-item label="结束时间" name="end_time">
                <a-date-picker
                    v-model:value="liveForm.end_time"
                    :showTime="showTimeOption"
                    :format="showTimedDateFormat"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-flex justify="center" class="my-3">
            <a-button type="primary" @click="confirmCourseAddLive">添加</a-button>
          </a-flex>
        </a-form>
      </a-modal>
      <a-modal v-model:open="openModal4" title="添加培训" @ok="addTraining">
      </a-modal>
      <a-modal v-model:open="openModal5" title="分发新资源" :footer="null" width="60%">
        <p style="color: #0BD0FF;">可用资源： 100 核 CPU  1280 G 内存  4T 硬盘空间</p>
        <a-form ref="formResourceRef" :model="resourceForm" :label-col="{style: {width: '100px'} }" :wrapper-col="{ span: 23 }">
          <a-row :gutter="[0,16]">
            <a-col :span="24" :md="12">
              <a-form-item label="资源事件" name="event" :rules="[{ required: true, message: '资源事件不能为空!' }]">
                <a-input v-model:value="resourceForm.event" />
              </a-form-item>
              <a-form-item label="分发时间" name="time" :rules="[{ required: true, message: '分发时间不能为空!' }]">
                <a-date-picker
                    v-model:value="resourceForm.time"
                    :showTime="showTimeOption"
                    :format="showTimedDateFormat"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-item label="选择学生" name="radioValue" :rules="[{ required: true}]">
                <a-radio-group v-model:value="resourceForm.radioValue" name="radioGroup">
                  <a-radio value="1">全班</a-radio>
                  <a-radio value="2">指定学生</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item v-if="resourceForm.radioValue == '2'" class="select_students" label="选择学生" name="students" :rules="[{ required: true, message: '指定学生不能为空!' }]">
                <a-select v-model:value="resourceForm.students" :allowClear="true" mode="multiple" :max-tag-count="3"
                  show-search :options="data_list.resource_student_ids" :filter-option="filterOption" placeholder="请指定学生">
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item label="资源时效">
            <a-radio-group v-model:value="resourceForm.day" button-style="solid">
              <a-radio-button value="1">一天</a-radio-button>
              <a-radio-button value="10">十天</a-radio-button>
              <a-radio-button value="30">一个月</a-radio-button>
              <a-radio-button value="90">三个月</a-radio-button>
              <a-radio-button value="180">半年</a-radio-button>
              <a-radio-button value="365">一年</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <div style="border-top: 1px solid #E6F2FF;margin-top: 10px;padding-top: 20px;">
            <a-form-item label="CPU：">
              <a-radio-group v-model:value="resourceForm.CPU" button-style="solid">
                <a-radio-button v-for="item in CPU_ids" 
                  :key="item.id" :value="item.value">{{item.value}}核</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="内存：">
              <a-radio-group v-model:value="resourceForm.RAM" button-style="solid">
                <a-radio-button v-for="item in RAM_ids" 
                  :key="item.id" :value="item.value">{{item.value}}G</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="磁盘：">
              <a-radio-group v-model:value="resourceForm.disk" button-style="solid">
                <a-radio-button v-for="item in disk_ids" 
                  :key="item.id" :value="item.value">{{item.value}}G</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </div>
          <a-flex justify="center" class="my-3">
            <a-button type="primary" @click="confirmFormDistribute">确定分发</a-button>
          </a-flex>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import { SearchOutlined,FilterFilled } from '@ant-design/icons-vue';
import {onMounted, ref, reactive, watch, markRaw, computed } from 'vue';
import {jsonRPC, getResponseData, getFailedMessage} from "@/utils/http_utils";
import {resolveEditorMode, setEditorMode} from "@/utils/form_utils";
import {getRouterQuery } from "@/utils/router_utils";
import {newShowTimedDateFormat, newShowTimeOption} from "@/utils/time_utils";
const showTimeOption = newShowTimeOption()
const showTimedDateFormat = newShowTimedDateFormat()
import { message } from "ant-design-vue";
import { PlusOutlined } from '@ant-design/icons-vue';
import { useRouter } from "vue-router";
const router = useRouter();
const class_id = ref(getRouterQuery(router, "id"));

const editorMode = ref('readonly')
resolveEditorMode(editorMode, class_id.value, getRouterQuery(router, 'mode'))
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';
import { RouterLink } from 'vue-router';

const showConfirm = (e) => {
  if (!e.environment_ids.length>0) {
    return
  }
  const rows = e.environment_ids.map((i) =>
    createVNode('tr', { key: i.index }, [
      createVNode('td', null, i.custom_name),
      createVNode('td', null, i.status),
      createVNode('td', null, [createVNode('a', {
          class: 'btn btn-outline-info btn-sm',
          onClick: () => {
            const url = router.resolve({
              path: `/terminal/solution/${i.solution_id}/inner_service_name/${i.inner_service_name}`
            }).href;
            window.open(url, '_blank'); // 在新窗口中打开链接
          },
        }, '打开')]),
    ])
  );
  Modal.info({
    title: "环境列表",
    content: createVNode('div', { class: 'table-responsive text-center' }, [
      createVNode('table', { class: 'table table-bordered' }, [
        createVNode('thead', null, [
          createVNode('tr', null, [
            createVNode('td', {style: {borderBottomWidth: '1px', backgroundColor: '#f4faff' }}, '名称'),
            createVNode('td', {style: {borderBottomWidth: '1px', backgroundColor: '#f4faff' }}, '状态'),
            createVNode('td', {style: {borderBottomWidth: '1px', backgroundColor: '#f4faff' }}, '操作')
          ])
        ]),
        createVNode('tbody', null, rows)
      ])
    ])
  });
};
const removeClass = (e)=>{
  jsonRPC({
    url: `/vue/console/eduAdmin/class/remove`,
    params:{
      class_id: class_id.value,
      student_id: e,
    },
    success() {
      fetchData()
      fetchStudentData()
    },
    fail(error) {
      logError(`移出失败, `, error);
      message.info('移出失败')
    },
  })
}
const openModal1 = ref(false)
const searchInput = ref();
const loading = ref(false)
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  studentForm.searchText = selectedKeys[0];
  studentForm.searchedColumn = dataIndex;
};
const handleReset = clearFilters => {
  clearFilters({
    confirm: true,
  });
  studentForm.searchText = '';
};
const columns11 = [
  {
    title: '学号',
    dataIndex: 'student_barcode',
    width: 180,
    customFilterDropdown: true,
    sorter: (a, b) => a.student_barcode - b.student_barcode,
    onFilter: (value, record) => record.student_barcode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
  },
  {
    title: '姓名',
    dataIndex: 'name',
    customFilterDropdown: true,
    onFilter: (value, record) =>record.name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
  },
  {
    title: '性别',
    dataIndex: 'sex',
    filters: [
      {
        text: '男',
        value: '男',
      },
      {
        text: '女',
        value: '女',
      },
    ],
    onFilter: (value, record) => record.sex && record.sex.indexOf(value) === 0
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    customFilterDropdown: true,
    onFilter: (value, record) =>record.phone.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
  },
];
const pagination11 = reactive({
  // 分页配置
  // pageSize: 5, // 每页显示的条数
  showSizeChanger: true, // 是否可以改变每页显示的条数
  pageSizeOptions: ['10', '20', '100'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  // current: 1, // 当前页数
  // total: 0, // 总条数
  // onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})
const hasSelected = computed(() => studentForm.student_ids.length > 0);
const onSelectChange = selectedRowKeys => {
  studentForm.student_ids = selectedRowKeys;
};
const studentForm = reactive({
  searchText: '',
  searchedColumn: '',
  student_ids: [],
  semester_id: null,
  school_id: null,
  department_id: null,
  major_id: null,
})
const confirmAddStudent = ()=>{
  loading.value = true;
  jsonRPC({
    url: `/vue/console/eduAdmin/class/addStudent`,
    params: {
      class_id: class_id.value,
      student_ids: studentForm.student_ids,
    },
    success() {
      fetchData()
      fetchStudentData()
    },
    fail(error) {
      logError(`保存失败, `, error)
      Modal.error({
        content: getFailedMessage(error),
        onOk() {
          window.history.back();
        },
      });
    },
  }).then(()=>{
    loading.value = false;
    openModal1.value = false;
    studentForm.student_ids = [];
  })
}

function updateOptions(sourceKey, targetKey, optionsKey, matchKey) {
  watch(() => studentForm[sourceKey], () => {
    const filteredOptions = data_list[optionsKey].filter(option => option[matchKey] === studentForm[sourceKey]);
    if (filteredOptions.length == 0) {
      studentForm[targetKey] = null;
    }
  });
}
updateOptions('school_id', 'department_id', 'department_ids', 'school_id');
updateOptions('department_id', 'major_id', 'major_ids', 'department_id');
const filteredStudentOptions = computed(() => {
  return data_list.student_options.filter(student => 
    (!studentForm.school_id || student.school_id === studentForm.school_id) &&
    (!studentForm.department_id || student.department_id === studentForm.department_id) &&
    (!studentForm.major_id || student.major_id === studentForm.major_id)
  );
});

const data_list = reactive({
  is_image: false,
  file_list: [],
  name: null,
  hw_class_number: null,
  people_number: null,
  teacher_id: null,
  school_id: null,
  department_id: null,
  teacher_ids: [],
  student_ids: [],
  student_options: [],
  course_ids: [],
  live_ids: [],
  train_ids: [],
  report_ids: [],
  event_ids: [],
  school_ids: [],
  department_ids: [],
  training_teacher_options: [],
  app_options: [],
  user_app_options: [],
  resource_student_ids: [],
});
watch(() => data_list.school_id, () => {
  const filteredDepartments = data_list.department_ids.filter(department => department.school_id === data_list.school_id);
  if (filteredDepartments.length > 0) {
    data_list.department_id = filteredDepartments[0].value; // 选择匹配的第一个部门
  } else {
    data_list.department_id = null; // 如果没有匹配的院系选项，设置为 null
  }
});
function fetchData() {
  jsonRPC({
    url: `/vue/console/eduAdmin/class/detail`,
    params:{
      class_id: class_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
      Modal.error({
        content: getFailedMessage(error),
        onOk() {
          window.history.back();
        },
      });
    },
  })
}
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const formRef = ref();
// 保存
const confirmFormUpdate = () => {
  formRef.value.validate()
  .then(async () => {
    await jsonRPC({
      url: `/vue/console/eduAdmin/class/write`,
      params: {
        id: class_id.value,
        name: data_list.name,
        hw_class_number: data_list.hw_class_number,
        teacher_ids: data_list.teacher_ids,
        school_id: data_list.school_id,
        department_id: data_list.department_id,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`保存成功`, data);
        setEditorMode(editorMode, 'readonly')
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    });
    return
  })
  .catch(error => {
    logError('error', error);
    return
  });
};
const openDeleteModal = ref(false)
const confirmFormDelete = () => {
  jsonRPC({
    url: "/vue/console/eduAdmin/class/delete",
    params: {
      class_id: class_id.value,
    },
    success() {
      router.replace('/console/eduAdmin')
    },
    fail(error) {
      logError(`查询失败, `, error)
      Modal.error({
        content: getFailedMessage(error),
        onOk() {
          window.history.back();
        },
      });
    },
  });
}
onMounted(()=>{
  jsonRPC({
    url: `/vue/console/eduAdmin/class/setting`,
    params:{},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
  fetchData()
  fetchStudentData()
  // 获取一次
  fetchCourseData()
  fetchResourceData()
});
const activeKey = ref('1');
const columns1 = [
  {
    title: '学生编号',
    dataIndex: 'student_barcode',
    align: 'center',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '性别',
    dataIndex: 'sex',
    align: 'center',
  },
  {
    title: '年龄',
    dataIndex: 'age',
    align: 'center',
  },
  {
    title: '应用环境',
    dataIndex: 'app',
    align: 'center',
  },
  {
    title: '实验报告',
    dataIndex: 'report',
    align: 'center',
  },
  {
    title: '考勤签到',
    dataIndex: 'attendance',
    align: 'center',
  },
  {
    title: '最近登录时间',
    dataIndex: 'recent_login_time',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const handlePageChange1 = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination1.current = page;
  fetchStudentData();
}
const pagination1 = reactive({
  // 分页配置
  pageSize: 8, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange1), // 页码改变时的回调函数
})
watch([pagination1.current], fetchStudentData);
const search1 = ref('');
const onSearch1 = (searchValue) => {
  if (search1.value != searchValue) {
    pagination1.current = 1;
    search1.value = searchValue;
    fetchStudentData();
  }
};
function fetchStudentData() {
  jsonRPC({
    url: `/vue/console/eduAdmin/class/detail/student`,
    params:{
      class_id: class_id.value,
      page_index: pagination1.current,
      page_size: pagination1.pageSize,
      search: search1.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      data_list.student_ids = data.records;
      pagination1.total = data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
      Modal.error({
        content: getFailedMessage(error),
        onOk() {
          window.history.back();
        },
      });
    },
  })
}
const columns2 = [
  {
    title: '课程ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '课程名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '授课老师',
    dataIndex: 'teacher_ids',
    align: 'center',
  },
  {
    title: '开课学期',
    dataIndex: 'semester',
    align: 'center',
  },
  {
    title: '考核方式',
    dataIndex: 'exam_type',
    align: 'center',
  },
  {
    title: '考试时间',
    dataIndex: 'exam_time',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const formCourseRef = ref()
const fetchCourseData = ()=>{
  jsonRPC({
    url: `/vue/console/eduAdmin/class/course/setting`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(courseForm, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
const courseForm = reactive({
  id: null,
  name: '',
  course_id: null,
  course_id_options: [],
  semester_id: null,
  semester_options: [], // 学期选项
  teacher_name: '张老师', // 授课老师名称
  exam_type: null,
  exam_type_options: [], // 考核方式选项
  exam_start_datetime: null,
  exam_end_datetime: null,
  exam_schedule_options: [], // 考试安排选项
  exam_paper_ids: [],
  exam_paper_options: [], // 考试卷选项
  course_live_id: null,
  start_date: null,
  expire_date: null,
  teacher_ids: [],
  teacher_ids_options: [],
  training_ids: [],
  training_ids_options: [],
  live_ids: [],
});
const liveForm = reactive({
  name: '',
  teacher_id: null,
  app_type: 'office',
  experiment_app_id: '',
  experiment_user_app_id: '',
  start_time: '',
  end_time: '',
});
const openModal2 = ref(false)
const rules2 = {
  name: [
    { required: true, message: '课程名称不能为空!'}
  ],
  semester_id: [
    { required: true, message: '开课学期不能为空!', trigger: 'change' }
  ],
  teacher_ids: [
    { required: true, type: 'array', message: '请选择授课老师!', trigger: 'change' }
  ],
  exam_type: [
    { required: true, message: '考核方式不能为空!', trigger: 'change' }
  ],
  exam_start_datetime: [
    { required: true, message: '考试开始时间不能为空!', trigger: 'change' }
  ],
  exam_end_datetime: [
    {
      required: true,
      validator: (rule, value) => {
        if (!value) {
          return Promise.reject('考试结束时间不能为空!');
        } else if (courseForm.exam_start_datetime && value <= courseForm.exam_start_datetime) {
          return Promise.reject('结束时间必须大于开始时间');
        } else {
          return Promise.resolve();
        }
      },
      trigger: 'change',
    },
  ],
  exam_paper_ids: [
    { required: true, message: '考试卷不能为空!', trigger: 'change' }
  ]
};
const openModal2State = ref('create')
const confirmAddCourse = ()=>{
  formCourseRef.value.validate()
  .then(() => {
    jsonRPC({
      url: `/vue/console/eduAdmin/class/course/create`,
      params: {
        class_id: class_id.value,
        course_name: courseForm.name,
        exam_type: courseForm.exam_type,
        course_id: courseForm.course_id,
        start_date: courseForm.start_date,
        expire_date: courseForm.expire_date,
        teacher_ids: courseForm.teacher_ids,
        training_ids: courseForm.live_ids.map(item => item.id),
        exam_start_datetime: courseForm.exam_start_datetime,
        exam_end_datetime: courseForm.exam_end_datetime,
        exam_paper_ids: courseForm.exam_paper_ids,
        semester_id: courseForm.semester_id,
      },
      success() {
        fetchData()
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    }).then(()=>{
      openModal2.value = false;
      formCourseRef.value.resetFields();
      courseForm.live_ids = [];
    })
  })
  .catch(error => {
    logError('error', error);
  });
}
const confirmUpdateCourse = ()=>{
  formCourseRef.value.validate()
  .then(() => {
    jsonRPC({
      url: `/vue/console/eduAdmin/class/course/update`,
      params: {
        id: courseForm.id,
        class_id: class_id.value,
        course_name: courseForm.name,
        exam_type: courseForm.exam_type,
        course_id: courseForm.course_id,
        start_date: courseForm.start_date,
        expire_date: courseForm.expire_date,
        teacher_ids: courseForm.teacher_ids,
        training_ids: courseForm.live_ids.map(item => item.id),
        exam_start_datetime: courseForm.exam_start_datetime,
        exam_end_datetime: courseForm.exam_end_datetime,
        exam_paper_ids: courseForm.exam_paper_ids,
        semester_id: courseForm.semester_id,
      },
      success() {
        fetchData()
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    }).then(()=>{
      openModal2.value = false;
      formCourseRef.value.resetFields();
      courseForm.live_ids = [];
    })
  })
  .catch(error => {
    logError('error', error);
  });
}
const openModal21 = ref(false)
const rules21 = {
  name: [
    {
      required: true,
      message: '请输入直播名称',
    },
  ],
  start_time: [
    {
      required: true,
      message: '请输入开始时间',
    },
  ],
  end_time: [
    {
      required: true,
      validator: (rule, value) => {
        if (!value) {
          return Promise.reject('请输入结束时间');
        } else if (liveForm.start_time && value <= liveForm.start_time) {
          return Promise.reject('结束时间必须大于开始时间');
        } else {
          return Promise.resolve();
        }
      },
      trigger: 'change',
    },
  ],
  teacher_id: [
    {
      required: true,
      message: '请选择直播讲师',
      trigger: 'change',
    },
  ],
  experiment_app_id: [
    {
      required: true,
      message: '请选择官方模板',
      trigger: 'change',
    },
  ],
  experiment_user_app_id: [
    {
      required: true,
      message: '请选择我的模板',
      trigger: 'change',
    },
  ],
}
const formCourseLiveRef = ref()
const confirmCourseAddLive = ()=>{
  formCourseLiveRef.value.validate()
  .then(async () => {
    await jsonRPC({
      url: `/vue/console/eduAdmin/class/live/create`,
      params: {
        class_id: class_id.value,
        name: liveForm.name,
        start_time: liveForm.start_time,
        end_time: liveForm.end_time,
        teacher_id: liveForm.teacher_id,
        app_type: liveForm.app_type,
        experiment_app_id: liveForm.experiment_app_id,
        experiment_user_app_id: liveForm.experiment_user_app_id,
      },
      success(res) {
        const data = getResponseData(res);
        courseForm.live_ids.push(data);
        fetchData()
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    }).then(()=>{
      openModal21.value = false;
      formCourseLiveRef.value.resetFields();
    })
    return
  })
  .catch(error => {
    logError('error', error);
    return
  });
}

const clickAddCourse = ()=>{
  openModal2.value = true;
  openModal2State.value = 'create';
  if (formCourseRef.value) {
    formCourseRef.value.resetFields();
  }
}
import moment from 'moment';
import dayjs from 'dayjs';
const editCourse = (e)=>{
  openModal2.value = true;
  openModal2State.value = 'update';
  courseForm.id = e;
  jsonRPC({
    url: `/vue/console/eduAdmin/class/course/edit`,
    params:{
      id: e,
    },
    success(res) {
      const data = getResponseData(res);
      data.exam_start_datetime = data.exam_start_datetime 
        ? dayjs(moment(new Date(data.exam_start_datetime)).toISOString()) 
        : dayjs(); // 如果没有值则使用当前时间
      data.exam_end_datetime = data.exam_end_datetime 
        ? dayjs(moment(new Date(data.exam_end_datetime)).toISOString()) 
        : dayjs(); // 如果没有值则使用当前时间
      Object.assign(courseForm, data)
    },
    fail(error) {
      logError(`删除失败, `, error);
    },
  })
}
const removeCourse = (e)=>{
  jsonRPC({
    url: `/vue/console/eduAdmin/class/course/delete`,
    params:{
      id: e,
    },
    success() {
      fetchData()
    },
    fail(error) {
      logError(`删除失败, `, error);
    },
  })
}
const columns3 = [
  {
    title: '实训ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '直播名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '实训讲师',
    dataIndex: 'teacher_ids',
    align: 'center',
  },
  {
    title: '所属课程',
    dataIndex: 'course',
    align: 'center',
  },
  {
    title: '直播时间',
    dataIndex: 'time',
    align: 'center',
  },
  {
    title: '签到人数',
    dataIndex: 'attendance',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const editLive = (e)=>{
  router.push(`/console/live/form/${e}`);
}
const removeLive = (e)=>{
  jsonRPC({
    url: `/vue/console/eduAdmin/class/live/delete`,
    params:{
      id: e,
    },
    success() {
      fetchData()
    },
    fail(error) {
      logError(`删除失败, `, error);
    },
  })
}
// 复制二维码图片
const copyQRCode = async () => {
  try {
    const qrCodeElement = document.getElementById('qrCode');
    const response = await fetch(qrCodeElement.src);
    const blob = await response.blob();
    const imgBitmap = await createImageBitmap(blob);

    const canvas = document.createElement('canvas');
    canvas.width = imgBitmap.width;
    canvas.height = imgBitmap.height;
    const context = canvas.getContext('2d');
    context.drawImage(imgBitmap, 0, 0);

    const pngBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

    const clipboardItem = new ClipboardItem({ 'image/png': pngBlob });

    await navigator.clipboard.write([clipboardItem]);
    message.success('二维码已复制到剪贴板！');
  } catch (err) {
    logError('复制失败：', err);
    message.error('复制失败，请手动复制！');
  }
};
const certificate_id = ref()
const openModal4 = ref(false)
const columns4 = [
  {
    title: '实验课题',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '所属课程',
    dataIndex: 'course',
    align: 'center',
  },
  {
    title: '发布时间',
    dataIndex: 'start_datetime',
    align: 'center',
  },
  {
    title: '提交截止时间',
    dataIndex: 'end_datetime',
    align: 'center',
  },
  {
    title: '提交情况',
    dataIndex: 'submit',
    align: 'center',
  },
  {
    title: '打分情况',
    dataIndex: 'correct',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const handlePageChange4 = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination4.current = page;
  fetchExamData();
}
const pagination4 = reactive({
  // 分页配置
  pageSize: 8, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange4), // 页码改变时的回调函数
})
watch([certificate_id], ()=>{
  fetchExamData()
});
watch([pagination4.current], fetchExamData);
function fetchExamData() {
  jsonRPC({
    url: `/api/class/course/exercise/report/list`,
    params:{
      class_id: class_id.value,
      page_index: pagination4.current,
      page_size: pagination4.pageSize,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      data_list.exam_ids = data;
      // pagination4.total = data.record_count;
      openModal4.value = false
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
const train_id = ref()
function addTraining() {
  jsonRPC({
    url: `/vue/console/student/class/detail/addTraining`,
    params:{
      class_id: class_id.value,
      train_id: train_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`添加成功`, data);
      openModal4.value = false;
      fetchData();
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
const batch = (course_id,paper_id) => {
  router.push(`/console/eduAdmin/class/course/report/batch?course_id=${course_id}&class_id=${class_id.value}&paper_id=${paper_id}`);
}
// const columns5 = [
//   {
//     title: '资源分发事件',
//     dataIndex: 'name',
//     align: 'center',
//   },
//   {
//     title: '分发时间',
//     dataIndex: 'distribute_datetime',
//     align: 'center',
//   },
//   {
//     title: '资源使用期限',
//     dataIndex: 'usage_days',
//     align: 'center',
//   },
//   {
//     title: 'CPU',
//     dataIndex: 'CPU',
//     align: 'center',
//   },
//   {
//     title: '内存',
//     dataIndex: 'RAM',
//     align: 'center',
//   },
//   {
//     title: '硬盘',
//     dataIndex: 'disk',
//     align: 'center',
//   },
//   {
//     title: '涉及学生',
//     dataIndex: 'student_ids',
//     align: 'center',
//   },
//   {
//     title: '资源状态',
//     dataIndex: 'state',
//     align: 'center',
//   },
// ];
const openModal5 = ref(false)
const resourceForm = reactive({
  event: '',
  time: '',
  radioValue: '2',
  students: [],
  CPU: '',
  RAM: '',
  disk: '',
  bandwidth: '',
  day: '1',
})
const CPU_ids = ref([])
const RAM_ids = ref([])
const disk_ids = ref([])
function fetchResourceData() {
  jsonRPC({
    url: "/api/experiment/app/resource/product_list",
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      if (Array.isArray(data)) {
        CPU_ids.value = (data.find(item => item.product_name == 'CPU') || {}).product_variant;
        if (CPU_ids.value.length > 0) {
          resourceForm.CPU = CPU_ids.value[0].value
        }
        RAM_ids.value = (data.find(item => item.product_name == '内存') || {}).product_variant;
        if (RAM_ids.value.length > 0) {
          resourceForm.RAM = RAM_ids.value[0].value
        }
        disk_ids.value = (data.find(item => item.product_name == '磁盘') || {}).product_variant;
        if (disk_ids.value.length > 0) {
          resourceForm.disk = disk_ids.value[0].value
        }
      }
      logDebug(`获取成功`, CPU_ids.value);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
const formResourceRef = ref()
const confirmFormDistribute = () => {
  formResourceRef.value.validate()
  .then(async () => {
    if (resourceForm.radioValue == '1') {
      resourceForm.students = []
      for (let index = 0; index < data_list.resource_student_ids.length; index++) {
        const element = data_list.resource_student_ids[index];
        resourceForm.students.push(element.value)
      }
    }
    await jsonRPC({
      url: `/vue/console/student/class/detail/resource`,
      params: {
        class_id: class_id.value,
        event: resourceForm.event,
        time: resourceForm.time,
        students: resourceForm.students,
        day: resourceForm.day,
        CPU: resourceForm.CPU,
        RAM: resourceForm.RAM,
        disk: resourceForm.disk,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`保存成功`, data);
        // 关闭页面
        openModal5.value = false;
        // 重置数据
        formResourceRef.value.resetFields();
        resourceForm.day = '1';
        resourceForm.students = [];
        if (CPU_ids.value.length > 0) {
          resourceForm.CPU = CPU_ids.value[0].value
        }
        if (RAM_ids.value.length > 0) {
          resourceForm.RAM = RAM_ids.value[0].value
        }
        if (disk_ids.value.length > 0) {
          resourceForm.disk = disk_ids.value[0].value
        }
        // 获取最新数据
        fetchData()
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    })
    return
  })
  .catch(error => {
    logError('error', error);
    return
  });
};
</script>

<style scoped lang="scss">
.eduAdminClass{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  .info{
    background-color: #fff;
    padding: 25px 40px;
    h4{
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 5vw;
      border-bottom: 2px solid #63B1FF;
    }
    img{
      width: 100%;
      max-width: 170px;
      max-height: 170px;
    }
    .radarChart{
      height: 0;
      padding-bottom: 100%;
    }
  }
}
:deep(.ant-table-wrapper .ant-table-thead >tr>th){
  background-color: #F9FCFF;
}
:deep(.ant-form-item){
  margin-bottom: 2px;
}
:deep(.ant-modal-title){
  text-align: center;
}
:deep(.ant-form-item-control-input){
  margin-right: 20px;
}
:deep(.select_students .ant-form-item-label){
  opacity: 0;
}
:deep(.ant-btn >span){
  display: inline-flex;
}
:where(.ant-radio-button-wrapper){
  height: 30px;
  min-width: 80px;
  line-height: 28px;
  border-radius: 4px;
  margin: 0 5px;
  margin-bottom: 10px;
  border-inline-start-width: 1px;
  text-align: center;
  &:not(:first-child)::before{
    content: none
  }
  &:first-child{
    border-start-start-radius: 4px; 
    border-end-start-radius: 4px;
  }
  &:last-child{
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }
}
</style>
