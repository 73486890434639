<template>
  <div ref="artRef"></div>
</template>

<script>
import Artplayer from "artplayer";
import Hls from 'hls.js';
import {logError} from "@/utils/logger";

function playM3u8(video, url, art) {
  if (Hls.isSupported()) {
    if (art.hls) art.hls.destroy();
    const hls = new Hls();
    hls.loadSource(url);
    hls.attachMedia(video);
    art.hls = hls;
    art.on('destroy', () => hls.destroy());
  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = url;
  } else {
    art.notice.show = 'Unsupported playback format: m3u8';
  }
}



export default {
  data() {
    return {
      instance: null,
    };
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // 创建自定义类型处理器对象
    const customType = {
      m3u8: playM3u8,
    };

    // 根据URL自动检测类型的函数
    const detectType = (url) => {
      if (!url) return '';
      
      if (url.includes('.m3u8') || url.includes('application/x-mpegURL')) {
        return 'm3u8';
      }
      return '';
    };

    // 获取初始类型
    const initialType = detectType(this.option.url);

    this.instance = new Artplayer({
      ...this.option,
      container: this.$refs.artRef,
      type: initialType || '',
      customType,
    });

    // 监听URL变化以自动切换播放器类型
    this.instance.on('url', (url) => {
      const newType = detectType(url);
      if (newType && this.instance.type !== newType) {
        this.instance.switchType(newType);
      }
    });

    this.$nextTick(() => {
      this.$emit("get-instance", this.instance);
    });
  },
  beforeDestroy() {
    if (this.instance) {
      // 确保在销毁前清理所有媒体实例
      try {
        if (this.instance.hls) {
          this.instance.hls.destroy();
          this.instance.hls = null;
        }
        this.instance.destroy(false);
      } catch (e) {
        logError('销毁播放器实例时出错:', e);
      }
    }
  },
};
</script>