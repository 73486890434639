<template>
  <Teleport to="body">
    <transition enter-active-class="slideInRight" leave-active-class="slideOutRight">
      <div class="ds-online-container" v-show="livebarShow">
        <div class="onlineUser">
          <div v-if="query_onlineLive_list.length >= 1">
            <a-tabs v-model:activeKey="activeKey" centered v-for="(live,index) in query_onlineLive_list" :key="live.id">
              <a-tab-pane key="1" :tab="live.name" :id="index">
                <a-button @click="handleSettingButtonClick">设置</a-button>
                <a-card :bordered="false" size="small" v-for="online in online_user_ids" :key="online.id">
                  <a-card-meta>
                    <template #avatar>
                      <a-avatar v-if="online.create_uid == 4" src="/hw_web/static/src/img/avatar_example.png"/>
                      <a-avatar v-else :src="`/web/image/res.users/${online.create_uid}/image_512`"/>
                    </template>
                    <template #title>
                      <a-flex justify="space-between" align="center" style="height: 32px;">
                        <div>
                          <a style="font-size: 16px;">{{ online.user_name }}</a>
                        </div>
                        <div class="converse_btn">
                          <img v-if="online.apply_call_state =='on'" class="mr-3"
                               src="/hw_course/static/src/img/live_talking.png" title="通话中"/>
                          <template v-if="teacher_uid">
                            <img v-if="online.apply_call_state =='ready'" class="mr-3"
                                 @click="handlePlayVoiceCall(online.id,online.user_id)"
                                 src="/hw_course/static/src/img/live_connect.png" title="接听语音"/>
                            <img v-if="online.apply_call_state =='ready'"
                                 @click="handleRejectVoiceCall(online.id,online.user_id)"
                                 src="/hw_course/static/src/img/live_reject_connect.png" title="拒绝"/>
                            <img v-if="online.apply_call_state =='on'"
                                 @click="teacherHandleVoiceCallLeaveBtn(online.id,online.user_id)"
                                 src="/hw_course/static/src/img/live_ringOff.png" title="挂断语音"/>
                          </template>
                        </div>
                      </a-flex>
                    </template>
                  </a-card-meta>
                </a-card>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div v-else>
            <a-empty>
              <template #description>
                <span>
                  暂无您参与的直播
                </span>
              </template>
            </a-empty>
          </div>
          <a-alert v-show="testMicrophoneButton" message="麦克风测试中：请打开声音，并持续讲话...." type="warning"
                   show-icon/>
          <audio loop autoplay id="J_prismAudio_local"></audio>
        </div>
        <a-drawer :width="350" title="通话设置" placement="left" :open="open" :closable="false" @close="onClose">
          <a-flex gap="middle" vertical>
            <div>
              <a>当前麦克风:{{ getMicLabelById(deviceInfo.micId) }}</a>
            </div>
            <div>
              <a>麦克风列表：</a>
              <a-select
                  v-model:value="MicValue"
                  style="width: 80%"
                  @change="handleChange"
                  placeholder="请下拉选择"
                  :field-names="{ label: 'label', value: 'deviceId',}"
                  :options="deviceInfo.micList"
                  :disabled="selectDisabled"
              >
              </a-select>
            </div>
            <a-button type="primary" @click="testMicrophoneAudioTrack()"
                      :disabled="testMicrophoneButton">
              确认并测试麦克风
            </a-button>
            <a-divider/>
            <div>
              <div style="padding-bottom: 10px">
                <a>当前扬声器:{{ getSpeakerLabelById(deviceInfo.speakerId) }}</a>
              </div>
              <div>
                <a>
                  扬声器列表：
                </a>
                <a-select
                    v-model:value="speakerValue"
                    style="width: 80%"
                    @change="handleSpeakerChange"
                    :field-names="{ label: 'label', value: 'deviceId',}"
                    :options="deviceInfo.speakerList"
                    placeholder="请下拉选择"
                    :disabled="selectDisabled"
                >
                </a-select>
              </div>
            </div>
            <a-button type="primary" @click="testMicrophoneAudioTrack()"
                      :disabled="testMicrophoneButton">
              确认并测试扬声器
            </a-button>
          </a-flex>
        </a-drawer>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import {
  computed,
  markRaw,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import {message, Modal, notification} from "ant-design-vue";
import DingRTC from 'dingrtc';
import {sleep} from "@/utils/time_utils";
import desktopStore from "@/stores/desktop";

const props = defineProps({
  livebarShow: {type: Boolean, default: false},
  OnlineLiveList: {type: Array, default: null},
});

let activeKey = ref('1');

let testMicrophoneButton = ref(false)
// 创建通话DingRTC引擎
const newClient = markRaw(DingRTC.createClient())
DingRTC.setLogLevel("debug");
logDebug("Formatted props:", JSON.stringify(props));

let teacher_uid = ref();
let login_user_id = ref();
const online_user_ids = ref([]);
const query_onlineLive_list = ref([]);
const current_online_id = ref();
const joinInfo = reactive({'appId': null, 'token': null, 'uid': null, 'channel': null, 'userName': null,});
const deviceInfo = ref({
  micList: [],
  speakerList: [],
  micId: null,
  speakerId: null,
});

let showTeacherCallButton = ref(true);
let showTeacherCallOffBtn = ref(false);
let currentRemoteUserId = 0;
let current_live_id = ref(false);
let currentRemoteUserTrack = null;
let RemoteUserAudioTrack = []
// 查询在线用户列表Token
let queryUserListToken = (live_id) => {
  return new Promise((resolve, reject) => {
    jsonRPC({
      url: `/api/live/${live_id}/channel/token`,
      success(res) {
        const data = getResponseData(res);
        Object.assign(joinInfo, data);
        logDebug('查询在线用户列表Token成功', data);
        resolve(data); // 成功后调用 resolve
      },
      fail(error) {
        logError('查询在线用户列表Token失败', error);
        reject(error);
      },
    });
  });
};
const getDeviceList = async () => {
  try {
    const [micList, speakerList] = await Promise.all([DingRTC.getMicrophones(), DingRTC.getPlaybackDevices()]);

    // 过滤有效设备
    const filterDevices = (list) => list.filter((item) => item.deviceId);
    const filteredMicList = filterDevices(micList);
    const filteredSpeakerList = filterDevices(speakerList);

    // 更新设备信息
    deviceInfo.value = {
      ...deviceInfo.value,
      micList: filteredMicList,
      speakerList: filteredSpeakerList,
      micId: filteredMicList[0]?.deviceId,
      speakerId: filteredSpeakerList[0]?.deviceId,
    };

    logDebug("获取播放设备", deviceInfo.value);
  } catch (error) {
    logError("设备获取失败", error);
  }
};
const checkAndAuthMicPhone = async function () {
  let getUserMedia
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    getUserMedia = navigator.mediaDevices.getUserMedia
  } else {
    getUserMedia = navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
  }

  if (getUserMedia) {
    await getUserMedia({audio: true}).then(async (stream) => {
      // 只是为了获取麦克风权限，获取以后立马关闭
      stream.getTracks().forEach(track => track.stop());
      await sleep(1000)
      open.value = false
      notification.info({
        message: '麦克风已开启，请重新点击“设置”按钮中的“测试麦克风”进行测试',
      })
    }).catch((e) => {
      logError("麦克风开启失败，", e);
      logDebug(`isElectron.value[${isElectron.value}]`)
      if (isElectron.value) {
        if (window.electronAPI && window.electronAPI.sendMessage) {
          const data = JSON.stringify({
            event: "checkAndAuthMicPhone",
            data: {}
          })
          logDebug(`window.electronAPI.sendMessage, data[${data}]`)
          window.electronAPI.sendMessage(data)
        }
      } else {
        notification.warning({
          message: '麦克风开启失败，请在浏览器“设置”中搜索“麦克风”关键词，允许本网站使用麦克风后再使用连麦功能！',
        })
      }
    })
  }
}

// 测试麦克风6秒后结束
const testMicrophoneAudioTrack = async () => {
  const supported = DingRTC.checkSystemRequirements();
  if (!supported) {
    notification.warning({
      message: '当前浏览器不支持通话',
    })
  }
  DingRTC.getMicrophones().then(async (microphoneList) => {
    if (microphoneList.length > 0) {
      const microphoneId = microphoneList[0].deviceId;
      let microphoneTrack
      try {
        microphoneTrack = await DingRTC.createMicrophoneAudioTrack({deviceId: microphoneId});
      } catch (e) {
        if (isElectron.value) {
          if (window.electronAPI && window.electronAPI.sendMessage) {
            const data = JSON.stringify({
              event: "checkAndAuthMicPhone",
              data: {}
            })
            logDebug(`window.electronAPI.sendMessage, data[${data}]`)
            window.electronAPI.sendMessage(data)
          }
        }
        throw e;
      }
      microphoneTrack.play('#J_prismAudio_local')
      testMicrophoneButton.value = true
      message.loading('设备测试中：请打开声音，并持续讲话....', 6);
      deviceInfo.value.micId = microphoneId
      setTimeout(() => {
        microphoneTrack.stop();
        microphoneTrack.close();
        testMicrophoneButton.value = false
        logDebug('Microphone track stopped');
      }, 6000);
    } else {
      await checkAndAuthMicPhone()
    }
  })
}

const selectDisabled = ref(false);
const MicValue = ref();
const speakerValue = ref();
const handleChange = value => {
  logDebug(`selected ${value}`);
  deviceInfo.value.micId = value
};
const handleSpeakerChange = value => {
  logDebug(`selected ${value}`);
  deviceInfo.value.speakerId = value
};

const open = ref(false);
const handleSettingButtonClick = () => {
  getDeviceList()
  open.value = true;
}
const onClose = () => {
  open.value = false;
};

/**
 * isSupport rtc能力检测
 */
async function AliRtcSupportCheck() {
  const supported = DingRTC.checkSystemRequirements();
  if (supported) {
    await getNewClient().then(
        await RemoteInit() //初始化远端设备，并做事件回调
    )
    LocalDeviceInit() // 初始化本地设备，并做事件回调
  } else {
    notification.warning({message: '当前浏览器不支持连麦',})
    return false
  }
}

/**
 * LocalDeviceInit 初始化本地设备，并做事件回调
 */
function LocalDeviceInit() {
  DingRTC.on('microphone-changed', (info) => {
    logDebug(info);
    if (info) {
      if (info.state == "active") {
        notification.warning({
          message: "音频采集设备状态变化,检测到插入新的麦克风设备！",
        })
      }
      if (info.state == "inactive") {
        notification.warning({
          message: "音频采集设备状态变化,检测到麦克风被拔出！",
        })
      }
    }

  })

  DingRTC.on('playback-device-changed', (info) => {
    logDebug(info);
    if (info) {
      if (info.state == "active") {
        notification.warning({
          message: "音频播放设备变化,检测到插入新的设备！",
        })
      }
      if (info.state == "inactive") {
        notification.warning({
          message: "音频播放设备变化,检测设备被拔出！",
        })
      }
    }
  });

  DingRTC.on('autoplay-failed', (track) => {
    logDebug(track);
    Modal.confirm({
      content: '由于浏览器自动播放限制，点击确定后才会开始播放音频',
      onOk: () => {
        track.play('#J_prismAudio_local');
      },
    });
  });
}

// 初始化最后警告时间
let lastWarningTime = 0;
// Notification，带有节流机制
const throttledNotification = (message, message_type) => {
  const currentTime = Date.now();
  if (currentTime - lastWarningTime >= 5000) { // 检查是否已经过去了5秒
    if (message_type == "warning") {
      notification.warning({
        message: message,
        duration: 1,
      });
    } else if (message_type == "info") {
      notification.info({
        message: message,
        duration: 1,
      });
    }
    lastWarningTime = currentTime; // 更新最后警告时间
  }
};

/**
 * LocalDeviceInit 初始化远端设备，并做事件回调
 */
async function RemoteInit() {
  // 检查网络质量并发出警告
  newClient.on('network-quality', (uplinkNetworkQuality, downlinkNetworkQuality) => {
    // 网络质量定义
    const networkQualityDescriptions = {
      '0': '网络状态未知',
      '1': '网络极佳',
      '2': '网络较好',
      '3': '网络一般',
      '4': '网络较差',
      '5': '网络极差',
      '6': '网络已断开'
    };
    // 检查网络质量并发出警告
    const checkNetworkQuality = (quality, direction) => {
      if (Number(quality) >= 5) {
        const message = `当前${direction}网络状态： ${networkQualityDescriptions[quality] || quality}`;
        throttledNotification(`当前网络不佳`, 'warning');
        logDebug(message);
      }
    };
    checkNetworkQuality(uplinkNetworkQuality, '上行');
    checkNetworkQuality(downlinkNetworkQuality, '下行');
  });

  // 远端用户取消发布轨道的回调
  newClient.on("user-unpublished", async (user, mediaType, auxiliary) => {
    logDebug("该回调通知远端用户取消发布音频轨道或者视频轨道。", user);
    logDebug("媒体类型：", mediaType);
    logDebug("辅助信息：", auxiliary);
    if (user && user.userId) {
      if (user.userId == currentRemoteUserId) {
        await unsubscribeRemoteUserAudioTrack(user)
      } else {
        const userTrackIndex = RemoteUserAudioTrack.findIndex(item => item.userId == user.userId);
        RemoteUserAudioTrack.splice(userTrackIndex, 1);
      }
    }
    logDebug("远端用户取消发布轨道的回调更新后的：RemoteUserAudioTrack", RemoteUserAudioTrack);
  });

  // 远端用户发布新轨道的回调
  newClient.on("user-published", async (user, mediaType, auxiliary) => {
    logDebug("该回调通知远端用户发布了新的音频轨道或者视频轨道。", user);
    logDebug("媒体类型：", mediaType);
    logDebug("辅助信息：", auxiliary);

    if (!user || !user.userId) {
      return;
    }
    RemoteUserAudioTrack.push(user)
    logDebug("远端用户发布了新的音频轨道或者视频轨道更新后的：RemoteUserAudioTrack", RemoteUserAudioTrack);
  });

}


// 老师侧：挂断语音，取消订阅
const teacherHandleVoiceCallLeaveBtn = async (online_id, user_id) => {
  logDebug("currentRemoteUserTrack", currentRemoteUserTrack)
  if (currentRemoteUserTrack != null) {
    currentRemoteUserTrack.stop()
    currentRemoteUserTrack.setVolume(0)
  }
  await applyCallRequest(online_id, "teacher_down");
  logDebug("unSubscribeResult 老师侧：挂断语音")
  clearAudioStatsTimer()
  logDebug(user_id)
};

// 老师： 拒绝通话
const handleRejectVoiceCall = async (online_id, user_id) => {
  logDebug(user_id);
  await applyCallRequest(online_id, 'reject');  // 申请接听语音通话
};

// 获取新的客户端
const getNewClient = async () => {
  if (current_live_id.value){
    await queryUserListToken(current_live_id.value); // 第一次加载，需要后端生成token

    try {
      if (!newClient?.joinInfo) {
        // 未初始化客户端，进行初始化和连接
        await newClient.join({
          appId: joinInfo.appId,
          token: joinInfo.token,
          uid: joinInfo.uid,
          channel: joinInfo.channel,
          userName: joinInfo.userName,
        });
        logDebug('DingRTC 客户端已成功加入频道');
      } else {
        logDebug('DingRTC 客户端已经初始化');
      }
    } catch (error) {
      logError('初始化或加入 DingRTC 客户端失败', error);
      // notification.error({message: '初始化或加入客户端失败，请重试'});
      throw error; // 抛出异常以供调用者处理
    }
  }

};
// 取消订阅
const unsubscribeRemoteUserAudioTrack = async (user) => {
  try {
    // 确保 newClient 已初始化
    if (!newClient) {
      throw new Error('DingRTC 客户端未初始化');
    }

    const userTrackIndex = RemoteUserAudioTrack.findIndex(item => item.userId == user.userId);
    if (userTrackIndex !== -1) {
      // 从 RemoteUserAudioTrack 列表中移除该用户的音频轨道
      RemoteUserAudioTrack.splice(userTrackIndex, 1);
      await getNewClient()
      await newClient.unsubscribe('mcu', 'audio').then(
          async (RemoteAudioTrack) => {
            if (RemoteAudioTrack) {
              logDebug("成功取消订阅远端音频RemoteAudioTrack", RemoteAudioTrack);
              notification.info({message: `对方已挂断}`});
              currentRemoteUserId = 0
            }
          }
      )
      logDebug("更新后的 RemoteUserAudioTrack 列表", RemoteUserAudioTrack);

    }
  } catch (error) {
    logError("取消订阅远端音频失败", error);
  }
}
// 获取在线用户列表
const queryOnlineUserList = async (live_id) => {
  if (!live_id) {
    return
  }
  await jsonRPC({
    url: `/api/live/${live_id}/online/user_list`,
    success(res) {
      const responseData = getResponseData(res);
      // logDebug('获取在线用户列表', responseData);
      const {online_user_ids: onlineUserIds, login_user_id: loginUserId, teacher_id: teacherId} = responseData;
      online_user_ids.value = onlineUserIds;

      const currentUser = onlineUserIds.find(user => user.user_id === loginUserId);
      current_online_id.value = currentUser ? currentUser.id : false;
      login_user_id.value = loginUserId;
      teacher_uid.value = teacherId;
    },
    fail(error) {
      logError('获取在线用户列表失败', error);
    },
  });
};
// 获取在线直播列表
const queryOnlineLiveList = async () => {
  await jsonRPC({
    url: `/api/my/live/list`,
    success(res) {
      const responseData = getResponseData(res);
      // logDebug('获取在线直播列表', responseData);
      query_onlineLive_list.value = responseData.records
      if (responseData && responseData.records.length >0){
        current_live_id.value = responseData.records[0].id
      }
      // logDebug('获取在线current_live_id', current_live_id.value);
    },
    fail(error) {
      logError('获取在线直播列表失败', error);
    },
  });
};

/**
 * 发送请求以更新语音状态
 */
const applyCallRequest = async (online_id, state) => {
  await jsonRPC({
    url: `/api/live/online/call/state`, // 请求的 API 端点
    params: {
      online_id: online_id, // 在线用户 ID
      apply_call_state: state, // 语音状态
    },
    // 请求成功后的回调函数
    success(res) {
      // 处理响应数据
      const data = getResponseData(res);
      logDebug("更新语音状态请求", data);
      // 更新在线用户列表
      queryOnlineUserList(current_live_id.value);
    },
    // 请求失败后的回调函数
    fail(error) {
      // 打印错误信息
      logError(`语音请求失败,`, `${error}`);
    },
  });
}

let audioStatsTimer = null;

// 获取远端用户的视频统计信息
const getRemoteAudioStats = async () => {
  if (newClient) {
    let getRemoteAudioStats = await newClient.getRemoteAudioStats()
    if (getRemoteAudioStats && getRemoteAudioStats.audioLevel) {
      logDebug("远端用户的音频统计信息-调用时的时间戳", getRemoteAudioStats.timestamp)
      logDebug("音频能量", getRemoteAudioStats.audioLevel)
      logDebug("往返时延", getRemoteAudioStats.rtt)
      logDebug("接收的音频总字节数 (bytes)", getRemoteAudioStats.receiveBytes)
      logDebug("接收的音频总包数", getRemoteAudioStats.receivePackets)
      logDebug("远端用户的音频统计信息", getRemoteAudioStats)
    }

  }
}
// 老师：接通电话
const handlePlayVoiceCall = async (online_id, user_id) => {
  logDebug(`User ID: ${user_id}`);
  await AliRtcSupportCheck()
  showTeacherCallOffBtn.value = true;
  message.success('连接中。。。');

  try {
    if (RemoteUserAudioTrack.length > 0) {
      logDebug("接通电话 RemoteUserAudioTrack ", RemoteUserAudioTrack);
      newClient.leave();
      const result = await newClient.join({
        appId: joinInfo.appId,
        token: joinInfo.token,
        uid: joinInfo.uid,
        channel: joinInfo.channel,
        userName: joinInfo.userName,
      });
      logDebug("接通电话, 重新进入频道 result ", result);
      if (result.remoteUsers.length > 0) {
        await subscribeToRemoteAudio(result.remoteUsers[0], online_id, user_id);
      }
    }
  } catch (error) {
    await handleCallError(online_id, error);
  }
};

const subscribeToRemoteAudio = async (remoteUser, online_id, user_id) => {
  try {
    const RemoteAudioTrack = await newClient.subscribe('mcu', 'audio');
    if (RemoteAudioTrack) {
      logDebug("接通电话 RemoteAudioTrack", RemoteAudioTrack);
      currentRemoteUserTrack = RemoteAudioTrack;
      currentRemoteUserId = user_id;

      RemoteAudioTrack.play('#J_prismAudio_local');
      RemoteAudioTrack.setVolume(0.8);  // Adjust the volume as needed

      showTeacherCallButton.value = false;
      await applyCallRequest(online_id, 'on');
      message.success('已接听');

      // Start the timer task to get remote audio stats every 3 seconds
      startAudioStatsTimer();
    }
  } catch (error) {
    await handleCallError(online_id, error);
  }
};

const handleCallError = async (online_id, error) => {
  showTeacherCallButton.value = true;
  currentRemoteUserTrack = null;
  await applyCallRequest(online_id, 'down');

  const errorMessage = error?.reason || error?.message || JSON.stringify(error);
  message.error(`接听失败: ${errorMessage}`);
  clearAudioStatsTimer();
};


function getMicLabelById(micId) {
  const mic = deviceInfo.value.micList.find(mic => mic.deviceId === micId);
  return mic ? mic.label : "设备未找到";
}

function getSpeakerLabelById(speakerId) {
  const speaker = deviceInfo.value.speakerList.find(speaker => speaker.deviceId === speakerId);
  return speaker ? speaker.label : "设备未找到";
}

const startAudioStatsTimer = () => {
  clearAudioStatsTimer();
  audioStatsTimer = setInterval(() => {
    getRemoteAudioStats();
  }, 5000);
};
const clearAudioStatsTimer = () => {
  if (audioStatsTimer) {
    clearInterval(audioStatsTimer);
    audioStatsTimer = null;
  }
};
let getOnlineLiveListTimer = ref();

onMounted(() => {
  queryOnlineLiveList()
  getOnlineLiveListTimer.value = setInterval(queryOnlineLiveList, 5000);
});
onBeforeUnmount(() => {
  clearInterval(getOnlineLiveListTimer.value);
});

let getqueryOnlineUserListTimer = ref();
onMounted(() => {
  getqueryOnlineUserListTimer.value = setInterval(() => {
    queryOnlineUserList(current_live_id.value);
  }, 5000);
});

onBeforeUnmount(() => {
  clearInterval(getqueryOnlineUserListTimer.value);
});


onMounted(async () => {
  try {
    const supported = DingRTC.checkSystemRequirements();
    if (supported) {
      LocalDeviceInit() // 初始化本地设备，并做事件回调
    } else {
      notification.warning({message: '当前浏览器不支持连麦',})
      return false
    }

    await getDeviceList()
  } catch (error) {
    logError('Error during initialization:', error);
  }
});


const isElectron = computed(() => {
  const $desktopStore = desktopStore();
  logDebug(`$desktopStore.type[${$desktopStore.type}]`)
  return `${$desktopStore.type}`.trim().toLowerCase() === 'electron'
})


</script>

<style lang="less" scoped>
.ds-online-container {
  position: fixed;
  right: 0;
  top: 0px;
  height: calc(100% - var(--task-bar-heihgt)); //calc(100% - @taskHeight);
  width: 300px;
  background-color: rgba(200, 200, 200, 0.7);
  backdrop-filter: blur(0.125rem); //毛玻璃蒙层
  z-index: 998;
}

.slideOutRight {
  animation: slideOutRight 0.9s;
}

.slideInRight {
  animation: slideInRight 0.7s;
}
.converse_btn {
  display: flex;

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
