<template>
  <div class="container live-info">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <span style="cursor: pointer;" @click="callParentMethod">在线直播</span>
        </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="console_live_detail">
      <h2 class="mb-3">
        {{ data_list.name }}
        <span v-for="row in data_list.course_category_ids" :key="row.id">
          {{ row.name }}
        </span>
      </h2>
      <a-row :gutter="[16,0]" class="mx-0">
        <a-col :span="18">
          <div class="img_box">
            <img v-if="data_list.has_image" :src="`/web/image/hw.training/${data_list.id}/image_1920?t=${new Date().getTime()}`"/>
            <img v-else src="/hw_web/static/src/img/live_default.png"/>
          </div>
        </a-col>
        <a-col :span="6" class="info">
          <div v-if="data_list.teacher_ids.length > 0" class="mb-3 user">
            <img v-if="data_list.teacher_ids[0].has_image" :src="`/web/image/hw.teacher/${data_list.teacher_ids[0].id}/image_128`"/>
            <img v-else src="/hw_web/static/src/img/avatar_live.png" />
            {{data_list.teacher_ids[0].name}}
          </div>
          <div>直播时间：{{data_list.start_time}}</div>
          <div>
            <template v-if="data_list.is_teacher">
              <!-- <a-button size="large" type="primary" @click="enterForm">编辑直播</a-button> -->
              <!-- <a-button size="large" type="primary" @click="openDeleteModal = true">删除直播</a-button> -->
              <a-modal v-model:open="openDeleteModal" title="删除直播" @ok="confirmFormDelete">
                <p>确定删除直播？ 直播ID：{{ liveId }}</p>
              </a-modal>
              <a-button size="large" type="primary" @click="openModal1 = true">直播配置</a-button>
              <a-modal v-model:open="openModal1" title="直播配置" @ok="openModal1 = false" width="60%">
                <p>推流地址:<a-typography-paragraph copyable>{{data_list.push_url}}</a-typography-paragraph></p>
                <p>OBS(服务器):</p>
                <a-row v-if="state == 'show'">
                  <a-col :span="24">
                    <a-typography-paragraph copyable style="display: inline-block;">{{data_list.livePushApp}}</a-typography-paragraph>
                    <a-typography-link @click="edit" class="mx-3">修改</a-typography-link>
                  </a-col>
                </a-row>
                <a-row v-else>
                  <a-col :span="12">
                    <a-form :model="formState" :rules="rules" ref="formRef">
                      <a-form-item name="livePushApp">
                        <a-input v-model:value="formState.livePushApp"/>
                      </a-form-item>
                    </a-form>
                  </a-col>
                  <a-col :span="12" class="pt-1">
                    <a-typography-link @click="save" class="mx-3">保存</a-typography-link>
                    <a-typography-link @click="cancel">取消</a-typography-link>
                  </a-col>
                </a-row>
                <p>OBS串流秘钥:<a-typography-paragraph copyable>{{data_list.push_auth_key}}</a-typography-paragraph></p>
              </a-modal>
              <a-button size="large" type="primary" @click="_onOpenManual">操作手册</a-button>
            </template>
            <a-button size="large" type="primary" @click="openModal2 = true">移动端</a-button>
            <a-modal v-model:open="openModal2" title="微信小程序二维码" @ok="copyQRCode" ok-text="复制二维码" class="text-center">
              <img id="qrCode" :src="`/web/image/hw.config.parameter/${data_list.WeChat_applet_Code}/image_1920`" width="258"/>
            </a-modal>
            <a-button size="large" v-if="data_list.live_video" type="primary" @click="_onOpenLiveVideo">直播回放</a-button>
            <template v-if="!data_list.live_ended">
              <a-button size="large" v-if="data_list.is_join" type="primary" @click="_onOpenLive" class="frequent">进入直播</a-button>
              <a-button size="large" v-else-if="data_list.resourcesAllFree" type="primary" @click="_onJoinLive" class="frequent">加入直播</a-button>
              <template v-else>
                <a v-if="data_list.list_price > 0 && !data_list.is_teacher" @click.prevent="_onBuyLive" class="btn btn-primary d-block w-100"
                    style="margin-top: 10px;background-color: #ff6607 !important;border-color: #ff6607 !important;">购买直播
                    <div class="stat" style="font-size: x-large;">¥  {{data_list.list_price}}</div>
                </a>
                <a-button size="large" v-else type="primary" @click="_onJoinLive" class="frequent">加入直播</a-button>
              </template>
            </template>
          </div>
        </a-col>
      </a-row>
      <h3>直播介绍</h3>
      <div v-html="data_list.description"></div>
    </div>
    <a-drawer
      title="操作手册"
      v-model:open="open"
      width="100%"
      placement="right"
      :closable="false"
      :get-container="false"
      :style="{ position: 'absolute' }"
    >
      <template #extra>
        <a-button type="primary" @click="onClose">
          返回
        </a-button>
      </template>
      <iframe width="100%" style="height: calc(100% - 5px)" :src="url" frameborder="0"/>
    </a-drawer>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import { onMounted, ref, reactive } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import { getRouterParam } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";

const router = useRouter();
const liveId = ref(getRouterParam(router, "id"));

function callParentMethod() {
  router.push(`/live`)
}

const url = ref('')
const open = ref(false);
let _onOpenManual = ()=>{
  open.value = true;
  url.value = `/hw_web/static/hi168_manual/index.html`
}
const onClose = () => {
  open.value = false;
};

const openDeleteModal = ref(false);
const openModal1 = ref(false);
const openModal2 = ref(false);

const data_list = reactive({
  student_id: 2,
  live_video: false,
  has_image: false,
  live_ended: false,
  teacher_ids: [],
});

const formRef = ref();
const rules = {
  livePushApp: [
    {
      required: true,
      message: '请输入OBS(服务器)',
    },
  ],
}
let state = ref('show')
let formState = reactive({
  livePushApp: '',
});
let edit = () => {
  state.value = 'edit'
};
let save = () => {
  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/vue/console/live/livePushApp/edit",
      params: {
        id: liveId.value,
        newPushApp: formState.livePushApp,
      },
      success(res) {
        const data = getResponseData(res)
        logDebug(`保存成功`, data)
        _getRecords()
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    }).then(
      state.value = 'show'
    );
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};
let cancel = () => {
  state.value = 'show';
  formState.livePushApp = data_list.livePushApp;
};

const confirmFormDelete = () => {
  jsonRPC({
    url: "/vue/console/live/delete",
    params: {
      id: liveId.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`保存成功`, data)
      openDeleteModal.value = false;
      router.push(`/live`)
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
};

// 打开直播
let _onOpenLive = ()=>{
  router.push(`/live/${liveId.value}`)
}
// 打开直播回放
let _onOpenLiveVideo = ()=>{
  window.open(data_list.live_video, '_blank');
}
// 加入直播
let _onJoinLive = ()=>{
  jsonRPC({
    url: `/vue/console/user_add_live/join`,
    params: {
      id: liveId.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`保存成功`, data)
      _getRecords()
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
// 购买直播
let _onBuyLive = ()=>{
  jsonRPC({
    url: `/vue/console/live/buy`,
    params: {
      id: liveId.value,
    },
    success() {
      router.push('/shop')
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

function _getRecords(){
  jsonRPC({
    url: `/vue/console/live/detail`,
    params: {
      id: liveId.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`保存成功`, data)
      Object.assign(data_list, data);
      formState.livePushApp = data_list.livePushApp;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

// 复制二维码图片
const copyQRCode = async () => {
  try {
    const qrCodeElement = document.getElementById('qrCode');
    const response = await fetch(qrCodeElement.src);
    const blob = await response.blob();
    const imgBitmap = await createImageBitmap(blob);

    const canvas = document.createElement('canvas');
    canvas.width = imgBitmap.width;
    canvas.height = imgBitmap.height;
    const context = canvas.getContext('2d');
    context.drawImage(imgBitmap, 0, 0);

    const pngBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

    const clipboardItem = new ClipboardItem({ 'image/png': pngBlob });

    await navigator.clipboard.write([clipboardItem]);
    message.success('二维码已复制到剪贴板！');
  } catch (err) {
    logError('复制失败：', err);
    message.error('复制失败，请手动复制！');
  }
};

onMounted(_getRecords);
</script>

<style scoped lang="scss">
.live-info {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  padding-top: 0;
  .console_live_detail {
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    h2 {
      font-size: 24px;
      display: flex;
      align-items: center;
      span {
        border: 1px solid #52c9ff;
        padding: 5px 10px;
        border-radius: 8px;
        color: #52c9ff;
        margin-left: 10px;
        font-size: 14px;
      }
    }
    .info {
      .user {
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      button{
        &.frequent {
          background-color: #5beda6;
          border-color: #5beda6;
          &:hover {
            background-color: #5beda6 !important;
            border-color: #5beda6 !important;
            opacity: 0.9;
          }
        }
      }
      > .btn {
        display: block;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        border-radius: 8px;
        padding: 0;
        line-height: 50px;
      }
      >div:last-child{
        display: flex;
        flex-direction: column;
        >*{
          margin-top: 10px;
        }
      }
    }
    h3 {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 15px;
      font-weight: bold;
      padding-left: 5px;
      line-height: 30px;
      border-left: 5px solid #0078ff;
    }
  }
}
</style>
