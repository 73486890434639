<template>
  <div>
    <a-button id="function-full-screen" :style="props.styleSetting.buttonStyle" type="primary"
              @click="openUpDownLoadWindow">
      <a-tooltip :placement="placement">
        <template #title>文件上传下载</template>
        <CloudUploadOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
    <a-drawer
        v-model:open="open"
        :root-style="{ color: 'blue' }"
        placement="right"
        title="文件上传下载"
        width="45vw"
        @after-open-change="afterOpenChange"
    >
      <a-typography>
        <a-typography-title :level="4">上传文件</a-typography-title>
        <a-typography-paragraph>
          在“命令行终端”中，输入 “ trz ” 命令，可以将本地文件上传到实验环境。比如：
        </a-typography-paragraph>
        <blockquote style="font-size: 18px">
          root@hwua:/home/hwua# trz
        </blockquote>
        <a-typography-paragraph>
          然后在弹出的文件选择器中，选择要上传的文件即可。“ trz ” 命令执行效果如下所示：
        </a-typography-paragraph>
        <a-typography-paragraph>
          <img alt="" class="top-theme" src="@/assets/upload.png" style="width: 40vw">
        </a-typography-paragraph>
        <a-typography-title :level="4">下载文件</a-typography-title>
        <a-typography-paragraph>
          在“命令行终端”中，输入 “ tsz 文件名 ” 命令，可以将实验环境里的文件下载到本地。
          比如，如果想将“/home/hwua/test/test001.txt”文件下载到本地，可以输入以下命令：
        </a-typography-paragraph>
        <blockquote style="font-size: 18px">
          root@hwua:/home/hwua# tsz /home/hwua/test/test001.txt
        </blockquote>
        <a-typography-paragraph>
          或者：
        </a-typography-paragraph>
        <blockquote style="font-size: 18px">
          root@hwua:/home/hwua# cd /home/hwua/test
        </blockquote>
        <blockquote style="font-size: 18px">
          root@hwua:/home/hwua# tsz test001.txt
        </blockquote>
        <a-typography-paragraph>
          然后在弹出的文件选择器中，选择保存下载文件的目录。“ tsz 文件名 ” 命令执行效果如下所示：
        </a-typography-paragraph>
        <a-typography-paragraph>
          <img alt="" class="top-theme" src="@/assets/download.png" style="width: 40vw">
        </a-typography-paragraph>
        <a-typography-paragraph>
          如果是需要下载文件夹，则需要添加上“-d”选项，也就是使用“ tsz -d 文件夹名 ”命令下载文件夹即可。
          比如，如果需要下载“/home/hwua/test”文件夹，则使用以下命令：
        </a-typography-paragraph>
        <blockquote style="font-size: 18px">
          root@hwua:/home/hwua# tsz -d test
        </blockquote>
      </a-typography>
    </a-drawer>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {CloudUploadOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug} from "@/utils/logger";

logComponents([CloudUploadOutlined])
logDebug('TerminalFunctionUpDownLoad setup!')

const placement = ref(window.location.search.includes('desktop') ? 'right' : 'bottom');
const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data

const ttyd_ingress_url = `${data['ttyd_ingress_url']}?提示：请在下方输入“trz”命令上传文件，或输入“tsz”加“空格”加“文件名”命令下载文件`
// const ttyd_ingress_url = `https://www.hi168.com/envttyd/wbrxsbaypwqxmzlg?提示：请在下方输入“trz”命令上传文件，或输入“tsz”加“空格”加“文件名”命令下载文件`

// 组件逻辑
const open = ref(false);
const afterOpenChange = bool => {
  logDebug('open', bool);
};
// const showDrawer = () => {
//   open.value = true;
// };

const windowId = `newWindow_${Date.now()}`.replaceAll('.', '_')
logDebug(`windowId[${windowId}]`);

const openUpDownLoadWindow = () => {
  const features = 'height=600,width=1100,left=220,top=100,resizable=no,' +
      'scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=no';
  window.open(ttyd_ingress_url, windowId, features);
};


onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalFunctionUpDownLoad onMounted.`)
})

const onSelected = function () {
  logDebug(`TerminalFunctionUpDownLoad onSelected.`)
}

defineExpose({
  primal,
  data,
  onSelected,
})


</script>

<style scoped>
#function-full-screen {
  background: #90EE90;
}
</style>

