<template>
  <div>
    <a-button id="function-student-app" :style="props.styleSetting.buttonStyle" type="primary"
              @click="showStudentAppModal">
      <a-tooltip :placement="placement">
        <template #title>学生应用</template>
        <BlockOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
    <a-modal v-model:open="openStudentAppModal"
             cancelText="取消" okText="确认"
             style="width: 70vw" title="学生应用列表"
             @ok="handleOkStudentAppModal">
      <a-row>
        <a-col :span="18"></a-col>
        <a-col :span="6">
          <a-input-search allowClear enter-button placeholder="按用户名搜索" style="width: 80%" @search="onSearch"/>
        </a-col>
      </a-row>
      <a-row style="margin-top: 10px">
        <a-col :span="24">
          <a-table :columns="studentAppColumns" :data-source="studentAppData"
                   :pagination="pagination">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'environment_ids'">
                <div v-for="item in record['environment_ids']" :key="item.name">
                  {{ item.name }}
                </div>
              </template>
              <template v-if="column.key === 'action'">
                <div v-for="item in record['app_env_link_list']" :key="item.name">
                  <router-link :to="item.link" target="_blank">
                    访问：{{ item.name }}
                  </router-link>
                </div>
              </template>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script setup>
import {markRaw, onMounted, reactive, ref} from 'vue'
import {BlockOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug, logError} from "@/utils/logger";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";

logDebug('TerminalFunctionAppSnapshot setup!')
logComponents([BlockOutlined])

const placement = ref(window.location.search.includes('desktop') ? 'right' : 'bottom');
const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data

const liveId = data['live_id']

const openStudentAppModal = ref(false);

const showStudentAppModal = function () {

  loadStudentApp().then(function () {
    openStudentAppModal.value = true;
  })
}

const handleOkStudentAppModal = () => {
  openStudentAppModal.value = false;
};

const studentAppColumns = [
  {
    title: '序号',
    // dataIndex是JSON数据的字段名称
    dataIndex: 'app_solution_id',
    // key是column的索引名称
    key: 'app_solution_id',
  },
  {
    title: '用户名',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: '实验环境',
    key: 'environment_ids',
    dataIndex: 'environment_ids',
  },
  {
    title: '应用状态',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const studentAppData = ref([]);

const handlePageChange = function (page, pageSize) {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page
}

const pagination = reactive({
  // 分页配置
  pageSize: 5, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const searchString = ref('');
const onSearch = (searchValue) => {
  if (searchString.value !== searchValue) {
    pagination.current = 1;
    searchString.value = searchValue;
    loadStudentApp();
  }
};

const adjustStudentAppData = function (studentApps) {
  for (const record of studentApps) {
    record['status'] = record['environment_ids'].map(x => x.status).join('; ')
    const solutionId = record['app_solution_id']
    const appEnvLinkList = record['inner_service_name'].map(function (inner_service_name) {
      return {
        'name': inner_service_name,
        'link': `/terminal/solution/${solutionId}/inner_service_name/${inner_service_name}`,
      }
    })
    record['app_env_link_list'] = appEnvLinkList
  }
  return studentApps
}

const loadStudentApp = function () {
  const getAppSnapshotResult = jsonRPC({
    url: "/api/experiment/live/app",
    params: {
      live_id: liveId,
      // 一次性取完所有应用
      page_size: 10000,
      page_index: 1,
      search: searchString.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('loadAppSnapshot', data)
      studentAppData.value = adjustStudentAppData(data['records'])
      pagination.total = studentAppData.value.length
    },
    fail(error) {
      logError(`获取应用快照列表失败, `, error)
      message.error(`获取应用快照列表失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return getAppSnapshotResult.then(function (res) {
    logDebug(`getAppSnapshotResult, `, res)
  })
}


onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalFunctionService onMounted.`)
})


defineExpose({
  primal,
  data,
})

</script>

<style scoped>
#function-student-app {
  background: #00BFFF;
}
</style>

