<template>
  <div class="console_image_app_create">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link v-if="!isVM" :to="`/console/image/container/${image_id}`">容器镜像详情</router-link>
        <router-link v-if="isVM" :to="`/console/image/vm/${vm_image_id}`">虚拟机镜像详情</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>制作应用模板</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row>
      <a-col :span="16">
        <a-form ref="formRef" :disabled="editorMode === 'readonly'" :label-col="labelCol"
                :model="data_list" :wrapper-col="wrapperCol" layout="horizontal">
          <a-row>
            <a-col :span="24">
              <a-form-item v-if="!isVM" label="容器镜像ID：" name='user_image_id'>
                <a-input :value="image_id" disabled/>
              </a-form-item>
              <a-form-item v-if="isVM" label="虚拟机镜像ID：" name='user_image_id'>
                <a-input :value="vm_image_id" disabled/>
              </a-form-item>
              <a-form-item :required="true" label="应用名称：" name='app_name'>
                <a-input v-model:value="data_list.app_name"/>
              </a-form-item>
              <a-form-item label="应用描述：" name='app_description'>
                <a-input v-model:value="data_list.app_description"/>
              </a-form-item>
              <a-form-item :rules="[{ required: true, message: '请输入应用图片'}]" label="应用图片："
                           name='app_image'>
                <a-upload
                    v-model:file-list="data_list.app_image"
                    :customRequest="uploadDummyRequest"
                    :max-count="1"
                    accept=".png,.jpg,.jpeg"
                    list-type="picture-card"
                    @preview="handlePreview"
                >
                  <div v-if="data_list.app_image.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
                <a-modal :footer="null" :open="previewVisible" :title="previewTitle" @cancel="cancelPreview">
                  <img :src="previewImage" alt="example" style="width: 100%"/>
                </a-modal>
              </a-form-item>
              <a-form-item label="CPU：" name='app_cpu'>
                <a-select v-model:value="data_list.app_cpu" class="form-item">
                  <a-select-option value="1">1核</a-select-option>
                  <a-select-option value="2">2核</a-select-option>
                  <a-select-option value="4">4核</a-select-option>
                  <a-select-option value="8">8核</a-select-option>
                  <a-select-option value="16">16核</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="内存：" name='app_memory'>
                <a-select v-model:value="data_list.app_memory" class="form-item">
                  <a-select-option value="1">1GB</a-select-option>
                  <a-select-option value="2">2GB</a-select-option>
                  <a-select-option value="4">4GB</a-select-option>
                  <a-select-option value="8">8GB</a-select-option>
                  <a-select-option value="16">16GB</a-select-option>
                  <a-select-option value="32">32GB</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="磁盘：" name='app_disk'>
                <a-select v-model:value="data_list.app_disk" class="form-item">
                  <template v-if="isVM">
                    <a-select-option value="100">100GB</a-select-option>
                  </template>
                  <template v-else>
                    <a-select-option value="10">10GB</a-select-option>
                    <a-select-option value="20">20GB</a-select-option>
                    <a-select-option value="40">40GB</a-select-option>
                    <a-select-option value="80">80GB</a-select-option>
                    <a-select-option value="100">100GB</a-select-option>
                  </template>
                </a-select>
              </a-form-item>
              <a-form-item label="带宽：" name='app_band_width'>
                <a-select v-model:value="data_list.app_band_width" class="form-item">
                  <a-select-option value="1">1Mbps</a-select-option>
                  <a-select-option value="2">2Mbps</a-select-option>
                  <a-select-option value="5">5Mbps</a-select-option>
                  <a-select-option value="10">10Mbps</a-select-option>
                  <a-select-option value="20">20Mbps</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-if="!isVM" label="GPU：" name='app_gpu'>
                <a-select v-model:value="data_list.app_gpu" :required="true" class="form-item">
                  <a-select-option value="none">无</a-select-option>
                  <a-select-option value="tesla-t4">tesla-t4</a-select-option>
                  <a-select-option value="tesla-v100-sxm3">tesla-v100-sxm3</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="服务端口：" name='service_port_list'>
                <div>
                  <a-row>
                    <a-col :span="24">
                      <a-table :columns="data_list.service_port_list.columns"
                               :data-source="data_list.service_port_list.data"
                               :pagination="false" bordered class="form-table"
                               size="small" tableLayout="fixed">
                        <template #bodyCell="{ column, index, record}">
                          <template v-if="column.dataIndex === 'service_type'">
                            <a-form-item :label="``" :name="`service_type_${index}`"
                                         :rules="[{ required: true, message: '请输入服务端口类型',
                                         validator: () => customCheckServicePort(index, 'service_type') }]"
                                         class="form-item">
                              <a-select v-model:value="record.service_type" class="form-item"
                                        @change="()=>handleServiceTypeChange(record)">
                                <a-select-option value="HTTP">HTTP</a-select-option>
                                <a-select-option value="TCP-LB">TCP</a-select-option>
                                <a-select-option value="UDP-LB">UDP</a-select-option>
                                <a-select-option value="HTTP-TTYD">TTYD终端</a-select-option>
                                <a-select-option value="HTTP-WEBSHELL">WEBSHELL终端</a-select-option>
                                <!--                                <a-select-option value="TCP">TCP(NodePort)</a-select-option>-->
                                <!--                                <a-select-option value="UDP">UDP(NodePort)</a-select-option>-->
                              </a-select>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'service_port'">
                            <a-form-item :label="``" :name="`service_port_${index}`"
                                         :rules="[{ required: true, message: '请输入服务端口号',
                                         validator: () => customCheckServicePort(index, 'service_port') }]"
                                         class="form-item">
                              <a-input-number v-model:value="record.service_port"
                                              class="form-item"
                                              max="65535" min="1"/>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'tcp_service_type'">
                            <a-form-item :label="``" :name="`tcp_service_type_${index}`"
                                         :rules="[{ required: true, message: '请输入TCP服务类型',
                                         validator: () => customCheckServicePort(index, 'tcp_service_type') }]"
                                         class="form-item">
                              <a-select v-if="['TCP', 'TCP-LB'].includes(record.service_type)"
                                        v-model:value="record.tcp_service_type" class="form-item">
                                <a-select-option value="TCP">无</a-select-option>
                                <a-select-option value="HTTP">HTTP</a-select-option>
                                <a-select-option value="HTTPS">HTTPS</a-select-option>
                              </a-select>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'action'">
                            <a-form-item class="form-item">
                              <a-tooltip>
                                <template #title>删除该条记录</template>
                                <CloseCircleOutlined style="color: red"
                                                     @click="()=>onDeleteServicePort(record.key)"/>
                              </a-tooltip>
                            </a-form-item>
                          </template>
                        </template>
                      </a-table>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24">
                      <a-button @click="()=>onAddServicePort()">新增服务端口</a-button>
                    </a-col>
                  </a-row>
                </div>
              </a-form-item>
              <a-form-item v-if="!isVM" label="环境变量：" name='app_band_width'>
                <div>
                  <a-row>
                    <a-col :span="24">
                      <a-table :columns="data_list.env_var_list.columns"
                               :data-source="data_list.env_var_list.data"
                               :pagination="false" bordered class="form-table"
                               size="small" tableLayout="fixed">
                        <template #bodyCell="{ column, index, record}">
                          <template v-if="column.dataIndex === 'env_name'">
                            <a-form-item :label="``" :name="`env_name_${index}`"
                                         :rules="[{ required: true, message: '请输入环境变量名称',
                                         validator: () => customCheckEnv(index, 'env_name') }]"
                                         class="form-item">
                              <a-input v-model:value="record.env_name" class="form-item"/>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'env_value'">
                            <a-form-item :label="``" :name="`env_value_${index}`"
                                         :rules="[{ required: true, message: '请输入环境变量值',
                                         validator: () => customCheckEnv(index, 'env_value') }]"
                                         class="form-item">
                              <a-input v-model:value="record.env_value" class="form-item"/>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'action'">
                            <a-form-item class="form-item">
                              <a-tooltip>
                                <template #title>删除该条记录</template>
                                <CloseCircleOutlined style="color: red"
                                                     @click="()=>onDeleteEnv(record.key)"/>
                              </a-tooltip>
                            </a-form-item>
                          </template>
                        </template>
                      </a-table>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24">
                      <a-button @click="()=>onAddEnv()">新增环境变量</a-button>
                    </a-col>
                  </a-row>
                </div>
              </a-form-item>
              <a-form-item v-if="!isVM" label="运行命令：" name='app_command'>
                <a-input v-model:value="data_list.app_command"/>
              </a-form-item>
              <a-form-item v-if="!isVM" label="运行命令参数：" name='app_arg_list'>
                <div>
                  <a-row>
                    <a-col :span="24">
                      <a-table :columns="data_list.app_arg_list.columns"
                               :data-source="data_list.app_arg_list.data"
                               :pagination="false" bordered class="form-table"
                               size="small" tableLayout="fixed">
                        <template #bodyCell="{ column, index, record}">
                          <template v-if="column.dataIndex === 'command_arg'">
                            <a-form-item :label="``" :name="`command_arg_${index}`"
                                         :rules="[{ required: false, message: '请输入命令参数',
                                         validator: () => customCheckCommandArg(index, 'command_arg') }]"
                                         class="form-item">
                              <a-input v-model:value="record.command_arg" class="form-item"/>
                            </a-form-item>
                          </template>
                          <template v-if="column.dataIndex === 'action'">
                            <a-form-item class="form-item">
                              <a-tooltip>
                                <template #title>删除该条记录</template>
                                <CloseCircleOutlined style="color: red"
                                                     @click="()=>onDeleteCommandArg(record.key)"/>
                              </a-tooltip>
                            </a-form-item>
                          </template>
                        </template>
                      </a-table>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24">
                      <a-button @click="()=>onAddCommandArg()">新增运行命令参数</a-button>
                    </a-col>
                  </a-row>
                </div>
              </a-form-item>
              <a-form-item v-if="!isVM" label="自定义OSS挂载目录：" name='oss_mount_dir_list'>
                <div style="display: flex">
                  <div>
                    <a-row>
                      <a-col :span="24">
                        <a-table :columns="data_list.oss_mount_dir_list.columns"
                                 :data-source="data_list.oss_mount_dir_list.data"
                                 :pagination="false" bordered class="form-table"
                                 size="small" tableLayout="fixed">
                          <template #bodyCell="{ column, index, record}">
                            <template v-if="column.dataIndex === 'dir_path'">
                              <a-form-item :label="``" :name="`dir_path_${index}`"
                                           :rules="[{ required: false, message: '请输入目录路径',
                                         validator: () => customCheckMountDirList(index, 'dir_path') }]"
                                           class="form-item">
                                <a-input v-model:value="record.dir_path" class="form-item"/>
                              </a-form-item>
                            </template>
                            <template v-if="column.dataIndex === 'action'">
                              <a-form-item class="form-item">
                                <a-tooltip>
                                  <template #title>删除该条记录</template>
                                  <CloseCircleOutlined style="color: red"
                                                       @click="()=>onDeleteOSSMountList(record.key)"/>
                                </a-tooltip>
                              </a-form-item>
                            </template>
                          </template>
                        </a-table>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="24">
                        <a-button @click="()=>onAddOSSMountList()">新增挂载目录路径</a-button>
                      </a-col>
                    </a-row>
                  </div>
                  <a-popover trigger="hover">
                    <template #content>
                      <p>OSS挂载目录</p>
                    </template>
                    <QuestionCircleTwoTone class="help-icon"/>
                  </a-popover>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-flex align="center" class="mb-5" gap="middle" justify="center">
            <a-button v-if="editorMode === 'create'" :loading="confirmFormCreateLoading"
                      type="primary"
                      @click="confirmFormCreate">创建
            </a-button>
            <a-button v-if="editorMode === 'update'" type="primary" @click="confirmFormUpdate">保存</a-button>
            <a-button v-if="editorMode !== 'readonly'" @click="cancelForm">取消</a-button>
          </a-flex>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref} from "vue";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {useRouter} from 'vue-router';
import {routerBack, routerReplace} from "@/utils/router_utils";
import {CloseCircleOutlined, PlusOutlined, QuestionCircleTwoTone} from "@ant-design/icons-vue";
import {newCancelPreview, newHandlePreview, newPreviewState, newUploadDummyRequest} from "@/utils/file_utils";
import {message} from "ant-design-vue";

const router = useRouter();

logDebug(`router.currentRoute.value`, router.currentRoute.value)
const isVM = `${router.currentRoute.value.path}`.startsWith("/console/image/vm/");

const image_id = router.currentRoute.value.params.image_id
logDebug(`isVM[${isVM}], image_id[${image_id}]`)

const vm_image_id = router.currentRoute.value.params.vm_image_id
logDebug(`isVM[${isVM}], vm_image_id[${vm_image_id}]`)

const editorMode = ref('create')

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 20,
};
const formRef = ref();

const uploadDummyRequest = newUploadDummyRequest()
const {previewVisible, previewImage, previewTitle} = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle)
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)

const data_list = reactive({
  app_type: isVM ? "VirtualMachineApp" : "ContainerApp",
  user_image_id: image_id,
  user_vm_image_id: vm_image_id,
  app_name: '',
  inner_service_name: '',
  app_description: '',
  app_image: [],
  app_cpu: '1',
  app_memory: '1',
  app_disk: isVM ? '100' : '10',
  app_band_width: '10',
  app_gpu: 'none',
  service_port_list: {
    columns: [
      {
        title: '端口类型',
        dataIndex: 'service_type',
      },
      {
        title: '端口号',
        dataIndex: 'service_port',
      },
      {
        title: 'TCP服务类型',
        dataIndex: 'tcp_service_type',
      },
      {
        title: '动作',
        dataIndex: 'action',
      },
    ],
    data: [
      // {
      //   key: '1',
      //   service_type: 'HTTP',
      //   service_port: '8080',
      // },
      // {
      //   key: '2',
      //   service_type: 'TCP',
      //   service_port: '6342',
      // },
      // {
      //   key: '3',
      //   service_type: 'UDP',
      //   service_port: '5353',
      // },
    ],
  },
  env_var_list: {
    columns: [
      {
        title: '变量名',
        dataIndex: 'env_name',
      },
      {
        title: '变量值',
        dataIndex: 'env_value',
      },
      {
        title: '动作',
        dataIndex: 'action',
      },
    ],
    data: [
      // {
      //   key: '1',
      //   env_name: 'username',
      //   env_value: 'odoo001',
      // },
      // {
      //   key: '2',
      //   env_name: 'password',
      //   env_value: '123456',
      // },
      // {
      //   key: '3',
      //   env_name: 'database',
      //   env_value: 'db001',
      // },
    ],
  },
  app_command: '',
  app_arg_list: {
    columns: [
      {
        title: '命令参数',
        dataIndex: 'command_arg',
      },
      {
        title: '动作',
        dataIndex: 'action',
      },
    ],
    data: [
      // {
      //   key: '1',
      //   command_arg: 'username',
      // },
      // {
      //   key: '2',
      //   command_arg: 'password',
      // },
      // {
      //   key: '3',
      //   command_arg: 'database',
      // },
    ],
  },
  oss_mount_dir_list: {
    columns: [
      {
        title: '目录路径',
        dataIndex: 'dir_path',
      },
      {
        title: '动作',
        dataIndex: 'action',
      },
    ],
    data: [
      // {
      //   key: '1',
      //   command_arg: 'username',
      // },
      // {
      //   key: '2',
      //   command_arg: 'password',
      // },
      // {
      //   key: '3',
      //   command_arg: 'database',
      // },
    ],
  },
});

function customCheckServicePort(index, key) {
  if (data_list.service_port_list.data[index][key]) {
    return Promise.resolve();
  }
  return Promise.reject();
}

function handleServiceTypeChange(record) {
  logDebug(`handleServiceTypeChange, service_type[${record.service_type}], service_port[${record.service_port}]`)
  if (record.service_type === 'HTTP-TTYD') {
    record.service_port = 7149
  } else if (record.service_type === 'HTTP-WEBSHELL') {
    record.service_port = 7179
  }
}

function customCheckEnv(index, key) {
  if (data_list.env_var_list.data[index][key]) {
    return Promise.resolve();
  }
  return Promise.reject();
}

function customCheckCommandArg(index, key) {
  if (data_list.app_arg_list.data[index][key]) {
    return Promise.resolve();
  }
  return Promise.reject();
}

const confirmFormCreateLoading = ref(false)

const normalize_service_port_list_data = function () {
  for (const port of data_list.service_port_list.data) {
    if (port.service_type === 'HTTP-TTYD') {
      port.is_shell = true
      port.shell_type = "ttyd"
    } else if (port.service_type === 'HTTP-WEBSHELL') {
      port.is_shell = true
      port.shell_type = "webshell"
    }
  }
}

const confirmFormCreate = () => {
  confirmFormCreateLoading.value = true
  normalize_service_port_list_data()
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormCreate')
        jsonRPC({
          url: `/api/make/image/create/app`,
          params: {
            user_image_id: image_id,
            user_vm_image_id: vm_image_id,
            app_type: data_list.app_type,
            app_name: data_list.app_name,
            inner_service_name: data_list.inner_service_name,
            app_description: data_list.app_description,
            app_image: data_list.app_image,
            app_cpu: data_list.app_cpu,
            app_memory: data_list.app_memory,
            app_disk: data_list.app_disk,
            app_band_width: data_list.app_band_width,
            app_gpu: data_list.app_gpu,
            service_port_list: data_list.service_port_list.data,
            env_var_list: data_list.env_var_list.data,
            app_command: data_list.app_command,
            app_arg_list: data_list.app_arg_list.data,
            oss_mount_dir_list: data_list.oss_mount_dir_list.data,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`创建应用模板成功`, data);
            message.info(`创建应用模板成功`, 3)
            if (isVM) {
              routerReplace(router, `/console/image/vm/${vm_image_id}`)
            } else {
              routerReplace(router, `/console/image/container/${image_id}`)
            }
          },
          fail(error) {
            logError(`创建应用模板失败`, error);
            const errMsg = getFailedMessage(error)
            message.error(`创建应用模板失败，原因[${errMsg}]`, 3)
            if (isVM) {
              routerReplace(router, `/console/image/vm/${vm_image_id}`)
            } else {
              routerReplace(router, `/console/image/container/${image_id}`)
            }
          },
        }).then(function () {
          confirmFormCreateLoading.value = false
        })
      })
      .catch(error => {
        logError('error', error);

      });
};

function customCheckMountDirList(index, key) {
  if (data_list.oss_mount_dir_list.data[index][key]) {
    return Promise.resolve();
  }
  return Promise.reject();
}

const onDeleteOSSMountList = function (key) {
  data_list.oss_mount_dir_list.data = data_list.oss_mount_dir_list.data.filter(x => x.key !== key)
}

const onAddOSSMountList = function () {
  const keys = data_list.oss_mount_dir_list.data.map(x => parseInt(x.key))
  const maxKey = Math.max(...keys)
  data_list.oss_mount_dir_list.data.push({
    key: `${maxKey + 1}`,
    env_name: '',
    env_value: '',
  })
}
const confirmFormUpdate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormUpdate')
      })
      .catch(error => {
        logError('error', error);

      });
};

const onDeleteServicePort = function (key) {
  data_list.service_port_list.data = data_list.service_port_list.data.filter(x => x.key !== key)
}

const onAddServicePort = function () {
  const keys = data_list.service_port_list.data.map(x => parseInt(x.key))
  const maxKey = Math.max(...keys)
  data_list.service_port_list.data.push({
    key: `${maxKey + 1}`,
    service_type: 'HTTP' || 'TCP' || 'UDP' || 'TCP-LB' || 'UDP-LB' || 'HTTP-TTYD' || 'HTTP-WEBSHELL',
    service_port: '80',
    tcp_service_type: 'TCP',
    is_shell: false,
    shell_type: 'ttyd',
  })
}

const onDeleteEnv = function (key) {
  data_list.env_var_list.data = data_list.env_var_list.data.filter(x => x.key !== key)
}

const onAddEnv = function () {
  const keys = data_list.env_var_list.data.map(x => parseInt(x.key))
  const maxKey = Math.max(...keys)
  data_list.env_var_list.data.push({
    key: `${maxKey + 1}`,
    env_name: '',
    env_value: '',
  })
}

const onDeleteCommandArg = function (key) {
  data_list.app_arg_list.data = data_list.app_arg_list.data.filter(x => x.key !== key)
}

const onAddCommandArg = function () {
  const keys = data_list.app_arg_list.data.map(x => parseInt(x.key))
  const maxKey = Math.max(...keys)
  data_list.app_arg_list.data.push({
    key: `${maxKey + 1}`,
    env_name: '',
    env_value: '',
  })
}

const cancelForm = () => {
  routerBack(router)
};

onMounted(() => {
  fetchData()
});

function fetchData() {
  let url = `/api/make/image/${image_id}/info`;
  if (isVM) {
    url = `/api/make/vm/image/${vm_image_id}/info`;
  }
  logDebug(`fetchData url[${url}]`)
  jsonRPC({
    url: url,
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取用户容器镜像信息成功`, data);
      Object.assign(data_list, data);
      data_list.inner_service_name = data.show_name
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
</script>

<style lang="scss" scoped>
.ant-table-cell {
  height: 10px;
}
</style>