<template>
  <div class="console_image_virtual_machine_create">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/image/vm">我的虚拟机镜像</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>新建虚拟机镜像</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row>
      <a-col :span="24" :lg="16">
        <a-form ref="formRef" :model="data_list" :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                layout="horizontal"
                :disabled="editorMode === 'readonly'"
        >
          <a-row>
            <a-col :span="24">
              <a-form-item label="虚拟机镜像ID：" name='id' v-show="editorMode!=='create'">
                <a-input v-model:value="data_list.id"/>
              </a-form-item>
              <a-form-item label="虚拟镜像名称：" name='name'>
                <a-input v-model:value="data_list.name"
                         placeholder="只能由小写英文字母和数字构成，且不能超过14位，比如：ubuntu2404v1"/>
              </a-form-item>
              <a-form-item label="虚拟镜像描述：" name='description'>
                <a-input v-model:value="data_list.description"
                         placeholder="填写对虚拟机镜像的简单描述，比如：这是Ubuntu24.04虚拟机镜像"/>
              </a-form-item>
              <a-form-item label="虚拟镜像下载地址：" name='data_volume_url'>
                <a-input v-model:value="data_list.data_volume_url"
                         placeholder="仅支持img和iso格式的虚拟机镜像，比如：http://172.16.99.117/iso/ubuntu-24.04-server-cloudimg-amd64.img"/>
              </a-form-item>
              <a-form-item label="虚拟镜像类型：" name='image_file_type'>
                <a-select :value="data_list.image_file_type">
                  <a-select-option value="iso">ISO安装镜像</a-select-option>
                  <a-select-option value="img">IMG启动镜像</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-flex justify="center" align="center">
            <a-button type="primary" v-if="editorMode === 'create'" @click="confirmFormCreate">创建</a-button>
            <a-button type="primary" v-if="editorMode === 'update'" @click="confirmFormUpdate">保存</a-button>
            <a-button v-if="editorMode !== 'readonly'" style="margin-left: 10px" @click="cancelForm">取消</a-button>
          </a-flex>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {reactive, ref, watch} from "vue";
import {useRouter} from 'vue-router';
import {routerBack, routerReplace} from "@/utils/router_utils";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";

const router = useRouter();

const image_id = router.currentRoute.value.params.image_id
logDebug(image_id)

const editorMode = ref('create')

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 20,
};
const formRef = ref();

const validateVmImageName = (rule, value) => {
  if (!value) {
    return Promise.reject(`请输入虚拟机镜像名称`);
  }

  const pattern = /^[a-z0-9]{1,14}$/;
  if (!pattern.test(value)) {
    return Promise.reject(`虚拟机镜像名称必须由“小写英文字母”或“数字”两种字符组成，且长度不能超过14个字符。`)
  }

  return Promise.resolve()
};

const validateVmDownloadURL = (rule, value) => {
  value = `${value}`.trim()
  if (!value) {
    return Promise.reject(`请输入虚拟机镜像下载地址`);
  }

  const pattern = /^(http|https):\/\//;
  if (!pattern.test(value)) {
    return Promise.reject(`虚拟机镜像下载地址必须是http或https协议`)
  }
  if (!`${value}`.toLowerCase().endsWith(".img") && !`${value}`.toLowerCase().endsWith(".iso")) {
    return Promise.reject(`仅支持img和iso格式的虚拟机镜像，请指定“.img”或“.iso”格式的虚拟机镜像下载地址。`)
  }
  return Promise.resolve()
};


const rules = {
  name: [
    {
      required: true,
      trigger: 'change',
      validator: validateVmImageName,
    },
  ],
  description: [
    {
      required: true,
      message: '请输入虚拟机镜像描述',
      trigger: 'change',
    },
  ],
  data_volume_url: [
    {
      required: true,
      trigger: 'change',
      validator: validateVmDownloadURL,
    },
  ],
  image_file_type: [
    {
      required: true,
      trigger: 'change',
    },
  ],
}

const data_list = reactive({
  name: '',
  description: '',
  data_volume_url: '',
  image_file_type: 'iso',
});


watch(() => data_list.data_volume_url, function (newVal) {
  logDebug(`watch data_list.data_volume_url, newVal: ${newVal}`);
  if (`${newVal}`.toLowerCase().endsWith(".img")) {
    data_list.image_file_type = 'img'
  }
  if (`${newVal}`.toLowerCase().endsWith(".iso")) {
    data_list.image_file_type = 'iso'
  }
})

const confirmFormCreate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormCreate')
        data_list.data_volume_url = `${data_list.data_volume_url}`.trim()
        jsonRPC({
          url: `/api/make/vm/image/create`,
          params: {
            'name': data_list.name,
            'description': data_list.description,
            'data_volume_url': data_list.data_volume_url,
            'image_file_type': data_list.image_file_type,
          },
          success(res) {
            const data = getResponseData(res);
            logDebug(`创建虚拟机镜像成功`, data);
            routerReplace(router, `/console/image/vm/${data.id}`)
          },
          fail(error) {
            logError(`创建虚拟机镜像失败`, error);
            const errMsg = getFailedMessage(error)
            if (`${errMsg}`.includes('虚拟机镜像已经存在')) {
              message.error('虚拟机镜像已经存在，请直接使用同名仓库虚拟机镜像', 3)
              setTimeout(function () {
                routerReplace(router, `/console/image/vm`)
              }, 3000)
            }
          },
        })
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const confirmFormUpdate = () => {
  formRef.value.validate()
      .then(function () {
        logDebug('confirmFormUpdate')
        data_list.data_volume_url = `${data_list.data_volume_url}`.trim()
        jsonRPC({
          url: `/api/make/image/update`,
          params: {
            'name': data_list.name,
            'description': data_list.description,
            'data_volume_url': data_list.data_volume_url,
            'image_file_type': data_list.image_file_type,
          },
          success(res) {
            logDebug(`更新虚拟机镜像成功`, res.data);
          },
          fail(error) {
            logError(`更新虚拟机镜像失败`, error);
          },
        })
      })
      .catch(error => {
        logError('error', error);
        return
      });
};

const cancelForm = () => {
  routerBack(router)
};
</script>

<style scoped lang="scss">

</style>