<template>
  <div class="container">
    <CloseOutlined style="position: absolute;top: 20px;right: 20px;font-size: 20px;cursor: pointer;" @click="goBack"/>
    <a-card class="main-card">
      <div class="header">
        <a-button
            class="back-button"
            type="primary"
            shape="circle"
            @click="goBack"
        >
          <template #icon>
            <left-outlined/>
          </template>
        </a-button>
        <img src="@/assets/icon/bucket.png" alt="bucket" class="header-icon"/>
        <h2 class="title">文件转存</h2>
      </div>

      <div class="file-list">
        <div v-for="record in sourceFile_list.records" :key="record" class="file-item">
          <div class="file-info">
            <span>分享人: {{ record.user_name }}</span>
            <a-divider type="vertical"/>
            <span>访问次数: {{ record.access_count }}</span>
            <a-divider type="vertical"/>
            <span>过期时间: {{
                record.share_type === 'permanent' ? '长期有效' : formatExpirationDate(record.expiration_date)
              }}</span>
          </div>
        </div>
        <a-card v-for="item in sourceFile_list.file_data" :key="item" class="file-item">
          <div class="file-content">
            <div class="file-details">
              <div class="file-info-row">
                <div class="file-info-text">
                  <p>文件名称: {{ item.file_name }}</p>
                  <p>文件大小: {{ formatFileSize(item.file_size) }}</p>
                </div>
                <div class="action-buttons">
                  <a-button type="primary" @click="downloadFile(item)">
                    <template #icon><CloudDownloadOutlined /></template>
                    下载
                  </a-button>
                  <a-button type="primary" @click="thunderDownload(item)">
                    <template #icon><ThunderboltOutlined /></template>
                    迅雷下载
                  </a-button>
                  <a-button type="primary" @click="copyBucketModel(item)">
                    <template #icon><CopyOutlined /></template>
                    转存
                  </a-button>
                </div>
              </div>
              <div class="preview-area">
                <Artplayer v-if="isVideoFile(item.file_type)" 
                  :option="getVideoPlayerOption(item)" 
                  :style="{height: '370px',width: '1024px'}"
                  class="preview-video"
                />
                <a-image v-else-if="isImageFile(item.file_type)" 
                  :src="getFileUrl(item)"
                  :alt="item.file_name"
                  class="preview-image"
                />
                <div v-else class="no-preview">
                  <a-empty description="暂不支持该类型文件预览"/>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </div>

      <!-- 转存对话框 -->
      <a-modal :open="openCopyBucketListModel"
               @cancel="openCopyBucketListModel=false"
               :mask="false"
               width="60%"
               @ok="copyBucket">
        <a-spin :spinning="targetBucket_list.loading" tip="正在执行转存...">
          <template #footer>
            <a-button shape="round" :loading="targetBucket_list.loading" key="back"
                      @click="openCopyBucketListModel=false">取消
            </a-button>
            <a-button shape="round" :loading="targetBucket_list.loading" key="submit" type="primary"
                      @click="copyBucket">转存到此
            </a-button>
          </template>

          <h4>选择目标位置</h4>
          <a-card :tab-list="tabListNoTitle" :active-tab-key="noTitleKey"
                  @tabChange="key => onTabChange(key, 'noTitleKey')">
            <!-- 面包屑导航 -->
            <a-breadcrumb separator=">">
              <a-breadcrumb-item v-if="computedBucketPaths.length===0">
                <div class="ellipsis" :title="record">{{ selectBucketCustomName }}</div>
              </a-breadcrumb-item>
              <template v-else>
                <a-breadcrumb-item @click="query_bucket_detail(selectBucketName)">
                                  <span style="cursor: pointer;" class="ellipsis"
                                        @click="query_bucket_detail(selectBucketName)">{{
                                      selectBucketCustomName
                                    }}</span>
                </a-breadcrumb-item>
                <a-breadcrumb-item v-for="(item, index) in computedBucketPaths" :key="item">
                                  <span v-if="index !== computedBucketPaths.length - 1" style="cursor: pointer;"
                                        @click="query_bucket_detail(selectBucketName, item.path)">{{ item.name }}</span>
                  <span v-else>{{ item.name }}</span>
                </a-breadcrumb-item>
              </template>
            </a-breadcrumb>

            <!-- 文件夹列表 -->
            <div style="width: 100%;height: 100%;">
              <a-list v-if="targetBucket_list.records.length > 0" :data-source="targetBucket_list.records">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <a-list-item-meta>
                      <template #title>
                        <a v-if="item.file_type && item.file_type == 'folder'"
                           @click="query_bucket_detail(item.user_bucket_name,item.file_key)"
                           :title="item.file_key">
                          <img v-if="item.file_type == 'folder'" src="@/assets/icon/folder.png">
                          <img v-else src="/hw_frontend/static/img/BucketFile.png">
                          {{ formatText(item.file_name) }}
                        </a>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
              <a v-else>
                <a-flex vertical align="center" justify="center">
                  <h3>保存到此处</h3>
                </a-flex>
              </a>
            </div>
          </a-card>
        </a-spin>
      </a-modal>
    </a-card>
    <a-modal class="preview-model"
             :open="openOnlinePreviewModel"
             @cancel="openOnlinePreviewModel=false"
             :destroyOnClose="true"
             :mask="false"
             width="60%"
             :footer="null"
             title="在线预览">
      <Artplayer v-if="showVideoPlayer" :option="onlinePreviewPlayerOption" :style="{height: '500px'}"/>
      <a-image v-if="showImagePreview" height="100%" width="100%" :src="onlinePreviewImage"/>
      <div v-if="!showVideoPlayer && !showImagePreview" style="background-color: #ccc;padding: 20px 0 1px;">
        <a-empty description="暂不支持该类型文件预览"/>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {logDebug, logError} from "@/utils/logger";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message, Modal} from "ant-design-vue";
import {LeftOutlined} from '@ant-design/icons-vue'
import Artplayer from "@/components/Artplayer.vue";
import {CopyOutlined, CloseOutlined, CloudDownloadOutlined, ThunderboltOutlined} from '@ant-design/icons-vue'

const route = useRoute()
const router = useRouter()

function formatText(input) {
  // 检查字符串是否以 '/' 结尾，如果是则去掉
  if (typeof input === "string" && input.endsWith("/")) {
    return input.slice(0, -1);
  }
  return input;
}

// 通过 `useRoute` 获取动态参数和查询参数
const id = route.params.id
const fid = route.query.fid
const exp = route.query.exp
const selectBucketCustomName = ref('');
const tabListNoTitle = ref([]);
const key = ref('');
const bucketName = ref('');
const noTitleKey = ref('');
const selectBucketName = ref('');
const onTabChange = (value, type) => {
  selectBucketName.value = value;
  query_bucket_detail(value, '')
  if (type === 'key') {
    key.value = value;
  } else if (type === 'noTitleKey') {
    noTitleKey.value = value;
  }
};
const computedBucketPaths = computed(() => {
  // 如果路径为空或无效，返回空数组
  if (!current_file_path.value || current_file_path.value.trim() === '') {
    return [];
  }

  // 去掉路径末尾的 `/`，然后按 `/` 分割路径
  const modifiedStr = current_file_path.value.endsWith('/') ? current_file_path.value.slice(0, -1) : current_file_path.value;
  const parts = modifiedStr.split('/');

  // 如果路径部分小于 1，返回空数组
  if (parts.length < 1) {
    return [];
  }

  const result = [];
  let prevPath = '';

  // 遍历路径部分并累加
  for (let i = 0; i < parts.length; i++) {
    const currentPart = parts[i];
    prevPath += currentPart + '/';

    // 添加当前路径部分的对象
    result.push({
      name: currentPart,
      path: prevPath
    });
  }

  return result;
});

const targetBucket_list = reactive({
  bucket_list: [],
  records: [],
  loading: false,
  used_sizes: 0,
  available_sizes: 0,
})
const sourceFile_list = reactive({
  records: [],
  file_data: [],
  loading: false,
})
const current_file = ref(null)

const target_file_path = ref('')  // 目标文件路径
const bucket_file_key_list = ref([])  // 转存文件key
const openCopyBucketListModel = ref(false)

const current_file_path = ref('');
const query_bucket_detail = (e = '', path = '') => {
  current_file_path.value = path;
  selectBucketName.value = e
  nextBucketContent();
};
const nextBucketContent = () => {
  logDebug("nextBucketContent按照前缀查询文件夹")
  jsonRPC({
    url: "/api/query/user/oss/bucket/detail",
    params: {
      'user_bucket_name': selectBucketName.value,
      'file_path': current_file_path.value,
    },
    success(res) {
      const data = getResponseData(res);
      selectBucketCustomName.value = data.custom_name;
      logDebug(`nextBucketContent按照前缀查询文件夹`, data);
      if (data.base_url) {
        oss_base_url.value = data.base_url
      }
      if (data.all_list.length > 0) {
        targetBucket_list.records = data.all_list.filter(item => item.file_type === 'folder')
      } else {
        targetBucket_list.records = []
      }
      logDebug(targetBucket_list.records)
    },
    fail(error) {
      logError(`加载数桶据失败`, error);
      message.error(error, 3);
    },
  })
}
// 复制
const copyBucketModel = (record) => {
  logDebug("copyBucketModel")
  current_file.value = record
  bucketName.value = record.user_bucket_name

  copyTargetBucket(current_file.value)
}
const copyTargetBucket = (record) => {
  // 复制到至目标存储桶
  logDebug(record)
  targetBucket_list.loading = true
  jsonRPC({
    url: "/api/query/user/bucket/list",
    success(res) {
      const data = getResponseData(res);
      logDebug("复制到目标存储桶")
      logDebug(data)

      targetBucket_list.bucket_list = data.user_id_bucket_list;
      tabListNoTitle.value = []
      if (data.user_id_bucket_list.length > 0) {
        for (let i = 0; i < data.user_id_bucket_list.length; i++) {
          let current_data = data.user_id_bucket_list[i]
          // 添加当前路径部分的对象
          tabListNoTitle.value.push({
            key: current_data.bucket_name,
            tab: current_data.bucket_custom_name,
          });
        }
        noTitleKey.value = tabListNoTitle.value[0].key
        query_bucket_detail(tabListNoTitle.value[0].key, '')
      }

      logDebug(targetBucket_list.bucket_list)
      targetBucket_list.used_sizes = data.used_sizes;
      targetBucket_list.available_sizes = data.available_sizes;
    },
    fail(error) {
      logError(`获取自定义桶数据失败`, error);
    },
  }).then(() => {
    targetBucket_list.loading = false
    openCopyBucketListModel.value = true
  });
}
const copyBucket = () => {
  if (!current_file.value) {
    message.error('请选择要转存的文件');
    return;
  }

  // 转存至另一个存储桶
  target_file_path.value = current_file_path.value
  bucket_file_key_list.value = [current_file.value.file_key]
  targetBucket_list.loading = true

  jsonRPC({
    url: "/api/oss/bucket/copy/other_bucket",
    params: {
      'bucket_name': bucketName.value,
      'bucket_file_key_list': bucket_file_key_list.value,
      'target_bucket_name': selectBucketName.value,
      'target_file_path': target_file_path.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`转存成功`, data);
      message.success(`转存成功`, 2)
      bucket_file_key_list.value = []
    },
    fail(error) {
      message.error(`转存失败,请稍后再试！`, 2)
      logError(`转存失败`, error);
      bucket_file_key_list.value = []
    },
  }).then(() => {
    openCopyBucketListModel.value = false
    targetBucket_list.loading = false
  })
}

function fetchData() {
  sourceFile_list.loading = true
  jsonRPC({
    url: "/api/oss/share/link/details",
    params: {
      'id': id,
      'fid': fid,
      'exp': exp,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`加载数桶据成功`, data);
      if (data.base_url) {
        oss_base_url.value = data.base_url
      }
      sourceFile_list.records = data.records;
      sourceFile_list.file_data = data.file_data_records;
    },
    fail(error) {
      logDebug(error)
      logError(`加载数桶据失败`, error);
      message.error(error, 3);
    },
  }).then(() => {
    sourceFile_list.loading = false;
  });
}

// 添加文件大小格式化函数
const formatFileSize = (bytes) => {
  if (!bytes || bytes === 0) return '0 B';

  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / Math.pow(k, i)).toFixed(2)} ${units[i]}`;
}

function formatExpirationDate(date) {
  // 实现日期格式化逻辑
  return date; // 临时返回，需要根据实际需求实现
}

const goBack = () => {
  router.back()
}
const oss_base_url = ref('')
const onlinePreviewPlayerOption = ref({
  url: "",
  autoSize: true,
  fullscreen: true,
  fullscreenWeb: true,
  playbackRate: true,
  setting: true,
  aspectRatio: true,
  hotkey: true,
  playsInline: true,
})
const onlinePreviewImage = ref('')
const openOnlinePreviewModel = ref(false)
const OnlinePreviewModelShowContent = reactive({})

// 判断是否为视频文件
const isVideoFile = (fileType) => {
  return videoTypes.includes(fileType);
}

// 判断是否为图片文件
const isImageFile = (fileType) => {
  return imageTypes.includes(fileType);
}

// 获取文件URL
const getFileUrl = (file) => {
  return `https://${oss_base_url.value}/${file.user_bucket_name}/${file.file_key}`;
}

// 获取视频播放器配置
const getVideoPlayerOption = (file) => {
  return {
    url: getFileUrl(file),
    autoSize: true,
    fullscreen: true,
    fullscreenWeb: true,
    playbackRate: true,
    setting: true,
    aspectRatio: true,
    hotkey: true,
    playsInline: true,
  }
}

// 视频文件类型
const videoTypes = [
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/mkv',
  'video/avi',
  'video/mov',
  'video/wmv',
  'video/flv',
  'video/mpeg',
  'video/3gpp',
  'video/3gpp2',
  'video/quicktime',
  'video/x-ms-wmv',
  'video/x-msvideo',
  'video/x-flv',
  'video/x-matroska',
  'video/mp2t'
];

// 图片文件类型
const imageTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
  'image/bmp',
  'image/tiff',
  'image/x-icon',
  'image/vnd.microsoft.icon',
  'image/heic',
  'image/heif',
  'image/avif',
  'image/apng',
  'image/x-windows-bmp',
  'image/x-portable-pixmap',
  'image/x-portable-bitmap',
  'image/x-targa',
  'image/x-tiff',
  'image/jp2',
  'image/jxr'
];

// 添加计算属性
const showVideoPlayer = computed(() => {
  return videoTypes.includes(OnlinePreviewModelShowContent.file_type);
});

const showImagePreview = computed(() => {
  return imageTypes.includes(OnlinePreviewModelShowContent.file_type);
});

// 修改下载函数
const downloadFile = (record) => {
  // 使用 Modal.confirm 替代 message.confirm
  Modal.confirm({
    title: '确认下载',
    content: `是否下载文件：${record.file_name}？`,
    okText: '确认',
    cancelText: '取消',
    onOk() {
      try {
        const bucket_name = record.user_bucket_name;
        const url = `https://${oss_base_url.value}/${bucket_name}/${record.file_key}`
        
        // 创建一个隐藏的 a 标签
        const link = document.createElement('a')
        link.href = url
        link.download = record.file_name || record.file_key.split('/').pop()
        
        // 如果需要添加认证信息，可以通过 URL 参数传递
        if (localStorage.getItem('token')) {
          link.href = `${url}?token=${localStorage.getItem('token')}`
        }
        
        // 触发点击事件
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        
        // 显示下载开始的提示
        message.success('开始下载文件')
        
      } catch (error) {
        logError('下载文件失败:', error)
        message.error('下载文件失败，请重试')
      }
    },
    onCancel() {
      // 用户取消下载
      message.info('已取消下载')
    }
  })
}

// 添加迅雷下载函数
const thunderDownload = (record) => {
  try {
    const bucket_name = record.user_bucket_name;
    const url = `https://${oss_base_url.value}/${bucket_name}/${record.file_key}`;
    
    // 创建迅雷专用链接
    const thunderLink = `thunder://${btoa('AA' + url + 'ZZ')}`;
    
    // 创建并触发链接
    const link = document.createElement('a');
    link.href = thunderLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    message.success('已唤起迅雷下载器');
  } catch (error) {
    logError('唤起迅雷下载失败:', error);
    message.error('唤起迅雷下载失败，请确保已安装迅雷');
  }
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`BucketCopy onMounted. id[${id}]`);
  fetchData()
});
</script>

<style scoped lang="scss">
.container {
  min-height: 100vh;
  min-width: 100vw;
  background: #f5f5f5;
  padding: 40px;
  box-sizing: border-box;
  position: fixed;  // 添加固定定位
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  // 确保覆盖整个视口
}

.main-card {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 24px;
  height: calc(100vh - 80px);  // 减去容器的上下padding
  overflow-y: auto;  // 添加垂直滚动
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  position: relative;
  
  .back-button {
    position: absolute;
    left: 0;
  }
  
  .header-icon {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  .title {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    color: #262626;
  }
}

.file-item {
  margin-bottom: 24px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    border-color: #e6e6e6;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  }
}

.file-info {
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  color: #595959;
  
  span {
    margin-right: 24px;
    font-size: 14px;
  }
}

.file-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  .file-info-text {
    flex: 1;
    min-width: 0;
    padding-right: 24px;
    
    p {
      margin: 0;
      line-height: 1.8;
      color: #262626;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:last-child {
        color: #595959;
        font-size: 14px;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 12px;
    
    .ant-btn {
      min-width: 88px;
    }
  }
}

.preview-area {
  padding: 24px;
  border-top: 1px solid #f0f0f0;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  .preview-image {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
  }

  .preview-video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    overflow-scrolling:touch;
  }

  .no-preview {
    color: #8c8c8c;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .main-card {
    padding: 20px;
  }

  .file-info-row {
    padding: 16px;
    
    .file-info-text {
      padding-right: 16px;
    }
  }
}
</style>