<template>
  <div id="live_page">
    <a-row id="header">
      <a-col id="header-left" :span="8">
        <a-typography-title :level="5">{{ liveName }}</a-typography-title>
      </a-col>
      <a-col id="header-middle" :span="11">
        <a-menu v-model:selectedKeys="selectedSwitches" mode="horizontal" :items="switchItems"
                theme="light" :multiple="false" @click="clickSwitchItem" style="min-width: 56px;"/>
        <a-menu v-model:selectedKeys="selectedLive" mode="horizontal" :items="liveItems"
                theme="light" :multiple="false" @click="clickLiveItem" style="min-width: 56px;"/>
        <a-menu v-model:selectedKeys="selectedEndpoints" mode="horizontal" :items="endpointItems"
                theme="light" :multiple="false" @click="clickEndpointItem" :style="menuStyle"/>
        <component v-for="item in functionItems" :key="item" :is="item['feature']"
                   :primal="item['key']" :data="item['data']" :styleSetting="functionItemStyleSetting"
                   @ok="item['ok']" @cancel="item['cancel']" @refresh="item['refresh']" @customize="item['customize']"/>
      </a-col>
    </a-row>
    <a-row id="body">
      <a-col id="body-content" :span="24">
        <div id="content-left" v-show="selectedSwitches.length>0 || (selectedLive.length>0 && selectedEndpoints.length>0)">
          <div class="upDown" v-if="selectedEndpoints.length>0">
            <component v-for="(item,index) in liveItems" :key="index" v-show="selectedLive[0]===item['key']" 
            :is="item['feature']" :primal="item['key']" :data="item['data']" @refresh="item['refresh']" ref="liveItemRefs"/>
            <component v-for="(item,index) in switchItems" :key="index" v-show="selectedSwitches[0]===item['key']" 
            :is="item['feature']" :primal="item['key']" :data="item['data']" @refresh="item['refresh']" ref="switchItemRefs"/>
          </div>
          <template v-else>
            <component v-for="(item,index) in switchItems" :key="index" v-show="selectedSwitches[0]===item['key']" 
            :is="item['feature']" :primal="item['key']" :data="item['data']" @refresh="item['refresh']" ref="switchItemRefs"/>
          </template>
        </div>
        <div id="content-resizer" v-show="selectedSwitches.length>0 || (selectedLive.length>0 && selectedEndpoints.length>0)" title="宽度调整条">⋮</div>
        <div id="content-right">
          <template v-if="selectedEndpoints.length>0">
            <component v-for="(item,index) in endpointItems" :key="index" v-show="selectedEndpoints[0]===item['key']" 
            :is="item['feature']" :primal="item['key']" :data="item['data']" @refresh="item['refresh']" ref="endpointItemRefs"/>
          </template>
          <template v-else>
            <component v-for="(item,index) in liveItems" :key="index" v-show="selectedLive[0]===item['key']" 
            :is="item['feature']" :primal="item['key']" :data="item['data']" @refresh="item['refresh']" ref="liveItemRefs"/>
          </template>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {h, markRaw, onMounted, ref, watch, onUnmounted} from 'vue';
import {CodeOutlined, DesktopOutlined, PlaySquareOutlined, WechatOutlined,} from '@ant-design/icons-vue';
import {isEmpty, isNotEmpty, isTrue, toFloat, toInt, toPixel} from "@/utils/common_utils";
import LiveDetailApp from "./LiveDetailApp";
import LiveDetailVideo from "./LiveDetailVideo";
import LiveDetailChat from "./LiveDetailChat";
import LiveDetailFunctionFullScreen from "./LiveDetailFunctionFullScreen";
import LiveDetailFunctionRefresh from "./LiveDetailFunctionRefresh";
import LiveDetailFunctionCreateApp from "./LiveDetailFunctionCreateApp";
import {getRouterParam} from "@/utils/router_utils";
import {useRouter} from "vue-router";
import {message} from "ant-design-vue";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import LiveDetailFunctionCreateNote from "@/views/live/LiveDetailFunctionCreateNote.vue";
import LiveDetailFunctionRank from "@/views/live/LiveDetailFunctionRank.vue";

const iconOptions = {
  style: {
    fontSize: '24px',
    marginRight: '-10px',
  }
}

const router = useRouter()
logDebug(`ConsoleLiveForm getRouterParam(router)[${JSON.stringify(getRouterParam(router))}]`)
const liveId = getRouterParam(router, 'live_id')
// http://127.0.0.1:8080/#/terminal/solution/193/inner_service_name/linux
logDebug(`LiveDetail, liveId[${liveId}]`)

const liveName = ref('');

const liveItems = ref([]);
const endpointItems = ref([]);
import { computed } from 'vue';
import {trackUV} from "@/utils/trackPage";
const menuStyle = computed(() => {
  const minWidth = 56 * endpointItems.value.length;
  return {
    minWidth: `${minWidth}px`
  };
});
// 测试下来只有静态ref支持获取component实例
const liveItemRefs = ref(undefined)
const selectedLive = ref([]);
const selectedLiveEvent = ()=>{
  // 当selectedLive区域的选项变化时，需要调用被选中组件的onSelected方法，同时调用未被选中组件的onUnSelected方法。
  if (isNotEmpty(selectedLive.value) && isNotEmpty(liveItemRefs.value)) {
    const selectedKey = selectedLive.value[0]
    for (const endpointItemRef of liveItemRefs.value) {
      if (isNotEmpty(endpointItemRef) && isNotEmpty(endpointItemRef.primal)) {
        if (endpointItemRef.primal === selectedKey) {
          const onSelected = endpointItemRef.onSelected
          if (typeof onSelected === 'function') {
            onSelected()
          }
        } else {
          const onUnSelected = endpointItemRef.onUnSelected
          if (typeof onUnSelected === 'function') {
            onUnSelected()
          }
        }
      }
    }
  }
  // 如果都没有选中，也需要调用onUnSelected，这是所有标签页都被关闭的情况。
  else if (isEmpty(selectedLive.value) && isNotEmpty(liveItemRefs.value)) {
    for (const endpointItemRef of liveItemRefs.value) {
      const onUnSelected = endpointItemRef.onUnSelected
      if (typeof onUnSelected === 'function') {
        onUnSelected()
      }
    }
  }
}
watch(selectedLive, selectedLiveEvent)
const endpointItemRefs = ref(undefined)
const selectedEndpoints = ref([]);
const user_id = ref('');
const selectedEndpointsEvent = ()=>{
  // 当selectedEndpoints区域的选项变化时，需要调用被选中组件的onSelected方法，同时调用未被选中组件的onUnSelected方法。
  if (isNotEmpty(selectedEndpoints.value) && isNotEmpty(endpointItemRefs.value)) {
    const selectedKey = selectedEndpoints.value[0]
    for (const endpointItemRef of endpointItemRefs.value) {
      if (isNotEmpty(endpointItemRef) && isNotEmpty(endpointItemRef.primal)) {
        if (endpointItemRef.primal === selectedKey) {
          const onSelected = endpointItemRef.onSelected
          if (typeof onSelected === 'function') {
            onSelected()
          }
        } else {
          const onUnSelected = endpointItemRef.onUnSelected
          if (typeof onUnSelected === 'function') {
            onUnSelected()
          }
        }
      }
    }
  }
  // 如果都没有选中，也需要调用onUnSelected，这是所有标签页都被关闭的情况。
  else if (isEmpty(selectedEndpoints.value) && isNotEmpty(endpointItemRefs.value)) {
    for (const endpointItemRef of endpointItemRefs.value) {
      const onUnSelected = endpointItemRef.onUnSelected
      if (typeof onUnSelected === 'function') {
        onUnSelected()
      }
    }
  }
}
watch(selectedEndpoints, selectedEndpointsEvent)

const loadLiveDetailSetting = function (success, fail = undefined) {
  return jsonRPC({
    url: "/api/experiment/live/setting",
    params: {
      live_id: liveId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取直播详情配置成功，data[${JSON.stringify(data)}]`)
      user_id.value = data.user_id
      if (typeof success === 'function') {
        success(data)
      }
      return true
    },
    fail(error) {
      logError(`获取直播详情配置失败`, error)
      message.error(`获取直播详情配置失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
      if (typeof fail === 'function') {
        fail(error)
      }
      return false
    }
  })
}

let terminalEndpoints = {}

const getAppEndpoints = function (data) {
  const appEndpoints = []
  if (isNotEmpty(data['inner_service_name'])) {
    for (const item of data['inner_service_name']) {
      const app = {
        key: `app_${item}`,
        icon: () => h(CodeOutlined, iconOptions),
        label: ``,
        title: `实验环境：${item}`,
        feature: markRaw(LiveDetailApp),
        data: {
          'solution_id': data['app_solution_id'],
          'inner_service_name': item,
        },
        type: 'app',
      }
      appEndpoints.push(app)
    }
  }
  return appEndpoints
}

const setEndpointItems = function (data) {
  logDebug(`setEndpointItems, data[${JSON.stringify(data)}]`)
  terminalEndpoints = {}
  terminalEndpoints['live'] = {
    key: 'live',
    icon: () => h(DesktopOutlined, iconOptions),
    label: '',
    title: '直播画面',
    feature: markRaw(LiveDetailVideo),
    type: 'live',
    data: data,
  }

  const lives = []
  const endpoints = []
  const showTerminalsOrder = ['live']
  for (const key of showTerminalsOrder) {
    if (isNotEmpty(terminalEndpoints[key])) {
      lives.push(terminalEndpoints[key])
    }
  }
  const appEndpoints = getAppEndpoints(data)
  for (const app of appEndpoints) {
    endpoints.push(app)
  }
  liveItems.value = lives
  endpointItems.value = endpoints
}
const clickLiveItem = function ({key}) {
  logDebug(`clickLiveItem, key[${key}]`)
};

const switchItems = ref([])
// 测试下来只有静态ref支持获取component实例
const switchItemRefs = ref(undefined)
const selectedSwitches = ref([]);

let terminalSwitches = {}

const setSwitchItems = function (data) {
  logDebug(`setSwitchItems, data[${JSON.stringify(data)}]`)

  terminalSwitches = {}
  terminalSwitches['chat'] = {
    key: 'chat',
    icon: () => h(WechatOutlined, iconOptions),
    label: '',
    title: '聊天',
    feature: markRaw(LiveDetailChat),
    data: data,
  }

  terminalSwitches['live-side'] = {
    key: 'live-side',
    icon: () => h(PlaySquareOutlined, iconOptions),
    label: '',
    title: '并排直播',
    feature: markRaw(LiveDetailVideo),
    data: data,
    disable: selectedEndpoints[0] === 'live',
  }

  const switches = []
  const showTerminalsOrder = ['chat']
  for (const key of showTerminalsOrder) {
    if (isNotEmpty(terminalSwitches[key])) {
      switches.push(terminalSwitches[key])
    }
  }

  switchItems.value = switches;
}

const defaultLeftWidthRatio = ref(0.3)
const defaultRightWidthRatio = ref(0.7)

const recoverContentAreaSize = function () {
  const bodyContent = document.getElementById('body-content')
  const contentLeft = document.getElementById('content-left')
  const contentRight = document.getElementById('content-right')
  const resizerWidth = 10
  if (bodyContent && contentLeft && contentRight) {
    if (isNotEmpty(selectedSwitches.value) || isNotEmpty(selectedEndpoints.value)) {
      contentLeft.style.width = toPixel(defaultLeftWidthRatio.value * bodyContent.clientWidth)
      contentRight.style.width = toPixel(defaultRightWidthRatio.value * bodyContent.clientWidth - resizerWidth);
    } else {
      contentLeft.style.width = toPixel(0);
      contentRight.style.width = toPixel(bodyContent.clientWidth)
    }
  }
}

window.addEventListener('resize', recoverContentAreaSize, true);

watch(selectedSwitches, function () {
  // 当selectedSwitches区域的选项变化时，需要调用被选中组件的onSelected方法，同时调用未被选中组件的onUnSelected方法。
  if (isNotEmpty(selectedSwitches.value) && isNotEmpty(switchItemRefs.value)) {
    const selectedKey = selectedSwitches.value[0]
    for (const switchItemRef of switchItemRefs.value) {
      if (isNotEmpty(switchItemRef) && isNotEmpty(switchItemRef.primal)) {
        if (switchItemRef.primal === selectedKey) {
          const onSelected = switchItemRef.onSelected
          if (typeof onSelected === 'function') {
            onSelected()
          }
        } else {
          const onUnSelected = switchItemRef.onUnSelected
          if (typeof onUnSelected === 'function') {
            onUnSelected()
          }
        }
      }
    }
  }
  // 如果都没有选中，也需要调用onUnSelected，这是所有标签页都被关闭的情况。
  else if (isEmpty(selectedSwitches.value) && isNotEmpty(switchItemRefs.value)) {
    for (const switchItemRef of switchItemRefs.value) {
      const onUnSelected = switchItemRef.onUnSelected
      if (typeof onUnSelected === 'function') {
        onUnSelected()
      }
    }
  }

  // 当selectedSwitches区域有折叠变化时，需要调整content-left和content-right的宽度
  recoverContentAreaSize()

  // 当selectedSwitches区域有折叠变化时，需要通知各个endpointItems进行刷新。
  // 当然，各个endpointItem组件刷不刷新就是他们自己控制的事情了。
  notifyTerminalEndpointsUpdate()
})

const notifyTerminalEndpointsUpdate = function () {
  logDebug('notifyTerminalEndpointsRefresh called')
  if (isNotEmpty(terminalEndpoints)) {
    for (const endpointItemRef of liveItemRefs.value) {
      if (isNotEmpty(endpointItemRef)) {
        const onUpdate = endpointItemRef.onUpdate
        if (typeof onUpdate === 'function') {
          onUpdate()
        }
      }
    }
  }
}

let global_notify_refresh_timer = null
const debounceNotifyTerminalEndpointsUpdate = function () {
  if (global_notify_refresh_timer != null) {
    clearTimeout(global_notify_refresh_timer);
  }
  global_notify_refresh_timer = setTimeout(function () {
    notifyTerminalEndpointsUpdate()
  }, 200)
}

const clickSwitchItem = function ({key}) {
  logDebug(`clickSwitchItem, key[${key}]`)
  if (isNotEmpty(selectedSwitches.value) && `${selectedSwitches.value[0]}` === `${key}`) {
    selectedSwitches.value = []
  } else {
    selectedSwitches.value = [key]
  }
};
const clickEndpointItem = function ({key}) {
  logDebug(`clickEndpointItem, key[${key}]`)
  if (isNotEmpty(selectedEndpoints.value) && `${selectedEndpoints.value[0]}` === `${key}`) {
    selectedEndpoints.value = []
  } else {
    selectedEndpoints.value = [key]
  }
  selectedLive.value = []
  selectedLive.value = [liveItems.value[0].key]
  setTimeout(()=>{
    selectedLiveEvent()
    selectedEndpointsEvent()
  },500)
};

const functionItemStyleSetting = {
  iconStyle: {
    "fontSize": '24px',
  },
  buttonStyle: {
    "height": '32px',
    "width": '32px',
    "display": 'flex',
    "align-items": "center",
    "justify-content": "center",
    "margin-left": "10px",
    "margin-right": "10px",
    "margin-bottom": "8px",
    "color": "#000",
    "background-color": "#fff",
    "box-shadow": "none",
  }
}

const functionItems = ref([])

// setTerminalPointerEvents用于禁止或打开VNC、HTTP等终端iframe响应鼠标事件，
// 这样在拖拽调整宽度时，鼠标才不会收到iframe里事件绑定的干扰
const setTerminalPointerEvents = function (enablePointerEvents = true) {
  logDebug(`setTerminalPointerEvents called, enablePointerEvents[${enablePointerEvents}]`)
  if (isNotEmpty(selectedEndpoints.value)) {
    for (const endpointItemRef of endpointItemRefs.value) {
      if (isNotEmpty(endpointItemRef)) {
        const setPointerEvents = endpointItemRef.setPointerEvents
        if (typeof setPointerEvents === 'function') {
          setPointerEvents(enablePointerEvents)
        }
      }
    }
  }
}

const dragResizeContentLeftRightWidth = function () {
  const bodyContent = document.getElementById('body-content')
  const contentResizer = document.getElementById("content-resizer");
  const contentLeft = document.getElementById('content-left')
  const contentRight = document.getElementById('content-right')
  contentResizer.onmousedown = function (mouseDownEvent) {
    // 暂时禁止VNC、HTTP等终端iframe响应鼠标事件，避免拖拽时鼠标收到iframe里绑定事件的干扰
    setTerminalPointerEvents(false)
    // 拖拽区 变色
    contentResizer.style.background = "#818181";
    // 鼠标按下时的宽度位置
    const startWidth = mouseDownEvent.clientX;
    // content-resizer区域宽度方向左边界位置记录到自定义属性startLeftWhenMouseDown上
    contentResizer.startLeftWhenMouseDown = contentResizer.offsetLeft;
    /* 鼠标拖拽 */
    document.onmousemove = function (mouseMoveEvent) {
      // contentWidth 是 body-content 区域的宽度
      const contentWidth = bodyContent.clientWidth
      logDebug(`contentWidth = ${contentWidth}`)
      const minLeftWidth = toInt(contentWidth * 0.2)
      const maxLeftWidth = toInt(contentWidth * 0.8)
      const resizerWidth = 10
      // endWidth是鼠标移动后宽度方向的位置，所以 endWidth - startWidth 表示鼠标在宽度方向移动的距离
      const endWidth = mouseMoveEvent.clientX;
      // leftWidth表示content-resizer区域在宽度方向上移动后的左边界位置，所以，同时leftWidth也是content-left区域的宽度。
      let leftWidth = contentResizer.startLeftWhenMouseDown + (endWidth - startWidth)
      /* 调整content-left区域最小宽度 */
      if (leftWidth < minLeftWidth) {
        leftWidth = minLeftWidth
      }
      /* 调整content-left区域最大宽度 */
      if (leftWidth > maxLeftWidth) {
        leftWidth = maxLeftWidth
      }
      // 设置content-resizer区域在宽度方向的左边界位置
      contentResizer.style.left = toPixel(leftWidth);
      // 设置content-left区域在宽度方向的宽度
      contentLeft.style.width = toPixel(leftWidth);
      // 设置右边宽度
      contentRight.style.width = toPixel(contentWidth - leftWidth - resizerWidth);

      debounceNotifyTerminalEndpointsUpdate()
    }
    /* 鼠标松开 */
    document.onmouseup = function () {
      // 取消事件
      document.onmousemove = null;
      document.onmouseup = null;
      // 恢复颜色
      contentResizer.style.background = "blue";
      // 记录body-content区域、content-left区域和content-right区域当前宽度
      let leftWidthRatio = toFloat(
          contentLeft.style.width.replace('px', '')) / bodyContent.clientWidth
      if (leftWidthRatio < 0.2) {
        leftWidthRatio = 0.2
      }
      if (leftWidthRatio > 0.8) {
        leftWidthRatio = 0.8
      }
      defaultLeftWidthRatio.value = leftWidthRatio
      defaultRightWidthRatio.value = 1 - leftWidthRatio
      // 重新允许VNC、HTTP等终端iframe响应鼠标事件
      setTerminalPointerEvents(true)
    }
  }
}

const setFunctionItems = function (data) {
  logDebug(data)

  // 根据后端传来的数据，决定右上角展示哪些功能按钮
  const enableFunctionItems = []
  enableFunctionItems.push({
    key: 'fullscreen',
    label: '全屏',
    title: '全屏',
    feature: markRaw(LiveDetailFunctionFullScreen),
    data: {
      'arg001': '可选，示例参数'
    },
    ok: function (args) {
      logDebug('可选，回调函数ok示例', args)
    },
    cancel: function (args) {
      logDebug('可选，回调函数cancel示例', args)
    },
    refresh: function (args) {
      logDebug('可选，回调函数refresh示例', args)
    },
    customize: function (args) {
      logDebug('可选，回调函数customize示例', args)
    }
  })
  enableFunctionItems.push({
    key: 'refresh',
    label: '刷新',
    title: '刷新',
    feature: markRaw(LiveDetailFunctionRefresh),
  })

  if (isNotEmpty(data['training_app_id'])) {
    enableFunctionItems.push({
      key: 'create-app',
      label: '创建实验环境',
      title: '创建实验环境',
      feature: markRaw(LiveDetailFunctionCreateApp),
      data: {
        'live_id': liveId,
      },
      refresh: function (args) {
        logDebug('实验环境列表refresh事件', args)
        // 延迟 delayMS 毫秒后再更新菜单，避免同时改动子组件和父组件的布局，造成Vue报Resizer错误
        const delayMS = 800
        const timeout = setTimeout(function () {
          loadLiveDetailSetting(function (data) {
            const appEndpoints = getAppEndpoints(data)
            const newAppEndpoints = []
            for (const newAppEndpoint of appEndpoints) {
              let found = null
              for (const oldHttpEndpoint of endpointItems.value) {
                if (newAppEndpoint.key === oldHttpEndpoint.key) {
                  found = oldHttpEndpoint
                  break
                }
              }
              if (found !== null) {
                newAppEndpoints.push(found)
              } else {
                newAppEndpoints.push(newAppEndpoint)
              }
            }
            const endpoints = []
            for (const item of endpointItems.value) {
              if (item.type !== 'app') {
                endpoints.push(item)
              }
            }
            for (const item of newAppEndpoints) {
              endpoints.push(item)
            }
            endpointItems.value = endpoints
            clearTimeout(timeout)
            functionItems.value = functionItems.value.filter(item => item.key !== 'create-app');
          })
        }, delayMS)
      },
    })
  }

  enableFunctionItems.push({
    key: 'create-note',
    label: '创建笔记',
    title: '创建笔记',
    feature: markRaw(LiveDetailFunctionCreateNote),
    data: {
      'live_id': liveId,
    },
  })

  data['enable_show_rank'] = true
  if (isNotEmpty(data['enable_show_rank'])) {
    enableFunctionItems.push({
      key: 'show-rank',
      label: '查看排名',
      title: '查看排名',
      feature: markRaw(LiveDetailFunctionRank),
      data: {
        'live_id': liveId,
      },
    })
  }

  const enableFunctionItemMap = {}
  for (const item of enableFunctionItems) {
    enableFunctionItemMap[item.key] = item
  }

  // 按照 enableFunctionItemOrder 的顺序显示右上角的功能按钮
  let enableFunctionItemOrder = ['create-app','create-note','fullscreen']
  if (endpointItems.value.length>0) {
    enableFunctionItemOrder = ['create-note','fullscreen']
  }
  const functionItemsValue = []
  for (const item of enableFunctionItemOrder) {
    if (isNotEmpty(enableFunctionItemMap[item])) {
      functionItemsValue.push(enableFunctionItemMap[item])
    }
  }
  // 更新functionItems的状态
  functionItems.value = functionItemsValue
}

const setLiveName = function (data) {
  liveName.value = data['head_title'];
}

// 关闭直播页面，发送请求
const handleLiveUserOffline = () => {
  const url = '/api/live/online/user/state';
  const data = {live_id: liveId, user_id:user_id.value, user_state: "offline", from_platform: 'web',}; // 需要发送的数据
  // 将数据转换为查询字符串
  const queryString = Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');
  // 创建一个新的Blob对象，并将其作为请求体发送
  const blob = new Blob([queryString], {type: 'application/x-www-form-urlencoded'});
  navigator.sendBeacon(url, blob);
}
onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`Terminal is now mounted.`)
  dragResizeContentLeftRightWidth();

  const loadTerminalSettingResult = loadLiveDetailSetting(function (data) {
    setLiveName(data)
    setEndpointItems(data)
    setSwitchItems(data)
    setFunctionItems(data)
  })

  loadTerminalSettingResult.then(function (result) {
    logDebug(`getTerminalSettingResult[${result}]`)
    if (isTrue(result)) {
      // 如果设置terminal成功，就将左侧栏的选中菜单设置为第一个endpointItem
      selectedLive.value = [liveItems.value[0].key]
      selectedSwitches.value = [switchItems.value[0].key]
    } else {
      message.error('跳转失败')
    }
  }).then(()=>{
    const livePlayerLive = document.getElementById('live-player-live');
    const videoElement = livePlayerLive ? livePlayerLive.querySelector('video') : null;

    if (videoElement) {
      // 创建按钮元素
      const actionButton = document.createElement('button');
      actionButton.textContent = '画中画';
      actionButton.style.display = 'none';
      actionButton.style.position = 'absolute';
      actionButton.style.bottom = '30px';
      actionButton.style.left = '50%';
      actionButton.style.transform = 'translate(-50%, -50%)';
      actionButton.style.borderRadius = '4px';
      actionButton.style.padding = '0 5px';

      // 将按钮添加到 livePlayerLive 容器中
      livePlayerLive.appendChild(actionButton);

      // 鼠标移入容器时显示按钮
      livePlayerLive.addEventListener('mouseenter', () => {
        actionButton.style.display = 'block';
      });

      // 鼠标移出容器时隐藏按钮
      livePlayerLive.addEventListener('mouseleave', () => {
        actionButton.style.display = 'none';
      });

      // 按钮点击事件
      actionButton.addEventListener('click', () => {
        if (document.pictureInPictureElement) {
          // 退出画中画模式
          document.exitPictureInPicture().catch(error => {
            logError('Failed to exit Picture-in-Picture mode:', error);
          });
          logDebug("关闭画中画");
        } else {
          // 进入画中画模式
          videoElement.requestPictureInPicture().catch(error => {
            logError('Failed to enter Picture-in-Picture mode:', error);
          });
          logDebug("打开画中画");
        }
      });
    }
  })
  window.addEventListener('beforeunload', handleLiveUserOffline);
})

onUnmounted(() => {
  window.removeEventListener('beforeunload', handleLiveUserOffline);
  window.removeEventListener('resize', recoverContentAreaSize, true);
});

// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});
</script>

<style scoped lang="scss">
#live_page{
  position: relative;
  height: calc(100vh - 50px);
  background-color: #fff;
}
#header {
  height: 8%;
  min-height: 60px;
  #header-left {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  #header-middle {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ant-menu {
      border-bottom: none;
    }
  }
}
#body {
  height: 88%;
  #body-content {
    height: 100%;
    display: flex;

    #content-left {
      height: 100%;
      width: 30%;
      .upDown{
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    /*拖拽区div样式*/
    #content-resizer {
      cursor: col-resize;
      background: blue;
      border-radius: 5px;
      margin-top: 40vh;
      width: 10px;
      height: 50px;
      font-size: 32px;
      color: white;
      /*拖拽区鼠标悬停样式*/
      &:hover {
        color: #444;
      }
    }

    #content-right {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
