import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { markRaw } from 'vue'
import { jsonRPC } from '@/utils/http_utils';
import blogStore from '@/stores/blog.js';

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate)

function apiPlugin() {
	// 包括当前应用程序的状态、路由信息、组件实例等
	return { $Http: markRaw(jsonRPC) }//使用markRaw标记为非响应式。即httpAxios 对象不会转为响应式
}

pinia.use(apiPlugin);

export {
	blogStore,
};

export default pinia