<template>
  <div style="height: calc(100vh - 50px);">
    <a-menu v-model:selectedKeys="selectedKeys" mode="horizontal">
      <a-menu-item key="environment">
        <span><router-link to="/console/app/environment">我的应用</router-link></span>
      </a-menu-item>
<!--      <a-menu-item key="template">-->
<!--        <span><router-link to="/console/app/template">我的模板</router-link></span>-->
<!--      </a-menu-item>-->
      <a-menu-item key="namespace">
        <span><router-link to="/console/app/namespace">命名空间</router-link></span>
      </a-menu-item>
    </a-menu>
    <a-layout style="height: calc(100vh - 96px);overflow: auto;">
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script setup>
import {logDebug} from "@/utils/logger";
logDebug('ConsoleApp setup.')
import {onMounted, ref} from "vue";
import {watchRouterSelectedKeys} from "@/utils/router_utils";
import {useRouter} from "vue-router";
const router = useRouter()

const selectedKeys = ref([]);
watchRouterSelectedKeys(router, selectedKeys, {
  'environment': '/console/app/environment',
  'template': '/console/app/template',
  'namespace': '/console/app/namespace',
})

onMounted(() => {
  
})
import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});
</script>

<style scoped>

</style>
