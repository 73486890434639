<template>
  <div class="not-found">
    <div class="error-container">
      <h1 class="error-code">404</h1>
      <p class="error-message">哎呀，页面走丢了~</p>
      <router-link to="/" class="back-home">返回首页</router-link>
    </div>
  </div>
</template>

<script setup>
// 保持原有的生命周期钩子和日志输出
import { logDebug } from "@/utils/logger";
import { onMounted } from "vue";

logDebug("NotFound setup.");

onMounted(() => {
  logDebug("NotFound page mounted.");
});
</script>

<style scoped>
/* 设置清新简洁的背景 */
.not-found {
  position: relative;
  background-color: #f0f8ff; /* 浅蓝色背景 */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
  color: #333; /* 深灰色字体 */
  margin: 0;
  overflow: hidden;
  text-align: center;
}

/* 错误容器，简洁现代 */
.error-container {
  padding: 40px 60px;
  background: #ffffff; /* 白色背景 */
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* 柔和的阴影 */
  animation: zoomIn 0.8s ease-out;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

/* 404数字 */
.error-code {
  font-size: 120px;
  font-weight: bold;
  color: #3498db; /* 蓝色 */
  margin: 0;
  letter-spacing: 8px;
}

/* 错误信息 */
.error-message {
  font-size: 22px;
  color: #7f8c8d; /* 灰色 */
  margin-top: 20px;
}

/* 返回首页按钮 */
.back-home {
  display: inline-block;
  margin-top: 30px;
  padding: 14px 28px;
  font-size: 18px;
  background-color: #2ecc71; /* 绿色 */
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.back-home:hover {
  background-color: #27ae60; /* 暗绿色 */
  transform: translateY(-5px);
}

/* 动画效果 */
@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
