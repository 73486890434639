<template>
  <div class="eduAdmin" ref="selectContainer">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="班级管理">
        <a-table :columns="columns1" :data-source="data_list1" :pagination="pagination1">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex == 'sex'">
              <template v-if="record.sex == 'male'">男</template>
              <template v-if="record.sex == 'female'">女</template>
            </template>
            <template v-if="column.dataIndex == 'operation'">
              <a-button type="link" @click="checkData1(record.id)">查看</a-button>
              <a-button type="link" @click="editData1(record.id)" style="color: #bbb;">编辑</a-button>
            </template>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="学生列表">
        <a-table :columns="columns" :data-source="data_list" :pagination="pagination">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex == 'sex'">
              <template v-if="record.sex == 'male'">男</template>
              <template v-if="record.sex == 'female'">女</template>
            </template>
            <template v-if="column.dataIndex == 'operation'">
              <a-button type="link" @click="checkData(record.id)">查看</a-button>
              <a-button type="link" @click="editData(record.id)" style="color: #bbb;">编辑</a-button>
            </template>
          </template>
        </a-table>
      </a-tab-pane>
      <template #rightExtra>
        <template v-if="activeKey == '1'">
          <div class="search">
            <a-button type="primary" class="mr-3" @click="openModal2 = true">创建班级</a-button>
            <a-input-search placeholder="请输入班级编号或名称" enter-button allowClear @search="onSearch1"/>
          </div>
        </template>
        <template v-if="activeKey == '2'">
          <div class="search">
            <a-input-search placeholder="请输入学生编号或名称" enter-button allowClear @search="onSearch"/>
          </div>
        </template>
      </template>
    </a-tabs>
    <a-modal v-model:open="openModal2" title="创建班级" width="60%" :getContainer="()=>$refs.selectContainer">
      <h4>班级信息</h4>
      <a-form ref="formClassRef" :model="classForm" :label-col="{style: {width: '100px'} }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="[16,16]">
          <a-col :span="24" :md="12">
            <a-form-item label="班级名称" name="name" :rules="[{ required: true, message: '班级名称不能为空!' }]">
              <a-input v-model:value="classForm.name" />
            </a-form-item>
            <a-form-item label="所属院校" name="live_id">
              <a-select v-if="disabled" v-model:value="classForm.school_id" :allowClear="true" disabled
                show-search :options="classForm.school_options" :filter-option="filterOption">
              </a-select>
              <a-select v-else v-model:value="classForm.school_id" :allowClear="true"
                show-search :options="classForm.school_options" :filter-option="filterOption">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="12">
            <a-form-item label="所属院系" name="department_id">
              <a-select v-model:value="classForm.department_id" :allowClear="true" show-search :filter-option="filterOption"
                :options="classForm.department_options.filter(department => department.school_id == classForm.school_id)">
              </a-select>
            </a-form-item>
            <a-form-item label="班主任">
              <a-select v-model:value="classForm.teacher_ids" :allowClear="true" mode="multiple" :max-tag-count="3"
                show-search :options="classForm.teacher_options" :filter-option="filterOption">
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-flex justify="space-between" align="center">
        <h4>班级学生</h4>
        <a-button type="primary" @click="openModal3 = true">添加学生</a-button>
      </a-flex>
      <a-table :columns="columns2" :dataSource="student_list" :pagination="false">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex == 'operation'">
            <a-button type="link" @click="onDelete(record.key)" style="color: #bbb;">移出班级</a-button>
          </template>
        </template>
      </a-table>
      <template #footer>
        <a-flex justify="center" class="my-3">
          <a-button type="primary" @click="confirmAddClass">确定</a-button>
        </a-flex>
      </template>
    </a-modal>
    <a-modal v-model:open="openModal3" title="添加班级学生" width="55%">
      <a-row :gutter="[16,16]" class="mx-0">
        <a-col :span="24" :md="8">
          <a-flex align="center">
            <span style="flex-shrink: 0;">院校:</span>
            <a-select v-model:value="studentSelect.school_id" :allowClear="true" show-search :filter-option="filterOption"
              :options="classForm.school_options" style="width: calc(100% - 31px);">
            </a-select>
          </a-flex>
        </a-col>
        <a-col :span="24" :md="8">
          <a-flex align="center">
            <span style="flex-shrink: 0;">院系:</span>
            <a-select v-model:value="studentSelect.department_id" :allowClear="true" show-search :filter-option="filterOption"
              :options="classForm.department_options.filter(department => department.school_id == studentSelect.school_id)" style="width: calc(100% - 31px);">
            </a-select>
          </a-flex>
        </a-col>
        <a-col :span="24" :md="8">
          <a-flex align="center">
            <span style="flex-shrink: 0;">专业:</span>
            <a-select v-model:value="studentSelect.major_id" :allowClear="true" show-search :filter-option="filterOption"
              :options="classForm.major_options.filter(major => major.department_id == studentSelect.department_id)" style="width: calc(100% - 31px);">
            </a-select>
          </a-flex>
        </a-col>
      </a-row>
      <a-table
        :row-selection="{ selectedRowKeys: classForm.selectedRowKeys, onChange: onSelectChange }"
        :columns="columns3"
        :data-source="filteredStudentOptions"
        :pagination="pagination3"
        :scroll="{ y: 'calc(50vh - 20px)'}"
      >
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex == 'id'">
            <span style="color: #1890ff">ID</span>
          </template>
        </template>
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon><SearchOutlined /></template>
              搜索
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
              重置
            </a-button>
          </div>
        </template>
        <template #customFilterIcon="{ filtered, column }">
          <template v-if="column.dataIndex == 'sex'">
            <FilterFilled />
          </template>
          <template v-else>
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
          </template>
        </template>
        <template #bodyCell="{ text, column }">
          <template v-if="classForm.searchText && classForm.searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${classForm.searchText})|(?=${classForm.searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === classForm.searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >
                {{ fragment }}
              </mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </template>
          <template v-else>
            <template v-if="column.dataIndex == 'name'">
              <div v-html="text"></div>
            </template>
            <template v-else>{{ text }}</template>
          </template>
        </template>
      </a-table>
      <template #footer>
        <span style="margin-right: 8px">
          <template v-if="hasSelected">
          {{ `已选择 ${classForm.selectedRowKeys.length} 项` }}
          </template>
        </span>
        <a-button key="back" @click="openModal3 = false">取消</a-button>
        <a-button key="submit" type="primary" @click="start" :loading="loading" :disabled="!hasSelected">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref, watch, markRaw, computed} from 'vue'
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {logDebug, logError} from "@/utils/logger";
import { SearchOutlined,FilterFilled } from '@ant-design/icons-vue';
import { routerPush } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();
const activeKey = ref('1');
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const openModal2 = ref(false)
const openModal3 = ref(false)
const loading = ref(false)
const formClassRef = ref()
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  classForm.searchText = selectedKeys[0];
  classForm.searchedColumn = dataIndex;
};
const handleReset = clearFilters => {
  clearFilters({
    confirm: true,
  });
  classForm.searchText = '';
};
const searchInput = ref();
const columns2 = ref([
  {
    title: '学号',
    dataIndex: 'student_barcode',
    width: 100,
    sorter: (a, b) => a.student_barcode - b.student_barcode,
  },
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: '性别',
    dataIndex: 'sex',
  },
  {
    title: '手机号',
    dataIndex: 'phone',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 80,
  },
]);
const student_list = ref([]);
const onDelete = key => {
  student_list.value = student_list.value.filter(item => item.key !== key);
};
const onSelectChange = selectedRowKeys => {
  classForm.selectedRowKeys = selectedRowKeys;
};
const columns3 = [
  {
    title: '学号',
    dataIndex: 'student_barcode',
    width: 180,
    customFilterDropdown: true,
    sorter: (a, b) => a.student_barcode - b.student_barcode,
    onFilter: (value, record) => record.student_barcode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
  },
  {
    title: '姓名',
    dataIndex: 'name',
    customFilterDropdown: true,
    onFilter: (value, record) =>record.name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
  },
  {
    title: '性别',
    dataIndex: 'sex',
    filters: [
      {
        text: '男',
        value: '男',
      },
      {
        text: '女',
        value: '女',
      },
    ],
    onFilter: (value, record) => record.sex && record.sex.indexOf(value) === 0
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    customFilterDropdown: true,
    onFilter: (value, record) =>record.phone.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
  },
];
const pagination3 = reactive({
  // 分页配置
  // pageSize: 5, // 每页显示的条数
  showSizeChanger: true, // 是否可以改变每页显示的条数
  pageSizeOptions: ['10', '20', '100'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  // current: 1, // 当前页数
  // total: 0, // 总条数
  // onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})
const hasSelected = computed(() => classForm.selectedRowKeys.length > 0);
const start = () => {
  loading.value = true;
  const data_list_questions = classForm.student_options.filter(element => {
    return classForm.selectedRowKeys.includes(element.id);
  });
  setTimeout(() => {
    student_list.value = [...student_list.value, ...data_list_questions];
    classForm.student_ids = [...new Set([...classForm.student_ids, ...classForm.selectedRowKeys])]; // 去重
    loading.value = false;
    openModal3.value = false;
    classForm.selectedRowKeys = [];
  }, 1000);
};
const classForm = reactive({
  searchText: '',
  searchedColumn: '',
  name: '',
  school_id: null,
  school_options: [],
  department_id: null,
  department_options: [],
  teacher_ids: [],
  teacher_options: [],
  student_options: [],
  student_ids: [],
  selectedRowKeys: [],
});
const studentSelect = reactive({
  school_id: null,
  department_id: null,
  major_id: null,
});
function updateOptions(sourceKey, targetKey, optionsKey, matchKey) {
  watch(() => studentSelect[sourceKey], () => {
    const filteredOptions = classForm[optionsKey].filter(option => option[matchKey] === studentSelect[sourceKey]);
    if (filteredOptions.length == 0) {
      studentSelect[targetKey] = null;
    }
  });
}
updateOptions('school_id', 'department_id', 'department_options', 'school_id');
updateOptions('department_id', 'major_id', 'major_options', 'department_id');
const filteredStudentOptions = computed(() => {
  return classForm.student_options.filter(student => 
    (!studentSelect.school_id || student.school_id === studentSelect.school_id) &&
    (!studentSelect.department_id || student.department_id === studentSelect.department_id) &&
    (!studentSelect.major_id || student.major_id === studentSelect.major_id)
  );
});
const confirmAddClass = ()=>{
  formClassRef.value.validate()
  .then(() => {
    jsonRPC({
      url: `/vue/console/eduAdmin/class/create`,
      params: {
        name: classForm.name,
        school_id: classForm.school_id,
        department_id: classForm.department_id,
        teacher_ids: classForm.teacher_ids,
        student_ids: classForm.student_ids,
      },
      success() {
        fetch1Data()
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    }).then(()=>{
      openModal2.value = false;
      formClassRef.value.resetFields();
    })
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
}
const disabled = ref(false)
onMounted(()=>{
  jsonRPC({
    url: `/vue/console/eduAdmin/setting`,
    params:{},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(classForm, data);
      if (classForm.school_id) {
        disabled.value = true;
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
  fetch1Data()
  fetchData()
});
// 学生列表
const columns = [
  {
    title: '学生编号',
    dataIndex: 'student_barcode',
    align: 'center',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '性别',
    dataIndex: 'sex',
    align: 'center',
  },
  {
    title: '年龄',
    dataIndex: 'age',
    align: 'center',
  },
  {
    title: '所在班级',
    dataIndex: 'class',
    align: 'center',
  },
  {
    title: '考勤信息',
    dataIndex: 'attendance',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const handlePageChange = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page;
  fetchData();
}
const pagination = reactive({
  // 分页配置
  pageSize: 8, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})
const data_list = ref([]);
const search = ref('');
function fetchData() {
  jsonRPC({
    url: `/vue/console/eduAdmin/student`,
    params:{
      page_index: pagination.current,
      page_size: pagination.pageSize,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      data_list.value = data.records;
      pagination.total = data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
const checkData = id=>{
  routerPush(router, `/console/eduAdmin/student/?id=${id}&mode=readonly`)
}
const editData = id=>{
  routerPush(router, `/console/eduAdmin/student/?id=${id}&mode=update`)
}
watch([pagination.current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    pagination.current = 1;
    search.value = searchValue;
    fetchData();
  }
};
// 班级管理
const columns1 = [
  {
    title: '班级编号',
    dataIndex: 'hw_class_number',
    align: 'center',
  },
  {
    title: '班级名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '学生人数',
    dataIndex: 'people_number',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const handlePageChange1 = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination1.current = page;
  fetch1Data();
}
const pagination1 = reactive({
  // 分页配置
  pageSize: 8, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange1), // 页码改变时的回调函数
})
const data_list1 = ref([]);
const search1 = ref('');
const checkData1 = id=>{
  routerPush(router, `/console/eduAdmin/class/?id=${id}&mode=readonly`)
}
const editData1 = id=>{
  routerPush(router, `/console/eduAdmin/class/?id=${id}&mode=update`)
}
function fetch1Data() {
  jsonRPC({
    url: `/vue/console/eduAdmin/class`,
    params:{
      page_index: pagination1.current,
      page_size: pagination1.pageSize,
      search: search1.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      data_list1.value = data.records;
      pagination1.total = data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
watch([pagination1.current], fetch1Data);
const onSearch1 = (searchValue) => {
  if (search1.value != searchValue) {
    pagination1.current = 1;
    search1.value = searchValue;
    fetch1Data();
  }
};


import {trackUV} from "@/utils/trackPage";
// 在页面加载时触发 UV 埋点
onMounted(() => {
  // 调用 trackUV 来统计 UV 数据
  trackUV(router.currentRoute);
});

</script>

<style scoped lang="scss">
.eduAdmin{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  h4{
    display: inline-block;
    font-size: 18px;
    padding-bottom: 5px;
    padding-right: 30px;
    border-bottom: 2px solid #63B1FF;
  }
  .top{
    .menu{
      a{
        color: #C7C7C7;
        font-size: 18px;
        font-weight: bold;
        &:first-child{
          margin-right: 25px;
        }
        &.active{
          color: #2982FF;
        }
      }
    }
  }
}
:deep(.ant-table-wrapper .ant-table-thead >tr>th){
  background-color: #F9FCFF;
}
:deep(.ant-tabs .ant-tabs-tab-btn){
  font-size: 18px;
  font-weight: bold;
  text-shadow: none !important;
}
:deep(.ant-tabs-top >.ant-tabs-nav::before){
  content: none;
}
:deep(.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar){
  display: none;
}
:deep(.ant-form){
  overflow: hidden;
}
</style>
