<template>
  <div class="algorithm">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item><router-link to="/console/eduAdmin">教务管理</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="`/console/eduAdmin/class/?id=${class_id}&mode=readonly`">{{ data_list.class_name }}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="`/console/eduAdmin/class/course?id=${course_id}&class_id=${class_id}`">{{ data_list.course_name }}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>成绩算法</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <h4><b>成绩算法</b></h4>
      <a-form ref="formRef" :model="data_list" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
        <a-form-item label="平时成绩录入方法" name="type_id" :rules="[{ required: true, message: '请选择平时成绩录入方法!' }]">
          <a-select v-model:value="data_list.type_id" :allowClear="true" style="width: 200px"
            show-search :options="data_list.type_ids" :filter-option="filterOption">
          </a-select>
        </a-form-item>
        <a-form-item v-if="data_list.type_id == 'platformComput'" label="平时成绩计算方法">
          <a-flex wrap="wrap" align="center">
            作业平均分：<a-input-number v-model:value="data_list.work_score" :min="1" :max="98"/>% + 
            报告平均分：<a-input-number v-model:value="data_list.report_score" :min="1" :max="98"/>% + 
            签到出勤分：<a-input-number v-model:value="data_list.signIn_score" :min="1" :max="98"/>% 
          </a-flex>
          <span>* 请在框内输入整数数字，并确保三个框内的数字相加等于100</span>
        </a-form-item>
        <a-form-item v-if="data_list.exam_type != 'assessment'" label="学期成绩计算方法">
          <a-flex wrap="wrap" align="center">
            平时成绩：<a-input-number v-model:value="data_list.peacetime_score" :min="1" :max="99"/>% + 
            考试成绩：<a-input-number v-model:value="data_list.exam_score" :min="1" :max="99"/>%
          </a-flex>
          <span>* 请在框内输入整数数字，并确保两个框内的数字相加等于100</span>
        </a-form-item>
        <a-flex v-if="data_list.type_id" justify="center">
          <a-button type="primary" @click="confirmForm">保存</a-button>
        </a-flex>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive} from 'vue'
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {getRouterQuery } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
const router = useRouter();
const course_id = ref(getRouterQuery(router, "course_id"));
const class_id = ref(getRouterQuery(router, "class_id"));

const data_list = reactive({
  class_name: '',
  course_name: '',
  type_ids: [],
  type_id: '',
  exam_type: '',
  work_score: 0,
  report_score: 0,
  signIn_score: 0,
  peacetime_score: 0,
  exam_score: 0,
})
const formRef = ref();
const confirmForm = ()=>{
  formRef.value.validate()
  .then(() => {
    if (data_list.type_id != 'manualInput') {
      if (data_list.peacetime_score + data_list.exam_score != 100) {
        return message.error("学期成绩相加不等于100")
      }
    }
    jsonRPC({
      url: `/vue/console/eduAdmin/class/course/algorithm/keep`,
      params:{
        course_id: course_id.value,
        class_id: class_id.value,
        type_id: data_list.type_id,
        work_score: data_list.work_score,
        report_score: data_list.report_score,
        signIn_score: data_list.signIn_score,
        peacetime_score: data_list.peacetime_score,
        exam_score: data_list.exam_score,
      },
      success() {
        message.success("保存成功")
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    })
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
}
function fetchData() {
  jsonRPC({
    url: `/vue/console/eduAdmin/class/course/algorithm`,
    params:{
      course_id: course_id.value,
      class_id: class_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}
onMounted(fetchData)
</script>

<style scoped lang="scss">
.algorithm{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  .info{
    background-color: #fff;
    padding: 1.5rem;
    h4{
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 30px;
      border-bottom: 2px solid #63B1FF;
    }
    span{
      display: block;
      color: #475AFF;
    }
  }
}
:deep(input:not([type='range'])){
  width: 50px;
}
</style>
