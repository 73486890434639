<template>
  <div id="ppt">
    <a-select
        v-model:value="pptValue"
        :options="pptOptions"
        :size="'large'"
        style="width: 100%; margin: 0; padding: 0"
    />
    <a-space :style="spinStyle">
      <a-typography-text strong>课件正在加载中，请耐心等待几秒钟......</a-typography-text>
      <a-spin class="spin" size="large"/>
    </a-space>

    <div id="ppt-content" :style="pptContentStyle">
      <div v-for="item in pptOptions"
           v-show="`${pptValue}` === `${item.key}`"
           :id="`ppt-editor-container-${item.key}`"
           :key="item.key"
           class="ppt-editor-container">
        <iframe v-show="`${pptValue}` === `${item.key}`" :src="`/api/courseware/${item.key}`"
                :style="iframeStyle" frameborder="0"
                height="100%" width="100%">
          当前浏览器不支持PDF预览，请下载到本地后观看：
          <a :href="`/api/courseware/${item.key}`">下载PDF</a>
        </iframe>
      </div>
    </div>
  </div>
</template>
<script setup>

import {onMounted, ref, watch} from 'vue';
import {logDebug} from "@/utils/logger";
import {isFalse, isTrue} from "@/utils/common_utils";

logDebug('TerminalPPT setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal
const data = props.data


const pptValue = ref();

const pptOptions = ref([]);

const pptContentStyle = ref('display: none')
const spinStyle = ref('display: none')


onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalPPT onMounted.`)
})


const watchHandler = function () {
  logDebug(`pptValue[${pptValue.value}]`)

  const pptId = pptValue.value

  pptContentStyle.value = 'display: none'
  spinStyle.value = 'display: block'

  for (const option of pptOptions.value) {
    if (option.key === pptId) {
      spinStyle.value = 'display: none'
      pptContentStyle.value = 'display: block'
    }
  }
}


watch(pptValue, function () {
  setTimeout(watchHandler, 300)
})

const isFirstSelected = ref(true)

const onSelected = function () {
  logDebug(`TerminalPPT onSelected. data[${JSON.stringify(data)}]`)
  if (isFalse(isFirstSelected.value)) {
    return
  }

  const options = []
  for (const item of data) {
    options.push({
      value: item.id,
      label: `${item.name}`.replace('.pdf', '').replace('.PDF', ''),
      key: item.id,
    })
  }
  pptOptions.value = options
  pptValue.value = pptOptions.value[0].value

  isFirstSelected.value = false
}

const iframeStyle = ref('pointer-events: auto')
const setPointerEvents = function (enablePointerEvents = true) {
  if (isTrue(enablePointerEvents)) {
    iframeStyle.value = 'pointer-events: auto'
  } else {
    iframeStyle.value = 'pointer-events: none'
  }
}

defineExpose({
  primal,
  data,
  onSelected,
  setPointerEvents
})

</script>
<style scoped>
#ppt {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

#ppt-content {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

.ppt-editor-container {
  width: 100%;
  height: v-bind(contentHeight);
}

.spin {
  margin-top: 10vh;
  width: 100%;
  height: 40vh
}
</style>

