<template>
  <div class="container mb-4 app_list">
    <a-row>
      <a-col flex="200px">
        <div class="menu_item search_type">
          <h6 @click="toggle(0)">应用类型<UpOutlined v-if="!data_list.items[0].isOpen" /><DownOutlined v-else /></h6>
          <ul v-show="data_list.items[0].isOpen">
            <li><a :class="{ active: search_type == 'All' }" @click="updateFilters('type', 'All')">全部</a></li>
            <li v-for="item in data_list.search_types" :key="item.value">
              <a :class="{ active: item.value == search_type }" @click="updateFilters('type', item.value)">{{item.label}}</a>
            </li>
          </ul>
        </div>
        <div class="menu_item search_category">
          <h6 @click="toggle(1)">应用类别<UpOutlined v-if="!data_list.items[1].isOpen" /><DownOutlined v-else /></h6>
          <ul v-show="data_list.items[1].isOpen">
            <li><a :class="{ active: search_category == 'All' }" @click="updateFilters('category', 'All')">全部</a></li>
            <li v-for="(item, index) in data_list.search_categorys" :key="item.id">
              <a :class="{ active: item.id == search_category }" @click="updateFilters('category', item.id)">
                {{ item.name }}
                <img v-if="index < 3" src="/hw_web/static/src/img/popular.png" alt="{{ item.name }}">
              </a>
            </li>
          </ul>
        </div>
        <div class="menu_item search_system">
          <h6 @click="toggle(2)">操作系统<UpOutlined v-if="!data_list.items[2].isOpen" /><DownOutlined v-else /></h6>
          <ul v-show="data_list.items[2].isOpen">
            <li><a :class="{ active: search_system == 'All' }" @click="updateFilters('system', 'All')">全部</a></li>
            <li v-for="item in data_list.search_systems" :key="item.value">
              <a :class="{ active: item.value == search_system }" @click="updateFilters('system', item.value)">{{item.label}}</a>
            </li>
          </ul>
        </div>
        <div class="menu_item search_structure">
          <h6 @click="toggle(3)">应用架构<UpOutlined v-if="!data_list.items[3].isOpen" /><DownOutlined v-else /></h6>
          <ul v-show="data_list.items[3].isOpen">
            <li><a :class="{ active: search_structure == 'All' }" @click="updateFilters('structure', 'All')">全部</a></li>
            <li v-for="item in data_list.search_structures" :key="item.value" :title="item.title">
              <a :class="{ active: item.value == search_structure }" @click="updateFilters('structure', item.value)">{{item.label}}</a>
            </li>
          </ul>
        </div>
      </a-col>
      <a-col flex="auto">
        <div class="top">
          <a-radio-group v-model:value="data_list.sort" :options="tagsDataOptions" optionType="button" buttonStyle="solid"/>
          <a-input-search placeholder="请输入应用关键词搜索" enter-button allowClear @search="onSearch" style="width: 300px;"/>
        </div>
        <a-card hoverable v-for="item in data_list.records" :key="item.id" @click="enterDetail(item.id);">
          <div class="info">
            <div class="img">
              <img :src="item.image_512+`?t=${new Date().getTime()}`"/>
            </div>
            <div class="details">
              <div class="details_top">
                <h5 class="wrap-text" :title="item.name">{{ item.name }}</h5>
                <span>{{item.type}}</span>
                <span v-if="item.category_ids.length > 0">{{item.category_ids[0]}}</span>
                <span v-if="item.system">{{item.system}}</span>
                <span v-if="item.structure">{{item.structure}}</span>
              </div>
              <div class="line-clamp-2" v-if="item.description" :title="item.description" v-html="item.description"></div>
            </div>
            <div class="chart" @click="chartClickHandler">
              <Line :data="item.click_ids" :options="chartOptions" :style="{ height: '80px' }" />
            </div>
          </div>
        </a-card>
        <!--分页-->
        <a-flex justify="center" class="mt-4">
          <a-pagination
            :hideOnSinglePage="true"
            v-model:current="current"
            :showSizeChanger="true"
            v-model:page-size="pageSize"
            :total="total"
            :locale="zhCn"
            :pageSizeOptions="pageSizeOptions"
          />
        </a-flex>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
function enterDetail(e) {
  router.push(`/app/${e}`)
}
import {DownOutlined,UpOutlined} from '@ant-design/icons-vue';
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, watch} from "vue";
import {jsonRPC,getResponseData} from "@/utils/http_utils";
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';

// 注册 Chart.js 插件和元素
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);

const chartClickHandler = (event) => {
  event.stopPropagation();  // 阻止事件冒泡
};
const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false  // 隐藏图例
    },
  },
  scales: {
    x: {
      display: false, // 隐藏 x 轴的标签
    },
    y: {
      beginAtZero: true,
      ticks: {
        display: false,  // 隐藏 Y 轴的刻度标签
      },
    }
  }
};
const tagsDataOptions = reactive([
  {
    value: 'hot',
    label: '热门',
  },
  {
    value: 'new',
    label: '上新',
  },
]);
const data_list = reactive({
  items: [
    { isOpen: true },
    { isOpen: true },
    { isOpen: true },
    { isOpen: true },
  ],
  sort: "hot",
  search_types: [
    {label: '容器',value: 'Container',},
    {label: '虚拟机',value: 'VM',},
  ],
  search_categorys: [],
  search_systems: [
    {label: 'Linux',value: 'Linux',},
    // {label: 'Windows',value: 'Windows',},
  ],
  search_structures: [
    {label: 'AMD',value: 'AMD',title: '高级微设备'},
    // {label: 'ARM',value: 'ARM',title: '高级精简指令集计算机'},
  ],
  records: [],
});
const search = ref('');
const search_type = ref('All'); // 应用类型
const search_category = ref('All'); // 应用类别
const search_system = ref('All'); // 操作系统
const search_structure = ref('All'); // 应用架构
const updateFilters = (type, value) => {
  if (type == "type") {
    search_type.value = value;
  } else if (type == "category") {
    search_category.value = value;
  } else if (type == "system") {
    search_system.value = value;
  } else if (type == "structure") {
    search_structure.value = value;
  }
};
const total = ref(0);
const current = ref(1);
const pageSize = ref(30);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
function fetchData() {
  jsonRPC({
    url: "/api/apps_list",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      sort: data_list.sort,
      search: search.value,
      search_type: search_type.value,
      search_category: search_category.value,
      search_system: search_system.value,
      search_structure: search_structure.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.records = data.records;
      data_list.search_categorys = data.search_categorys;
      total.value = data.record_count;
    },
    fail(error) {
      logError(`查询失败app, `, error);
    },
  });
}
onMounted(() => {
  fetchData()
});
watch([pageSize, current, search_type, search_category, search_system, search_structure, () => data_list.sort], fetchData);
function toggle(index) {
  data_list.items[index].isOpen = !data_list.items[index].isOpen;
}
</script>

<style scoped lang="scss">
.app_list{
  padding-top: 20px;
  padding-bottom: 20px;
  .ant-row{
    gap: 20px;
    flex-wrap: nowrap;
    .menu_item{
      padding: 15px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 1px 1px 3px 0px #cdcdcd66;
      margin-bottom: 10px;
      h6{
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;
        >span{
          color: #44abe3;
        }
      }
      ul{
        li{
          margin-bottom: 5px;
          a{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #B4B4B4;
            &.active{
              color: #2983FF;
            }
            &:hover{
              color: #ff8c00;
            }
          }
          img{
            height: 14px;
            width: 14px;
          }
        }
      }
    }
    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .ant-card{
      margin-top: 10px;
      :where(.ant-card-body){
        padding: 10px 20px;
      }
      .info{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 14px;
        .img{
          flex-shrink: 0;
          display: flex;
          align-items: center;
          img{
            height: 80px;
            width: 80px;
          }
        }
        .details{
          flex-grow: 1;
          .details_top{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 10px;
            h5{
              margin-bottom: 0;
            }
            span{
              display: inline-block;
              height: 20px;
              font-size: 14px;
              line-height: 1;
              padding: 3px 10px;
              border-radius: 10px;
              color: #006BFF;
              background-color: #BFE8FF;
              margin-left: 5px;
              white-space: nowrap;
            }
          }
        }
        .chart{
          flex-shrink: 0;
          height: 80px;
        }
      }
    }
  }
}
:where(.ant-radio-group){
  :where(.ant-radio-button-wrapper){
    padding-inline: 26px;
    color: #86B9FF;
    border: 1px solid #86B9FF !important;
    border-radius: 16px !important;
    margin-right: 10px;
    &:not(:first-child)::before{
      content: none
    }
  }
  :where(.ant-radio-button-wrapper-checked){
    background-color: #86B9FF !important;
  }
}
:deep(.ant-btn-primary){
  background-color: #86b9ff;
}
</style>