<template>
  <div class="container training_exam">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/training">证书培训</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/training/${training_id}`">{{ data_list.training_name }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.paper_name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div id="exam_form">
      <div class="border-bottom pb-4">
        <h4 class="text-center">{{ data_list.paper_name }}</h4>
        <template v-if="data_list.submitted">
          <h6 class="text-secondary text-center mt-3">
            考卷总分：{{ data_list.total }} | 
            及格分：{{ data_list.standard_score }} | 
            成绩：{{ data_list.score }} | 
            考试结果：
            <template v-if="data_list.exam_result === '0'">未通过</template>
            <template v-if="data_list.exam_result === '1'">通过</template>
            <template v-if="data_list.exam_result === '2'">待批阅</template>
            <template v-if="data_list.exam_result === '3'">未提交</template>
          </h6>
          <p class="mt-3 text-center text-secondary">
            姓名：{{ data_list.student_name }} | 
            身份证：<template v-if="data_list.identification">{{ data_list.identification }}</template>
          </p>
        </template>
        <template v-else>
          <h6 class="text-secondary text-center mt-3">
            考试时间：{{ data_list.exam_duration }}分钟 | 
            题目：{{ data_list.exam_paper_history_ids.length }}道 | 
            满分：{{ data_list.total }}分 | 
            及格：{{ data_list.standard_score }}分
          </h6>
          <p class="mt-3 text-center text-secondary">
            姓名：{{ data_list.student_name }} | 
            身份证：<template v-if="data_list.identification">{{ data_list.identification }}</template>
          </p>
          <h6 class="text-danger text-center mt-3">
            考试期间请勿关闭本窗口，如不小心关闭，请从证书培训页面重新进入考试。
          </h6>
        </template>
      </div>
      <div
        v-for="(row, rowIndex) in data_list.exam_paper_history_ids"
        :key="rowIndex"
        class="border-bottom py-4"
      >
        <div class="font-weight-bold">
          ({{ rowIndex + 1 }}) 
          <span v-if="row.eq_type === 'radio'">[单选]</span>
          <span v-if="row.eq_type === 'checkbox'">[多选]</span>
          <span v-if="row.eq_type === 'tf'">[判断]</span>
          <span v-if="row.eq_type === 'essay_question'">[问答]</span>
          <span v-if="row.eq_type === 'gapfill'">[填空]</span>
          <span v-if="row.eq_type === 'code'">[编程题]</span>
          {{ row.score }}分
          <div style="color: #17a2b8;" v-html="row.question" />
        </div>

        <div v-if="['radio', 'checkbox', 'tf'].includes(row.eq_type)">
          <div v-for="(option, optionIndex) in data_list.options" :key="optionIndex">
            <label
              v-if="row[option]"
              class="form-check-label d-flex align-items-start"
              :class="{ 'text-muted': data_list.submitted }"
            >
              <input
                :type="row.eq_type"
                :value="option"
                :name="'question_' + rowIndex"
                class="mt-1 mr-1"
                :disabled="data_list.submitted"
                @change="updateAnswer(rowIndex, option, $event)"
              />
              {{ option.toUpperCase() }}、<div v-html="row[option]" />
            </label>
          </div>
        </div>

        <textarea
          v-else-if="['essay_question', 'gapfill'].includes(row.eq_type)"
          class="form-control"
          :name="'question_' + rowIndex"
          rows="4"
          placeholder="请在此处输入答案"
          :readonly="data_list.submitted"
          @input="updateAnswer(rowIndex, $event.target.value)"
        ></textarea>
        <CodeQuestion v-else-if="['code'].includes(row.eq_type)" :disabled="data_list.submitted"
          :exam_id="exam_id" :exam_history_id="row.id" :code_id="row.exam_code_question_id"/>

        <div v-if="data_list.submitted" class="mt-3">
          <template v-if="['radio', 'tf', 'checkbox', 'code'].includes(row.eq_type)">
            <p v-if="['code'].includes(row.eq_type)" class="text-info">
              参考答案：<span v-if="row.solution" v-html="row.solution"/>
            </p>
            <p v-else-if="row.solution.replace(/,|，/g, '') === row.answer" class="text-success">
              回答正确！答案：{{ row.solution?row.solution:'' }}
            </p>
            <p v-else class="text-danger">
              回答错误！您的答案：{{ row.answer }}，正确答案：{{ row.solution?row.solution:'' }}
            </p>
          </template>
          <div v-else class="text-warning">
            判题中！参考答案：<div v-if="row.solution" v-html="row.solution"/>
          </div>
        </div>
      </div>
      <div v-if="!data_list.submitted" class="form-group mt-4 text-center">
        <a-button
          type="primary"
          size="large"
          @click="onSubmit"
          :loading="submitting"
        >
          提交答案
        </a-button>
      </div>
    </div>
    <a-back-top />
  </div>
</template>

<script setup>
import CodeQuestion from "./CodeQuestion.vue";
import { ref, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { jsonRPC, jsonOwlRPC, getResponseData } from "@/utils/http_utils";
import { getRouterParam, routerBack } from "@/utils/router_utils";
import { logDebug, logError } from "@/utils/logger";

// 路由参数
const router = useRouter();
const training_id = ref(getRouterParam(router, "id"));
const paper_id = ref(getRouterParam(router, "exam_id"));
const exam_id = ref();

// 响应式数据
const data_list = reactive({
  training_name: "",
  paper_name: "",
  submitted: false,
  total: 0,
  standard_score: 0,
  score: 0,
  exam_result: "",
  exam_duration: 0,
  exam_paper_history_ids: [],
  student_name: "",
  identification: "",
  options: [],
});

const answers = reactive([]);
const submitting = ref(false);

// 更新答案
const updateAnswer = (rowIndex, value, event = null) => {
  const name = `question_${rowIndex}`;
  const answerIndex = answers.findIndex((a) => a.name === name);

  if (event?.target?.type === "checkbox") {
    if (answerIndex === -1) {
      answers.push({ name, value: [value] });
    } else {
      const currentAnswer = answers[answerIndex].value;
      if (event.target.checked) {
        currentAnswer.push(value);
      } else {
        const valueIndex = currentAnswer.indexOf(value);
        if (valueIndex > -1) currentAnswer.splice(valueIndex, 1);
      }
    }
  } else {
    if (answerIndex === -1) {
      answers.push({ name, value });
    } else {
      answers[answerIndex].value = value;
    }
  }
};

// 提交答案
const onSubmit = () => {
  submitting.value = true;
  const formattedAnswers = data_list.exam_paper_history_ids.map((item, index) => {
    const answerData = answers.find((a) => a.name === `question_${index}`) || {};
    return {
      id: item.id,
      answer: Array.isArray(answerData.value) ? answerData.value.sort().join("") : answerData.value || "",
    };
  });
  logDebug(formattedAnswers);
  jsonOwlRPC({
    url: `/certification/exam/submit`,
    params: { exam_id: exam_id.value, answers: formattedAnswers },
    success(res) {
      logDebug(res);
      fetchData()
      message.success("考试提交成功！");
    },
    fail(error) {
      logError("考试提交失败", error);
      message.error("考试提交失败，请稍后再试");
    },
  }).then(()=>{
    submitting.value = false;
  });
};

// 获取数据
const fetchData = () => {
  jsonOwlRPC({
    url: `/certification/exam_detail`,
    params: { exam_id: exam_id.value },
    success(res) {
      logDebug(res);
      Object.assign(data_list, res);
    },
    fail(error) {
      logError("考试详情加载失败", error);
    },
  });
};

onMounted(()=>{
  jsonRPC({
    url: "/api/training/exam",
    params: { training_id: training_id.value, paper_id: paper_id.value },
    success(res) {
      const data = getResponseData(res);
      exam_id.value = data.exam_id;
      data_list.training_name = data.training_name;
      fetchData()
    },
    fail(error) {
      logError("生成考试失败", error);
      message.error("生成考试失败，请稍后再试");
      routerBack(router);
    },
  });
});
</script>

<style scoped lang="scss">
.training_exam {
  .border-bottom {
    border-bottom: 1px solid #ddd;
  }
  .text-center {
    text-align: center;
  }
  .text-secondary {
    color: #6c757d;
  }
  .text-danger {
    color: #dc3545;
  }
}
#exam_form{
  background-color: #fff;
  padding: 1.5rem;
}
</style>
