<template>
  <div>
    <!-- 触发模态框的按钮 -->
    <a-button id="function-app-snapshot" :style="props.styleSetting.buttonStyle" type="primary" @click="showAppSnapshotModal">
      <a-tooltip :placement="placement">
        <template #title>应用密码</template>
        <KeyOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
    <!-- 账号密码展示模态框 -->
    <a-modal v-model:visible="visible" title="账号和密码" :footer="null" @cancel="closeModal">
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="账号">
          <a-typography-paragraph :copyable="{ text: app_username }">
            <a-input v-model:value="app_username" :bordered="false" placeholder="账号" readonly style="width: 200px"/>
          </a-typography-paragraph>
        </a-form-item>
        <a-form-item label="密码">
          <a-typography-paragraph :copyable="{ text: app_password }">
            <a-input-password v-model:value="app_password" :bordered="false" placeholder="密码" readonly style="width: 200px"/>
          </a-typography-paragraph>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { KeyOutlined } from '@ant-design/icons-vue';

const placement = ref(window.location.search.includes('desktop') ? 'right' : 'bottom');
const props = defineProps({
  primal: { type: String },
  data: { type: Object },
  styleSetting: { type: Object },
});

const app_username = ref(props.data['app_username']);
const app_password = ref(props.data['app_password']);
const visible = ref(false); // 初始设置模态框为关闭

// 打开模态框
const showAppSnapshotModal = () => {
  visible.value = true;
};

// 关闭模态框
const closeModal = () => {
  visible.value = false;
};
</script>

<style scoped lang="scss">

</style>