<template>
  <div ref="selectContainer" class="report">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/console/eduAdmin">教务管理</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/eduAdmin/class/?id=${class_id}&mode=readonly`">
          {{ data_list.class_name }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="`/console/eduAdmin/class/course?id=${course_id}&class_id=${class_id}`">
          {{ data_list.course_name }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>实验报告</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <a-flex justify="space-between" align="center">
        <h4><b>实验报告</b></h4>
        <div class="menu">
          <a-button type="primary" @click="publish">发布新课题</a-button>
        </div>
      </a-flex>
      <a-table :columns="columns" :dataSource="data_list.records" :pagination="pagination">
        <template #bodyCell="{ column, record, text }">
          <template v-if="column.dataIndex == 'submit'">{{text}}/{{data_list.people_number}}</template>
          <template v-if="column.dataIndex == 'correct'">{{text}}/{{record.submit}}</template>
          <template v-if="column.dataIndex == 'operation'">
            <a v-if="record.submit > 0" href="#" @click.prevent="batch(record.id)">报告打分</a>
            <span v-else>暂无提交</span>
            <!-- <a href="#" @click.prevent="batch(record.id)">导出报告</a> -->
          </template>
        </template>
      </a-table>
    </div>
    <a-modal v-model:open="openModal" title="发布新课题" :footer="null" :getContainer="()=>$refs.selectContainer" width="60%">
      <a-form ref="formRef" :model="reportForm" :label-col="{style: {width: '100px'} }" :wrapper-col="{ span: 23 }">
        <a-row :gutter="[16,16]" class="mx-0">
          <a-col :span="24" :md="12">
            <a-form-item label="课题名称" name="name" :rules="[{ required: true, message: '课题名称不能为空!' }]">
              <a-input v-model:value="reportForm.name" placeholder="请输入课题名称"/>
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="12">
            <a-form-item label="提交截止时间" name="end_datetime" :rules="[{ required: true, message: '报告提交截止时间不能为空!' }]">
              <a-date-picker
                  v-model:value="reportForm.end_datetime"
                  :showTime="showTimeOption"
                  :format="showTimedDateFormat"
                  placeholder="请选择截止时间"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="课题内容" name="reportContent" :rules="descriptionRules">
          <RichEditor :height="301" :editMode="editorMode" v-model="reportForm.reportContent"/>
        </a-form-item>
      </a-form>
      <a-flex justify="center">
        <a-button type="primary" @click="confirmAddReport">确定</a-button>
      </a-flex>
    </a-modal>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, ref, reactive, watch, markRaw} from 'vue'
import {jsonRPC, getResponseData} from "@/utils/http_utils";
import {getRouterQuery} from "@/utils/router_utils";
import {useRouter} from "vue-router";
import RichEditor from "@/components/RichEditor.vue";
import { message } from "ant-design-vue";
import {newShowTimedDateFormat, newShowTimeOption} from "@/utils/time_utils";
const showTimeOption = newShowTimeOption()
const showTimedDateFormat = newShowTimedDateFormat()
const router = useRouter();
const course_id = ref(getRouterQuery(router, "course_id"));
const class_id = ref(getRouterQuery(router, "class_id"));

const reportForm = reactive({
  name: '',
  course_id: '',
  end_datetime: null,
  reportContent: null,
});
const descriptionRules = [
  {
    required: true,
    message: '请输入课题内容',
    validator(_, value) {
      // 如果内容为空或者只是一个空的 <p><br></p> 标签
      const isEmpty = !value || /^<p><br><\/p>$/i.test(value.trim());
      
      if (isEmpty) {
        return Promise.reject('请输入课题内容');
      }
      return Promise.resolve();
    }
  }
];

const editorMode = ref('write')
const openModal = ref(false)
const publish = () => {
  openModal.value = true;
}

const batch = (paper_id) => {
  router.push(`/console/eduAdmin/class/course/report/batch?course_id=${course_id.value}&class_id=${class_id.value}&paper_id=${paper_id}`);
}

const data_list = reactive({
  class_name: '',
  course_name: '',
  people_number: 0,
  type_id: '',
  records: [],
})
const formRef = ref()
// 确定创建课题
function confirmAddReport() {
  if (reportForm.reportContent == '<p><br></p>') {
    return message.warning("请输入课题内容")
  }
  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: `/api/class/course/exercise/report/create`,
      params: {
        belongs_course: course_id.value,
        class_id: class_id.value,
        course_id: course_id.value,
        report_content: reportForm.reportContent,
        title: reportForm.name,
        file_path: "",
        score: 100,
        start_datetime: "",
        end_datetime: reportForm.end_datetime,
        pass_score: 60,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`获取成功需求数据成功`, data);
        fetchData()
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    }).then(()=>{
      openModal.value = false;
      formRef.value.resetFields();
    })
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
}

function fetchData() {
  jsonRPC({
    url: `/api/class/course/exercise/report/list`,
    params: {
      course_id: course_id.value,
      class_id: class_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功需求数据成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  })
}

onMounted(fetchData)
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '实验课题',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '发布时间',
    dataIndex: 'start_datetime',
    align: 'center',
  },
  {
    title: '报告提交截止时间',
    dataIndex: 'end_datetime',
    align: 'center',
  },
  {
    title: '提交情况',
    dataIndex: 'submit',
    align: 'center',
  },
  {
    title: '打分情况',
    dataIndex: 'correct',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
  },
];
const handlePageChange = (page, pageSize) => {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page;
  fetchData();
}
const pagination = reactive({
  // 分页配置
  pageSize: 8, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})
watch([pagination.current], fetchData);
</script>

<style scoped lang="scss">
.report {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;

  .menu {
    button {
      margin-bottom: 10px;
    }
  }

  .info {
    background-color: #fff;
    padding: 1.5rem;

    h4 {
      display: inline-block;
      font-size: 18px;
      padding-bottom: 5px;
      padding-right: 30px;
      border-bottom: 2px solid #63B1FF;
    }

    table {
      text-align: center;

      td {
        height: 46px;
      }
    }
  }
}
</style>
