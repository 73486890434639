// x = null instanceof Object
// false
// x = {}
// {}
// y = x instanceof Object
// true
export function isNullOrUndefined(val) {
    return val === null || typeof val === 'undefined'
}

export function isNotNullOrUndefined(val) {
    return !isNullOrUndefined(val)
}

export function isEmpty(val) {
    return val === null || val === '' || val === false ||
        typeof val === 'undefined' || val.length === 0 || `${val}`.toLowerCase() === 'false';
}

export function isEmptyOrNone(val) {
    return val === null || val === '' || val === false ||
        typeof val === 'undefined' || val.length === 0 || [
            'undefined', 'false', 'none', ''
        ].includes(`${val}`.trim().toLowerCase());
}

export function isNotEmpty(val) {
    return !isEmpty(val);
}

export function isFalse(val) {
    if (isEmpty(val)) {
        return true;
    }
    return val === false;
}

export function isTrue(val) {
    return !isFalse(val);
}

export function toInt(val) {
    return parseInt(`${val}`)
}

export function toPercentInt(val, minValInt = undefined) {
    let result = parseInt(`${100 * val}`)
    if (typeof minValInt !== 'undefined') {
        if (result < minValInt) {
            result = minValInt
        }
    }
    return result
}

export function toPixel(val) {
    return `${toInt(val)}px`
}

export function toFloat(val) {
    return parseFloat(`${val}`)
}

export function toString(val) {
    return `${val}`
}


export function concatUrlPath(url, path) {
    if (isEmpty(path)) {
        return url
    }
    url = `${url}`.trim()
    if (url.endsWith('/')) {
        url = url.substring(0, url.length - 1)
    }
    path = `${path}`.trim()
    if (path.startsWith('/')) {
        path = path.substring(1, path.length)
    }
    return `${url}/${path}`
}

