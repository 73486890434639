<template>
  <div class="container mb-4">
    <div class="menu_168">
      <div>
        <router-link to="/live" class="menu_basic">
          <div><img src="/hw_web/static/src/web/img/live_icon.png"/></div>
          直播
        </router-link>
        <router-link to="/course" class="menu_basic">
          <div><img src="/hw_web/static/src/web/img/training_icon.png"/></div>
          培训
        </router-link>
        <div class="menu_basic">
          <div style="background-color: #A89FFF;">
            <img src="/hw_web/static/src/web/img/app_icon.png"/>
          </div>
          应用
        </div>
        <div class="menu_custom">
          <div class="menu_foundation mb-0">
            <div v-for="item in tagsDataOptions" :key="item.id">
              <a :class="{ active: item.value === search_state }" @click="handleTagChange(item.value)">
                {{ item.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
    </div>
    <div ref="appsList" class="apps_list">
      <!--APPs 列表-->
      <div style="margin-top: 16px; margin-bottom: 16px;">
        <a-row :gutter="[16,16]" v-if="data_list.length > 0">
          <a-col :xs="24" :sm="12" :md="{span: 6}" v-for="item in data_list" :key="item.id">
            <a-card hoverable style="width: 100%;height: 100%" @click="enterDetail(item.id);">
              <div class="info">
                <div class="top">
                  <img :src="item.image_512+`?t=${new Date().getTime()}`"/>
                  <div>
                    <h5 class="line-clamp-1">{{ item.name }}</h5>
                    <div>
                      <span class="line-clamp-1">{{ item.type }}</span>
                      <div class="d-flex text-muted">
                        <div title="环境数量">
                          <img src="/hw_web/static/src/img/case_questions.png"/>
                          <span>{{ item.used_nums }}</span>
                        </div>
                        <div title="查看次数">
                          <img src="/hw_web/static/src/img/case_study.png"/>
                          <span>{{ item.view_nums }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="line-clamp-1" v-if="item.description" :title="item.description" v-html="item.description"></div>
                <!-- <a-flex justify="space-between" align="center" class="mt-auto">
                  <div>CPU：<span class="text-muted">{{ item.cpu_limit }} 核</span></div>
                  <div>内存：<span class="text-muted">{{ item.mem_limit }} GB</span></div>
                  <div>磁盘：<span class="text-muted">{{ item.disk_limit }} GB</span></div>
                </a-flex> -->
              </div>
            </a-card>
          </a-col>
        </a-row>
        <a-empty v-else :image="simpleImage"/>
      </div>
      <!--分页-->
      <a-flex justify="center">
        <a-pagination
          :hideOnSinglePage="true"
          v-model:current="current"
          :showSizeChanger="true"
          v-model:page-size="pageSize"
          :total="total"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
        />
      </a-flex>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
function enterDetail(e) {
  router.push(`/app/${e}`)
}
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, watch} from "vue";
import {jsonRPC,getResponseData} from "@/utils/http_utils";
import {zhCn} from "@/utils/zhCn";
import {Empty} from 'ant-design-vue';
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
const search_state = ref('All');
const search = ref(null);
const data_list = ref([]);
const total = ref(0);
const current = ref(1);
const appsList = ref(null);
const pageSize = ref(80);
const pageSizeOptions = ref(Array.from({length: 4}, (_, index) => (pageSize.value * (index + 1)).toString()));

const tagsDataOptions = reactive([
  {
    label: '全部',
    value: 'All',
  },
  {
    label: '容器',
    value: 'Container',
    color: '#8dff00',
  },
  {
    label: '虚拟机',
    value: 'VM',
    color: '#FFEB90',
  },
  {
    label: '第三方',
    value: 'ThirdParty',
    color: '#A89FFF',
  },
]);

function handleTagChange(e) {
  search_state.value = e;
  current.value = 1;
  fetchData();
}

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};

onMounted(() => {
  fetchData()
});
watch([pageSize, current], fetchData);
function fetchData() {
  jsonRPC({
    url: "/api/app_list",
    params: {
      state: search_state.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.value = data.records;
      total.value = data.record_count;
    },
    fail(error) {
      logError(`查询失败app, `, error);
    },
  });
}
</script>

<style scoped lang="less">
.menu_168 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  > div:first-child {
    display: flex;
    align-items: center;
    .menu_basic {
      text-align: center;
      margin-right: 10px;
      color: #757575;
      div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #d6d6d6;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
    .menu_custom {
      .menu_foundation,
      .menu_append {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a {
          display: inline-block;
          border: 1px solid #d3d3d3;
          border-radius: 15px;
          color: #a9a9a9;
          line-height: 28px;
          padding: 0 15px;
          margin-right: 10px;
          margin-bottom: 10px;
          &.active {
            color: #4e95e0;
            background-color: #e6e6e6;
          }
        }
      }
    }
  }
  .ant-input-search {
    flex-shrink: 0;
  }
  .search_bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    input {
      border: none;
      background-color: transparent;
      &:focus-visible {
        outline: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    button {
      font-weight: normal;
      bottom: auto;
      color: #77b7ff;
    }
  }
  & + .home_live {
    .info-special {
      background-color: #fff;
    }
    .info {
      background-color: #fff;
    }
  }
}
:deep(.ant-card){
  display: flex;
  flex-direction: column;
}
:deep(.global-search-wrapper .ant-card .ant-card-body){
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
:deep(.ant-col .ant-card .ant-card-body){
  padding: 0;
  height: 100%;
}
@media (min-width: 768px) {
  :deep(.ant-col-md-4-8){
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.info{
  display: inline-grid;
  height: 100%;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 0px #8c8c8c66;
  .top{
    display: flex;
    gap: 0 10px;            
    >img{
      flex-shrink: 0;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      object-fit: contain;
    }
    >div{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      h5{
        font-size: 16px;
      }
      >div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2px 5px;
        flex-wrap: wrap;
        >span{
          font-size: 12px;
          border-radius: 8px;
          padding: 2px 6px;
          background-color: #9DCBFF;
          color: #fff;
        }
        >div{
          flex-shrink: 0;
          gap: 0 5px;
          span{
            vertical-align: bottom;
            margin-left: 2px;
          }
        }
      }
    }
  }
  >.line-clamp-1{
    height: 21px;
    margin: 5px 0;
    overflow: hidden;
  }
  .text-muted {
    font-size: 14px;
    white-space: nowrap;
    justify-content: flex-end;
    > div {
      display: flex;
      gap: 2px;
      align-items: center;
      img{
        height: 15px;
        width: 15px;
      }
      span {
        // color: #007bff;
        white-space: nowrap;
      }
    }
  }
}
</style>