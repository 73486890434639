<template>
  <div class="console_course_create">
    <div class="top">
      <h4>制作课程<a-radio-group v-model:value="course_state" :options="tagsDataOptions"
        optionType="button" buttonStyle="solid" @change="checked => handleTagChange(checked)"/>
      </h4>
      <div class="search">
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
        <a-button v-if="!is_student" type="primary" @click="createCourse">新建课程</a-button>
      </div>
    </div>
    <a-list item-layout="vertical" size="large" :data-source="data_list">
      <template #renderItem="{ item }">
        <a-card hoverable @click="enterDetail(item.id)" class="item_card">
          <template #cover>
            <div class="info">
              <span v-if="item.course_state == '已发布'" class="publish">已发布</span>
              <span v-else-if="item.course_state == '已归档'" class="done">已归档</span>
              <span v-else>制作中</span>
              <div class="row">
                <div class="col-md-3">
                  <div class="img_box">
                    <img v-if="item.image" :src="`/web/image/hw.course/${item.id}/image_512?t=${new Date().getTime()}`"/>
                    <img v-else src="/hw_web/static/src/img/course_default.png"/>
                  </div>
                </div>
                <div class="col-md-9">
                  <h5>{{ item.name }}</h5>
                  <p v-if="item.english_name">{{ item.english_name }}</p>
                  <div v-if="item.course_nature">
                    课程性质：{{ item.course_nature }}<span class="mx-3"/>
                    适用专业：{{ item.applicable_majors }}
                  </div>
                  <div v-if="(item.weekly_hours || 0) != 0 || (item.credits || 0) != 0">
                    周学时 / 学分：{{item.weekly_hours}}  / {{ item.credits }}
                  </div>
                  <div v-if="item.teaching_materials">
                    使用教材：{{item.teaching_materials}}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </a-card>
      </template>
    </a-list>
    <a-flex justify="center">
      <a-pagination
        v-model:current="current"
        :showSizeChanger="showSizeChanger"
        v-model:page-size="pageSize"
        :total="total"
        :locale="zhCn"
        :pageSizeOptions="pageSizeOptions"
        :hideOnSinglePage="true"
      />
    </a-flex>
    <a-modal v-model:open="open" title="创建课程" width="60vw" @ok="handleOk" :footer='null'>
      <a-form ref="courseFormRef" :model="formState" :label-col="{ span: 8 }" :wrapper-col="{ span: 10 }" @submit="handleOk">
        <a-form-item label="课程名称" name="name" :rules="[{ required: true, message: '请输入课程名称!' }]">
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item label="英文名称" name="english_name">
          <a-input v-model:value="formState.english_name" />
        </a-form-item>
        <a-form-item label="课程性质" name="course_nature" :rules="[{ required: true, message: '请输入课程性质!' }]">
          <a-input v-model:value="formState.course_nature" />
        </a-form-item>
        <a-form-item label="适用专业" name="applicable_majors">
          <a-input v-model:value="formState.applicable_majors" />
        </a-form-item>
        <a-form-item label="使用教材" name="teaching_materials">
          <a-input v-model:value="formState.teaching_materials" />
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
          <a-button type="primary" html-type="submit" :loading="loading">开始制作</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import { zhCn } from "@/utils/zhCn";
import {logDebug, logError} from "@/utils/logger";
import { useRouter } from 'vue-router';
import { message } from "ant-design-vue";
const router = useRouter();

const course_state = ref('all');
const tagsDataOptions = reactive([
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '制作中',
    value: 'draft',
  },
  {
    label: '已发布',
    value: 'published',
  },
  {
    label: '已归档',
    value: 'done',
  },
]);
function handleTagChange() {
  let search_value = course_state.value
  if (search_value == 'all') {
    search_state.value = null;
  } else {
    search_state.value = search_value;
  }
  current.value = 1;
  fetchData();
}
const enterDetail = (e)=>{
  router.push(`/console/course/${e}`);
}
const open = ref(false);
const createCourse = () => {
  open.value = true;
};
const courseFormRef = ref();
const formState = reactive({
  name: '',
  english_name: '',
  course_nature: '',
  applicable_majors: '',
  teaching_materials: ''
});
const loading = ref(false)
const handleOk = () => {
  loading.value = true;
  jsonRPC({
    url: "/api/make/course/base/create",
    params: {
      name: formState.name,
      english_name: formState.english_name,
      course_nature: formState.course_nature,
      applicable_majors: formState.applicable_majors,
      teaching_materials: formState.teaching_materials,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      open.value = false;
      courseFormRef.value.resetFields();
      fetchData();
    },
    fail(error) {
      logError(`查询失败, `, error);
      message.error(error)
    },
  }).then(()=>{
    loading.value = false;
  });
};

const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(4);
const current = ref(1);
const search = ref("");
const search_state = ref(null);
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);

function fetchData() {
  jsonRPC({
    url: "/api/make/course/my/list",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
      state: search_state.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      data_list.value = data.records;
      total.value = data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

const is_student = ref(true)
onMounted(()=>{
  fetchData();
  jsonRPC({
    url: `/vue/users/appIcon`,
    success(res) {
      const data = getResponseData(res);
      is_student.value = data.is_student;
    },
    fail(error) {
      logError('判断是学生用户出错', error);
    },
  });
});
watch([pageSize, current], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
</script>

<style scoped lang="scss">
.console_course_create{
  padding: 16px;
  .top{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    h4{
      font-size: 18px;
      margin-bottom: 0;
    }
    .search{
      >button{
        margin-left: 16px;
      }
    }
  }
  .item_card {
    margin-bottom: 15px;
    padding: 15px 20px;
    position: relative;
    overflow: hidden;
    .info{
      img{
        border-radius: 0 !important;
      }
      >span{
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #79AEFF;
        color: #fff;
        line-height: 30px;
        padding: 0 15px;
        border-bottom-left-radius: 8px;
        &.publish{
          background-color: #40E697;
        }
        &.done{
          background-color: #B0B0B0;
        }
      }
    }
  }
}
:where(.ant-radio-group){
  margin-left: 10px;
  :where(.ant-radio-button-wrapper){
    color: #278FFF;
    border: none;
    line-height: 32px;
    border-radius: 16px;
    background-color: #EDEDED;
    margin-left: 10px;
    &:not(:first-child)::before{
      content: none
    }
    &:first-child{
      border-inline-start: none;
      border-start-start-radius: 0; 
      border-end-start-radius: 0;
      border-radius: 16px;
    }
    &:last-child{
      border-start-end-radius: 0;
      border-end-end-radius: 0;
      border-radius: 16px;
    }
  }
}

</style>