<template>
  <div class="container mb-4">
    <div class="menu_168">
      <div>
        <div class="menu_basic">
          <div style="background-color: #68cff3">
            <img src="/hw_web/static/src/web/img/live_icon.png"/>
          </div>
          直播
        </div>
        <router-link to="/course" class="menu_basic">
          <div><img src="/hw_web/static/src/web/img/training_icon.png"/></div>
          培训
        </router-link>
        <router-link to="/app" class="menu_basic">
          <div><img src="/hw_web/static/src/web/img/app_icon.png"/></div>
          应用
        </router-link>
        <div class="menu_custom">
          <div class="menu_foundation mb-0">
            <a :class="{ active: type_id === 0 }" @click="updateFilters('type_id', 0)">全部</a>
            <div v-for="item in data_list.type_ids" :key="item.id">
              <a :class="{ active: item.id === type_id }" @click="updateFilters('type_id', item.id)">
                {{ item.name }}
              </a>
            </div>
          </div>
          <div class="menu_append">
            <a :class="{ active: category_id === 0 }" @click="updateFilters('category_id', 0)">全部</a>
            <div v-for="item in data_list.category_ids" :key="item.id">
              <a :class="{ active: item.id === category_id }" @click="updateFilters('category_id', item.id)">
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
    </div>
    <a-list :grid="{ gutter: 16, column: 4, xs: 1, sm: 2}" :data-source="data_list.records">
      <template #renderItem="{ item }">
        <a-list-item class="px-0">
          <a-card hoverable style="width: 100%;height: 100%" @click="enterDetail(item.id)">
          <template #cover>
          <div class="info">
            <a class="img_box">
              <img v-if="item.has_image" :src="`/web/image/hw.training/${item.id}/image_1920?t=${new Date().getTime()}`"/>
              <img v-else src="/hw_web/static/src/img/live_default.png"/>
            </a>
            <div>
              <h5><a class="line-clamp-1" :title="item.name">{{item.name}}</a></h5>
              <h5>
                <span v-for="category in item.course_category_ids" :key="category.id">
                  {{category.name}}
                </span>
              </h5>
              <p>{{item.start_datetime}}</p>
              <div class="teacher">
                <div class="user">
                  <template v-if="item.teacher_ids.length > 0">
                    <img v-if="item.teacher_ids[0]?.has_image" :src="`/web/image/hw.teacher/${item.teacher_ids[0]?.id}/image_128`"/>
                    <img v-else src="/hw_web/static/src/img/avatar_live.png" />
                    {{item.teacher_ids[0]?.name}}
                  </template>
                </div>
                <div class="stat">
                  <span v-if="data_list.resourcesAllFree || item.list_price == 0" style="color: #39BFED;">免费</span>
                  <span v-else-if="!item.is_join"
                      class="badge badge-pill badge-primary" style="font-size: small;background-color:#0078ff;" >¥  {{item.list_price}}
                  </span>
                </div>
              </div>
            </div>
            <span v-if="item.live_state == 2">直播中</span>
            <span v-else-if="item.live_state == 3" style="background-color: #ffeb90">已结束</span>
            <span v-else style="background-color: #ffeb90">未直播</span>
          </div>
          </template>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
    <a-flex justify="center" class="py-3">
      <a-pagination
        v-model:current="current"
        :showSizeChanger="showSizeChanger"
        v-model:page-size="pageSize"
        :total="total"
        :locale="zhCn"
        :pageSizeOptions="pageSizeOptions"
        :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, watch } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { zhCn } from "@/utils/zhCn";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter } from "vue-router";
const router = useRouter();

// 更新过滤条件
const updateFilters = (type, value) => {
  if (type === "type_id") {
    type_id.value = value;
  } else if (type === "category_id") {
    category_id.value = value;
  }
};
const type_id = ref(0);
const category_id = ref(0);
const search = ref(null);
const data_list = reactive({
  records: [],
  record_count: 0,
  user_blog_num: 0,
  category_ids: [],
  type_ids: [],
});
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
function fetchData() {
  jsonRPC({
    url: `/api/live`,
    params: {
      type_id: type_id.value,
      category_id: category_id.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取在线直播成功`, data);
      Object.assign(data_list, data);
      total.value = data.record_count
    },
    fail(error) {
      logError(`获取在线直播失败`, error);
      message.error(`获取在线直播失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV(router.currentRoute);
});
watch([type_id, category_id, pageSize, current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
function enterDetail(e) {
  router.push(`/live/info/${e}`)
}
</script>

<style scoped lang="scss">
.menu_168 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  > div:first-child {
    display: flex;
    align-items: center;
    .menu_basic {
      text-align: center;
      margin-right: 10px;
      color: #757575;
      div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #d6d6d6;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
    .menu_custom {
      .menu_foundation,
      .menu_append {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a {
          display: inline-block;
          border: 1px solid #d3d3d3;
          border-radius: 15px;
          color: #a9a9a9;
          line-height: 28px;
          padding: 0 15px;
          margin-right: 10px;
          margin-bottom: 10px;
          &.active {
            color: #4e95e0;
            background-color: #e6e6e6;
          }
        }
      }
    }
  }
  .ant-input-search {
    flex-shrink: 0;
  }
  .search_bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    input {
      border: none;
      background-color: transparent;
      &:focus-visible {
        outline: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    button {
      font-weight: normal;
      bottom: auto;
      color: #77b7ff;
    }
  }
  & + .home_live {
    .info-special {
      background-color: #fff;
    }
    .info {
      background-color: #fff;
    }
  }
}
.info {
  height: 100%;
  position: relative;
  box-shadow: 1px 1px 4px 0px #9d9d9d66;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  > div {
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h5 {
      font-size: 16px;
      margin-bottom: 5px;
      a {
        font-weight: bold;
      }
      span {
        display: inline-block;
        color: #52c9ff;
        font-size: 12px;
        border: 1px solid #52c9ff;
        border-radius: 4px;
        padding: 1px 4px;
        margin-right: 5px;
      }
    }
    p {
      color: #a6a6a6;
      margin-bottom: 5px;
    }
    .teacher {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      .user {
        display: flex;
        align-items: center;
        img {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .stat {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 10px;
          border-radius: 0;
        }
      }
    }
  }
  > span {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #90ffa5;
    padding: 0 15px;
    line-height: 30px;
    border-bottom-right-radius: 8px;
  }
}
</style>
