<template>
  <div class="container job_page">
    <div class="row">
      <div class="col-md-8">
        <div class="search">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <button class="nav-link" :class="{ active: activeTab == 'company' }" @click="activeTab = 'company'">
                搜公司
              </button>
            </li>
            <li class="nav-item">
              <button class="nav-link" :class="{ active: activeTab == 'office' }" @click="activeTab = 'office'">
                搜职位
              </button>
            </li>
          </ul>
          <div class="tab-content">
            <div v-show="activeTab == 'company'">
              <input type="text" class="form-control" placeholder="请输入公司名称" v-model="keyCompany" @keyup.enter="searchCompany"/>
              <a-button type="text" @click="searchCompany">搜索</a-button>
            </div>
            <div v-show="activeTab == 'office'">
              <input type="text" class="form-control" placeholder="请输入职位名称" v-model="keyOffice" @keyup.enter="searchOffice"/>
              <a-button type="text" @click="searchOffice">搜索</a-button>
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="row pt-0">
            <div class="col-md-2 pl-0">工作地区</div>
            <div class="col-md-10 p-0">
              <span class="mr-3" :class="{ active: cityId === 0 }" @click="updateFilters('city', 0)">全部</span>
              <span v-for="city in data_list.job_city" :key="city.id" class="mr-3" 
                :class="{ active: city.id === cityId }" @click="updateFilters('city', city.id)">{{ city.name }}</span>
            </div>
          </div>
          <div class="row pb-0">
            <div class="col-md-2 pl-0">岗位职能</div>
            <div class="col-md-10 p-0">
              <span class="mr-3" :class="{ active: categoryId === 0 }" @click="updateFilters('category', 0)">全部</span>
              <span v-for="category in data_list.job_category" :key="category.id" class="mr-3" 
                :class="{ active: category.id === categoryId }" @click="updateFilters('category', category.id)">{{ category.name }}</span>
            </div>
          </div>
        </div>
        <div class="list">
          <a-menu v-model:selectedKeys="menu_current" mode="horizontal" :items="menu_items"/>
          <div class="info" v-for="row in data_list.records" :key="row.id">
            <div>
              <h5>
                <router-link :to="`/job/${row.id}`">{{ row.name }}</router-link>
                <span v-for="skill in row.skill_ids" :key="skill.id">
                  {{ skill.name }}
                </span>
              </h5>
              <router-link :to="`/job/hiring/${row.company_id.id}`">{{ row.company_id.name }}</router-link>
            </div>
            <div>
              <a-flex gap="5">
                <div class="wage">
                  {{ (row.salary_minimum / 10000).toFixed(2) }} - 
                  {{ (row.salary_maximum / 10000).toFixed(2) }} 万
                </div>
                <div v-if="row.city_id">
                  {{ row.city_id }}<template v-if="row.county_id"> - {{ row.county_id }}</template>
                </div>
                <div v-if="row.city_id && row.experience_id">|</div>
                <div v-if="row.experience_id">{{ row.experience_id }}</div>
                <div v-if="row.experience_id && row.education_id">|</div>
                <div v-if="row.city_id && row.education_id && !row.experience_id">|</div>
                <div v-if="row.education_id">{{ row.education_id }}</div>
              </a-flex>
              <a-flex gap="5">
                <div v-if="row.company_id.company_type">
                  <span v-if="row.company_id.company_type === 'central_enterprises'">央企</span>
                  <span v-else-if="row.company_id.company_type === 'state-owned_enterprise'">国企</span>
                  <span v-else-if="row.company_id.company_type === 'listed_company'">上市公司</span>
                  <span v-else-if="row.company_id.company_type === 'private_enterprise'">民营企业</span>
                  <span v-else-if="row.company_id.company_type === 'start_ups'">初创公司</span>
                  <span v-else-if="row.company_id.company_type === 'sino_foreign_joint_venture'">中外合资</span>
                  <span v-else-if="row.company_id.company_type === 'foreign_enterprise'">外企</span>
                </div>
                <div v-if="row.company_id.company_type && row.company_id.company_size">|</div>
                <div v-if="row.company_id.company_size">
                  {{ row.company_id.company_size }}
                </div>
              </a-flex>
            </div>
            <div>
              <div>
                <span v-for="welfare in row.welfare_ids" :key="welfare.id">
                  {{ welfare.name }}
                </span>
              </div>
              <div>
                <span v-for="category in row.company_business" :key="category.id">
                  {{ category.name }}
                </span>
              </div>
            </div>
          </div>
          <a-flex justify="center" class="mt-3">
            <a-pagination
              v-model:current="current"
              :showSizeChanger="showSizeChanger"
              v-model:page-size="pageSize"
              :total="data_list.record_count"
              :locale="zhCn"
              :pageSizeOptions="pageSizeOptions"
              :hideOnSinglePage="true"
            />
          </a-flex>
        </div>
      </div>
      <div class="col-md-4">
        <div class="news">
          <h4>
            就业动态
            <router-link to="/news">全部动态 <DoubleRightOutlined /></router-link>
          </h4>
          <router-link v-if="data_list.job_news" :to="`/news/${data_list.job_news.id}`" class="img-box">
            <img :src="`/web/image/hw.news/${data_list.job_news.id}/image_512`" />
            <div class="text p-3">{{ data_list.job_news.title }}</div>
          </router-link>
          <div v-else class="img-box">
            <img src="/hw_web/static/src/img/job_news.jpg" />
          </div>
        </div>
        <div class="topic">
          <h4>
            面试刷题
            <router-link to="job/exercise">全部题目 <DoubleRightOutlined /></router-link>
          </h4>
          <div v-for="(row, index) in data_list.job_exercises.slice(0, 3)" :key="`popular-${index}`">
            <img src="/hw_web/static/src/img/popular.png">
            <router-link :to="`/job/exercise?paper_id=${row.id}`" class="line-clamp-1">
              {{ row.exercise }}
            </router-link>
            <p style="color: #f00;">{{ row.num }}人次</p>
          </div>
          <div v-for="(row, index) in data_list.job_exercises.slice(3)" :key="`other-${index}`">
            <router-link :to="`/job/exercise?paper_id=${row.id}`" class="line-clamp-1">
              {{ row.exercise }}
            </router-link>
            <p>{{ row.num }}人次</p>
          </div>
          <a-alert v-if="data_list.job_exercises.length==0" message="未有上线的练习。" type="info" show-icon />
        </div>
        <div class="company">
          <h4>热门企业</h4>
          <div v-for="(row,index) in data_list.job_company" :key="index">
            <h5>
              <img v-if="row.is_logo" :src="`/web/image/hw.job.recruit.company/${row.id}/logo`"/>
              <img v-else src="/hw_web/static/src/img/company_logo.png"/>
              <router-link :to="`/job/hiring/${row.id}`">{{ row.name }}</router-link>
            </h5>
            <p>
              <span>
                <template v-if="row.company_type">
                  {{
                    row.company_type === 'central_enterprises'? '央企'
                    : row.company_type === 'state-owned_enterprise'? '国企'
                    : row.company_type === 'listed_company'? '上市公司'
                    : row.company_type === 'private_enterprise'? '民营企业'
                    : row.company_type === 'start_ups'? '初创单位'
                    : row.company_type === 'sino_foreign_joint_venture'? '中外合资'
                    : '外企'
                  }} |
                </template>
                <template v-if="row.company_size">{{ row.company_size }}</template>
              </span>
              <span class="m-0" v-for="(category,i) in row.company_business" :key="category.id">
                  {{ category.name }}<span v-if="i < row.company_business.length - 1"> /</span>
              </span>
            </p>
            <div>
              <p><span>{{ row.job_num }}</span> 个岗位在招</p>
              <p><span class="num">{{ row.job_recruit }}</span> 人次投递</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, watch } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { logDebug, logError } from "@/utils/logger";
import { zhCn } from "@/utils/zhCn";
import { DoubleRightOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter } from "vue-router";
const router = useRouter();

const menu_current = ref(['new']);
const menu_items = ref([
  {
    key: 'new',
    label: '最新发布',
  },
  {
    key: 'salary',
    label: '薪资优先',
  },
])
// 数据状态管理
const activeTab = ref("company");
const cityId = ref(0);
const categoryId = ref(0);
const keyCompany = ref("");
const keyOffice = ref("");
// 更新过滤条件
const updateFilters = (type, value) => {
  if (type === "city") {
    cityId.value = value;
  } else if (type === "category") {
    categoryId.value = value;
  }
};

const data_list = reactive({
  job_city: [],
  job_category: [],
  job_news: null,
  job_exercises: [],
  job_company: [],

  records: [],
  record_count: 0,
});
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
function fetchData() {
  jsonRPC({
    url: "/api/job",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      keyOffice: keyOffice.value,
      keyCompany: keyCompany.value,
      order: menu_current.value[0],
      city_id: cityId.value,
      category_id: categoryId.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取校园招聘数据成功`, data);
      Object.assign(data_list,data)
    },
    fail(error) {
      logError(`获取校园招聘数据失败`, error);
      message.error(`获取校园招聘数据失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  jsonRPC({
    url: "/api/job/base",
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取校园招聘数据成功`, data);
      Object.assign(data_list,data)
    },
    fail(error) {
      logError(`获取校园招聘数据失败`, error);
      message.error(`获取校园招聘数据失败，请稍后再试`);
    },
  });
  fetchData();
  trackUV(router.currentRoute);
});
watch([cityId, categoryId, menu_current, pageSize, current], fetchData);
const searchCompany = ()=>{
  keyOffice.value = '';
  current.value = 1;
  fetchData();
}
const searchOffice = ()=>{
  keyCompany.value = '';
  current.value = 1;
  fetchData();
}
</script>

<style scoped lang="scss">
.job_page {
  margin-top: 20px;
  margin-bottom: 30px;
  > .row {
    > .col-md-8 {
      display: flex;
      flex-direction: column;
      > .search {
        display: flex;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 4px;
        box-shadow: 0px 2px 2px 1px #0006;
        > ul {
          flex-shrink: 0;
          flex-direction: column;
          .nav-link{
            color: #033E80;
            font-weight: bold;
            &.active{
              color: #fff;
              background-color: #409aff;
            }
          }
        }
        > .tab-content {
          width: 100%;
          > div {
            position: relative;
            height: 100%;
            > .form-control {
              height: 100%;
              padding-left: 3rem;
              padding-right: 8rem;
              font-size: 16px;
              border: none;
              color: #000;
              &:focus {
                box-shadow: none;
                border-color: #ced4da;
              }
            }
            button {
              position: absolute;
              top: 50%; // 垂直方向居中
              right: 30px; // 距离右侧 30px
              transform: translateY(-50%); // 垂直方向偏移自身高度的一半，实现真正的居中
              font-size: 16px;
              font-weight: bold;
              color: #0078ff;
            }
          }
        }
      }
      > .menu {
        padding: 15px;
        background-color: #fff;
        margin-bottom: 20px;
        > div {
          padding: 12px 5px;
          margin: 0;
          &:not(:last-child) {
            border-bottom: 1px solid #cbe4ff;
          }
          .col-md-2 {
            line-height: 27px;
          }
          > div {
            > span {
              white-space: nowrap;
              line-height: 27px;
              transition: all 0.2s;
              cursor: pointer;
              &.active {
                padding: 5px 15px;
                background-color: #aae2ff;
                border-radius: 15px;
              }
            }
          }
        }
      }
      > .list {
        flex: 1 0 auto;
        padding: 15px;
        padding-top: 0;
        background-color: #fff;
        .info {
          padding: 15px;
          margin-top: 12px;
          box-shadow: 2px 2px 2px 2px #ccc;
          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            > h5 {
              font-size: 16px;
              margin: 0;
              > a {
                font-size: 16px;
                color: #000;
                &:hover{
                  color: #ff8c00;
                }
              }
              > span {
                background-color: #d9ebff;
                padding: 3px 7px;
                border-radius: 4px;
                margin-bottom: -3px;
                position: relative;
                top: -2px;
                margin-left: 6px;
                font-size: 14px;
              }
            }
            > div {
              > .wage {
                display: inline-block;
                font-size: 18px;
                color: #ff5200;
                margin-right: 10px;
              }
              > span {
                color: #939393;
                white-space: nowrap;
                &:not(:last-child) {
                  margin-right: 1rem;
                }
              }
            }
          }
        }
      }
    }
    > .col-md-4 {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        a{
          font-size: 14px;
          color: #101010;
          &:hover{
            color: #ff8c00;
          }
        }
      }
      > .news {
        padding: 15px;
        background-color: #fff;
        margin-bottom: 20px;
        > div:last-child {
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        }
        .img-box{
          position: relative;
          display: block;
          .text {
            max-height: 100%;
            overflow: hidden;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
          }
        }
      }
      > .topic {
        padding: 15px;
        background-color: #fff;
        margin-bottom: 20px;
        >div{
          display: flex;
          gap: 5px;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          img{
            height: 25px;
            width: 25px;
          }
          a{
            flex-grow: 1;
            color: #101010;
            &:hover{
              color: #ff8c00;
            }
          }
          p {
            margin: 0;
            color: #0093de;
          }
        }
      }
      > .company {
        flex: 1 0 auto;
        padding: 15px;
        background-color: #fff;
        > div {
          margin-top: 10px;
          padding: 10px;
          background-color: #f0f9ff;
          > h5 {
            margin-bottom: 5px;
            line-height: 24px;
            img{
              height: 24px;
              width: 24px;
              border-radius: 50%;
              object-fit: cover;
            }
            > a {
              font-size: 16px;
              color: #000;
              &:hover{
                color: #ff8c00;
              }
            }
          }
          > p {
            margin-bottom: 5px;
            font-size: 0;
            > span {
              font-size: 12px;
              margin-right: 1rem;
            }
          }
          > div {
            > p {
              display: inline-block;
              margin-bottom: 0;
              margin-right: 10px;
              white-space: nowrap;
              > span {
                color: #0078ff;
                &.num {
                  color: #ff5200;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
