import {ref} from "vue";
import {logDebug, logError} from "@/utils/logger";
import { message } from "ant-design-vue";

// 不改变原图片，直接使用文件的原始数据
export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);  // 只获取逗号后的部分，去掉 data:image/jpeg;base64,
        reader.onerror = error => reject(error);

        // 确保 file 是 Blob 类型
        if (file instanceof Blob) {
            reader.readAsDataURL(file);
        } else {
            const blob = new Blob([file]);
            reader.readAsDataURL(blob);
        }
    });
}

export function newPreviewState() {
    return {
        previewVisible: ref(false),
        previewImage: ref(''),
        previewTitle: ref(''),
    }
}

export function newHandlePreview(previewImage, previewVisible, previewTitle) {
    return async file => {
        if (!file.url && !file.preview) {
            // 获取原始文件数据的 Base64 编码
            file.preview = await getBase64(file.originFileObj);
        }
        previewImage.value = file.url || file.preview;
        previewVisible.value = true;
        previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    };
}

export function newCancelPreview(previewVisible, previewTitle) {
    return async () => {
        previewVisible.value = false;
        previewTitle.value = '';
    };
}

export function newUploadDummyRequest() {
    return ({file, onSuccess}) => {
        // 模拟上传，直接调用 onSuccess
        setTimeout(() => {
            onSuccess(file);
        }, 0);
    };
}

export function newWangEditorToolbarConfig() {
    //去掉上传视频
    return {excludeKeys: ["uploadVideo"],}
}

export function newWangEditorConfig() {
    return {
        placeholder: '请在此输入...',
        // 参考 https://blog.csdn.net/taogugu/article/details/124241151
        MENU_CONF: {
            uploadImage: {
                server: '/blog/upload-img', // 上传图片地址
                timeout: 30 * 1000, // 30s
                fieldName: 'custom-fileName',
                meta: {},
                metaWithUrl: true, // 参数拼接到 url 上
                headers: {Accept: 'text/x-json'},
                maxFileSize: 10 * 1024 * 1024, // 10M

                onBeforeUpload(files) {
                    logDebug('onBeforeUpload', files)
                    return files // 返回哪些文件可以上传
                    // return false 会阻止上传
                },
                onProgress(progress) {
                    logDebug('onProgress', progress)
                },
                onSuccess(file, res) {
                    logDebug('onSuccess', file, res)
                },
                onFailed(file, res) {
                    message.error(res.message)
                    logDebug('onFailed', file, res)
                },
                onError(file, err, res) {
                    message.error(err.message)
                    logError('onError', file, err, res)
                },
            },
            codeSelectLang: {
                codeLangs: [
                    {text: 'Bash', value: 'bash'},
                    {text: 'Javascript', value: 'javascript'},
                    {text: 'CSS', value: 'css'},
                    {text: 'HTML', value: 'html'},
                    {text: 'Java', value: 'java'},
                    {text: 'Go', value: 'go'},
                    {text: 'Python', value: 'python'},
                    {text: 'C/C++', value: 'cpp'},
                    {text: 'SQL', value: 'sql'},
                    {text: 'Markdown', value: 'markdown'},
                ]
            }
        }
    }
}