<template>
  <div class="function-create-note">
    <a-button id="function-create-note" type="primary" @click="showNoteListModal"
              :style="props.styleSetting.buttonStyle">
      <a-tooltip>
        <template #title>我的笔记</template>
        <FormOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
    <a-modal v-model:open="openNoteListModal" title="我的笔记" style="width: 70vw" :footer="null" :getContainer="getContainer" >
      <a-flex justify="flex-end">
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
        <a-button type="primary" @click="showCreateNoteModal" style="margin-left: 16px;">新建笔记</a-button>
      </a-flex>
      <a-modal v-model:open="openCreateNoteModal" title="新建笔记" style="width: 60vw;"
               :confirm-loading="confirmCreateNote" @ok="handleOkCreateNoteModal" :getContainer="getContainer">
        <a-form ref="formRef" style="margin-top: 30px;" :model="createNoteForm" autocomplete="off">
          <a-form-item label="笔记标题：" name="title" :rules="[{ required: true, message: '请输入笔记标题！'}]">
            <a-input v-model:value="createNoteForm.title" :maxlength="20" placeholder="请输入笔记标题（必填）！"/>
          </a-form-item>
          <a-form-item label="笔记内容：" name="description" :rules="descriptionRules">
            <RichEditor :height="301" :editMode="editorMode" v-model="createNoteForm.description"/>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-table :columns="noteColumns" :data-source="noteData" :pagination="pagination" style="margin-top: 10px;">
        <template #bodyCell="{ column,index }">
          <template v-if="column.dataIndex == 'action'">
            <a-typography-text style="color: #1677ff;cursor: pointer;" @click="showDrawer(index)">
              查看详情
            </a-typography-text>
          </template>
        </template>
      </a-table>
    </a-modal>
    <!--笔记详情信息-->
    <a-drawer width="640" placement="right" :closable="true" :open="open" @close="onClose" :getContainer="getContainer">
      <template #extra>
        <template v-if="!edit">
          <a-button style="margin-right: 8px" @click="edit = true" type="primary">修改</a-button>
          <a-button style="margin-right: 8px" @click="openDeleteModal = true" danger>删除</a-button>
          <a-button style="margin-right: 8px" @click="onClose">关闭</a-button>
        </template>
        <template v-else>
          <a-button style="margin-right: 8px" @click="onEdit" type="primary">确认修改</a-button>
          <a-button style="margin-right: 8px" @click="onCancel">取消</a-button>
        </template>
      </template>
      <h5>标题：
        <template v-if="!edit">{{ drawerData.title }}</template>
        <a-input v-else v-model:value="drawerData.title"/>
      </h5>
      <h5>创建者：{{ drawerData.creator }}</h5>
      <h5>创建时间：{{ drawerData.create_date }}</h5>
      <h5>内容：</h5>
      <div v-if="!edit" v-html="drawerData.description"></div>
      <RichEditor v-else :height="301" :editMode="editorMode" v-model="drawerData.description"/>
    </a-drawer>
    <a-modal v-model:open="openDeleteModal" title="删除笔记" @ok="onDelete" :getContainer="getContainer">
      <p>确定删除？ 删除ID：{{ drawerData.id }}</p>
    </a-modal>
  </div>
</template>

<script setup>
import {markRaw, onMounted, reactive, ref} from 'vue'
import {FormOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug, logError} from "@/utils/logger";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import RichEditor from "@/components/RichEditor.vue";
import {message} from 'ant-design-vue';

logDebug('LiveDetailFunctionCreateNote setup!')
logComponents([FormOutlined])

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data
const appEnvId = data['live_id']
const editorMode = ref('write')

const openNoteListModal = ref(false);
const handlePageChange = function (page, pageSize) {
  logDebug(`page[${page}], pageSize[${pageSize}]`)
  pagination.current = page
  loadNoteData()
}

const pagination = reactive({
  // 分页配置
  pageSize: 5, // 每页显示的条数
  showSizeChanger: false, // 是否可以改变每页显示的条数
  pageSizeOptions: ['5', '10', '20'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  current: 1, // 当前页数
  total: 0, // 总条数
  onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})

const noteColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '笔记标题',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '学生',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: '提交时间',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: '笔记内容',
    dataIndex: 'action',
    key: 'action',
  },
];
const noteData = ref([]);
const descriptionRules = [
  {
    required: true,
    message: '请输入笔记内容！',
    validator(_, value) {
      // 如果内容为空或者只是一个空的 <p><br></p> 标签
      const isEmpty = !value || /^<p><br><\/p>$/i.test(value.trim());
      
      if (isEmpty) {
        return Promise.reject('请输入笔记内容！');
      }
      return Promise.resolve();
    }
  }
];

let container = null
const getContainer = () => {
  return container
}
onMounted(() => {
  container = document.querySelector('.function-create-note')
})

const loadNoteData = () => {
  const getLiveNoteResult = jsonRPC({
    url: "/api/experiment/live/note",
    params: {
      app_env_id: appEnvId,
      search: search.value,
      page_index: pagination.current,
      page_size: pagination.pageSize,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('loadNoteData', data)
      noteData.value = data.records
      pagination.total = data.record_count
    },
    fail(error) {
      logError(`获取直播笔记列表失败, `, error)
    },
  })

  return getLiveNoteResult.then((res) => {
    logDebug(`getLiveNoteResult, `, res)
  })
}

const showNoteListModal = function () {
  loadNoteData().then(function () {
    openNoteListModal.value = true
  })
}

const edit = ref(false);
const open = ref(false);
const drawerData = ref();
const search = ref("");
const showDrawer = (e) => {
  drawerData.value = noteData.value[e]
  open.value = true;
};
let openDeleteModal = ref(false);
const onDelete = () => {
  openDeleteModal.value = false;
  jsonRPC({
    url: "/api/experiment/live/note/delete",
    params: {
      id: drawerData.value.id,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('onDelete', data)
      message.success('删除直播笔记成功')
      loadNoteData()
    },
    fail(error) {
      logError(`删除直播笔记失败, `, error)
    },
  }).then(() => {
    open.value = false;
  })
};
const onEdit = () => {
  jsonRPC({
    url: "/api/experiment/live/note/edit",
    params: {
      id: drawerData.value.id,
      title: drawerData.value.title,
      description: drawerData.value.description,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('onEdit', data)
      message.success('修改直播笔记成功')
      loadNoteData()
    },
    fail(error) {
      logError(`修改直播笔记失败, `, error)
    },
  }).then(() => {
    edit.value = false;
  })
};
const onCancel = () => {
  edit.value = false;
  open.value = false;
  loadNoteData()
};
const onClose = () => {
  open.value = false;
};
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    pagination.current = 1;
    search.value = searchValue;
    loadNoteData()
  }
};

const openCreateNoteModal = ref(false);
const confirmCreateNote = ref(false);
const formRef = ref();
const createNoteForm = reactive({
  'title': '',
  'description': '',
})
const showCreateNoteModal = function () {
  openCreateNoteModal.value = true
}

const createNote = function () {
  logDebug(`createNote, createNoteForm[${JSON.stringify(createNoteForm)}]`)

  const createNoteResult = jsonRPC({
    url: "/api/experiment/live/note/create",
    params: {
      title: createNoteForm.title,
      description: createNoteForm.description,
      live_id: appEnvId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`createNoteResult, data[${JSON.stringify(data)}]`)
    },
    fail(error) {
      logError(`创建直播笔记失败, `, error)
    },
  })

  return createNoteResult.then(function (res) {
    logDebug(`createNoteResult, `, res)
  })
}

const handleOkCreateNoteModal = () => {
  formRef.value.validate()
      .then(() => {
        confirmCreateNote.value = true
        const createAppSnapshotResult = createNote()
        createAppSnapshotResult.then(function () {
          openCreateNoteModal.value = false;
          confirmCreateNote.value = false
        }).then(function () {
          loadNoteData()
          for (const key in createNoteForm) {
            createNoteForm[key] = ''; // 将每个字段重置为空字符串
          }
        })
      })
      .catch(error => {
        logDebug('error', error);
        return
      });
};

defineExpose({
  primal,
  data,
})
</script>

<style scoped>
#function-create-note {
  background: #00BFFF;
}
</style>