<template>
  <div>
    <a-button id="function-refresh" :style="props.styleSetting.buttonStyle" type="primary"
              @click="clickHandler">
      <a-tooltip :placement="placement">
        <template #title>刷新</template>
        <ReloadOutlined :style="props.styleSetting.iconStyle"/>
      </a-tooltip>
    </a-button>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {ReloadOutlined} from '@ant-design/icons-vue';
import {logComponents, logDebug} from "@/utils/logger";

logComponents([ReloadOutlined])
logDebug('TerminalFunctionRefresh setup!')

const placement = ref(window.location.search.includes('desktop') ? 'right' : 'bottom');
const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const primal = props.primal
const data = props.data

// 组件逻辑
const handleRefresh = function () {
  window.location.reload();
}

const clickHandler = function () {
  logDebug('刷新')
  logDebug(JSON.stringify(props.data))

  handleRefresh()

  // emit("ok", "我是子组件fullscreen传入的ok值"); //触发事件
  // emit("cancel", "我是子组件fullscreen传入的cancel值"); //触发事件
  // emit("refresh", "我是子组件fullscreen传入的refresh值"); //触发事件
  // emit("customize", {
  //   'arg1': '参数1',
  //   'arg2': 2,
  // }) //触发事件
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalFunctionFullScreen onMounted.`)
})

const onSelected = function () {
  logDebug(`TerminalFunctionFullScreen onSelected.`)
}

defineExpose({
  primal,
  data,
  onSelected,
})


</script>

<style scoped>
#function-refresh {
  background: #00BFFF;
}
</style>

