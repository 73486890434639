<template>
  <div class="home">
    <div class="home_banner">
      <!-- 加载Swiper的Pagination(分页)和Autoplay(自动播放)模块 -->
      <!-- 设置每次显示一个滑块 -->
      <!-- 滑块间距为0 -->
      <!-- 启用循环模式，滑动到最后一个时会循环到第一个 -->
      <!-- 启用键盘控制滑动功能 -->
      <!-- 启用可点击的分页，并使用自定义bulletRenderer -->
      <!-- 自动播放，每隔2500ms切换一次，且用户交互后不会停止自动播放 -->
      <swiper
          :modules="[Pagination, Autoplay, Keyboard, EffectFade]"
          :slides-per-view="1"
          :space-between="0"
          :loop="true"
          :effect="'fade'"
          :keyboard="{ enabled: true }"
          :pagination="{ clickable: true, renderBullet: bulletRenderer }"
          :autoplay="{delay: 2500,pauseOnMouseEnter: true}"
      >
        <swiper-slide v-for="(row, index) in data_list.news" :key="index">
          <a v-if="row.external_url" :href="row.external_url" class="img_box_banner">
            <img :src="`/web/image/hw.news/${row.id}/image_1920`"/>
          </a>
          <router-link v-else :to="`/news/${row.id}`" class="img_box_banner">
            <img :src="`/web/image/hw.news/${row.id}/image_1920`"/>
          </router-link>
        </swiper-slide>
        <swiper-slide v-if="data_list.news.length == 0">
          <div class="img_box_banner">
            <img src="/hw_web/static/src/web/img/bg_home.png"/>
          </div>
        </swiper-slide>
      </swiper>
      <div class="row">
        <div class="col-sm-3 col-md-5 col-lg-8"></div>
        <div class="col-sm-9 col-md-7 col-lg-4">
          <div id="scroll-container">
            <ul id="scroll-list">
              <!-- 循环遍历 news 数据 -->
              <li v-for="(row, index) in data_list.news" :key="index">
                <router-link :to="`/news/${row.id}`">
                  <b :title="row.title">{{ row.title }}</b>
                  <span>{{ row.date }}</span>
                </router-link>
              </li>
              <!-- 显示查看更多链接 -->
              <li v-if="data_list.news.length > 3">
                <router-link to="/news" style="color: #007bff">查看更多..</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container home_app" v-if="data_list.app.length>0">
      <h4>应用案例</h4>
      <div class="row">
        <div v-for="row in data_list.app" :key="row.id" class="col-sm-6 col-md-3">
          <div class="info">
            <router-link :to="`/app/${row.id}`" class="img_box1">
              <img v-if="row.app_id" :src="`/web/image/hw.experiment.app/${row.app_id.id}/image_512`"/>
              <img v-else-if="row.user_app_id"
                   :src="`/web/image/hw.experiment.user.app/${row.user_app_id.id}/image_512`"/>
              <img v-else :src="`/web/image/hw.experiment.template/${row.id}/image_512`"/>
            </router-link>
            <div>
              <h5>
                <router-link :to="`/app/${row.id}`" class="line-clamp-1" :title="row.name">
                  <b>{{ row.app_id?.name || row.user_app_id?.name || row.name }}</b>
                </router-link>
                <span>
                  {{ row.type === 'Container' ? '容器' : row.type === 'VM' ? '虚拟机' : '第三方应用' }}
                </span>
              </h5>
              <p class="line-clamp-2" v-if="row.description" :title="row.description">{{ row.description }}</p>
              <div class="d-flex text-muted">
                <div title="环境数量">
                  <img src="/hw_web/static/src/img/case_questions.png"/>
                  <span>{{ row.used_nums }}</span>
                </div>
                <div title="查看次数">
                  <img src="/hw_web/static/src/img/case_study.png"/>
                  <span>{{ row.view_nums }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container home_live" v-if="data_list.training.length>0">
      <h4>推荐直播</h4>
      <div class="row">
        <div v-for="row in data_list.training" :key="row.id" class="col-sm-6 col-md-3">
          <div class="info">
            <router-link :to="`/live/info/${row.id}`" class="img_box">
              <img v-if="row.has_image_512" :src="`/web/image/hw.training/${row.id}/image_512`"/>
              <img v-else src="/hw_web/static/src/img/live_default.png"/>
            </router-link>
            <div>
              <h5>
                <router-link :to="`/live/info/${row.id}`" class="line-clamp-1" :title="row.name">{{ row.name }}</router-link>
              </h5>
              <div class="summary">
                <span v-for="category in row.course_category_ids" :key="category.id">{{ category.name }}</span>
              </div>
              <div class="teacher">
                <div class="user" v-if="row.teacher_ids && row.teacher_ids[0]">
                  <img v-if="row.teacher_ids[0].has_image_512"
                        :src="`/web/image/hw.teacher/${row.teacher_ids[0].id}/image_128`"/>
                  <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
                  {{ row.teacher_ids[0].name }}
                </div>
                <p v-if="row.live_plan_ids[0]">{{ row.live_plan_ids[0].start_time }}</p>
              </div>
            </div>
            <span v-if="row.live_state == 'living'">直播中</span>
            <span v-else-if="item.live_state == 3" style="background-color: #ffeb90">已结束</span>
            <span v-else style="background-color: #ffeb90">未直播</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container home_course" v-if="data_list.course">
      <h4>开班课程</h4>
      <div class="row">
        <div v-for="row in data_list.course" :key="row.id" class="col-sm-6 col-md-3">
          <div class="info">
            <router-link :to="`/course/${row.id}`" class="img_box">
              <img v-if="row.has_image_512" :src="`/web/image/hw.course/${row.id}/image_512`"/>
              <img v-else src="/hw_web/static/src/img/course_default.png"/>
            </router-link>
            <div>
              <h5>
                <router-link :to="`/course/${row.id}`" class="line-clamp-1" :title="row.name">{{ row.name }}</router-link>
              </h5>
              <h5>
                <span v-for="category in row.course_category_ids" :key="category.id">{{ category.name }}</span>
              </h5>
              <div>
                {{ row.student_count }}人已参加
                <span v-if="row.list_price > 0 && !data_list.resourcesAllFree"
                      class="badge badge-pill">¥{{ row.list_price }}</span>
                <span v-else>免费</span>
              </div>
            </div>
            <span v-if="row.list_price > 0 && !data_list.resourcesAllFree" class="new">新课特惠</span>
            <span v-else>限时免费</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {onMounted, reactive} from "vue";
import {Autoplay, EffectFade, Keyboard, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";
import {trackPage} from "@/utils/trackPage";
import {useRouter} from "vue-router";

const router = useRouter();
const bulletRenderer = (index, className) => {
  const _className = `${className} swiper-pagination-bullet-custom`;
  return `<span class="${_className}">${index + 1}</span>`;
};

const data_list = reactive({
  news: [],
  app: [],
  training: [],
  course: [],
  resourcesAllFree: false,
});

onMounted(() => {
  jsonRPC({
    url: "/vue/home/base",
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取首页信息成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败`, error);
    },
  });
  trackPage(router.currentRoute);
});
</script>

<style scoped lang="scss">
.home {
  // 按比例封装图片
  .img_box_banner {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    // padding-bottom: calc(100% / (24 / 5));
    padding-bottom: 300px;

    > img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover; /* 图像保持自身比例填满容器，并裁剪掉超出的部分 */
    }
  }

  // 轮播分页样式
  --swiper-pagination-bottom: 16px;
  :deep(.swiper-pagination-bullet-custom) {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    text-align: center;
    background-color: #f8f8f8;
    color: #24292f94;
    transition: all 0.2s;
    opacity: 0.6;

    &:hover {
      opacity: 1;
      color: #24292f;
    }

    &.swiper-pagination-bullet-active {
      opacity: 1;
      color: #fff;
      background-color: #007aff;
    }
  }

  .home_banner {
    position: relative;
    margin-bottom: 20px;

    > .row {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      overflow: hidden;
      pointer-events: none;
      z-index: 2;

      #scroll-container {
        position: relative;
        padding: 18px 25px;
        background-color: #ffffffa1;
        height: calc(100% - 40px);
        margin: 20px 30px;
        pointer-events: auto;

        #scroll-list {
          position: absolute;
          height: calc(100% - 36px);
          width: calc(100% - 50px);
          overflow: hidden;
          margin-bottom: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          li {
            width: 100%;
            line-height: 2;
            list-style-type: none;
            padding: 8px 0;

            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 5px;

              b {
                width: 100%;
                font-size: 14px;
                color: #000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              span {
                flex-shrink: 0;
                font-size: 12px;
                color: #888;
              }
            }
          }
        }
      }
    }
  }

  .home_app {
    margin-bottom: 4px;

    > h4 {
      font-size: 20px;
      padding-left: 10px;
      border-left: 15px solid #259ced;
      line-height: 40px;
      margin-bottom: 20px;
    }

    > .row {
      > div {
        margin-bottom: 20px;

        .info {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          box-shadow: 1px 1px 4px 0px #9d9d9d66;
          border-radius: 8px;
          overflow: hidden;
          height: 100%;

          > div {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px;

            h5 {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
              margin-bottom: 5px;

              a {
                font-weight: bold;
                font-size: 14px;
                color: #000;
              }

              span {
                flex-shrink: 0;
                font-size: 12px;
                border-radius: 8px;
                padding: 3px 5px;
                background-color: #9dcbff;
                color: #fff;
              }
            }

            p {
              margin-bottom: 5px;
            }

            .text-muted {
              justify-content: flex-end;
              > div {
                display: flex;
                gap: 5px;
                margin-right: 10px;
                align-items: center;
                img{
                  height: 15px;
                  width: 15px;
                }
                span {
                  color: #007bff;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }

  .home_live {
    margin-bottom: 4px;

    > h4 {
      font-size: 20px;
      padding-left: 10px;
      border-left: 15px solid #259ced;
      line-height: 40px;
      margin-bottom: 20px;
    }

    .col-sm-6,
    .col-md-6 {
      margin-bottom: 20px;
    }

    .info {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      box-shadow: 1px 1px 4px 0px #9d9d9d66;
      border-radius: 8px;
      overflow: hidden;

      > div {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;

        h5 {
          font-size: 16px;
          margin-bottom: 5px;

          a {
            font-weight: bold;
            font-size: 14px;
            color: #000;
          }
        }

        .summary {
          margin-bottom: 2px;

          span {
            display: inline-block;
            color: #52c9ff;
            font-size: 12px;
            border: 1px solid #52c9ff;
            border-radius: 4px;
            padding: 1px 4px;
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }

        .teacher {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0;

          .user {
            display: flex;
            align-items: center;

            img {
              height: 30px;
              width: 30px;
              border-radius: 50%;
              margin-right: 5px;
            }
          }

          p {
            color: #a6a6a6;
            margin-bottom: 0px;
          }

          .stat {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
              margin-left: 10px;
            }
          }
        }
      }

      > span {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #90faff;
        padding: 0 15px;
        line-height: 30px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .home_course {
    margin-bottom: 10px;

    > h4 {
      font-size: 20px;
      padding-left: 10px;
      border-left: 15px solid #259ced;
      line-height: 40px;
      margin-bottom: 20px;
    }

    .info {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      height: calc(100% - 20px);
      box-shadow: 1px 1px 4px 0px #9d9d9d66;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 20px;
      position: relative;

      > div {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;

        h5 {
          font-size: 16px;
          margin-bottom: 5px;

          a {
            font-weight: bold;
            font-size: 14px;
            color: #000;
          }

          span {
            display: inline-block;
            color: #52c9ff;
            font-size: 12px;
            border: 1px solid #52c9ff;
            border-radius: 4px;
            padding: 1px 4px;
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }

        p {
          color: #a6a6a6;
          margin-bottom: 5px;
        }

        > div {
          display: flex;
          justify-content: space-between;

          span {
            color: #39bfed;

            &.badge {
              font-size: 14px;
              background-color: #0078ff;
              color: #fff;
            }
          }
        }
      }

      > span {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #90faff;
        padding: 0 15px;
        line-height: 30px;
        border-bottom-right-radius: 8px;

        &.new {
          background-color: #90ffa5;
        }
      }
    }
  }
}
</style>
