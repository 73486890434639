<template>
  <div class="signup-container">
    <img class="logo" src="/hw_frontend/static/img/hi168_logo_black.png">
    <a-form ref="formSignupRef" :rules="rules" :model="formState" autocomplete="off" @finish="onSignupFinish" :label-col="{ style: {width: '100px'} }">
      <a-form-item label="手机号" name="login_phone">
        <a-input v-model:value="formState.login_phone" :disabled="isDisabled"/>
      </a-form-item>
      <a-form-item label="验证码" name="login_auth_code">
        <div style="display: flex;align-items: stretch;">
          <a-input v-model:value="formState.login_auth_code" style="margin-right: 10px;"/>
          <a-button type="primary" :disabled="isDisabled" @click="getVerificationCode" style="height: auto;width: auto;">
            {{ isDisabled ? `重新获取(${curCount})` : '获取验证码' }}
          </a-button>
        </div>
      </a-form-item>
      <a-form-item label="姓名" name="name">
        <a-input v-model:value="formState.name"/>
      </a-form-item>
      <a-form-item label="密码" name="password">
        <a-input-password v-model:value="formState.password"/>
      </a-form-item>
      <a-form-item label="确认密码" name="confirm_password">
        <a-input-password v-model:value="formState.confirm_password"/>
      </a-form-item>
      <a-form-item label="邀请码" name="invitation_code">
        <a-input v-model:value="formState.invitation_code" :disabled="props.invitation_code"/>
      </a-form-item>
      <a-flex justify="center" align="center" class="mt-4">
        <a-button type="primary" html-type="submit">立即注册</a-button>
      </a-flex>
      <div class="text-center">
        <a href="#" class="btn btn-link btn-sm" @click.prevent="enterDetail">已有账号?</a>
      </div>
    </a-form>
    <a-flex justify="center" align="center" class="bottom" style="position: absolute;right: 30px;bottom: 10px;">
      登录 或 注册 代表您已阅读并同意 <a href="/protocol/licence" class="btn btn-link btn-sm" target="_blank">用户协议</a>
      和<a href="/protocol/privacy" class="btn btn-link btn-sm" target="_blank">隐私政策</a>
    </a-flex>
  </div>
</template>

<script setup>
const emits = defineEmits(['call-parent-method']);
function enterDetail() {
  emits('call-parent-method',{
    component: 'login',
  });
}
const props = defineProps({
  invitation_code: {
    type: Number,
    default: null,
  }
});
import {logDebug, logError} from "@/utils/logger";
import {onUnmounted,reactive, ref} from 'vue';
import {getFailedMessage, jsonRPC, jsonOwlRPC, getResponseData} from "@/utils/http_utils";
import {message} from "ant-design-vue";

const formState = reactive({
  login_phone: '',
  login_auth_code: '',
  name: '',
  password: '',
  confirm_password: '',
  invitation_code: props.invitation_code,
});
const rules = {
  login_phone: [
    { required: true, message: '请输入手机号！' },
    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确！' }
  ],
  login_auth_code: [
    { required: true, message: '请输入验证码！' }
  ],
  name: [
    { required: true, message: '请输入姓名！' }
  ],
  password: [
    { required: true, message: '请输入密码！' },
    { min: 8, max: 16, message: '密码长度必须为8到16个字符' },
    { 
      validator: (_, value) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/;
        if (!value || pattern.test(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject('密码必须包含大小写字母、数字');
        }
      },
      trigger: 'blur'
    }
  ],
  confirm_password: [
    { required: true, message: '请再次输入密码！' },
    { 
      validator: (_, value) => {
        if (value !== formState.password) {
          return Promise.reject('两次输入的密码不一致！');
        }
        return Promise.resolve();
      },
      trigger: 'blur'
    }
  ]
};

const curCount = ref(120);
const timer = ref(null);
const isDisabled = ref(false);
const getVerificationCode = ()=>{
  formSignupRef.value.validateFields(['login_phone'])
  .then(() => {
    // 验证通过后执行获取验证码的逻辑
    jsonOwlRPC({
      url: "/register/send_user_sms",
      params: {
        mobile: formState.login_phone,
      },
      success(res) {
        logDebug(`验证码发送成功`, res)
        isDisabled.value = true;
        curCount.value = 120;
        timer.value = setInterval(SetRemainTime, 1000);
      },
      fail(error) {
        logError(`验证码获取失败, `, error)
        message.error(`验证码获取失败，[${getFailedMessage(error)}]`);
      }
    })
  })
  .catch((error) => {
    // 验证失败时处理逻辑
    logDebug('手机号验证未通过:', error);
  });
}
const SetRemainTime = () => {
  if (curCount.value < 1) {
    clearInterval(timer.value);
    timer.value = null;
    isDisabled.value = false;
  } else {
    curCount.value--;
  }
};

// onFinish 是前端校验成功的回调，通常用于表单验证。
const formSignupRef = ref();
const onSignupFinish = values => {
  logDebug('Success:', values);
  formSignupRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/hw_web/login/signup",
      params: {
        login_phone: formState.login_phone,
        login_auth_code: formState.login_auth_code,
        name: formState.name,
        password: formState.password,
        confirm_password: formState.confirm_password,
        invitation_code: formState.invitation_code,
      },
      success(res) {
        const data = getResponseData(res);
        logDebug(`注册成功`, data);
        message.success('注册成功')
        enterDetail()
      },
      fail(error) {
        logError(`注册失败, `, error)
        message.error(`注册失败，[${getFailedMessage(error)}]`);
      }
    })
  })
  .catch(error => {
    logDebug('error', error);
  });
}
onUnmounted(() => {
  if (timer.value) {
    clearInterval(timer.value);
  }
});
</script>

<style scoped lang="scss">
.signup-container{
  min-width: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 50px;
  padding-top: 95px;
  border-radius: 16px;
  overflow: hidden;
  backdrop-filter: blur(10px);  // 让盒子后面的内容变模糊，但盒子内部的文字不会模糊
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 127, 255, 1);
  .logo{
    height: 50px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
h2{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
}
button{
  height: 40px;
  width: 150px;
}
</style>