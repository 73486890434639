<template>
  <div class="video">
    <div :id="state.player_id"></div>
    <a-modal
        v-model:open="openMessage"
        title="提示："
        @ok="handleOkMessage"
        :mask="false"
        :footer="null"
        style="width: 60vw;"
    >
      <a-typography-title :level="4" class="modal-title">
        您的浏览器不允许自动播放视频，请点击直播画面左下角的蓝色播放按钮“>>”开始观看！
      </a-typography-title>
      <a-typography-text type="success">
        该提示框会在
        <a-typography-text strong class="warning-text">{{ openMessageLeftSeconds }}</a-typography-text>
        秒内自动关闭。您也可以点击
        <a-typography-text class="warning-text">提示框右上角的“X”按钮</a-typography-text>
        或
        <a-typography-text class="warning-text">提示框之外的区域</a-typography-text>
        关闭该提示框。
      </a-typography-text>
    </a-modal>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onBeforeUnmount, reactive, ref, watch} from "vue";
import chatMessageStore from "@/stores/chat.js";
import $ from "jquery";

const props = defineProps({
  primal: String,
  data: Object,
});

const primal = props.primal;
const data = props.data;

const state = reactive({
  player_id: `live-player-${primal}`,
  timer: null,
  player: null,
  pull_url: data.pull_url,
  pull_url_flv: data.pull_url_flv,
  pull_url_rts: data.pull_url_rts,
  live_state: data.live_state,
});

const openMessage = ref(false);
const openMessageLeftSeconds = ref(20);
const countDownOpenMessage = () => {
  const intervalId = setInterval(() => {
    openMessageLeftSeconds.value -= 1;
    if (openMessageLeftSeconds.value <= 0) {
      clearInterval(intervalId);
      openMessage.value = false;
    }
  }, 1000);
};

const handleOkMessage = () => {
  openMessage.value = false;
};

const hasInitialized = ref(false);
const $chatMessageStore = chatMessageStore();
const danmukuList = ref([]); // 定义 danmukuList

import {jsonOwlRPC} from "@/utils/http_utils";
import {loadJavaScriptIfNotExist, loadStyleIfNotExist} from "@/utils/load_utils";
let counter = 0;  // 初始化计数器
state.timer = setInterval(async () => {
  // 查询直播状态
  await jsonOwlRPC({
    url: "/live/state",
    params: {
      stream_id: data.stream_id,
    },
    success(res) {
      state.live_state = res;
      if (state.player) {
        if (state.live_state != "online") { // 直播不在线
          $('.prism-player .prism-info-display').css({'display':'block'});
          state.player._status = 'loading';
          counter++;  // 每次直播状态不在线时，计数器加1
          // 如果计数器达到30次，则清除定时器
          if (counter >= 30) {
            clearInterval(state.timer);
            $('.prism-player .prism-ErrorMessage').css({'display':'block'});
            $('.prism-player .prism-ErrorMessage .prism-error-operation a').css({'display':'none'});
            $('.prism-player .prism-ErrorMessage .prism-error-operation a.prism-button-refresh').css({'display':'inline-block'});
          }
        } else {
          $('.prism-player .prism-info-display').css({'display':'none'});
          if (state.player._status != 'playing') {
            state.player.play();
            state.player._status = 'playing';
          }
          counter = 0;  // 如果直播状态在线，重置计数器
        }
      }
    },
    fail(error) {
      logError(`创建失败, `, error);
    },
  });
}, 1000 * 5);
const _onPlayerReady = () => {
  logDebug("更新观看时长");
};

const _onLiveStreamStop = () => {
  $(".prism-button-orange, .prism-detect-info").hide();
  const hintText = '<p style="font-size:24px">主播暂时离开，请稍后点击刷新再试！</p>';
  $(".prism-error-content").html(hintText);
};

const initialize = async () => {
  logDebug(`state.pull_url[${state.pull_url}], state.pull_url_flv[${state.pull_url_flv}], state.pull_url_rts[${state.pull_url_rts}]`);

  if (!window.Aliplayer) {
    await loadStyleIfNotExist("/hw_web_console/static/lib/aliplayer/aliplayer-min.css")
    await loadJavaScriptIfNotExist("/hw_web_console/static/lib/aliplayer/aliplayer-min.js")
    await loadJavaScriptIfNotExist("/hw_web_console/static/lib/aliplayer/aliplayercomponents-1.0.9.min.js")
  }

  state.player = new window.Aliplayer({
    id: state.player_id,
    source: state.pull_url_rts,
    width: "100%",
    height: "100%",
    autoplay: true,
    liveStreamStop: true,
    isLive: true,
    rePlay: false,
    playsinline: true,
    preload: true,
    controlBarVisibility: "hover",
    useH5Prism: true,
    language: "zh-cn",
    keyShortCuts: true,
    autoplayPolicy: {
      fallbackToMute: true,
      showUnmuteBtn: true,
    },
    skinLayout: [
      {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
      {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
      {name: "infoDisplay"},
      {
        name: "controlBar", align: "blabs", x: 0, y: 0, children: [
          {name: "liveDisplay", align: "tlabs", x: 15, y: 6},
          {name: "fullScreenButton", align: "tr", x: 10, y: 10},
          {name: "subtitle", align: "tr", x: 15, y: 12},
          {name: "setting", align: "tr", x: 15, y: 12},
          {name: "volume", align: "tr", x: 5, y: 10},
        ]
      },
    ],
    components: [{
      name: 'AliplayerDanmuComponent',
      type: window.AliPlayerComponent.AliplayerDanmuComponent,
      args: [danmukuList.value],
    }],
  });

  state.player.on("ready", _onPlayerReady);
  state.player.on('liveStreamStop', _onLiveStreamStop);
  state.player.on('autoplay', (data) => {
    logDebug(`autoplay data[${JSON.stringify(data)}]`);
    if (!data.paramData) {
      if (!openMessage.value) {
        openMessage.value = true;
        countDownOpenMessage();
      }
    }
  });

  $('.prism-player .prism-big-play-btn, .prism-player .prism-big-play-btn .outter').css({
    "background-image": 'url(/hw_web_console/static/src/img/project_double_right.png)',
  });
  const hintText = '<p style="font-size:24px;color: #fff;">主播暂时离开，请稍后点击刷新再试！</p>';
  $('.prism-player .prism-info-display').html(hintText);
  $('.prism-player .prism-info-display').css({'display': 'none'});

  const AliplayerDanmuComponent = state.player.getComponent('AliplayerDanmuComponent');
  if (AliplayerDanmuComponent) {
    setAliplayerDanmuComponent(AliplayerDanmuComponent);
  }
  hasInitialized.value = true;
};

const setAliplayerDanmuComponent = (component) => {
  component.CM.options.global.scale = 2.5;
  component.CM.options.global.size = 36;
  component.CM.width = 1500;
  component.CM.height = 400;
  component.CM.start();
  component.send({
    "mode": 1,
    "text": `欢迎来到Hi168学习，当前课程为：${data.head_title}`,
    "stime": 1000,
    "size": 46,
    "color": 0xffffff,
  });
  danmukuList.value = component.danmukuList;
};

const uninitialized = () => {
  logDebug(`LiveDetailVideo uninitialized, state.player_id = ${state.player_id}`);
  try {
    if (state.timer) clearInterval(state.timer);
    if (state.player) {
      state.player.stop();
      state.player.dispose();
    }
  } catch (e) {
    logDebug('uninitialized failed', e)
  }
  hasInitialized.value = false;
};

const messageQueueState = ref({
  messageQueue: [], // 消息队列
  processing: false // 是否正在处理消息
});

const processNextMessage = () => {
  if (messageQueueState.value.messageQueue.length === 0) {
    messageQueueState.value.processing = false;
    return;
  }
  let color_list = [0xffffff,0xff0000,0x0000ff]
  let size_list = [32,23,36,42]
  const randomColorIndex = Math.floor(Math.random() * color_list.length);
  const randomSizeIndex = Math.floor(Math.random() * size_list.length);
  const chat = messageQueueState.value.messageQueue.shift();
  const cmt = {
    "mode": 1,
    "text": chat.comment,
    "stime": chat.create_date,
    "size": size_list[randomSizeIndex],
    "color": color_list[randomColorIndex],
    "dur": Math.floor(Math.random()*4000 + 4000),
  };

  if (chat.create_uid == sessionStorage.getItem('user_id')) {
    cmt["border"] = "1px solid #ffffff";
  }

  const AliplayerDanmuComponent = state.player.getComponent('AliplayerDanmuComponent');
  if (AliplayerDanmuComponent) {
    AliplayerDanmuComponent.send(cmt);
  }
  setTimeout(processNextMessage, 3000); // 假设每条消息显示3秒后处理下一条
};

const onSelected = () => {
  logDebug(`LiveDetailVideo onSelected, data[${JSON.stringify(data)}]`);
  if (hasInitialized.value) return;
  initialize();

  watch(() => $chatMessageStore.current_chat_list, (newValue) => {
    // 确保 newValue 是一个数组，其中每个元素也是一个数组
    newValue.forEach(chatItem => {
      if (chatItem && chatItem.comment && chatItem.comment.length < 100 && !chatItem.comment.startsWith('<')) {
        messageQueueState.value.messageQueue.push(chatItem);
      }
      $chatMessageStore.deleteChatMessage(chatItem.id)
    });
    if (!messageQueueState.value.processing) {
        messageQueueState.value.processing = true;
        processNextMessage();
      }
  }, { deep: true });
};

const onUnSelected = () => {
  logDebug(`LiveDetailVideo onUnSelected, data[${JSON.stringify(data)}]`);
  uninitialized();
};

onBeforeUnmount(() => {
  uninitialized();
});

defineExpose({
  primal,
  data,
  onSelected,
  onUnSelected,
});
</script>

<style scoped lang="scss">
.video{
  height: 100%;
  overflow: hidden;
  >div{
    width: 100%;
    height: 100%;
    background-color: #000;
  }
}
.modal-title {
  color: #007bff;
}

.warning-text {
  color: red;
}
</style>
