import { notification } from 'ant-design-vue';
import { defineStore } from 'pinia';
import { reactive } from 'vue';
import { logDebug } from "@/utils/logger";
import helper from '@/utils/helper.js'

const useNotificationStore = defineStore('notification', () => {
    const state = reactive({
        notifications: [], // 存储通知
    });

    // 添加通知
    function addNotification({ title, content='未知内容', close_time=5 }) {
        const userSession = sessionStorage.getItem('user_id');
        let message_title = '系统通知';
        if (!userSession) return;
        if (title){
            message_title = title;
        }
        let closeTime = 2 * 1000
        if (close_time) {
            closeTime = close_time * 1000;
            logDebug(closeTime)
        }

        const messageId = helper.uuid();
        const message = {
            id: messageId,
            title: message_title,
            content: content,
            session: userSession,
            maxCount:3,
        };

        if (!state.notifications.some(notif => notif.id === message.id)) {
            state.notifications.push(message);
            showNotification(message,closeTime);  // 显示通知
        }
    }

    // 显示通知
    function showNotification(message,closeTime) {
        const key = message.id; // 使用唯一的 key

        notification.open({
            key,
            message: message.title,
            description: message.content,
            onClose: () => {
                removeNotification(message.id); // 通知关闭时移除
            },
        });

        // 设置定时器，2秒后自动关闭通知
        setTimeout(() => {
            notification.close(key); // 关闭通知
        }, closeTime); // 2秒后自动关闭
    }

    // 移除通知
    function removeNotification(id) {
        state.notifications = state.notifications.filter(notif => notif.id !== id);
    }

    return {
        addNotification,
        removeNotification,
        notifications: state.notifications,
    };
});

export default useNotificationStore;
