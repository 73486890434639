<template>
  <div class="blog_bage">
    <a-row gutter="20">
      <a-col :span="4">
        <div class="blog_hot_post">
          <h5>热门话题
            <span @click="onOneChange"><RedoOutlined />换一批</span>
          </h5>
          <ul>
            <li v-for="(item, index) in data_list.hot_topics" :key="index">
              <a-space :size="5">
                <span>#</span>
                <span @click="choseTopic(item)" class="line-clamp-1">{{item.name}}</span>
              </a-space>
            </li>
          </ul>
        </div>
        <div class="blog_hot_post">
          <h5>论坛版块</h5>
          <a-menu v-model:selectedKeys="selectedKeys" class="menu" mode="vertical" :items="sectionItems" @click="handleClick"></a-menu>
        </div>
      </a-col>
      <a-col :span="20">
        <div class="info">
          <div class="blog_title">
            <div class="tags">
              <a class="tag" :class="{ active: tag_id == 1 }" @click="onTag(1)">最新</a>
              <a class="tag" :class="{ active: tag_id == 0 }" @click="onTag(0)">推荐</a>
              <a class="tag" :class="{ active: tag_id == 2 }" @click="onTag(2)">我的关注</a>
              <a-tag class="line-clamp-1 activeTopic" v-show="activeTopic" :key="activeTopic" @close="handleTagClose" closable>
                {{ activeTopic }}
              </a-tag>
            </div>
            <div class="search">
              <a @click="goToWrite" class="write_blog">
                <img src="/hw_blog/static/src/img/write_blog.png"/>
                <span>发帖子</span>
              </a>
              <a-input-search placeholder="请输入帖子关键词" enter-button allowClear @search="onSearch"/>
            </div>
          </div>
          <div class="blog_list" v-for="item in data_list.records" :key="item.id">
            <div class="blog_item" @click="goToDetail(item.id)">
              <div class="left">
                <h5 class="line-clamp-1">{{ item.name }}</h5>
                <a-flex justify="left">
                  <span v-for="i in item.sections" :key="i.id">{{i.name}}</span>
                </a-flex>
              </div>
              <div class="right">
                <div class="blog_list_top">
                  <router-link @click.stop="" :to="`/blog/homepage?user_id=${item.user.id}`" class="user">
                    <img v-if="item.user.has_image" :src="`/web/image/res.users/${item.user.id}/image_128`"/>
                    <img v-else src="/hw_web/static/src/img/avatar_live.png"/>
                    {{item.user.name}}
                  </router-link>
                  <span class="ml-3">{{ item.post_date }}</span>
                </div>
                <div class="blog_list_bottom">
                  <a-tooltip placement="bottom">
                    <template #title>浏览量</template>
                    <span>
                      <img src="/hw_blog/static/src/img/visited.png"/>
                      <span>{{ item.visits }}</span>
                    </span>
                  </a-tooltip>
                  <a-tooltip placement="bottom">
                    <template #title>评论人数</template>
                    <span>
                      <img v-if="item.is_comments" src="/hw_blog/static/src/img/info.png"/>
                      <img v-else src="/hw_blog/static/src/img/comment.png"/>
                      <span>{{ item.comments }}</span>
                    </span>
                  </a-tooltip>
                  <a-tooltip placement="bottom">
                    <template #title>点赞</template>
                    <span @click.stop="onBlogLike(item.id)">
                      <img v-if="item.is_likes" src="/hw_blog/static/src/img/liked.png"/>
                      <img v-else src="/hw_blog/static/src/img/praise.png"/>
                      <span>{{ item.likes }}</span>
                    </span>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <a-flex justify="right" class="mt-3">
            <a-pagination
              v-model:current="current"
              :showSizeChanger="showSizeChanger"
              v-model:page-size="pageSize"
              :total="data_list.record_count"
              :locale="zhCn"
              :pageSizeOptions="pageSizeOptions"
              :hideOnSinglePage="true"
            />
          </a-flex>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { RedoOutlined } from '@ant-design/icons-vue';
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, h, reactive, watch } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterQuery } from "@/utils/router_utils";
import { zhCn } from "@/utils/zhCn";
import { message, Modal } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter } from "vue-router";

const goToWrite = () => {
  if (!data_list.meta1phone) {
    Modal.warning({
      title: '实名认证提醒',
      content: h('div', {}, [
        h('p', '使用应用、实训、论坛等需要实名认证，未实名认证将会被限制使用，首次实名认证成功可获得 20 点算力点。'),
        h(
          'a',
          {
            style: { color: 'blue', cursor: 'pointer' },
            onClick: () => {
              router.push('/my'); // 点击蓝色 a 标签跳转
              Modal.destroyAll(); // 关闭弹窗
            },
          },
          '点击进行实名'
        )
      ]),
      width: '50%',
      footer: null,
      maskClosable: true,
      closable: true,
    });
    return;
  }
  router.push('/blog/write');
};
const activeTopic = ref('');
const router = useRouter();
const selectedKeys = ref([]);
const sectionItems = ref([]);
const handleClick = menuInfo => {
  router.push({
    path: `/forum/section/${menuInfo.key}`,
  });
};
const tag_id = ref(1);
const search = ref('');
const data_list = reactive({
  records: [],
  record_count: 0,
  user_blog_num: 0,
  reply_comment_count: 0,
  comment_count: 0,
  like_count: 0,
});
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
const handleTagClose = () => {
  router.push('/blog')
};
const choseTopic = (e) => {
  jsonRPC({
    url:'/api/blog/post/topic/posts',
    params: {
      post_topic_id: e.id,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(data);
      if(!data.post_list) {
        return message.warning('该话题下没有博客');
      }
      data_list.records = data.post_list;
      data_list.record_count = data.post_list.length;
      activeTopic.value = data.post_topic_name;
    },
    fail(error) {
      logError(`获取话题相关博客失败`, error);
    }
  });
}

async function fetchData () {
  await jsonRPC({
    url: `/api/blog`,
    params: {
      tag_id: tag_id.value,
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取技术论坛成功`, data);
      Object.assign(data_list, data);
      if (getRouterQuery(router, "topic")) {
        let topic_id = Number(getRouterQuery(router, "topic"));
        tag_id.value = topic_id;
        choseTopic({id: topic_id});
      }
    },
    fail(error) {
      logError(`获取技术论坛失败`, error);
      message.error(`获取技术论坛失败，请稍后再试`);
    },
  });
  await jsonRPC({
    url: `/api/blog/forum/section`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取技术论坛成功`, data);
      data.records.forEach(element => {
        element.key = element.id;
        element.title = element.id;
        element.label = element.name;
        const iconUrl = element.image_url;
        if (typeof iconUrl === 'string' && iconUrl.length > 0) {
          element.icon = () =>
            h('img', {
              src: iconUrl,
              style: { width: '25px', height: '25px', marginRight: '8px' },
            });
        } else {
          element.icon = () =>
            h('span', {
              style: { width: '25px', height: '25px', marginRight: '8px' },
            });
        }
      });
      sectionItems.value = data.records;
    },
    fail(error) {
      logError(`获取技术论坛失败`, error);
      message.error(`获取技术论坛失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV(router.currentRoute);
});
watch([tag_id, pageSize, current], fetchData);
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};

// 分类
const onTag = (e)=>{
  tag_id.value = e;
}

// 热帖推荐换一批
const onOneChange = ()=>{
  jsonRPC({
    url: `/api/blog/post/topic`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取热帖推荐成功`, data);
      data_list.hot_topics = data.post_topic_list;
    },
    fail(error) {
      logError(`获取热帖推荐失败`, error);
      message.error(`获取热帖推荐失败，请稍后再试`);
    },
  });
}
const onBlogLike = (e) => {
  jsonRPC({
    url: `/api/blog/like/${e}`,
    params: {},
    success(res) {
      logDebug(res);
      fetchData();
    },
    fail(error) {
      logError(`操作失败`, error);
    },
  });
};

const goToDetail = (id) => {
  router.push(`/blog/${id}`);
};
</script>

<style scoped lang="scss">
  span,a,div{
    word-break: break-all;
    white-space: normal;
  }
.blog_bage {
  padding: 20px;
  .ant-menu{
    border-radius: 8px;
  }
  .info{
    // background-color: #fff;
    .blog_title{
      display: flex;
      margin-bottom: 15px;
      .activeTopic{
        background-color: #fff;
        border-radius: 5px;
        line-height: 20px;
        padding-top: 5px;
        color: #006BFF;
      }
      .tags {
        flex: 1 1 100%;
        display: flex;
        flex-wrap: wrap;
        .tag {
          color: #000;
          border-radius: 8px;
          padding: 0.3rem 1rem;
          cursor: pointer;
          &.active,
          &:hover {
            background-color: #D5E9FF;
          }
          &:not(:last-child){
            margin-right: 10px;
          }
        }
        .selecters{
          flex: 0 0 auto;
          .ant-select{
            width: 100px; 
            margin-left: 18px;
            :deep(.ant-select-selector) {
              background-color: #C9E0FF;
              color: #006BFF;
            }
            :deep(.ant-select-arrow) {
              color: #006BFF; // 修改箭头符号的颜色为红色
              font-size: 15px;
            }
          }
          
        }
      }

      .search {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        :deep(.ant-input-search-button) {
          background-color: #77b7ff;
        }
        .write_blog {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          background-color: #77b7ff;
          color: #fff;
          border-radius: 15px;
          padding: 0.25rem 0.75rem;
          line-height: 20px;
          margin-right: 1rem;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .ant-btn-primary{
          background-color: #77b7ff;
        }
      }
    }
    .blog_list{
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.4);
      margin-bottom: 15px;
      height: 100px;

      >a{
        display: flex;
        justify-content: space-between;
        padding: 15px;
        height: 100%;
        h5{
          font-size: 18px;
          color: #101010;
          font-weight: 600;
        }
      }
      .right,.left{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .left{
        span{
          background-color: #E4EFFF;
          color: #2580fd;
          padding: 3px 7px;
          border-radius: 4px;
          margin-right: 6px;
          font-size: 14px;
        }
      }
      .right{
        flex-shrink: 0;
      }
      .blog_list_top{
        display: flex;
        align-items: center;
        color: #A1A1A1;
        margin-bottom: 5px;
        .user{
          display: flex;
          align-items: center;
          color: #A1A1A1;
          &:hover{
            color: #ff8c00;
          }
        }
        img{
          height: 25px;
          width: 25px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
      .blog_list_bottom{
        display: flex;
        justify-content: flex-end;
        column-gap: 18px;
        margin-top: 5px;
        img{
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
  }
  .blog_user{
    border-radius: 8px;
    background-color: #fff;
    padding: 25px;
    margin-bottom: 15px;
    .user_info{
      text-align: center;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 1rem;
      }
      h5{
        font-size: 16px;
      }
    }
    .blog_info{
      display: flex;
      justify-content: space-around;
      a{
        display: flex;
        flex-direction: column;
        align-items: center;
        .count{
          font-size: 28px;
          color: #429bff;
        }
        .title{
          color: #000;
        }
      }
    }
  }
  .blog_message{
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5{
      font-weight: bold;
      font-size: 16px;
    }
    img{
      width: 25px;
      height: 25px;
    }
    a{
      display: flex;
      gap: 10px;
      padding: 10px 0;
      color: #101010;
      &:hover{
        color: #ff8c00;
      }
      span{
        flex-shrink: 0;
        margin-left: auto;
        .ant-badge-count{
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
  .blog_hot_post{
    flex-grow: 1;
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    li{
      span{
        cursor: pointer;
        &:hover{
          color: #ff8c00;
        }
      }
    }
    .menu{
      :deep(li) {
        display: flex;
        align-items: center;
        color: #9B9B9B;
        padding-left: 8px;
      }
    }
    h5{
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      span{
        font-size: 14px;
        color: #949494;
        cursor: pointer;
        display: flex;
        gap: 5px;
      }
    }
    ul{
      padding: 0;
      list-style: none;
      li{
        margin: 10px 0;
        a{
          color: #000;
          &:hover{
            color: #ff8c00;
          }
        }
      }
    }
  }
}

.blog_list {
  .blog_item {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    height: 100%;
    cursor: pointer;
    
    &:hover {
      h5 {
        color: #ff8c00;
      }
    }
  }
}
</style>
