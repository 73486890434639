<template>
  <div class="homework-form">
    <a-row v-if="editorMode === 'create'" style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          创建练习
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" @click="cancelForm">
          取消创建
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else-if="editorMode === 'update'" style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          编辑练习
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" @click="cancelForm">
          取消编辑
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else-if="editorMode === 'readonly'" style="margin: 10px">
      <a-col :span="18">
        <a-typography-title :level="4">
          班级详情
        </a-typography-title>
      </a-col>
      <a-col :span="6" style="display: flex; justify-content: space-around">
        <div>
          <a-button type="primary" @click="openDeleteModal=true">删除</a-button>
          <a-modal v-model:open="openDeleteModal" title="删除" @ok="confirmFormDelete">
            <p>确定要删除?</p>
          </a-modal>
        </div>
        <a-button type="primary" @click="cancelForm">
          返回
        </a-button>
        <a-button type="primary" @click="editorMode = 'update'">
          进入编辑
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else style="margin: 10px">
      <a-col :span="20">
        <a-typography-title :level="4">
          班级详情
        </a-typography-title>
      </a-col>
      <a-col :span="4" style="display: flex; justify-content: center">
        <a-button type="primary" v-if="editorMode === 'readonly'" @click="editorMode = 'update'">
          进入编辑
        </a-button>
      </a-col>
    </a-row>
    <!-- 页面表单 -->
    <a-form ref="formRef" :model="data_list" :rules="rules"
      :label-col="{style: {width: '150px'} }"
      :wrapper-col="{span: 14}"
      layout="horizontal"
      :disabled="editorMode === 'readonly'"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="练习名称：" name='name'>
            <a-input v-model:value="data_list.name"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="练习内容：" name='questions' :wrapper-col="{ width: '100%' }">
        <a-flex justify="flex-end" align="center">
          <a-button type="primary" class="carate_question" @click="Modal1 = true">新建题目</a-button>
          <a-button type="primary" class="select_question" @click="state.Modal = true">从题库选题</a-button>
        </a-flex>
      </a-form-item>
      <a-table :columns="columns" :dataSource="question_list" :pagination="false" :scroll="{ y: '50vh' }">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex == 'name'">
            <div v-html="text"></div>
          </template>
          <template v-else-if="column.dataIndex == 'operation'">
            <a-popconfirm title="确定要删除?" @confirm="onDelete(record.key)">
              <a style="color: #1677ff;cursor: pointer;">删除</a>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
      <a-flex justify="center" align="center" class="mt-4">
        <a-button type="primary" v-if="editorMode === 'create'" @click="confirmFormCreate">创建</a-button>
        <a-button type="primary" v-if="editorMode === 'update'" @click="confirmFormUpdate">保存</a-button>
        <a-button v-if="editorMode !== 'readonly'" style="margin-left: 10px" @click="cancelForm">取消</a-button>
      </a-flex>
    </a-form>
    <!-- 新建题目 -->
    <a-modal v-model:open="Modal1" title="新建题目" :confirm-loading="loading1" @ok="createQuestion" width="60%">
      <a-form ref="formRef1" :model="create_list" :rules="rules1" @submit="createQuestion" :label-col="{style: {width: '100px'} }"
        style="height: 60vh;overflow: auto;">
        <a-row>
          <a-col :span="12">
            <a-form-item label="题目类型：" name='type'>
              <a-select v-model:value="create_list.type">
                <a-select-option v-for="item in data_list.type_options" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="题目标签：" name='tag'>
              <a-select v-model:value="create_list.tag" mode="multiple" :max-tag-count="3">
                <a-select-option v-for="item in data_list.tag_options" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="题目内容：" name="question_name">
          <RichEditor v-model="create_list.question_name" :height="301" :editMode="editorMode"/>
        </a-form-item>
        <a-row>
          <a-col :span="12">
            <a-form-item v-for="option in ['A', 'B', 'C', 'D']" :key="option"
              :label="`选项 ${option}：`" :name="`option_${option.toLowerCase()}`">
              <a-input v-model:value="create_list[`option_${option.toLowerCase()}`]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item v-for="option in ['E', 'F', 'G', 'H']" :key="option"
              :label="`选项 ${option}：`" :name="`option_${option.toLowerCase()}`">
              <a-input v-model:value="create_list[`option_${option.toLowerCase()}`]"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="题目答案：" name="solution">
          <a-input v-model:value="create_list.solution"/>
        </a-form-item>
        <a-form-item label="参考答案：" name="qa_solution">
          <RichEditor v-model="create_list.qa_solution" :height="301" :editMode="editorMode"/>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 从题库选题 -->
    <a-modal v-model:open="state.Modal" title="题库选题" width="60%">
      <a-table
        :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }"
        :columns="columns1"
        :data-source="data_list.question_ids"
        :pagination="pagination"
        :scroll="{ y: '50vh' }"
      >
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex == 'id'">
            <span style="color: #1890ff">ID</span>
          </template>
        </template>
        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`搜索 ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon><SearchOutlined /></template>
              搜索
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
              重置
            </a-button>
          </div>
        </template>
        <template #customFilterIcon="{ filtered, column }">
          <template v-if="column.dataIndex == 'type'">
            <FilterFilled />
          </template>
          <template v-else>
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
          </template>
        </template>
        <template #bodyCell="{ text, column }">
          <template v-if="state.searchText && state.searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${state.searchText})|(?=${state.searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === state.searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >
                {{ fragment }}
              </mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </template>
          <template v-else>
            <template v-if="column.dataIndex == 'name'">
              <div v-html="text"></div>
            </template>
            <template v-else>{{ text }}</template>
          </template>
        </template>
      </a-table>
      <template #footer>
        <span style="margin-right: 8px">
          <template v-if="hasSelected">
          {{ `已选择 ${state.selectedRowKeys.length} 项` }}
          </template>
        </span>
        <a-button key="back" @click="state.Modal = false">取消</a-button>
        <a-button key="submit" type="primary" @click="start" :loading="state.loading" :disabled="!hasSelected">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, computed} from 'vue'
import {useRouter} from "vue-router";
import {jsonRPC,getResponseData} from "@/utils/http_utils";
import { SearchOutlined,FilterFilled } from '@ant-design/icons-vue';
import RichEditor from "@/components/RichEditor"
const router = useRouter()

const formRef = ref();
const formRef1 = ref();
const question_list = ref([]);
const onDelete = key => {
  question_list.value = question_list.value.filter(item => item.key !== key);
};
const columns = ref([
  {
    title: 'ID',
    dataIndex: 'id',
    width: 100,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: '题目类型',
    dataIndex: 'type',
    width: 130,
    filters: [
      {
        text: '单选',
        value: '单选',
      },
      {
        text: '多选',
        value: '多选',
      },
      {
        text: '填空',
        value: '填空',
      },
      {
        text: '判断',
        value: '判断',
      },
      {
        text: '问答',
        value: '问答',
      },
      {
        text: '编程',
        value: '编程',
      },
    ],
    onFilter: (value, record) => record.type && record.type.indexOf(value) === 0
  },
  {
    title: '题目内容',
    dataIndex: 'name',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 80,
  },
]);
const rules = {
  name: [
    {
      required: true,
      message: '请输入练习名称',
    },
  ],
  questions: [
    {
      required: true,
      message: '请输入练习内容',
      trigger: 'change',
    },
  ],
}
const rules1 = {
  question_name: [
    {
      required: true,
      message: '请输入问题',
      validator(_, value) {
        // 如果内容为空或者只是一个空的 <p><br></p> 标签
        const isEmpty = !value || /^<p><br><\/p>$/i.test(value.trim());
        
        if (isEmpty) {
          return Promise.reject('请输入问题');
        }
        return Promise.resolve();
      }
    }
  ],
  type: [
    {
      required: true,
      message: '请选择类型',
      trigger: 'change',
    },
  ],
  tag: [
    {
      required: true,
      message: '请选择标签',
      trigger: 'change',
    },
  ],
  solution: [
    {
      required: true,
      message: '请输入答案',
    },
  ],
  qa_solution: [
    {
      required: true,
      message: '请输入参考答案',
      validator(_, value) {
        // 如果内容为空或者只是一个空的 <p><br></p> 标签
        const isEmpty = !value || /^<p><br><\/p>$/i.test(value.trim());
        
        if (isEmpty) {
          return Promise.reject('请输入参考答案');
        }
        return Promise.resolve();
      }
    }
  ],
  option_a: [
    {
      required: true,
      message: '请输入选项A',
    },
  ],
  option_b: [
    {
      required: true,
      message: '请输入选项B',
    },
  ],
}
const data_list = reactive({
  name: '',
  questions: [],
})

const editorMode = ref('create')
const course_id = router.currentRoute.value.params.course_id
const chapter_id = router.currentRoute.value.params.chapter_id
const lesson_id = router.currentRoute.value.params.lesson_id
const exerciseId = ref(0)

// 创建
const confirmFormCreate = () => {
  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/vue/console/course/exercise/create",
      params: {
        name: data_list.name,
        questions: data_list.questions,
      },
      success(res) {
        const data = getResponseData(res)
        logDebug(`获取成功`, data)
        cancelForm()
      },
      fail(error) {
        logError(`查询失败, `, error)
      },
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};
// 更新
const confirmFormUpdate = () => {
  formRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/vue/console/course/exercise/write",
      params: {
        name: data_list.name,
        questions: data_list.questions,
      },
      success(res) {
        const data = getResponseData(res)
        logDebug(`获取成功`, data)
        cancelForm()
      },
      fail(error) {
        logError(`保存失败, `, error)
      },
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};
// 返回
const cancelForm = () => {
  router.push(`/console/course/${course_id}/${chapter_id}/${lesson_id}/edit`);
};
// 删除
const openDeleteModal = ref(false)
const confirmFormDelete = () => {
  jsonRPC({
    url: "/vue/console/course/exercise/delete",
    params: {
      id: exerciseId.value,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      openDeleteModal.value = false;
      cancelForm()
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}
// 获取基础信息
function fetchData() {
  return jsonRPC({
    url: "/vue/console/course/exercise/setting",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取成功`, data)
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}
onMounted(() => {
  fetchData()
})

const Modal1 = ref(false);
const loading1 = ref(false);
const create_list = reactive({
  question_name: '',
  type: '',
  tag: [],
  solution: '',
  qa_solution: '',
})
// 创建问题
const createQuestion = () => {
  formRef1.value.validate()
  .then(() => {
    let params = {
      name: create_list.question_name,
      type: create_list.type,
      tag: create_list.tag,
      solution: create_list.solution,
      qa_solution: create_list.qa_solution,
    }
    // 循环添加选项
    for (let i = 97; i <= 104; i++) {
      const option = String.fromCharCode(i);
      params[`option_${option}`] = create_list[`option_${option}`];
    }
    loading1.value = true;
    jsonRPC({
      url: "/vue/console/class/homework/create/question",
      params: params,
      success(res) {
        const data = getResponseData(res)
        logDebug(`查询成功`,data);
        fetchData().then(()=>{
          data_list.questions.push(data);
          question_list.value = data_list.question_ids.filter(element => {
            return data_list.questions.includes(element.id);
          });
        });
      },
      fail(error) {
        logError(`查询失败, `, error)
      },
    }).then(()=>{
      loading1.value = false;
      Modal1.value = false;
      formRef1.value.resetFields();
    });
    return
  })
  .catch(error => {
    logDebug('error', error);
    return
  });
};
const pagination = reactive({
  // 分页配置
  // pageSize: 5, // 每页显示的条数
  showSizeChanger: true, // 是否可以改变每页显示的条数
  pageSizeOptions: ['10', '20', '100'], // 可选的每页显示条数
  showQuickJumper: false, // 是否可以快速跳转到指定页
  showTotal: total => `共 ${total} 条`, // 显示总条数和当前数据范围
  hideOnSinglePage: true, // 只有一页时是否隐藏分页器 true为不显示 false为显示
  // current: 1, // 当前页数
  // total: 0, // 总条数
  // onChange: markRaw(handlePageChange), // 页码改变时的回调函数
})
const columns1 = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 180,
    customFilterDropdown: true,
    sorter: (a, b) => a.id - b.id,
    onFilter: (value, record) => record.id.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
  },
  {
    title: '题目类型',
    dataIndex: 'type',
    width: 130,
    filters: [
      {
        text: '单选',
        value: '单选',
      },
      {
        text: '多选',
        value: '多选',
      },
      {
        text: '填空',
        value: '填空',
      },
      {
        text: '判断',
        value: '判断',
      },
      {
        text: '问答',
        value: '问答',
      },
      {
        text: '编程',
        value: '编程',
      },
    ],
    onFilter: (value, record) => record.type && record.type.indexOf(value) === 0
  },
  {
    title: '题目内容',
    dataIndex: 'name',
    customFilterDropdown: true,
    onFilter: (value, record) =>record.name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
      }
    },
  },
];
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  state.searchText = selectedKeys[0];
  state.searchedColumn = dataIndex;
};
const handleReset = clearFilters => {
  clearFilters({
    confirm: true,
  });
  state.searchText = '';
};
const searchInput = ref();
const state = reactive({
  searchText: '',
  searchedColumn: '',
  selectedRowKeys: [],
  Modal: false,
  loading: false,
});
const hasSelected = computed(() => state.selectedRowKeys.length > 0);
const start = () => {
  state.loading = true;
  const data_list_questions = data_list.question_ids.filter(element => {
    return state.selectedRowKeys.includes(element.id);
  });
  setTimeout(() => {
    question_list.value = [...question_list.value, ...data_list_questions];
    data_list.questions = [...new Set([...data_list.questions, ...state.selectedRowKeys])]; // 去重
    state.loading = false;
    state.Modal = false;
    state.selectedRowKeys = [];
  }, 1000);
};
const onSelectChange = selectedRowKeys => {
  state.selectedRowKeys = selectedRowKeys;
};
</script>

<style scoped lang='scss'>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.homework-form{
  height: 100%;
  form{
    height: 100%;
    padding: 25px 30px;
    background-color: #fff;
    .carate_question{
      background-color: #EDEDED;
      color: #53A6FF;
      height: 30px;
      border-radius: 20px;
      margin-right: 15px;
    }
    .select_question{
      background-color: #53A6FF;
      height: 30px;
      border-radius: 20px;
    }
  }
}
</style>
