<template>
  <iframe :id='vnc_frame_id' :src="url" :style="iframeStyle" class="vnc-frame" frameborder="0"
          @click="onFocus"/>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import {
  isEmptyOrNone,
  isFalse,
  isNotEmpty,
  isNotNullOrUndefined,
  isNullOrUndefined,
  isTrue
} from "@/utils/common_utils";

logDebug('TerminalVNC setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal
const data = props.data
const vnc_frame_id = `vnc_frame_${primal}`

const deploy_token = data['deploy_token']
logDebug(`deploy_token[${deploy_token}]`)

const url = ref('')

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalVNC onMounted.`)
})

const doFocusOnFrame = function (try_times) {
  logDebug(`focusOnFrame start, try_times[${try_times}]`)
  const vncFrameDom = document.getElementById(vnc_frame_id);
  logDebug(`vncFrameDom[${vncFrameDom}]`)
  if (isNotNullOrUndefined(vncFrameDom)) {
    logDebug(`vncFrameDom.focus() start`)
    vncFrameDom.blur()
    vncFrameDom.focus()
    logDebug(`vncFrameDom.focus() end`)
    if (isNotNullOrUndefined(vncFrameDom.contentWindow)) {
      logDebug(`vncFrameDom.contentWindow.postMessage start`)
      setTimeout(function () {
        vncFrameDom.contentWindow.postMessage(JSON.stringify({
          focus: 'true'
        }), "*")
      }, 1000)
      logDebug(`vncFrameDom.contentWindow.postMessage end`)
    }
  }
  logDebug(`focusOnFrame end, try_times[${try_times}]`)
}

const focusOnFrame = function (try_times, try_interval_ms) {
  if (try_times <= 0) {
    return
  }

  doFocusOnFrame(try_times)

  const timeout = setTimeout(function (inner_try_times) {
    clearTimeout(timeout)
    focusOnFrame(inner_try_times, try_interval_ms)
  }, try_interval_ms, try_times - 1)
}

const isFirstSelected = ref(true)
const onSelected = function () {
  logDebug(`TerminalVNC onSelected. data[${JSON.stringify(data)}]`)

  if (isFalse(isFirstSelected.value)) {
    // 这个地方只能通知子frame聚焦一次，否则会造成子frame里的其它focus事件被这里的focus事件覆盖掉。
    focusOnFrame(1, 1000)
    return
  }

  if (isTrue(data['vm_enable'])) {
    // virtVNC的问题：
    // https://github.com/wavezhang/virtVNC/issues/4
    // https://github.com/wavezhang/virtVNC/issues/5
    // vm_vnc_url 地址："/hw_experiment/static/src/lib/novnc/vnc.html?encrypt={{abc_svc_vnc_ssl}}&host={{abc_svc_vnc_host}}&port={{abc_svc_vnc_port}}&logging=debug&env_name={{env.name}}&namespace={{namespace}}&action=vm---{{vm_port}}---{{vm_name}}---{{deploy_token}}&resize=scale"
    // vm_rdp_url 地址：https://yxiisybpnuah.www.hi168.com/experiment/environment/vm/link/MjMAYwBteXNxbA==
    if (primal === 'vnc') {
      if (isNotEmpty(data['vnc_domain_host'])) {
        url.value = `/hw_experiment/static/src/lib/novnc/vnc.html?encrypt=${data['abc_svc_vnc_ssl']}&host=${data['vnc_domain_host']}&port=${data['abc_svc_vnc_port']}&logging=debug&resize=remote`
      } else {
        url.value = `${data['vm_rdp_url']}`
      }
    } else {
      url.value = `${data['vm_vnc_url']}`
    }
  } else {
    if (isEmptyOrNone(data['vnc_domain_host'])) {
      url.value = `/hw_experiment/static/src/lib/novnc/vnc.html?encrypt=${data['abc_svc_vnc_ssl']}&host=${data['abc_svc_vnc_host']}&port=${data['abc_svc_vnc_port']}&logging=debug&env_name=${data['env_name']}&namespace=${data['namespace']}&action=pod---${deploy_token}&resize=remote`
    } else {
      url.value = `/hw_experiment/static/src/lib/novnc/vnc.html?encrypt=${data['abc_svc_vnc_ssl']}&host=${data['vnc_domain_host']}&port=${data['abc_svc_vnc_port']}&logging=debug&resize=remote`
    }
  }

  isFirstSelected.value = false
}

const onFocus = function () {
  logDebug(`TerminalVNC onFocus. data[${JSON.stringify(data)}]`)
  focusOnFrame(1, 1000)
}

const iframeStyle = ref('pointer-events: auto')
const setPointerEvents = function (enablePointerEvents = true) {
  if (isTrue(enablePointerEvents)) {
    iframeStyle.value = 'pointer-events: auto'
  } else {
    iframeStyle.value = 'pointer-events: none'
  }
}

function control_panel_vnc() {
  logDebug("control_panel_vnc called!")
  const vnc_frame = document.getElementById(vnc_frame_id)
  if (isNullOrUndefined(vnc_frame)) {
    logDebug('vnc_frame not ready')
    return
  }
  const noVNC_control_bar_anchor = vnc_frame.contentWindow.document.getElementById("noVNC_control_bar_anchor")
  if (isNullOrUndefined(noVNC_control_bar_anchor)) {
    logDebug('noVNC_control_bar_anchor not ready')
    return
  }

  if ('display: none' === noVNC_control_bar_anchor.getAttribute('style')) {
    noVNC_control_bar_anchor.setAttribute('style', '')
  } else {
    noVNC_control_bar_anchor.setAttribute('style', 'display: none')
  }
}

function reloadIframe(frameId) {
  if (isNotEmpty(frameId)) {
    logDebug('reloadIframe')
    const totalTryCount = 20
    const tryIntervalMS = 100
    const reloadIframeHandler = function (tryCount) {
      if (tryCount < 1) {
        logError(`reloadIframeHandler failed after try ${tryCount} times`)
        return;
      }
      const iframe = document.getElementById(frameId)
      if (iframe === null || typeof iframe === 'undefined') {
        setTimeout(reloadIframeHandler, tryIntervalMS, tryCount - 1)
        return
      }
      const originSrc = iframe.src
      logDebug(`old iframe.src[${iframe.src}], tryCount[${tryCount}]`)
      iframe.src = 'about:blank'
      logDebug(`middle iframe.src[${iframe.src}], tryCount[${tryCount}]`)
      const _t = setTimeout(() => {
        iframe.src = originSrc
        logDebug(`new iframe.src[${iframe.src}], tryCount[${tryCount}]`)
        clearTimeout(_t)
      }, 1000)
    }
    reloadIframeHandler(totalTryCount)
  }
}

function control_refresh_vnc() {
  logDebug("control_refresh_vnc called!")
  const vnc_frame = document.getElementById(vnc_frame_id)
  if (isNullOrUndefined(vnc_frame)) {
    logDebug('vnc_frame not ready')
    return
  }
  setTimeout(function () {
    reloadIframe(vnc_frame_id)
  }, 10)
}

const onConfig = function (args) {
  logDebug(`TerminalVNC onConfig. args[${args}]`)
  if (args['event'] === 'control_refresh_vnc') {
    control_refresh_vnc()
  } else if (args['event'] === 'focus') {
    onFocus()
  } else {
    control_panel_vnc()
  }
}

const onUpdate = function () {
  logDebug(`TerminalVNC onUpdate.`)
  // control_refresh_vnc()
}

defineExpose({
  primal,
  data,
  onSelected,
  setPointerEvents,
  onConfig,
  onUpdate,
})

</script>

<style scoped>
.vnc-frame {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}
</style>

